import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Cookies, CookiesProvider } from 'react-cookie';
import { IntercomProvider } from 'react-use-intercom';
import { initSentry } from 'src/startup';
import { Providers } from 'src/providers';
import App from 'src/App';
import { FullPageSpinner } from 'src/components/Spinner';
import 'src/index.css';
import 'src/services/i18n/i18n';

import { INTERCOM_APP_ID } from 'src/env.json';

initSentry();

export const cookies = new Cookies();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Providers>
      <CookiesProvider cookies={cookies}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <Suspense fallback={<FullPageSpinner />}>
            <App />
          </Suspense>
        </IntercomProvider>
      </CookiesProvider>
    </Providers>
  </React.StrictMode>,
);
