import React, { useEffect } from 'react';
import { useVideoPlayerV2 } from 'src/features/video-player/video-player';
import { useMediaSignedUrlsQueryContext } from 'src/features/media/use-media-signed-urls-query-context';
import { useInitializeRequestRevisionVideoPlayerGlobalStore } from 'src/features/revisions/use-request-revision-video-player-global-store';
import { RevisionVideoComments } from 'src/features/revisions/revision-video-comments';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { ComposedVideoPlayer } from 'src/features/video-player/composed-video-player';

export const RevisionVideoPlayer: React.FC = () => {
  const signedUrls = useMediaSignedUrlsQueryContext();
  const revisionsStore = useRequestRevisionsStore();
  const playerContext = useVideoPlayerV2();
  // :)
  const pageStore = useInitializeRequestRevisionVideoPlayerGlobalStore();

  useEffect(() => {
    pageStore.unmount();
  }, [playerContext.src]);

  const media = revisionsStore.selectedRevisionMedia!;

  return (
    <ComposedVideoPlayer
      ratio={16 / 9}
      media={media}
      sprite={signedUrls.data?.sprite}
      poster={media.poster}
      enableCustomTimeFormats={media.hasFps}
      onReady={pageStore.mount}
      onError={pageStore.unmount}
      onTimeFormatChange={pageStore.setTimeFormat}
    >
      <RevisionVideoComments duration={playerContext.meta.durationInSeconds} />
    </ComposedVideoPlayer>
  );
};
