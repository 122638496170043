import React, { useRef } from 'react';
import { useField } from 'formik';
import { ErrorMessage } from 'src/components/Form/ErrorMessage';
import { FormMediaObject, UploadInputProps } from './S3UploadInput.types';
import { useGenerateMultipartUpload } from './useGenerateMultipartUpload';
import { resolveValidationRule } from '../Form.validator';
import { If } from 'src/components/If';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';

const S3UploadInput: React.FC<UploadInputProps> = ({
  name,
  model,
  collection_name,
  minSize,
  maxSize,
  maxItems,
  className,
  children,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [{ value: uploadedFiles }, , { setError, setTouched }] = useField<FormMediaObject[]>(name);

  const { generateUpload, isGenerating } = useGenerateMultipartUpload();

  const { isFailed } = useMediaUploadStore();
  const uploadsFailed = uploadedFiles.some((m) => isFailed(m.id));

  const validate = (files: FileList): void => {
    const errors: any[] = [];

    resolveValidationRule(maxItems, (limit) => `Can't upload more than ${limit} files.`).validate(
      (limit, error) => {
        if (files.length + uploadedFiles.length > limit) {
          errors.push(error);
        }
      },
    );

    resolveValidationRule(
      maxSize,
      (size) => `Can't upload larger than ${size} bytes files.`,
    ).validate((size, error) => {
      if (Array.from(files).find((file) => file.size > size)) {
        errors.push(error);
      }
    });

    resolveValidationRule(
      minSize,
      (size) => `Can't upload smaller than ${size} bytes files.`,
    ).validate((size, error) => {
      if (Array.from(files).find((file) => file.size < size)) {
        errors.push(error);
      }
    });

    if (errors.length) {
      throw new Error(errors.join('\n'));
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);

    const files = e.currentTarget.files!;

    try {
      validate(files);
      await generateUpload(model, files, collection_name);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setTouched(true);
    }
  };

  return (
    <>
      <button
        className={className}
        type={'button'}
        onClick={() => fileInputRef.current?.click()}
        title={'Upload files'}
      >
        <If when={!children}>
          {isGenerating ? (
            <i className={'bx bx-loader-circle spin px-4 fs-4'} />
          ) : (
            <i className={'bx bx-cloud-upload text-light px-4 fs-4'} />
          )}
        </If>

        <If when={!!children}>{children}</If>

        <input
          {...props}
          ref={fileInputRef}
          type={'file'}
          multiple={true}
          className={'d-none'}
          onChange={handleChange}
        />
      </button>
      <ErrorMessage name={name} />
      <If when={uploadsFailed}>
        <span className="text-danger mt-2 invalid-feedback d-block">
          Some files failed to upload, remove them and try again
        </span>
      </If>
    </>
  );
};

export default S3UploadInput;
