import React from 'react';
import { ImageRoundedCover } from 'src/components/Generic';

type Props<T extends Record<string, any> = React.ComponentPropsWithoutRef<'img'>> = {
  src: string;
  as?: string | React.ComponentType<T> | React.ComponentClass<T> | React.FunctionComponent<T>;
} & T;

const ImageMedia: <T extends Record<string, any> = React.ComponentPropsWithoutRef<'img'>>(
  props: Props<T>,
) => JSX.Element = ({ as: Component = 'img', ...props }) => {
  if (Component === 'img') {
    return <ImageRoundedCover {...props} />;
  }

  // eslint-disable-next-line
  // @ts-ignore
  return <Component {...props} />;
};

export { ImageMedia };
