import styled from 'styled-components';
import {
  Badge,
  breakpointUp,
  ChatInputFallback,
  shadeColor,
  tintColor,
} from '@becreatives/becreatives-ui';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { S3UploadInput } from 'src/components/Form/S3UploadInput';

const ChatCardFallback = styled(ChatInputFallback)`
  opacity: 1;
`;

const MessagesWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  margin: 1.5rem 1rem;
  width: 100%;
  min-height: 450px;
`;

const MessagesScrollbar = styled(PerfectScrollbar)`
  .ps__rail-y {
    width: 0.5rem;

    ${breakpointUp.md} {
      border-radius: 1rem !important;
      background: ${({ theme }) => tintColor(theme.color.light, 20)} !important;

      :hover {
        background: ${({ theme }) => theme.color.light} !important;
      }
    }

    .ps__thumb-y {
      width: 0.4rem;
      background: ${({ theme }) => theme.utils.colors.gray};
      border-radius: 1rem;
    }
  }

  overflow-y: auto;
  padding-right: 0.8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const VersionBadge = styled(Badge)`
  color: ${({ theme }) => theme.color.dark};
  background-color: ${({ theme }) => shadeColor(theme.color.light, 10)} !important;
  font-size: 0.75rem;
  padding: 0.37rem 1rem;
  font-weight: 400;
  align-self: center;
  margin-bottom: 1rem;

  ${breakpointUp.md} {
    font-size: 0.9rem;
    padding: 0.63rem 1.5rem;
  }
`;

const UploadButton = styled(S3UploadInput)`
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
  display: flex;
  align-items: center;
`;

const UploadingFilesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
  align-items: start;
  justify-content: start;
`;

export {
  ChatCardFallback,
  MessagesScrollbar,
  VersionBadge,
  MessagesWrapper,
  UploadButton,
  UploadingFilesRow,
};
