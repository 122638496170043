class JsonWrapper {
  public static toJson: <T = unknown>(json: string | null | undefined, fallback?: T) => T = (
    json,
    fallback = null as any,
  ) => {
    if (!json) {
      return fallback;
    }

    try {
      return JSON.parse(json);
    } catch (e: any) {
      return fallback;
    }
  };

  public static toString = (json: any): string => {
    return JSON.stringify(json);
  };
}

export { JsonWrapper };
