import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/lib/utils';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { If } from 'src/components/If';
import { FileIcon } from 'src/components/FileIcon';
import { formatSize } from 'src/helpers/file';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Button } from 'src/components/ui/button';
import { Href } from 'src/components/ui/href';
import { Download } from 'lucide-react';

export const ComposedMediaCard: React.FC<
  React.ComponentProps<typeof Card> & {
    fileName: string;
    fileSize: number;
    mimeType: string;
    download?: string;
    thumbnail?: string;
    actions?: React.ReactNode;
  }
> = ({ fileName, fileSize, mimeType, download, className, thumbnail, actions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Card className={cn(className)} {...props}>
      <CardContent className={'tw-w-full tw-items-stretch tw-p-0'}>
        <AspectRatio ratio={16 / 9}>
          <div className={'tw-relative tw-h-full tw-w-full'}>
            <div className={'tw-absolute tw-inset-0'}>
              <If
                when={!!thumbnail}
                else={
                  <div
                    className={
                      'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-t-md tw-bg-indigo-200'
                    }
                  >
                    <FileIcon mimeType={mimeType} size={36} className={'tw-text-dark-800'} />
                  </div>
                }
              >
                <img
                  className={'tw-h-full tw-w-full tw-rounded-t-lg tw-object-cover'}
                  src={thumbnail}
                  alt={fileName}
                  title={fileName}
                />
              </If>
            </div>

            <div className={'tw-absolute tw-inset-0 tw-z-10 tw-flex tw-items-stretch tw-p-2'}>
              {/*<Badge className={'tw-self-start'} variant={'default'}>*/}
              {/*  Overlay*/}
              {/*</Badge>*/}
            </div>
          </div>
        </AspectRatio>
      </CardContent>
      <CardHeader className={'tw-gap-2 tw-px-3 tw-pb-0 tw-pt-3'}>
        <CardTitle
          className={
            'tw-line-clamp-2 tw-h-10 tw-w-full tw-break-all tw-text-base tw-font-bold tw-leading-normal tw-text-dark'
          }
        >
          {fileName}
        </CardTitle>
        <CardDescription className={'tw-line-clamp-1 tw-text-base'}>
          {mimeType} | {formatSize(fileSize)}
        </CardDescription>
      </CardHeader>
      <CardFooter className={'tw-justify-end tw-px-3 tw-pb-3 tw-pt-0'}>
        {actions}

        <If when={!!download}>
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <Button size={'icon'} variant={'ghost'} asChild>
                  <Href variant={'unset'} href={download} target={'_blank'} rel={'noreferrer'}>
                    <Download size={20} />
                  </Href>
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('Download')}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </If>
      </CardFooter>
    </Card>
  );
};
