import React, { createContext } from 'react';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { render } from 'src/lib/utils';
import { authQueries } from 'src/api/queries';
import { Api } from 'src/api';
import { UseQueryOptionsObject } from 'src/api/queries/queries.utils';

type ApiResponse = Awaited<ReturnType<typeof Api.company.fetchCompany>>;
type CompanyQueryContextType = ReturnType<typeof useMakeQuery<ApiResponse>>;
const CompanyQueryContext = createContext<CompanyQueryContextType | undefined>(undefined);

const CompanyQueryProvider: React.FC<{
  id: string;
  children: React.ReactNode | ((query: CompanyQueryContextType) => React.ReactNode);
  config?: UseQueryOptionsObject<ApiResponse>;
}> = ({ id, children, config = {} }) => {
  const query = useMakeQuery([authQueries.company, id], () => Api.company.fetchCompany(id), {
    suspense: true,
    retry: false,
    staleTime: 60 * 1000,
    ...config,
  });

  return (
    <CompanyQueryContext.Provider value={query}>
      {render(children, query)}
    </CompanyQueryContext.Provider>
  );
};

const useCompanyQueryContext = () => {
  const context = React.useContext(CompanyQueryContext);

  if (!context) {
    throw new Error('useCompanyQuery must be used within a CompanyQuery');
  }

  return context;
};

const useCompanyQueryContextData = () => {
  const context = useCompanyQueryContext();

  if (!context) {
    throw new Error('useCompanyQuery must be used within a CompanyQuery');
  }

  return context.data;
};

export { CompanyQueryProvider, useCompanyQueryContext, useCompanyQueryContextData };
