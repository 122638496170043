import { RequestOutcome } from 'src/lib/services/api/request-api/request-client';
import { ApiQueryObject, mergeQueryParams } from 'src/lib/services/api-query-params';
import { useQueryClient } from '@tanstack/react-query';
import { authQueries } from 'src/api/queries';
import { requestClient, requestOutcomeStatus } from 'src/lib/services/api/request-api';
import {
  archivedOutcomesFilters,
  deliverableOutcomesFilters,
  pendingOutcomesFilters,
  unfinishedOutcomesFilters,
} from 'src/features/requests/request-outcome/filter-queries';
import { reactQueryClient } from 'src/providers/ReactQueryClientProvider';

type ApiResponse = Awaited<ReturnType<typeof requestClient.outcomes>>;

const statusOrder: Record<RequestOutcome['status'], number> = {
  [requestOutcomeStatus.currentlyEditing]: 1,
  [requestOutcomeStatus.delivered]: 2,
  [requestOutcomeStatus.complete]: 3,
};

const sortOutcomes = (a: RequestOutcome, b: RequestOutcome) => {
  // Compare by status first
  const statusComparison = statusOrder[a.status] - statusOrder[b.status];
  if (!Number.isNaN(statusComparison) && statusComparison !== 0) {
    return statusComparison;
  }

  // If statuses are equal, compare by 'sort'
  return a.sort - b.sort;
};

type RequestOutcomesQueryMutator = {
  setData: (
    updater: Parameters<typeof reactQueryClient.setQueryData<ApiResponse>>[1],
  ) => ReturnType<typeof reactQueryClient.setQueryData<ApiResponse>>;
  invalidate: (
    config?: Parameters<typeof reactQueryClient.invalidateQueries>[1],
  ) => ReturnType<typeof reactQueryClient.invalidateQueries>;
  push: (outcome: RequestOutcome) => void;
  remove: (outcome: string | null | undefined) => void;
};

export const useRequestOutcomesQueryMutationDecorator = (
  requestId: string,
  filters?: ApiQueryObject,
): RequestOutcomesQueryMutator => {
  const client = useQueryClient();
  const queryKey = [authQueries.requestOutcomes, requestId, filters];

  const push = (outcome: RequestOutcome): void => {
    client.setQueryData<ApiResponse>(queryKey, (prev) => {
      if (!prev) {
        return prev;
      }

      const next = { ...prev };
      next.items.push(outcome);
      next.items.sort(sortOutcomes);
      next.meta.total += 1;

      return next;
    });
  };

  const remove = (outcome: string | null | undefined): void => {
    client.setQueryData<ApiResponse>(queryKey, (prev) => {
      if (!prev) {
        return prev;
      }

      const next = { ...prev };
      next.items = next.items.filter((item) => item.id !== outcome);
      next.meta.total -= 1;

      return next;
    });
  };

  return {
    setData: (updater: Parameters<typeof client.setQueryData<ApiResponse>>[1]) =>
      client.setQueryData(queryKey, updater),
    invalidate: (config?: Parameters<typeof client.invalidateQueries>[1]) =>
      client.invalidateQueries({ ...(config ?? {}), queryKey }),
    push,
    remove,
  };
};

export const useRequestPendingOutcomesQueryMutator = (id: string, filters?: ApiQueryObject) =>
  useRequestOutcomesQueryMutationDecorator(
    id,
    mergeQueryParams(pendingOutcomesFilters, filters ?? {}),
  );

export const useRequestDeliverableOutcomesQueryMutator = (id: string, filters?: ApiQueryObject) =>
  useRequestOutcomesQueryMutationDecorator(
    id,
    mergeQueryParams(deliverableOutcomesFilters, filters ?? {}),
  );

export const useRequestArchivedOutcomesQueryMutator = (id: string, filters?: ApiQueryObject) =>
  useRequestOutcomesQueryMutationDecorator(
    id,
    mergeQueryParams(archivedOutcomesFilters, filters ?? {}),
  );

export const useRequestUnfinishedOutcomesQueryMutator = (id: string, filters?: ApiQueryObject) =>
  useRequestOutcomesQueryMutationDecorator(
    id,
    mergeQueryParams(unfinishedOutcomesFilters, filters ?? {}),
  );
