import { createQueryParams } from 'src/lib/services/api-query-params';
import { requestOutcomeStatus } from 'src/lib/services/api/request-api';
import omit from 'lodash/omit';

export const pendingOutcomesFilters = createQueryParams({
  filters: [
    {
      id: 'status',
      value: requestOutcomeStatus.queued,
    },
  ],
  limit: 100,
});

export const archivedOutcomesFilters = createQueryParams({
  filters: [
    {
      id: 'status',
      value: requestOutcomeStatus.archived,
    },
  ],
  limit: 100,
});

export const deliverableOutcomesFilters = createQueryParams({
  filters: [
    {
      id: 'status',
      value: [
        requestOutcomeStatus.currentlyEditing,
        requestOutcomeStatus.delivered,
        requestOutcomeStatus.complete,
      ],
    },
  ],
  limit: 100,
});

export const unfinishedOutcomesFilters = createQueryParams({
  filters: [
    {
      id: 'status',
      value: Object.values(
        omit(requestOutcomeStatus, [requestOutcomeStatus.archived, requestOutcomeStatus.complete]),
      ),
    },
  ],
  limit: 1,
});
