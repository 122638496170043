import { default as Types } from './types.json';

class FileTypes {
  private static readonly data: { [key: string]: string | string[] } = Types;

  public static readonly mimeToExtension = (mimes_types: string[]): string[] => {
    const results: string[] = [];

    mimes_types.forEach((mime_type) => {
      if (!FileTypes.data[mime_type]) {
        results.push(mime_type);
      } else {
        if (Array.isArray(FileTypes.data[mime_type])) {
          results.push(...FileTypes.data[mime_type]);
        } else {
          results.push(FileTypes.data[mime_type] as string);
        }
      }
    });

    return FileTypes.filterUniques(results);
  };

  public static readonly getExtension = (fileName: string): string | undefined => {
    const parts: string[] = fileName.split('.');
    if (parts.length) {
      return parts[parts.length - 1];
    }

    return undefined;
  };

  private static readonly filterUniques = (arr: string[]): string[] => {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  };
}

export default FileTypes;
