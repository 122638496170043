import { Http } from 'src/api/Http';

class Client {
  http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  buildUrl = (url: string, params: { [key: string]: string }): string => {
    for (const key in params) {
      const value = params[key];
      url = url.replace('{' + key + '}', value);
    }

    return url;
  };
}

export default Client;
