import { ArrayParam, JsonParam, NumberParam, StringParam } from 'use-query-params';
import { ApiQueryObject } from './api-query-params.types';

export const makeApiQueryParamsConfig = () => ({
  page: NumberParam,
  limit: NumberParam,
  search: StringParam,
  sorts: JsonParam,
  filters: JsonParam,
  include: ArrayParam,
});

export type ApiQueryParamsConfig = ReturnType<typeof makeApiQueryParamsConfig>;

const resolveQueryParams = (id?: string) => {
  return id
    ? {
        [id]: JsonParam,
      }
    : makeApiQueryParamsConfig();
};

const createQueryParams = <Params extends ApiQueryObject>(params: Params): Params => params;

const mergeQueryParams = <Params extends ApiQueryObject, Values extends ApiQueryObject>(
  params: Params,
  values: Values,
): Params & Values => {
  const newParams = { ...params };
  if (values.search) {
    newParams.search = values.search;
  }

  if (values.page) {
    newParams.page = values.page;
  }

  if (values.limit) {
    newParams.limit = values.limit;
  }

  if (values.sorts?.length) {
    newParams.sorts = (() => {
      const ids = values.sorts.map((sort) => sort.id);
      const updatedSorts = newParams.sorts?.filter((sort) => !ids.includes(sort.id)) || [];
      return [...updatedSorts, ...values.sorts];
    })();
  }

  if (values.filters?.length) {
    newParams.filters = (() => {
      const ids = values.filters.map((filter) => filter.id);
      const updatedFilters = newParams.filters?.filter((filter) => !ids.includes(filter.id)) || [];
      return [...updatedFilters, ...values.filters];
    })();
  }

  if (values.include?.length) {
    newParams.include = (() => {
      const newIncludes = values.include.map((id) => id);
      const updatedIncludes = newParams.include?.filter((id) => !newIncludes.includes(id)) || [];
      return [...updatedIncludes, ...newIncludes];
    })();
  }

  return newParams as Params & Values;
};

export { resolveQueryParams, createQueryParams, mergeQueryParams };
