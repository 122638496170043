import { SetStateAction, useMemo } from 'react';
import { useQueryParams } from 'use-query-params';
import {
  ApiQueryObject,
  FilterParam,
  PaginationObject,
  UseApiUrlQueryParamsFn,
} from './api-query-params.types';
import { resolveQueryParams } from './api-query-params.utils';
import { useDebouncedAction } from 'src/lib/utils';

const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_PAGE_SIZE = 10;

const useApiQueryParams: UseApiUrlQueryParamsFn = (id) => {
  const [urlQuery, setQuery] = useQueryParams(resolveQueryParams(id), {
    includeAllParams: false,
    updateType: 'replaceIn',
  });

  const filters = useMemo<ApiQueryObject>(() => {
    return ((id ? (urlQuery as Record<string, ApiQueryObject>)[id] : urlQuery) ??
      {}) as ApiQueryObject;
  }, [urlQuery, id]);

  const setFilters = (updater: SetStateAction<ApiQueryObject>) => {
    setQuery((prev) => {
      if (!id) {
        return updater instanceof Function ? updater(prev as ApiQueryObject) : updater;
      }

      if (!(id in prev)) {
        return { [id]: {} };
      }

      const tableFilters = (prev as Record<string, ApiQueryObject>)[id] ?? {};
      const value = updater instanceof Function ? updater(tableFilters) : updater;

      return { [id]: value };
    });
  };

  const debounceSetQuery = useDebouncedAction(setFilters);

  const setSearch = (search: string | undefined) => {
    debounceSetQuery((prev) => ({ ...prev, search }));
  };

  const setPage = (page: number) => {
    setFilters((prev) => ({ ...prev, page }));
  };

  const setLimit = (limit: number) => {
    setFilters((prev) => ({ ...prev, limit }));
  };

  const setPagination = (pagination: PaginationObject) => {
    setFilters((prev) => ({ ...prev, ...pagination }));
  };

  const setFilter = (filter: FilterParam) => {
    setFilters((prev) => {
      const filters = (prev.filters ?? []).filter((f) => f.id !== filter.id);
      const result = {
        ...prev,
        filters,
        page: DEFAULT_PAGE_INDEX,
      };

      result.filters.push(filter);

      return result;
    });
  };

  const removeFilter = (filterId: FilterParam['id']) => {
    setFilters((prev) => {
      const filters = prev.filters?.filter((f) => f.id !== filterId);
      return {
        ...prev,
        filters: filters?.length ? filters : undefined,
        page: DEFAULT_PAGE_INDEX,
      };
    });
  };

  const getFilter = (id: FilterParam['id']) => {
    return filters.filters?.find((f) => f.id === id)?.value;
  };

  const clearFilters = () => {
    setFilters((prev) => {
      return { ...prev, filters: undefined, search: undefined, page: undefined, sorts: undefined };
    });
  };

  const setInclude = (...args: string[]) => {
    setFilters((prev) => {
      return { ...prev, include: args };
    });
  };

  const reset = () => {
    setFilters({});
  };

  return {
    filters: filters ?? {},
    setFilters,
    setSearch,
    setPage,
    setLimit,
    setPagination,
    setFilter,
    removeFilter,
    getFilter,
    clearFilters,
    setInclude,
    reset,
  };
};

export { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, useApiQueryParams };
