import React from 'react';

type Colors =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  color?: Colors;
}

const Spinner: React.FC<Props> = ({ color = 'primary', ...props }) => {
  const classNames = ['spinner-border', `text-${color}`];
  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div {...props} className={classNames.join(' ')}>
      <span className={'sr-only'}>Loading...</span>
    </div>
  );
};

export type { Props as SpinnerProps };
export default Spinner;
