import styled from 'styled-components';
import { breakpointDown, Popover } from '@becreatives/becreatives-ui';

const colors = ['#999999', '#FFD465', '#FF5A3C', '#FF65E6', '#A965FF', '#6865FF'];

const TeamFormPopover = styled(Popover)`
  .popover {
    --bs-popover-max-width: 720px;
    overflow: auto !important;
    max-height: 400px;

    ${breakpointDown.md} {
      --bs-popover-max-width: 100%;
      transform: none !important;
      inset: auto 0 0 0 !important;
      max-height: 80vh;
    }

    min-width: 40%;

    z-index: 1100 !important;
  }
`;

export { colors, TeamFormPopover };
