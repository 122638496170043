import { Client } from 'src/api/Client';

class CompanyPersonnelClient extends Client {
  public readonly route = {
    delete: `${this.http.baseUrl}/company-personnel/{model}/delete`,
    restore: `${this.http.baseUrl}/company-personnel/{model}/restore`,
    permissions: `${this.http.baseUrl}/company-personnel/{model}/permissions`,
  };

  permissions = (model: string): Promise<void> =>
    this.http.get(this.buildUrl(this.route.permissions, { model }));

  delete = (model: string): Promise<void> =>
    this.http.delete(this.buildUrl(this.route.delete, { model }));

  restore = (model: string): Promise<void> =>
    this.http.post(this.buildUrl(this.route.restore, { model }));
}

export { CompanyPersonnelClient };
