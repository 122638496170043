import React, { useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TaskResponse, taskStatus, UpdateTaskRequest } from 'src/api/services/TaskClient';
import { FieldComplex } from 'src/components/Form/FieldComplex';
import { PluginTextArea } from 'src/components/Form/PluginTextArea';
import { ReactSelectFormInput } from 'src/components/ReactSelectInput';
import { useTask } from '../../../models';
import { extractFormErrors } from '../../Form/Form.utils';
import { Button } from '@becreatives/becreatives-ui';

type Props = {
  task: TaskResponse;
  onSubmit?: (
    values: UpdateTaskRequest,
    helpers: FormikHelpers<UpdateTaskRequest>,
  ) => Promise<void>;
};

const EditTaskForm: React.FC<Props> = ({ task, onSubmit }) => {
  const { t } = useTranslation('attributes/task');

  const { update } = useTask(task.id, { enabled: false });

  const validationSchema = useCallback(() => {
    return Yup.object().shape({
      title: Yup.string().required(),
      description: Yup.string().nullable(),
      status: Yup.string().nullable().required(),
    });
  }, []);

  return (
    <Formik<UpdateTaskRequest>
      validationSchema={validationSchema}
      initialValues={{
        title: task.title,
        description: task.description,
        status: task.status,
      }}
      onSubmit={async (values, helpers) => {
        try {
          await update(values);
          onSubmit?.(values, helpers);
        } catch (e: any) {
          helpers.setErrors(extractFormErrors(e) ?? {});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FieldComplex name={'title'} label={t('title')} type={'text'} required={true} />

          <FieldComplex
            name={'description'}
            label={t('description')}
            rows={6}
            as={PluginTextArea}
          />

          <FieldComplex
            name={'status'}
            label={t('status')}
            as={ReactSelectFormInput}
            isClearable={false}
            options={[
              {
                value: taskStatus.stale,
                label: t(`status.${taskStatus.stale}`, { ns: 'models/task' }),
              },
              {
                value: taskStatus.done,
                label: t(`status.${taskStatus.done}`, { ns: 'models/task' }),
              },
            ]}
            className={''}
            required={true}
          />

          <div className={'d-flex'}>
            <Button
              className={'ms-auto w-xl flex-grow-1 flex-md-grow-0'}
              disabled={isSubmitting}
              type={'submit'}
            >
              {t('save', { ns: 'actions' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { EditTaskForm };
