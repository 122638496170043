import React from 'react';
import { LinkProps as RouterLinkProps, Link as RouterLink, useLocation } from 'react-router-dom';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from 'src/lib/utils';

export const linkVariants = cva(
  'tw-cursor-pointer tw-transition-colors focus-visible:!tw-underline focus-visible:tw-underline-offset-2',
  {
    variants: {
      variant: {
        default: 'tw-text-primary hover:tw-text-primary/70',
        secondary: 'tw-text-secondary hover:tw-text-secondary/70',
        muted: 'tw-text-muted hover:tw-text-muted-foreground',
        sky: 'tw-text-blue',
        brand: 'tw-text-brand hover:tw-text-brand/70',
        unset: '',
      },
      underline: {
        unset: '',
        solid: '!tw-underline tw-underline-offset-2',
        hover: 'tw-underline-offset-2 hover:!tw-underline',
      },
    },
    defaultVariants: {
      variant: 'default',
      underline: 'unset',
    },
  },
);

export type LinkProps = RouterLinkProps & VariantProps<typeof linkVariants>;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, variant, underline, to, ...props }, ref) => {
    const { pathname } = useLocation();

    // TODO: add compatability to object type
    const route = typeof to === 'string' && to === pathname ? '#' : to;

    return (
      <RouterLink
        className={cn(
          linkVariants({
            variant,
            underline,
            className,
          }),
        )}
        ref={ref}
        {...props}
        to={route}
      />
    );
  },
);
Link.displayName = 'Link';

export { Link };
