import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledRoleBadge } from './RoleBadge.styled';
import { BadgeProps } from '@becreatives/becreatives-ui';
import { Role } from 'src/api/services/RoleClient';

type Props = BadgeProps & {
  role: Role;
};

// todo: update role badge
const RoleBadge: React.FC<Props> = ({ role, ...props }) => {
  const { t } = useTranslation('models/role');

  return (
    <StyledRoleBadge role={role} {...props}>
      {t(role)}
    </StyledRoleBadge>
  );
};

export { RoleBadge };
