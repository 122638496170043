import { str } from './str';

const get: <T = unknown>(key: string, json?: boolean) => T | null = (key, json = false) => {
  try {
    const data = localStorage.getItem(key);
    if (!data) {
      return null;
    }

    return json ? str.toJson(data) : (data as never);
  } catch (e) {
    console.error('Failed to access local storage', e);
  }

  return null;
};

const set = (key: string, value: unknown): void => {
  try {
    const data = typeof value === 'string' ? value : JSON.stringify(value);

    localStorage.setItem(key, data);
  } catch (e) {
    console.error('Failed to access local storage', e);
  }
};

const remove = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error('Failed to access local storage', e);
  }
};

const storage = {
  get,
  set,
  remove,
} as const;

export { storage };
