import { create } from 'zustand';

interface FileMetadata {
  id: string;
  name: string;
  uploadId?: string;
}

interface UploadStatus {
  isUploading: boolean;
  failed?: boolean;
  abortController?: AbortController;
}

interface UploadDetails {
  progress: number;
  remainingTime: number;
  uploadSpeed?: number;
}

interface MediaUploadStore {
  uploads: { [key: string]: FileMetadata };
  details: { [key: string]: UploadDetails };
  status: { [key: string]: UploadStatus };
  addUpload: (file: FileMetadata) => void;
  isUploading: (fileId: string) => boolean;
  getUpload: (fileId: string) => FileMetadata | undefined;
  getDetails: (fileId: string) => UploadDetails | undefined;
  getStatus: (fileId: string) => UploadStatus | undefined;
  updateProgress: (
    fileId: string,
    progress: number,
    remainingTime: number,
    uploadSpeed?: number,
  ) => void;
  finishUpload: (fileId: string) => void;
  removeUpload: (fileId: string) => void;
  isFailed: (fileId: string) => boolean;
  setFailed: (fileId: string) => void;
  abort: (fileId: string) => void;
}

const useMediaUploadStore = create<MediaUploadStore>((set, get) => ({
  uploads: {},
  details: {},
  status: {},

  isUploading: (mediaId) => {
    return !!get().status[mediaId]?.isUploading;
  },

  getUpload: (mediaId: string) => {
    return get().uploads[mediaId];
  },

  getDetails: (mediaId: string) => {
    return get().details[mediaId];
  },

  getStatus: (mediaId: string) => {
    return get().status[mediaId];
  },

  isFailed: (mediaId: string) => {
    return !!get().status[mediaId]?.failed;
  },

  addUpload: (file) => {
    set((state) => ({
      uploads: {
        ...state.uploads,
        [file.id]: file,
      },
      status: {
        ...state.status,
        [file.id]: {
          isUploading: true,
          abortController: new AbortController(),
        },
      },
      details: {
        ...state.details,
        [file.id]: {
          progress: 0,
          remainingTime: 0,
          uploadSpeed: 0,
        },
      },
    }));
  },

  updateProgress: (fileId, progress, remainingTime, uploadSpeed) => {
    set((state) => ({
      details: {
        ...state.details,
        [fileId]: { ...state.details[fileId], progress, remainingTime, uploadSpeed },
      },
    }));
  },

  finishUpload: (fileId) => {
    set((state) => ({
      details: {
        ...state.details,
        [fileId]: {
          ...state.details[fileId],
          progress: 100,
          remainingTime: 0,
          uploadSpeed: 0,
        },
      },
      status: {
        ...state.status,
        [fileId]: {
          ...state.status[fileId],
          isUploading: false,
        },
      },
    }));
  },

  setFailed: (fileId) => {
    set((state) => ({
      status: {
        ...state.status,
        [fileId]: {
          ...state.status[fileId],
          failed: true,
        },
      },
    }));
  },

  removeUpload: (fileId) => {
    set((state) => {
      const uploads = { ...state.uploads };
      delete uploads[fileId];

      const details = { ...state.details };
      delete details[fileId];

      const status = { ...state.status };
      delete status[fileId];

      return { uploads, details, status };
    });
  },

  abort: (fileId) => {
    get().status[fileId]?.abortController?.abort();
  },
}));

export { useMediaUploadStore };
export type { FileMetadata, UploadDetails, UploadStatus };
