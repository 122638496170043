import { ValueOfObject } from 'src/types/generics';

const roles = {
  admin: 'admin',
  customer: 'customer',
  editor: 'editor',
  teamLead: 'team_lead',
  substituteTeamLead: 'substitute_team_lead',
} as const;

type Role = ValueOfObject<typeof roles>;

type RoleResponse = {
  id: string;
  name: Role;
  created_at: string;
};

export type { Role, RoleResponse };
export { roles };
