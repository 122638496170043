import styled from 'styled-components';

const OrderNotesContentWrapper = styled.div`
  padding: 2rem;
`;

const OrderNotesActionsSection = styled.div`
  margin-bottom: 1.5rem;
`;

const OrderNotesItemsSection = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;

const OrderNotesItemsBlock = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`;

export {
  OrderNotesContentWrapper,
  OrderNotesActionsSection,
  OrderNotesItemsSection,
  OrderNotesItemsBlock,
};
