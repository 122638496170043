import React, { useState } from 'react';
import Select, { Props as ReactSelectProps } from 'react-select';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { scopedQueries, useMakeInfiniteQuery } from 'src/api/queries';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { InfiniteData, QueryFunctionContext } from '@tanstack/query-core/src/types';
import { configReactSelectProps } from 'src/components/ReactSelectInput/ReactSelectInput.utils';
import { ReactSelectAsyncMenuList } from 'src/components/ui/react-select/components';
import { isPaginatedResponse } from 'src/api/Client';
import { NoOptionsMessage } from './no-options-message';
import { useQueryClient } from '@tanstack/react-query';
import { useReactSelectValue } from 'src/components/ReactSelectInput/useReactSelectValue';
import { getUniqueOptions } from './react-select.utils';

type Props<TQueryFnData> = Omit<
  ReactSelectProps<OptionObject>,
  'options' | 'filterOption' | 'value' | 'onChange'
> & {
  options: (data?: TQueryFnData[]) => OptionObject[];
  queryOptions: Omit<UseInfiniteQueryOptions<TQueryFnData>, 'queryFn'> & {
    queryFn: (
      context: QueryFunctionContext<readonly any[]>,
      filters: ApiQueryObject,
    ) => Promise<TQueryFnData> | TQueryFnData;
  };
  filters?: ApiQueryObject;
  onSearch?: (search: string) => void;
  value?: any;
  setValue: (value: any) => void;
  isError?: boolean;
};

const AsyncReactSelect = <TQueryFnData,>({
  queryOptions,
  onSearch,
  options: getOptions,
  filters = {},
  value,
  setValue,
  ...props
}: Props<TQueryFnData>): JSX.Element => {
  const queryClient = useQueryClient();

  const getPagesFromClient = () => {
    return queryClient
      .getQueriesData<InfiniteData<TQueryFnData>>({
        queryKey: [...queryOptions.queryKey!, scopedQueries.infinite],
        exact: false,
      })
      .flatMap(([, pages]) => pages?.pages ?? []);
  };

  const makeOptions = () => getUniqueOptions(getOptions(getPagesFromClient()));

  const [options, setOptions] = useState<OptionObject[]>(makeOptions());

  const { hasNextPage, fetchNextPage, isFetchingNextPage, isInitialLoading } =
    useMakeInfiniteQuery<TQueryFnData>(
      {
        ...queryOptions,
        queryFn: (context) =>
          queryOptions.queryFn(context, {
            ...filters,
            page: context.pageParam,
          }),
        getNextPageParam: (lastPage) => {
          if (!isPaginatedResponse(lastPage)) {
            return queryOptions.getNextPageParam;
          }

          return lastPage.meta.current_page < lastPage.meta.last_page
            ? lastPage.meta.current_page // current_page comes as a number of the next page from backend
            : undefined;
        },
        onSuccess: () => {
          setOptions(makeOptions());
        },
      },
      filters,
    );

  const valueProps = useReactSelectValue(
    {
      value,
      setValue,
    },
    { ...props, options, onChange: undefined },
  );

  props = configReactSelectProps(props);

  props.components!['MenuList'] = ReactSelectAsyncMenuList;

  if (isInitialLoading) {
    props.components!['NoOptionsMessage'] = NoOptionsMessage.LoadingOptions;
  }

  return (
    <Select
      {...props}
      {...valueProps}
      onMenuScrollToBottom={async () => {
        if (!hasNextPage || isFetchingNextPage) {
          return;
        }

        await fetchNextPage();
      }}
      options={options}
      onInputChange={onSearch}
    />
  );
};

export type { Props as AsyncReactSelectProps };
export { AsyncReactSelect };
