const acronym = (string: string | undefined | null): string => {
  if (!string) {
    return '';
  }

  let name = '';
  const split = string.split(' ');
  split.forEach((word) => {
    name += word.charAt(0);
  });
  return name;
};

const toJson: <T = unknown>(json: string | null | undefined, fallback?: T) => T = (
  json,
  fallback,
) => {
  if (!json) {
    return fallback;
  }

  try {
    return JSON.parse(json);
  } catch (e: unknown) {
    return fallback;
  }
};

const slugify = (string: string): string => {
  return string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

const capitalizeFirst = (string: string): string => {
  return string[0].toUpperCase() + string.slice(1);
};

const pad = (str: string | number, n: number): string => {
  const parsed = `${str}`;
  const i = parsed.indexOf('.');

  if (!i) {
    return parsed;
  }

  return parsed.substring(0, i + 1 + n);
};

const embedUrlsAsLinks = (str: string): string => {
  const rgx = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.?[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/g,
  );

  return str.replace(rgx, (url) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
};

const str = {
  acronym,
  toJson,
  slugify,
  capitalizeFirst,
  pad,
  embedUrlsAsLinks,
};

export { str };
