import { ValueOfObject } from 'src/types/generics';

const authQueries = {
  profile: 'profile',
  profileSocialAccounts: 'profileSocialAccounts',
  assignment: 'assignment',
  assignments: 'assignments',
  subscription: 'subscription',
  subscriptions: 'subscriptions',
  company: 'company',
  companies: 'companies',
  companiesOptions: 'companiesOptions',
  companyPersonnel: 'companyPersonnel',
  companyBrands: 'companyBrands',
  request: 'request',
  requests: 'requests',
  requestOutcomes: 'requestOutcomes',
  requestOutcomesCount: 'requestOutcomesCount',
  requestOutcome: 'requestOutcome',
  requestCounts: 'requestsCounts',
  brand: 'brand',
  users: 'users',
  user: 'user',
  notifications: 'notifications',
  unreadNotifications: 'unreadNotifications',
  task: 'task',
  team: 'team',
  teams: 'teams',
  teamMember: 'teamMember',
  teamMembers: 'teamMembers',
  teamMemberAssignments: 'teamMemberAssignments',
  airtableImportables: 'airtableImportables',
  airtableBase: 'airtableBase',
  airtableBases: 'airtableBases',
  airtableTable: 'airtableTable',
  airtableTables: 'airtableTables',
  airtableModelTemplates: 'airtableModelTemplates',
  videoTemplate: 'videoTemplate',
  videoTemplates: 'videoTemplates',
  videoTemplateExamples: 'videoTemplateExamples',
  videoTemplateExample: 'videoTemplateExample',
} as const;

type AuthQueries = ValueOfObject<typeof globalQueries>;

const scopedQueries = {
  table: 'tableQuery',
  infinite: 'infiniteQuery',
} as const;

type ScopedQueries = ValueOfObject<typeof scopedQueries>;

const globalQueries = {
  stripeProducts: 'stripe_products',
  media: 'media',
  mediaSignedUrl: 'mediaSignedUrl',
  sharedMedia: 'sharedMedia',
} as const;

type GlobalQueries = ValueOfObject<typeof globalQueries>;

export { authQueries, scopedQueries, globalQueries };
export type { AuthQueries, ScopedQueries, GlobalQueries };
