import React from 'react';
import { DeletableMinimalMediaCard } from 'src/components/MediaCard/MinimalMediaCard';
import { If } from 'src/components/If';
import { ModalButton } from 'src/components/Button';
import { EditRevisionFormModal } from './edit-revision-form-modal';
import { cn } from 'src/lib/utils';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { Separator } from 'src/components/ui/separator';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

const RevisionMediaList: React.FC<Props> = (props) => {
  const request = useDecoratedRequestContext();

  const { setSelectedRevisionMedia, deleteMediaMutation, selectedRevisionMedia, selectedRevision } =
    useRequestRevisionsStore();

  const isEditable =
    (request.isCurrentlyEditing || request.isDelivered) && selectedRevision?.isLatest;

  const defaultMedia = selectedRevision?.media.filter((m) => m.collection_name === 'default') ?? [];
  const projectFilesMedia =
    selectedRevision?.media.filter((m) => m.collection_name === 'project_files') ?? [];
  const transcriptMedia =
    selectedRevision?.media.filter((m) => m.collection_name === 'transcript') ?? [];
  const thumbnailsMedia =
    selectedRevision?.media.filter((m) => m.collection_name === 'thumbnails') ?? [];
  const captionsMedia =
    selectedRevision?.media.filter((m) => m.collection_name === 'captions') ?? [];
  const resizesMedia = selectedRevision?.media.filter((m) => m.collection_name === 'resizes') ?? [];

  return (
    <div
      {...props}
      className={cn(
        'tw-flex tw-h-36 tw-min-w-28 tw-rounded xl:tw-h-auto xl:tw-flex-col',
        props.className,
      )}
    >
      <If when={isEditable}>
        <ModalButton
          className={'btn btn-primary px-2 py-0 tw-self-stretch xl:tw-hidden'}
          modal={EditRevisionFormModal}
          modalProps={{
            backdrop: 'static',
          }}
        >
          <i className={'bx bx-cloud-upload fs-5'} />
        </ModalButton>
      </If>

      <div
        className={
          'tw-relative tw-flex tw-w-full tw-flex-1 tw-gap-3 tw-rounded xl:tw-w-28 xl:tw-flex-col'
        }
      >
        <div
          className={
            'tw-absolute tw-inset-0 tw-flex tw-items-end tw-gap-2 tw-overflow-x-auto tw-px-2 tw-py-2 xl:tw-flex-col xl:tw-items-center xl:tw-overflow-y-auto xl:tw-px-0'
          }
        >
          {defaultMedia.map((m) => (
            <div
              key={m?.id}
              className={cn('tw-cursor-pointer tw-p-2', {
                'tw-rounded': selectedRevisionMedia?.id === m?.id,
              })}
              style={{
                backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
                border:
                  selectedRevisionMedia?.id === m?.id
                    ? '1px solid #2779C6'
                    : '1px solid transparent',
                transition: 'all 0.25s ease-in-out',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRevisionMedia(m?.id);
              }}
            >
              <DeletableMinimalMediaCard
                displayName={true}
                beforeDelete={(e) => {
                  e.stopPropagation();
                  return confirm('Are you sure you want to delete this file?');
                }}
                onDelete={async () => deleteMediaMutation.mutate(m!.id)}
                canView={false}
                canDownload={true}
                media={m!}
                canDelete={!deleteMediaMutation.isLoading && isEditable}
                className={'tw-mx-auto'}
              />
            </div>
          ))}

          {!!resizesMedia.length && (
            <>
              <Separator className={'tw-my-0 tw-hidden xl:tw-block'} />
              <Separator orientation={'vertical'} className={'tw-mx-0 xl:tw-hidden'} />

              {resizesMedia.map((m) => (
                <div
                  key={m?.id}
                  className={cn('tw-cursor-pointer tw-p-2', {
                    'tw-rounded': selectedRevisionMedia?.id === m?.id,
                  })}
                  style={{
                    backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
                    border:
                      selectedRevisionMedia?.id === m?.id
                        ? '1px solid #2779C6'
                        : '1px solid transparent',
                    transition: 'all 0.25s ease-in-out',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRevisionMedia(m?.id);
                  }}
                >
                  <DeletableMinimalMediaCard
                    displayName={true}
                    onDelete={async () => deleteMediaMutation.mutate(m!.id)}
                    beforeDelete={(e) => {
                      e.stopPropagation();
                      return confirm('Are you sure you want to delete this file?');
                    }}
                    canDelete={true}
                    canView={false}
                    canDownload={true}
                    media={m!}
                    className={'tw-mx-auto'}
                  />
                </div>
              ))}
            </>
          )}

          {!!captionsMedia.length && (
            <>
              <Separator className={'tw-my-0 tw-hidden xl:tw-block'} />
              <Separator orientation={'vertical'} className={'tw-mx-0 xl:tw-hidden'} />

              {captionsMedia.map((m) => (
                <div
                  key={m?.id}
                  className={cn('tw-cursor-pointer tw-p-2', {
                    'tw-rounded': selectedRevisionMedia?.id === m?.id,
                  })}
                  style={{
                    backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
                    border:
                      selectedRevisionMedia?.id === m?.id
                        ? '1px solid #2779C6'
                        : '1px solid transparent',
                    transition: 'all 0.25s ease-in-out',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRevisionMedia(m?.id);
                  }}
                >
                  <DeletableMinimalMediaCard
                    displayName={true}
                    onDelete={async () => deleteMediaMutation.mutate(m!.id)}
                    beforeDelete={(e) => {
                      e.stopPropagation();
                      return confirm('Are you sure you want to delete this file?');
                    }}
                    canDelete={true}
                    canView={false}
                    canDownload={true}
                    media={m!}
                    className={'tw-mx-auto'}
                  />
                </div>
              ))}
            </>
          )}

          {!!thumbnailsMedia.length && (
            <>
              <Separator className={'tw-my-0 tw-hidden xl:tw-block'} />
              <Separator orientation={'vertical'} className={'tw-mx-0 xl:tw-hidden'} />

              {thumbnailsMedia.map((m) => (
                <div
                  key={m?.id}
                  className={cn('tw-cursor-pointer tw-p-2', {
                    'tw-rounded': selectedRevisionMedia?.id === m?.id,
                  })}
                  style={{
                    backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
                    border:
                      selectedRevisionMedia?.id === m?.id
                        ? '1px solid #2779C6'
                        : '1px solid transparent',
                    transition: 'all 0.25s ease-in-out',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRevisionMedia(m?.id);
                  }}
                >
                  <DeletableMinimalMediaCard
                    displayName={true}
                    onDelete={async () => deleteMediaMutation.mutate(m!.id)}
                    beforeDelete={(e) => {
                      e.stopPropagation();
                      return confirm('Are you sure you want to delete this file?');
                    }}
                    canDelete={true}
                    canView={false}
                    canDownload={true}
                    media={m!}
                    className={'tw-mx-auto'}
                  />
                </div>
              ))}
            </>
          )}

          {!!projectFilesMedia.length && (
            <>
              <Separator className={'tw-my-0 tw-hidden xl:tw-block'} />
              <Separator orientation={'vertical'} className={'tw-mx-0 xl:tw-hidden'} />

              {projectFilesMedia.map((m) => (
                <div
                  key={m?.id}
                  className={cn('tw-cursor-pointer tw-p-2', {
                    'tw-rounded': selectedRevisionMedia?.id === m?.id,
                  })}
                  style={{
                    backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
                    border:
                      selectedRevisionMedia?.id === m?.id
                        ? '1px solid #2779C6'
                        : '1px solid transparent',
                    transition: 'all 0.25s ease-in-out',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRevisionMedia(m?.id);
                  }}
                >
                  <DeletableMinimalMediaCard
                    displayName={true}
                    canView={false}
                    canDownload={true}
                    media={m!}
                    className={'tw-mx-auto'}
                  />
                </div>
              ))}
            </>
          )}

          {!!transcriptMedia.length && (
            <>
              <Separator className={'tw-my-0 tw-hidden xl:tw-block'} />
              <Separator orientation={'vertical'} className={'tw-mx-0 xl:tw-hidden'} />

              {transcriptMedia.map((m) => (
                <div
                  key={m?.id}
                  className={cn('tw-cursor-pointer tw-p-2', {
                    'tw-rounded': selectedRevisionMedia?.id === m?.id,
                  })}
                  style={{
                    backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
                    border:
                      selectedRevisionMedia?.id === m?.id
                        ? '1px solid #2779C6'
                        : '1px solid transparent',
                    transition: 'all 0.25s ease-in-out',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRevisionMedia(m?.id);
                  }}
                >
                  <DeletableMinimalMediaCard
                    displayName={true}
                    canView={false}
                    canDownload={true}
                    media={m!}
                    className={'tw-mx-auto'}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <If when={isEditable}>
        <ModalButton
          className={
            'btn btn-primary px-2 py-1 tw-hidden tw-w-full tw-content-center tw-items-center tw-self-end xl:tw-flex'
          }
          modal={EditRevisionFormModal}
          modalProps={{
            backdrop: 'static',
          }}
        >
          Add files <i className={'bx bx-cloud-upload fs-5 tw-ms-1'} />
        </ModalButton>
      </If>
    </div>
  );
};

export { RevisionMediaList };
