import { MediaObject, VideoRepresentationObject } from 'src/api/services/MediaClient';
import { float, system } from 'src/lib/utils';

const videoExtensions = [
  'avi',
  'mp4',
  'mov',
  'mkv',
  'webm',
  'wmv',
  'flv',
  '3gp',
  'mpg',
  'mpeg',
  'm4v',
  'm2v',
  'mxf',
  'ts',
  'vob',
  'ogv',
  'ogg',
  'ogx',
  'rm',
  'rmvb',
  'asf',
  'dv',
  'divx',
  'xvid',
  'mts',
];

const gcd = (a: number, b: number): number => {
  while (b != 0) {
    const remainder = a % b;
    a = b;
    b = remainder;
  }

  return a;
};

type GetAspectRatioOptions = {
  enforceHorizontal?: boolean;
};

const getAspectRatio = (
  width: number,
  height: number,
  options: GetAspectRatioOptions = {},
): number => {
  const commonDivisor = gcd(width, height);

  // Divide width and height by the GCD to get the aspect ratio
  const ratioWidth = width / commonDivisor;
  const ratioHeight = height / commonDivisor;

  if (options.enforceHorizontal) {
    return ratioWidth > ratioHeight ? ratioWidth / ratioHeight : ratioHeight / ratioWidth;
  }

  return ratioWidth / ratioHeight;
};

const video = {
  hasExtension: (ext: string) => videoExtensions.includes(ext),
} as const;

type MediaCustomProperties = MediaObject['custom_properties'];

/**
 * Method resolves the src options from `videoRepresentations` model. Model was used when creating streams manually with ffmpeg.
 * For older media, streams will be resolved from `generatedStreams` property.
 * @deprecated
 */
const makeSourceOptionsLegacy = (media: ReturnType<typeof decorateMedia>) => {
  const options: {
    label: string;
    value: string;
  }[] = [
    {
      label:
        media.height && media.width
          ? `Original ${media.isHorizontal ? media.height : media.width}p`
          : 'Original',
      value: media.index_url,
    },
  ];

  if (!media.hasGeneratedStreams) {
    return options;
  }

  media.generatedStreams?.forEach((stream) => {
    options.push({
      label: {
        dash: 'Dash (most devices)',
        hls: 'HLS (iPhones)',
      }[stream.format],
      value: stream.index_url,
    });
  });

  return options;
};

export const makeSourceOptions = (media: ReturnType<typeof decorateMedia>) => {
  if (!media.video || media.video.status !== 'generated') {
    return makeSourceOptionsLegacy(media);
  }

  const options: {
    label: string;
    value: string;
  }[] = [
    {
      label:
        media.height && media.width
          ? `Original ${media.isHorizontal ? media.height : media.width}p`
          : 'Original',
      value: media.index_url,
    },
  ];

  if (media.video.hls) {
    options.push({
      label: 'HLS (iPhones)',
      value: media.video.hls,
    });
  }

  if (media.video.dash) {
    options.push({
      label: 'Dash (most devices)',
      value: media.video.dash,
    });
  }

  return options;
};

/**
 * Method resolves the src options from `videoRepresentations` model. Model was used when creating streams manually with ffmpeg.
 * For older media, streams will be resolved from `generatedStreams` property.
 * @deprecated
 */
const getPreferredSourceLegacy = (media: ReturnType<typeof decorateMedia>) => {
  if (!media.hasGeneratedStreams) {
    return media.index_url;
  }

  if (system.isIOS() || !system.supportsMSE()) {
    return media.hlsStream?.index_url ?? media.index_url;
  }

  return media.dashStream?.index_url ?? media.index_url;
};

export const getPreferredSource = (media: ReturnType<typeof decorateMedia>) => {
  if (!media.video || media.video.status !== 'generated') {
    return getPreferredSourceLegacy(media);
  }

  if (system.isIOS() || !system.supportsMSE()) {
    return media.video.hls ?? media.index_url;
  }

  return media.video.dash ?? media.index_url;
};

export const decorateMedia = (media: MediaObject) => {
  const getCustomProperty = <T extends keyof MediaCustomProperties>(
    key: T,
  ): MediaCustomProperties[T] | undefined => media?.custom_properties?.[key];

  const extension = media.file_name.toLowerCase().split('.').pop() ?? '',
    isVideo = media.mime_type.startsWith('video/') || video.hasExtension(extension),
    isImage = media.mime_type.startsWith('image/'),
    isAudio = media.mime_type.startsWith('audio/'),
    isUnknown = !isVideo && !isImage && !isAudio,
    isSvg = media.mime_type === 'image/svg+xml',
    height = getCustomProperty('height') ?? 0,
    width = getCustomProperty('width') ?? 0,
    fps = (() => {
      let result = parseInt(getCustomProperty('fps' as any)); // legacy
      const parts = getCustomProperty('r_frame_rate')?.split('/');
      if (parts?.length === 2) {
        result = Math.round(parseInt(parts[0]) / parseInt(parts[1]));
      }

      return Number.isNaN(result) ? undefined : result;
    })(),
    hasFps = typeof fps === 'number',
    durationInSeconds = float.toFinite(parseFloat(getCustomProperty('duration') ?? '0')),
    codecName = getCustomProperty('codec_name'),
    isHorizontal = !!(width && height && width > height),
    aspectRatio =
      width && height
        ? getAspectRatio(width, height, {
            enforceHorizontal: true,
          })
        : 16 / 9,
    originalResolution = isHorizontal ? height : width,
    canPlay = isVideo || isAudio,
    /**
     * @deprecated
     * @todo streams src should be resolved from `MediaObject['video']` property.
     */
    generatedStreams = media?.representations.filter((r) => r.status === 'generated'),
    /** @deprecated Use `media.video` property instead. */
    hasGeneratedStreams = !!generatedStreams?.length,
    /** @deprecated Use `media.video` property instead. */
    hasStreams = media?.representations.length > 0,
    /** @deprecated Use `media.video` property instead. */
    stream = media?.representations.reduce(
      (acc, cur) => {
        if (cur.status !== 'generated') {
          return acc;
        }

        acc[cur.format] = cur;
        return acc;
      },
      {} as Record<VideoRepresentationObject['format'], VideoRepresentationObject>,
    ),
    /** @deprecated Use `media.video.hls` property instead. */
    hlsStream = stream?.hls,
    /** @deprecated Use `media.video.dash` property instead. */
    dashStream = stream?.dash,
    getConversion = (conversion: string) => media?.generated_conversions?.[conversion],
    thumbnail = getConversion('thumbnail'),
    poster = getConversion('poster'),
    hasPoster = !!poster,
    hasThumbnail = !!thumbnail;

  return {
    ...media,
    isVideo,
    isImage,
    isAudio,
    isSvg,
    isUnknown,
    height,
    width,
    fps,
    hasFps,
    durationInSeconds,
    codecName,
    isHorizontal,
    aspectRatio,
    originalResolution,
    canPlay,
    generatedStreams,
    hasGeneratedStreams,
    hasStreams,
    stream,
    hlsStream,
    dashStream,
    thumbnail,
    poster,
    hasPoster,
    hasThumbnail,
    getCustomProperty,
    getConversion,
  };
};

export { getAspectRatio, video };
