import { useVideoPlayerV2 } from 'src/features/video-player/video-player';

export const useVideoPlayerControlsVisibility = (): Pick<
  ReturnType<typeof useVideoPlayerV2>,
  'controlsVisible' | 'setControlsVisible'
> => {
  const { controlsVisible, setControlsVisible } = useVideoPlayerV2();

  return {
    controlsVisible,
    setControlsVisible,
  };
};
