import { useQueryParam } from 'use-query-params';

const useRequestPreview = () => {
  const [requestDetailsId, setRequestDetailsId] = useQueryParam<string | undefined>(
    'request-details',
  );

  const previewRequest = (requestId: string | undefined) => {
    setRequestDetailsId(requestId, 'replaceIn');
  };

  return {
    requestDetailsId,
    previewRequest,
  };
};

export { useRequestPreview };
