import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { AlertCircle, CheckCircle2, LucideProps, XCircle } from 'lucide-react';

import { cn } from 'src/lib/utils';

const alertVariants = cva('', {
  variants: {
    variant: {
      default: 'tw-bg-background tw-text-foreground [&>svg]:tw-text-foreground',
      destructive: 'tw-border-red tw-bg-orange-100 tw-text-orange-900 [&>svg]:tw-text-orange-900',
      warning: 'tw-border-yellow tw-bg-yellow-100 tw-text-yellow-900 [&>svg]:tw-text-yellow-900',
      success: 'tw-border-green tw-bg-green-100 tw-text-green-900 [&>svg]:tw-text-green-900',
    },
    layout: {
      base: 'tw-relative tw-w-full tw-rounded-lg tw-border tw-p-4 [&>svg+div]:tw-translate-y-[-3px] [&>svg]:tw-absolute [&>svg]:tw-left-4 [&>svg]:tw-top-4 [&>svg~*]:tw-pl-7',
      flex: 'tw-rounded-lg tw-border tw-p-4',
    },
  },
  defaultVariants: {
    variant: 'default',
    layout: 'base',
  },
});

const alertIconVariants = cva('', {
  variants: {
    // "size" name is occupied by LucideProps
    sizeVariant: {
      default: 'tw-h-5 tw-w-5',
      sm: 'tw-h-4 tw-w-4',
    },
  },
  defaultVariants: {
    sizeVariant: 'default',
  },
});

export interface AlertProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof alertVariants> {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, variant, layout, ...props }, ref) => (
    <div
      ref={ref}
      role="alert"
      className={cn(alertVariants({ variant, layout }), className)}
      {...props}
    />
  ),
);
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <p
      ref={ref}
      className={cn('tw-mb-1 tw-font-medium tw-leading-none tw-tracking-tight', className)}
      {...props}
    />
  ),
);
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('[&_p]:tw-leading-relaxed', className)} {...props} />
));
AlertDescription.displayName = 'AlertDescription';

const AlertDescriptionStrong = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('tw-text-lg tw-font-bold', className)} {...props} />
));
AlertDescriptionStrong.displayName = 'AlertDescriptionStrong';

export type AlertIconProps = LucideProps &
  Pick<AlertProps, 'variant'> &
  VariantProps<typeof alertIconVariants>;

const AlertIcon: React.FC<AlertIconProps> = ({ variant, sizeVariant, className, ...props }) => {
  switch (variant) {
    case 'destructive':
      return <XCircle className={cn(alertIconVariants({ sizeVariant }), className)} {...props} />;
    case 'success':
      return (
        <CheckCircle2 className={cn(alertIconVariants({ sizeVariant }), className)} {...props} />
      );
    default:
      return (
        <AlertCircle className={cn(alertIconVariants({ sizeVariant }), className)} {...props} />
      );
  }
};

export { Alert, AlertTitle, AlertDescription, AlertDescriptionStrong, AlertIcon };
