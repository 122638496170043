import { jwt } from 'src/lib/modules/jwt';

import { VAPID_PUBLIC_KEY, API_URL } from 'src/env.json';

const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator;

const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const storePushSubscription = (pushSubscription: PushSubscription) => {
  fetch(`${API_URL}/profile/subscribe-web-notifications`, {
    method: 'POST',
    body: JSON.stringify(pushSubscription),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Expose-Headers': 'Access-Control-*',
      'Access-Control-Allow-Headers':
        'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      'Access-Control-Allow-Origin': '*',
      Allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      Authorization: `Bearer ${jwt.getToken()}`,
    },
  }).catch((e) => {
    console.error(e?.message);
    console.error(e);
  });
};

const subscribeUser = () => {
  navigator.serviceWorker.ready
    .then((registration) => {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
      };

      return registration.pushManager.subscribe(subscribeOptions);
    })
    .then((pushSubscription) => {
      storePushSubscription(pushSubscription);
    })
    .catch((e) => console.warn(`Failed to subscribe user for notifications\n${e}`));
};

const initPushNotifications = () => {
  if (Notification.permission === 'granted') {
    subscribeUser();
    return;
  }

  if (!navigator.serviceWorker.ready) {
    return;
  }

  new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  })
    .then((permissionResult) => {
      if (permissionResult !== 'granted') {
        console.warn('Permission to send notifications was denied');
      } else {
        subscribeUser();
      }
    })
    .catch((e) => console.warn(`Failed to initialize notification push\n${e}`));
};

const pushNotification = {
  isSupported,
  init: initPushNotifications,
};

export { pushNotification };
