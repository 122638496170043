import * as React from 'react';

import { cn } from 'src/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

type TableProps = React.HTMLAttributes<HTMLTableElement> & {
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
};

const tableRowVariants = cva(
  'tw-transition-colors [&_td]:data-[active=true]:tw-border-brand [&_td]:data-[active=true]:hover:tw-border-brand',
  {
    variants: {
      variant: {
        default:
          '[&_td]:tw-bg-neutral-50 [&_td]:hover:tw-border-brand-subtle [&_td]:hover:tw-bg-brand-100 [&_td]:data-[active=true]:tw-bg-brand-200 [&_td]:data-[active=true]:hover:tw-bg-brand-300',
        highlight:
          '[&_td]:tw-border-brand-subtle [&_td]:tw-bg-brand-200 [&_td]:hover:tw-bg-brand-300 [&_td]:data-[active=true]:tw-border-brand [&_td]:data-[active=true]:hover:tw-bg-brand-300',
        disabled: 'tw-cursor-default [&_td]:tw-bg-neutral-50',
        suspended: 'tw-cursor-default [&_td]:tw-bg-neutral-200/80',
        nested:
          '[&_td]:tw-bg-background [&_td]:hover:tw-border-brand-subtle [&_td]:hover:tw-bg-brand-100',
        unset: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type TableRowProps = React.HTMLAttributes<HTMLTableRowElement> &
  VariantProps<typeof tableRowVariants> & { active?: boolean };

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, wrapperProps, ...props }, ref) => (
    <div
      {...wrapperProps}
      className={cn(
        'tw-relative tw-w-full tw-grow tw-overflow-auto tw-rounded-lg tw-border tw-bg-background tw-px-3',
        wrapperProps?.className,
      )}
    >
      <table
        ref={ref}
        className={cn(
          'tw-w-full tw-caption-bottom tw-border-separate tw-border-spacing-y-3',
          className,
        )}
        {...props}
      />
    </div>
  ),
);
Table.displayName = 'Table';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ ...props }, ref) => <thead ref={ref} {...props} />);
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn(
      '[&_td:first-child]:tw-rounded-bl-lg [&_td:first-child]:tw-rounded-tl-lg [&_td:first-child]:tw-border-l [&_td:last-child]:tw-rounded-br-lg [&_td:last-child]:tw-rounded-tr-lg [&_td:last-child]:tw-border-r [&_td]:tw-border-b [&_td]:tw-border-t [&_tr:last-child]:tw-border-0',
      className,
    )}
    {...props}
  />
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn('tw-font-medium [&>tr]:last:tw-border-b-0 [&_td]:tw-border-t', className)}
    {...props}
  />
));
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, variant, active, ...props }, ref) => (
    <tr
      ref={ref}
      data-state={variant ?? 'default'}
      data-active={active || false}
      className={cn(tableRowVariants({ variant, className }))}
      {...props}
    />
  ),
);
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'tw-h-12 tw-border-t-0 tw-px-4 tw-py-1 tw-text-left tw-align-middle tw-font-medium tw-text-dark [&:has([role=checkbox])]:tw-pr-0',
      className,
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'tw-p-4 tw-align-middle tw-transition-colors [&:has([role=checkbox])]:tw-pr-0',
      className,
    )}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn('tw-mt-4 tw-text-sm tw-text-muted-foreground', className)}
    {...props}
  />
));
TableCaption.displayName = 'TableCaption';

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };
