import React from 'react';
import { CallableChildren, cn, render } from 'src/lib/utils';
import { useVideoProgressStore } from 'src/features/video-player/use-video-progress-store';
import { useVideoPlayerV2 } from 'src/features/video-player/video-player';
import { formatTime, TimeFormat } from 'src/features/video-player/video-player-utils';
import { shallow } from 'zustand/shallow';

type VideoProgressTimeTextProps = Omit<React.ComponentProps<'div'>, 'children'> & {
  children?: CallableChildren<{
    playedSeconds: number;
    totalSeconds: number;
    timeFormat: TimeFormat;
  }>;
};

export const VideoProgressTimeText: React.FC<VideoProgressTimeTextProps> = ({
  className,
  children,
  ...props
}) => {
  const progressStore = useVideoProgressStore((store) => store, shallow);

  const {
    timeFormat,
    meta: { durationInSeconds, fps },
  } = useVideoPlayerV2();

  const formatOptions = {
    fps,
    format: timeFormat,
  };

  return (
    <div
      {...props}
      className={cn(
        'tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-100',
        className,
      )}
    >
      {children ? (
        render(children, {
          playedSeconds: progressStore.playedSeconds,
          totalSeconds: durationInSeconds,
          timeFormat,
        })
      ) : (
        <>
          <span>{formatTime(progressStore.playedSeconds, formatOptions)}</span>
          <span className={'tw-mx-1'}>/</span>
          <span>{formatTime(durationInSeconds, formatOptions)}</span>
        </>
      )}
    </div>
  );
};
