import { storage } from 'src/lib/utils/storage';

const storageKey = 'jwt-becreatives' as const;

type StorageTokenObject = {
  token: string;
  expires: number;
};

type JwtObject = {
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  prv: string;
  sub: string;
  impersonator: string | null;
};

const getToken = () => storage.get<StorageTokenObject>(storageKey, true)?.token;

const setToken = (token: string | StorageTokenObject) => {
  storage.set(storageKey, typeof token === 'string' ? { token, expired: false } : token);
};

const removeToken = () => storage.remove(storageKey);

const parseToken = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const parsed = JSON.parse(window.atob(base64));
  return parsed as JwtObject;
};

const isTokenExpired = (token?: string | null | StorageTokenObject) => {
  if (!token) return true;

  const jwt = typeof token === 'string' ? token : token.token;

  const parsed = parseToken(jwt);
  const now = Date.now() / 1000;
  return parsed.exp < now;
};

const payload = () => (isTokenExpired(getToken()) ? null : parseToken(getToken()!));

const jwt = {
  storageKey,
  payload,
  getToken,
  setToken,
  removeToken,
  parseToken,
  isTokenExpired,
} as const;

export type { JwtObject };
export { jwt };
