import { useParams } from 'react-router-dom';

const useRequestId = (): string => {
  const { requestId } = useParams<{ requestId: string }>();

  if (!requestId) {
    throw Error('requestId is not set');
  }

  return requestId!;
};

export { useRequestId };
