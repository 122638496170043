type ValidationRule<V, M = string> = {
  message: M;
  value: V;
};

const isValidationRule = <T, M = string>(arg: any): arg is ValidationRule<T, M> => {
  return !!arg && typeof arg === 'object' && 'value' in arg && 'message' in arg;
};

export type { ValidationRule };
export { isValidationRule };
