import React, { ComponentProps, forwardRef } from 'react';
import { Card, CardContent, CardHeader, CardProps } from 'src/components/ui/card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import { If } from 'src/components/If';
import { cn } from 'src/lib/utils';
import { Separator } from 'src/components/ui/separator';
import { ChevronUp } from 'lucide-react';

export type CollapsibleCardProps = Pick<
  ComponentProps<typeof Collapsible>,
  'open' | 'onOpenChange'
> &
  Omit<CardProps, 'title'> & {
    icon?: React.ReactNode;
    title?: React.ReactNode;
  };

const CollapsibleCardTrigger: React.FC<
  Omit<React.ComponentProps<typeof CollapsibleTrigger>, 'asChild'> & { open?: boolean }
> = ({ className, children, open, ...props }) => (
  <CollapsibleTrigger {...props} asChild>
    <div className={cn('tw-flex tw-items-center', className)}>
      {children}
      <div className={'tw-flex tw-grow tw-items-center'}>
        <div className={'tw-mx-6 tw-grow lg:tw-mx-12'}>
          <Separator className={'tw-bg-neutral-50'} />
        </div>
        <div>
          <ChevronUp
            size={20}
            className={cn('tw-transition-transform', {
              'tw-rotate-180': !open,
            })}
          />
        </div>
      </div>
    </div>
  </CollapsibleTrigger>
);

const CollapsibleCard = forwardRef<HTMLDivElement, CollapsibleCardProps>(
  ({ className, icon = null, title = null, children, open, onOpenChange, ...props }, ref) => (
    <Collapsible asChild={true} open={open} onOpenChange={onOpenChange} ref={ref}>
      <Card
        {...props}
        className={cn(
          'tw-flex tw-flex-col tw-gap-6 data-[state=open]:tw-pb-4 desktop:tw-px-6 desktop:tw-py-2',
          className,
        )}
      >
        <If when={!!(icon || title)}>
          <CardHeader
            className={'tw-gap-6 tw-px-6 tw-py-4 tw-capitalize desktop:tw-px-4 desktop:tw-py-6'}
          >
            <CollapsibleCardTrigger open={open}>
              <div className={'tw-flex tw-items-center tw-gap-2 tw-text-title tw-text-dark'}>
                {icon}

                <h2 className={'tw-text-title-sm desktop:tw-text-title'}>{title}</h2>
              </div>
            </CollapsibleCardTrigger>
          </CardHeader>
        </If>
        {children}
      </Card>
    </Collapsible>
  ),
);
CollapsibleCard.displayName = 'CollapsibleCard';

const CollapsibleCardContent: React.FC<
  Omit<React.ComponentProps<typeof CollapsibleContent>, 'asChild'>
> = ({ className, children, ...props }) => {
  return (
    <CollapsibleContent
      className={cn('tw-px-6 tw-pb-0 desktop:tw-px-10', className)}
      asChild
      {...props}
    >
      <CardContent>{children}</CardContent>
    </CollapsibleContent>
  );
};

export { CollapsibleCardTrigger, CollapsibleCard, CollapsibleCardContent };
