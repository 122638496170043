import { Client, PaginatedResponse } from 'src/api/Client';
import { BrandsTableResponse, BrandResponse } from './BrandClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class BrandClient extends Client {
  public readonly route = {
    companyFilter: `${this.http.baseUrl}/admin/brands/{model}/filter`,
    read: `${this.http.baseUrl}/admin/brands/{model}`,
  };

  filterBrands = (
    model: string,
    params?: ApiQueryObject,
  ): Promise<PaginatedResponse<BrandsTableResponse>> => {
    const url = this.buildUrl(this.route.companyFilter, { model });

    return this.http.get(url, { params });
  };

  fetchBrand = (model: string): Promise<BrandResponse> => {
    return this.http.get(this.buildUrl(this.route.read, { model }));
  };
}

export default BrandClient;
