import React from 'react';
import {
  PopoverBody,
  PopoverHeader,
  Text,
  PopoverProps,
  TooltipIcon,
  TooltipButton,
} from '@becreatives/becreatives-ui';
import styled from 'styled-components';
import RevisionVersionTooltipPng from 'src/assets/images/revision-version-tooltip-image.png';
import { RevisionVersionsPopover } from './RevisionsContentMain.styled';
import { useToggle } from 'src/helpers/useToggle';
import { useTranslation } from 'react-i18next';

const PopoverImage = styled.img.attrs((props) => ({ ...props, src: RevisionVersionTooltipPng }))`
  max-width: 100%;
  padding: 1rem 0;
`;

const RevisionVersionTooltipPopover: React.FC<Omit<PopoverProps, 'target'>> = (props) => {
  const { t } = useTranslation('pages/shared');

  const [isOpen, setOpen, toggle] = useToggle();

  return (
    <>
      <TooltipButton
        $centeredContent={true}
        id={'RevisionVersionTooltipPopover'}
        onMouseLeave={() => setOpen(false)}
        onMouseEnter={() => setOpen(true)}
        onTouchEnd={() => setOpen(false)}
        onTouchStart={() => setOpen(true)}
      >
        <TooltipIcon size={'xs'} />
      </TooltipButton>
      <RevisionVersionsPopover
        target={'RevisionVersionTooltipPopover'}
        isOpen={isOpen}
        toggle={toggle}
        {...props}
      >
        <PopoverHeader>
          <Text tag={'b'}>{t('revisions.revision.guide.title')}</Text>
        </PopoverHeader>
        <PopoverBody>
          <Text color={'gray'}>{t('revisions.revision.guide.body')}</Text>
          <PopoverImage />
        </PopoverBody>
      </RevisionVersionsPopover>
    </>
  );
};

export { RevisionVersionTooltipPopover };
