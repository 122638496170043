import React from 'react';
import { components, MenuListProps } from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { useToggle } from 'src/lib/state-utils';
import { If } from 'src/components/If';
import { useDebouncedAction } from 'src/lib/utils';
import { ScrollableContainer } from 'src/components/ui/scrollable-container';
import { Loader2 } from 'lucide-react';

type ReactSelectMenuListComponentType = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MenuListProps<Option, IsMulti, Group>,
) => JSX.Element;

const ReactSelectAsyncMenuList: ReactSelectMenuListComponentType = ({
  innerProps,
  selectProps,
  ...props
}) => {
  const [isAwaiting, , toggle] = useToggle();
  const { onMenuScrollToBottom } = selectProps;

  const handleScroll = useDebouncedAction(
    async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (!onMenuScrollToBottom) {
        return;
      }

      toggle();

      try {
        await onMenuScrollToBottom(e as any);
      } catch (e) {
        throw e;
      } finally {
        toggle();
      }
    },
    [onMenuScrollToBottom],
  );

  return (
    <ScrollableContainer<HTMLDivElement>>
      {({ ref, onScrollToBottom }) => (
        <components.MenuList
          {...props}
          className={'tw-relative tw-px-1'}
          selectProps={selectProps}
          innerProps={{
            ...innerProps,
            onScroll: (e) => onScrollToBottom(() => handleScroll(e))(),
          }}
          innerRef={ref}
        >
          <>
            {props.children}

            <If when={isAwaiting}>
              <div
                className={
                  'tw-sticky tw-bottom-0 tw-end-1/2 tw-bg-gradient-to-t tw-from-background tw-from-30% tw-py-2'
                }
              >
                <Loader2
                  className={'tw-mx-auto tw-size-9 tw-animate-spin tw-text-center tw-text-primary'}
                />
              </div>
            </If>
          </>
        </components.MenuList>
      )}
    </ScrollableContainer>
  );
};

export { ReactSelectAsyncMenuList };
