import { Client } from './Client';
import { UserChannel } from './Channels/UserChannel';

class Broadcast {
  public readonly user: UserChannel;

  constructor(client: Client) {
    this.user = new UserChannel(client);
  }
}

export default Broadcast;
