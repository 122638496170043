import { TOptions } from 'i18next';

const datetimeFormatValues = {
  seconds: 's',
  minutes: 'i',
  hours: 'h',
  days: 'd',
  weeks: 'w',
  months: 'm',
} as const;

type DatetimeFormatValues = typeof datetimeFormatValues[keyof typeof datetimeFormatValues];

type GetTimeReturnObject = {
  value: number;
  unit: DatetimeFormatValues;
};

type GetTimeValueFn = (time: number) => GetTimeReturnObject;

const getTransObject = ({ value, unit }: GetTimeReturnObject): [string, TOptions] => [
  `diff_for_humans.${unit}`,
  {
    count: value,
    replace: {
      value,
    },
  },
];

const getSeconds: GetTimeValueFn = (time: number) => ({
  value: Math.floor(time / 1000),
  unit: datetimeFormatValues.seconds,
});

const getMinutes: GetTimeValueFn = (time: number) => ({
  value: Math.floor(getSeconds(time).value / 60),
  unit: datetimeFormatValues.minutes,
});

const getHours: GetTimeValueFn = (time: number) => ({
  value: Math.floor(getMinutes(time).value / 60),
  unit: datetimeFormatValues.hours,
});

const getDays: GetTimeValueFn = (time: number) => ({
  value: Math.floor(getHours(time).value / 24),
  unit: datetimeFormatValues.days,
});

const getWeeks: GetTimeValueFn = (time: number) => ({
  value: Math.floor(getDays(time).value / 7),
  unit: datetimeFormatValues.weeks,
});

const getMonths: GetTimeValueFn = (time: number) => ({
  value: Math.floor(getWeeks(time).value / 4),
  unit: datetimeFormatValues.months,
});

const hasMinutes = (time: number) => !!getMinutes(time).value;

const hasHours = (time: number) => !!getHours(time).value;

const hasDays = (time: number) => !!getDays(time).value;

const hasWeeks = (time: number) => !!getWeeks(time).value;

const hasMonths = (time: number) => !!getMonths(time).value;

export {
  datetimeFormatValues,
  getTransObject,
  getSeconds,
  getMinutes,
  getHours,
  getDays,
  getWeeks,
  getMonths,
  hasMinutes,
  hasHours,
  hasDays,
  hasWeeks,
  hasMonths,
};

export type { DatetimeFormatValues };
