import React from 'react';

type Props = {
  when?: boolean;
  else?: React.ReactNode;
  children?: React.ReactNode;
};

const If: React.FC<Props> = ({ when, else: otherwise, children }) => {
  return when ? <>{children}</> : otherwise ? <>{otherwise}</> : null;
};

export { If };
