import React, { useRef, useState } from 'react';
import { Button } from 'src/components/ui/button';
import { ArrowDownUp, Check, ChevronLeft, Filter, Plus, Settings2 } from 'lucide-react';
import { useFilters } from 'src/lib/services/api-query-params/use-filters';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from 'src/components/ui/drawer';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { If } from 'src/components/If';
import { SortCommandList, SortMenuItem } from 'src/features/sort-and-filter/sort-menu';
import { useRequestFilters } from 'src/features/requests/request-list/use-request-filters';
import { useRequestSorts } from 'src/features/requests/request-list/use-request-sorts';
import { getObjectEntries } from 'src/helpers/getObjectEntries';
import { CSSTransition } from 'react-transition-group';
import { useApiQueryParams } from 'src/lib/services/api-query-params';
import { useSorts } from 'src/lib/services/api-query-params/use-sorts';
import { useToggle } from 'src/lib/state-utils';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { CardErrorFallback } from 'src/features/fallback';
import { ErrorBoundary } from 'react-error-boundary';

type RequestsFilterDrawerContentPage = 'filter' | 'sort' | undefined;

type DrawerContentPageProps = {
  setContentPage: (page: RequestsFilterDrawerContentPage) => void;
};

const RequestsFilterDrawer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { filters } = useApiQueryParams();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const filtersContentRef = useRef<HTMLDivElement>(null);
  const sortContentRef = useRef<HTMLDivElement>(null);

  const [contentPage, setContentPage] = useState<RequestsFilterDrawerContentPage>();

  return (
    <Drawer onClose={() => setContentPage(undefined)}>
      <DrawerTrigger asChild>
        <Button variant="outline" size={'lg'} className={'tw-relative tw-px-4'}>
          {(!!filters.sorts || !!filters.filters) && (
            <div
              className={'tw-absolute tw-flex tw-items-center tw-justify-center'}
              style={{
                right: '-0.5rem',
                top: '-0.5rem',
              }}
            >
              <span className={'tw-size-4 tw-rounded-full tw-bg-orange'} />
            </div>
          )}
          <Settings2 size={20} />
          <span className="tw-sr-only">{t('common:filters', 'Filters')}</span>
        </Button>
      </DrawerTrigger>
      <DrawerContent className={'tw-min-h-[50vh] tw-px-4'}>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <CardErrorFallback
              className={'tw-mt-6'}
              error={error}
              resetErrorBoundary={resetErrorBoundary}
            />
          )}
        >
          <div className="tw-relative tw-mx-auto tw-flex tw-h-full tw-w-full tw-max-w-2xl tw-grow">
            <CSSTransition
              in={!contentPage}
              nodeRef={mainContentRef}
              unmountOnExit
              timeout={280}
              classNames={{
                enter: 'tw-animate-in tw-slide-in-from-left tw-fade-in tw-duration-300',
                exit: 'tw-animate-out tw-slide-out-to-left tw-fade-out tw-duration-300 tw-absolute',
              }}
            >
              <RequestsDrawerMainContent ref={mainContentRef} setContentPage={setContentPage}>
                {children}
              </RequestsDrawerMainContent>
            </CSSTransition>

            <CSSTransition
              in={contentPage === 'filter'}
              nodeRef={filtersContentRef}
              timeout={280}
              unmountOnExit
              classNames={{
                enter: 'tw-animate-in tw-slide-in-from-right tw-fade-in tw-duration-300',
                exit: 'tw-animate-out tw-slide-out-to-right tw-fade-out tw-duration-300 tw-absolute',
              }}
            >
              <RequestsDrawerFilterContent
                ref={filtersContentRef}
                setContentPage={setContentPage}
              />
            </CSSTransition>

            <CSSTransition
              in={contentPage === 'sort'}
              nodeRef={sortContentRef}
              timeout={280}
              unmountOnExit
              classNames={{
                enter: 'tw-animate-in tw-slide-in-from-right tw-fade-in tw-duration-300',
                exit: 'tw-animate-out tw-slide-out-to-right tw-fade-out tw-duration-300 tw-absolute',
              }}
            >
              <RequestsDrawerSortContent ref={sortContentRef} setContentPage={setContentPage} />
            </CSSTransition>
          </div>
        </ErrorBoundary>
      </DrawerContent>
    </Drawer>
  );
};

const RequestsDrawerMainContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<DrawerContentPageProps>
>(({ children, setContentPage }, ref) => {
  const { t } = useTranslation();
  const { filters, clearFilters } = useApiQueryParams();
  const { getFilterCounts } = useFilters();
  const { filterGroups } = useRequestFilters();

  const filterCounts = getFilterCounts(filterGroups);
  const totalFilterCount = filterCounts.filter((count) => count > 0).length;
  const totalSortCount = filters.sorts?.length || 0;

  return (
    <div ref={ref} className={'tw-flex tw-w-full tw-grow tw-flex-col'}>
      <div className="tw-flex tw-items-center tw-justify-between">
        <DrawerHeader className={'tw-text-start'}>
          <DrawerTitle className={'tw-text-xl'}>
            {t('common:sort_and_filter', 'Sort & Filter')}
          </DrawerTitle>
        </DrawerHeader>

        <If when={!!filters.filters?.length || !!filters.sorts?.length}>
          <Button
            type={'button'}
            variant={'text'}
            size={'lg'}
            onClick={() => clearFilters()}
            className={'tw-animate-in tw-fade-in'}
          >
            {t('actions:clear_all', 'Clear all')}
          </Button>
        </If>
      </div>

      <ScrollArea className={'tw-h-[50vh] tw-grow'}>
        <div className={'tw-flex tw-flex-col tw-gap-4 tw-p-4'}>
          {children}

          <Button
            size={'iconStart'}
            variant={'outline'}
            className={'tw-h-11'}
            onClick={() => setContentPage('filter')}
          >
            <Filter size={20} />
            {totalFilterCount > 0 ? (
              <>
                {t('common:filters', { defaultValue: 'Filters' })}: {totalFilterCount}
              </>
            ) : (
              <>{t('common:filter', { defaultValue: 'Filter' })}</>
            )}
          </Button>

          <Button
            size={'iconStart'}
            variant={'outline'}
            className={'tw-h-11'}
            onClick={() => setContentPage('sort')}
          >
            <ArrowDownUp size={20} />
            {totalSortCount > 0 ? (
              <>
                {t('common:sorts', { defaultValue: 'Sorts' })}: {totalSortCount}
              </>
            ) : (
              <>{t('common:sort', { defaultValue: 'Sort' })}</>
            )}
          </Button>
        </div>
      </ScrollArea>
    </div>
  );
});

RequestsDrawerMainContent.displayName = 'RequestsDrawerMainContent';

const RequestsDrawerFilterContent = React.forwardRef<HTMLDivElement, DrawerContentPageProps>(
  ({ setContentPage }, ref) => {
    const { t } = useTranslation();
    const { filterGroups } = useRequestFilters();
    const { filters, getFilterCounts, clearFilters, isFilterChecked } = useFilters();

    const filterCounts = getFilterCounts(filterGroups);

    return (
      <div ref={ref} className={'tw-flex tw-w-full tw-flex-col'}>
        <div className="tw-flex tw-items-center tw-justify-between">
          <DrawerHeader className={'tw-flex tw-items-center tw-gap-2 tw-text-start'}>
            <Button size={'iconSm'} variant={'ghost'} onClick={() => setContentPage(undefined)}>
              <ChevronLeft className={'tw-size-4'} />
            </Button>
            <DrawerTitle className={'tw-text-xl'}>{t('common:filters', 'Filters')}</DrawerTitle>
          </DrawerHeader>

          <If when={!!filters?.length}>
            <Button
              type={'button'}
              variant={'text'}
              size={'lg'}
              onClick={() => clearFilters()}
              className={'tw-animate-in tw-fade-in'}
            >
              {t('actions:clear', {
                defaultValue: 'Clear',
              })}
            </Button>
          </If>
        </div>

        <ScrollArea className={'tw-h-[50vh] tw-grow'}>
          <div className={'tw-p-4'}>
            {getObjectEntries(filterGroups).map(([key, group], index) => (
              <div key={key}>
                {group.filter ? (
                  <>
                    {(() => {
                      const isChecked = isFilterChecked(group.filter.filter);

                      return (
                        <div
                          className={'tw-flex tw-gap-2 tw-px-2 tw-py-1.5'}
                          onClick={() => {
                            group.filter.updater(group.filter.filter, !isChecked);
                          }}
                        >
                          {isChecked && (
                            <Check className={'tw-size-4 tw-animate-in tw-slide-in-from-left'} />
                          )}
                          {group.filter.label}
                        </div>
                      );
                    })()}
                  </>
                ) : (
                  <>
                    <div className={'tw-px-2 tw-py-1.5 tw-font-bold'}>
                      {group.label} {filterCounts[index] > 0 ? <>({filterCounts[index]})</> : null}
                    </div>
                    <div className={'tw-ms-8 tw-flex tw-flex-col tw-gap-2'}>
                      {group.filters.map(({ label, updater, filter }) => {
                        const isChecked = isFilterChecked(filter);

                        return (
                          <div
                            key={`${key}-label`}
                            className={'tw-flex tw-gap-2 tw-px-2 tw-py-1.5'}
                            onClick={() => {
                              updater(filter, !isChecked);
                            }}
                          >
                            {isChecked && (
                              <Check className={'tw-size-4 tw-animate-in tw-slide-in-from-left'} />
                            )}
                            {label}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    );
  },
);

RequestsDrawerFilterContent.displayName = 'RequestsDrawerFilterContent';

const RequestsDrawerSortContent = React.forwardRef<HTMLDivElement, DrawerContentPageProps>(
  ({ setContentPage }, ref) => {
    const { t } = useTranslation();
    const { sortList } = useRequestSorts();
    const {
      sorts,
      addSort,
      updateSort,
      removeSort,
      clearSorts,
      getSortOrderOptions,
      getAvailableSorts,
    } = useSorts();

    const [additionSortMenuOpen, setAdditionSortMenuOpen] = useToggle();

    const isSortApplied = !!sorts?.length;
    const availableSorts = getAvailableSorts(sortList);

    return (
      <div ref={ref} className={'tw-flex tw-w-full tw-flex-col'}>
        <div className="tw-flex tw-items-center tw-justify-between">
          <DrawerHeader className={'tw-flex tw-items-center tw-gap-2 tw-text-start'}>
            <Button size={'iconSm'} variant={'ghost'} onClick={() => setContentPage(undefined)}>
              <ChevronLeft className={'tw-size-4'} />
            </Button>
            <DrawerTitle className={'tw-text-xl'}>{t('common:sort', 'Sort')}</DrawerTitle>
          </DrawerHeader>

          <If when={isSortApplied}>
            <Button
              type={'button'}
              variant={'text'}
              size={'lg'}
              onClick={() => clearSorts()}
              className={'tw-animate-in tw-fade-in'}
            >
              {t('actions:clear', {
                defaultValue: 'Clear',
              })}
            </Button>
          </If>
        </div>

        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <CardErrorFallback
              className={'tw-mt-6'}
              error={error}
              resetErrorBoundary={resetErrorBoundary}
            />
          )}
        >
          <ScrollArea className={'tw-h-[50vh] tw-grow tw-pb-4'}>
            <div className={'tw-p-4'}>
              <If
                when={isSortApplied}
                else={
                  <Popover modal={true}>
                    <PopoverTrigger asChild>
                      <Button variant={'text'} size={'iconStartSm'}>
                        <Plus size={20} />
                        {t('actions:add_sort', 'Add sort')}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      align={'start'}
                      className={
                        'tw-max-h-[var(--radix-popper-available-height)] tw-overflow-y-auto'
                      }
                    >
                      <SortCommandList
                        sortList={availableSorts}
                        onSelect={addSort}
                        commandListProps={{ className: 'tw-max-h-none' }}
                      />
                    </PopoverContent>
                  </Popover>
                }
              >
                <div className={'tw-flex tw-grow tw-flex-col tw-gap-4'}>
                  {sorts?.map((sort, index) => (
                    <SortMenuItem
                      key={index}
                      item={sort}
                      allSorts={sortList}
                      sortList={availableSorts}
                      updateSort={updateSort}
                      removeSort={removeSort}
                      getSortOrderOptions={getSortOrderOptions}
                    />
                  ))}

                  <div>
                    <Popover
                      modal={true}
                      open={additionSortMenuOpen}
                      onOpenChange={(open) => setAdditionSortMenuOpen(open)}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant={'text'}
                          size={'iconStartSm'}
                          disabled={!availableSorts.length}
                        >
                          <Plus size={20} />
                          {t('actions:add_another_sort', 'Add another sort')}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent align={'start'}>
                        <SortCommandList
                          sortList={availableSorts}
                          onSelect={(value) => {
                            addSort(value);
                            setAdditionSortMenuOpen(false);
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </If>
            </div>
          </ScrollArea>
        </ErrorBoundary>
      </div>
    );
  },
);

RequestsDrawerSortContent.displayName = 'RequestsDrawerSortContent';

export { RequestsFilterDrawer };
