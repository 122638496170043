import React from 'react';
import { ImageMedia } from './ImageMedia';
import { CenteredSpinner } from 'src/components/Spinner';
import { useSvgObjectUrl } from './useSvgObjectUrl';
import NotFoundImage from 'src/assets/images/404-error.png';

type Props = React.ComponentPropsWithoutRef<'img'> & {
  src: string;
};

const SVGMedia: React.FC<Props> = ({ src, ...props }) => {
  const { data: blobUrl, isLoading, isError } = useSvgObjectUrl(src);

  return isLoading ? (
    <CenteredSpinner />
  ) : isError ? (
    <ImageMedia src={NotFoundImage} {...props} />
  ) : (
    <ImageMedia src={blobUrl} {...props} />
  );
};

export { SVGMedia };
