import { useTaskQuery } from 'src/api/queries';
import { Api } from '../../api';
import {
  TaskResponse,
  TaskStatus,
  taskStatus,
  UpdateTaskRequest,
} from '../../api/services/TaskClient';
import { UseQueryOptionsObject } from '../../api/queries/queries.utils';

const useTask = (taskId: string, options?: UseQueryOptionsObject<TaskResponse>) => {
  const { data, setQueryData } = useTaskQuery(taskId, options);

  const isStale = data?.status === taskStatus.stale,
    isDone = data?.status === taskStatus.done;

  const changeStatus = async (status: TaskStatus) => Api.task.changeStatus(taskId, status);

  const complete = async () => {
    await Api.task.complete(taskId).then(() => setQueryData({ status: taskStatus.done }));
  };

  const update = async (data: UpdateTaskRequest) =>
    Api.task.update(taskId, data).then((response) => setQueryData(response));

  const remove = async () => Api.task.delete(taskId);

  return {
    ...(data ?? {}),
    isStale,
    isDone,
    complete,
    update,
    remove,
    changeStatus,
  };
};

export { useTask };
