import { AxiosHttpError, FormErrorsResponse, HttpErrorResponse } from './Http.types';

class HttpError implements HttpErrorResponse<FormErrorsResponse> {
  public readonly message: string;
  public readonly code: number | string;
  public readonly errors?: { form: FormErrorsResponse };

  constructor(error: AxiosHttpError) {
    this.message =
      error.response?.data.message ??
      error.response?.data.error ??
      error.message ??
      'Unknown error';
    this.code = error.response?.status ?? error.code ?? 0;
    this.errors = error.response?.data?.errors;
  }
}

export { HttpError };
