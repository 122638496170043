import { createQueryParams, mergeQueryParams } from 'src/lib/services/api-query-params';
import { userStatus, userType } from 'src/lib/services/api/profile-api';

const createUsersFilterQuery = () =>
  createQueryParams({
    sorts: [
      {
        id: 'first_name',
        desc: false,
      },
    ],
  });

const createEditorsFilterQuery = () =>
  mergeQueryParams(createUsersFilterQuery(), {
    filters: [
      {
        id: 'type',
        value: [userType.teamLead, userType.editor],
      },
      { id: 'status', value: userStatus.active },
    ],
  });

const createTeamLeadsFilterQuery = () =>
  mergeQueryParams(createUsersFilterQuery(), {
    filters: [
      {
        id: 'type',
        value: [userType.teamLead],
      },
      { id: 'status', value: userStatus.active },
    ],
  });

export const createEditorRequestsFilterQuery = (editorId: string) =>
  createQueryParams({
    filters: [
      {
        id: 'editor',
        value: editorId,
      },
    ],
  });

export { createUsersFilterQuery, createEditorsFilterQuery, createTeamLeadsFilterQuery };
