import { useMakeQuery } from 'src/api/queries/useMakeQuery';

const useSvgObjectUrl = (url: string) => {
  return useMakeQuery<string>(
    [url],
    () =>
      fetch(url)
        .then((r) => r.blob())
        .then(
          (b) =>
            new File([b], 'svg-image.svg', {
              type: 'image/svg+xml',
            }),
        )
        .then((f) => URL.createObjectURL(f)),
    {
      staleTime: 60 * 1000,
    },
  );
};

export { useSvgObjectUrl };
