import { UserType } from 'src/lib/services/api/profile-api';
import { ValueOfObject } from 'src/types/generics';

const teamMemberRole = {
  editor: 'editor',
  teamLead: 'team_lead',
  substituteTeamLead: 'substitute_team_lead',
} as const;

type AvailableUserResponse = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: UserType;
};

type TeamMemberRole = ValueOfObject<typeof teamMemberRole>;

type AssignmentSubscriptionObject = {
  id: string;
  name: string;
  slug: string;
};

type TeamMemberAssignment = {
  id: string;
  company: {
    id: string;
    name: string;
  };
  plan: AssignmentSubscriptionObject;
  addons: AssignmentSubscriptionObject[];
  created_at: string;
};

type TeamMemberResponse = {
  id: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  role: TeamMemberRole;
  assignments: TeamMemberAssignment[];
  created_at: string;
};

type CreateTeamMemberRequest = {
  user_id: string;
  role: TeamMemberRole;
};

type UpdateTeamMemberRequest = {
  role: TeamMemberRole;
};

export type {
  AvailableUserResponse,
  AssignmentSubscriptionObject,
  TeamMemberAssignment,
  TeamMemberResponse,
  TeamMemberRole,
  CreateTeamMemberRequest,
  UpdateTeamMemberRequest,
};
export { teamMemberRole };
