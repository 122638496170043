import React from 'react';
import clsx from 'clsx';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>> & {
  fileName: string;
};

const FileName: React.FC<Props> = ({ fileName, ...props }) => {
  const ext = fileName.split('.').pop();

  return (
    <div className={clsx('d-flex', props.className)} {...props} title={fileName}>
      <p
        className={'m-0 text-truncate'}
        style={{
          maxWidth: '5ch',
        }}
      >
        {fileName}
      </p>
      <p className={'m-0'}>{ext}</p>
    </div>
  );
};

export { FileName };
