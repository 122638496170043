import React from 'react';
import { GripHorizontal, LucideProps } from 'lucide-react';
import { cn } from 'src/lib/utils';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  iconProps?: LucideProps;
};

const DraggableCardGrip: React.FC<Props> = ({ iconProps, className, ...props }) => {
  const { className: iconClassName, ...restIconProps } = iconProps || {};

  return (
    <div
      className={cn('tw-flex tw-cursor-grab tw-items-center tw-justify-center', className)}
      {...props}
    >
      <GripHorizontal
        className={cn('tw-h-5 tw-w-5 tw-text-neutral-400', iconClassName)}
        {...restIconProps}
      />
    </div>
  );
};

export { DraggableCardGrip };
