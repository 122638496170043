import { Client, PaginatedResponse } from 'src/api/Client';
import { CreateTeamRequest, TeamResponse, UpdateTeamRequest } from './TeamClient.types';
import { RequestsCountsResponse, RequestsTableResponse } from '../RequestClient';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { AxiosRequestConfig } from 'axios';

class TeamClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/admin/teams`,
    filterRequests: `${this.http.baseUrl}/admin/teams/{model}/requests`,
    getRequestsCounts: `${this.http.baseUrl}/admin/teams/{model}/requests-counts`,
    create: `${this.http.baseUrl}/admin/teams`,
    view: `${this.http.baseUrl}/admin/teams/{model}`,
    update: `${this.http.baseUrl}/admin/teams/{model}`,
    delete: `${this.http.baseUrl}/admin/teams/{model}`,
  };

  filter = (params?: ApiQueryObject): Promise<PaginatedResponse<TeamResponse>> =>
    this.http.get(this.route.filter, { params });

  filterRequests = (
    model: string,
    config?: AxiosRequestConfig,
  ): Promise<PaginatedResponse<RequestsTableResponse>> =>
    this.http.get(this.buildUrl(this.route.filterRequests, { model }), config);

  getRequestsCounts = (model: string): Promise<RequestsCountsResponse> =>
    this.http.get(this.buildUrl(this.route.getRequestsCounts, { model }));

  create = (data: CreateTeamRequest): Promise<TeamResponse> =>
    this.http.post(this.route.create, data);

  view = (model: string): Promise<TeamResponse> =>
    this.http.get(this.buildUrl(this.route.view, { model }));

  update = (model: string, data: UpdateTeamRequest): Promise<TeamResponse> =>
    this.http.put(this.buildUrl(this.route.update, { model }), data);

  delete = (model: string): Promise<void> =>
    this.http.delete(this.buildUrl(this.route.delete, { model }));
}

export { TeamClient };
