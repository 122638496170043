import React, { ElementType } from 'react';
import { ModalProps } from 'reactstrap';
import { useToggle } from 'src/helpers/useToggle';

type ModalPropsObject = Omit<ModalProps, 'onSubmit'>;

type Props<
  ModalProps extends ModalPropsObject,
  TogglerProps extends React.ComponentPropsWithoutRef<ElementType>,
> = React.PropsWithChildren<TogglerProps> & {
  modal:
    | React.ComponentType<ModalProps>
    | React.ComponentClass<ModalProps>
    | React.FunctionComponent<ModalProps>;
  modalProps?: Partial<Omit<ModalProps, 'isOpen' | 'toggle'>>;
  initialOpen?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  toggler:
    | React.ComponentType<TogglerProps>
    | React.ComponentClass<TogglerProps>
    | React.FunctionComponent<TogglerProps>;
};

const ModalToggler = <
  ModalProps extends ModalPropsObject,
  TogglerProps extends Record<string, any>,
>({
  modal: Modal,
  modalProps = {},
  initialOpen = false,
  toggler: Toggler,
  onClick,
  ...props
}: Props<ModalProps, TogglerProps>) => {
  const [isOpen, setOpen, toggle] = useToggle(initialOpen);

  return (
    <>
      <Toggler
        {...(props as TogglerProps)}
        onClick={(e: any) => {
          e.preventDefault();
          setOpen(true);
          onClick?.(e);
        }}
      >
        {props.children}
      </Toggler>
      <Modal {...(modalProps as ModalProps)} isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export { ModalToggler };
