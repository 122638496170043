import { useTranslation } from 'react-i18next';
import { SortItem } from 'src/lib/services/api-query-params/use-sorts';
import { useParams } from 'react-router-dom';

const useRequestSorts = () => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();

  const sortList: SortItem[] = [
    {
      label: t('pages/shared:requests_list.column.title', 'Request name'),
      keywords: ['request', 'name', 'title'],
      sort: {
        id: 'title',
        desc: false,
      },
    },
    {
      label: t('pages/shared:requests_list.column.status', 'Status'),
      keywords: ['status'],
      sort: {
        id: 'status',
        desc: false,
      },
    },
    {
      label: t('pages/shared:requests_list.column.priority', 'Priority'),
      keywords: ['priority'],
      format: 'number',
      sort: {
        id: 'priority',
        desc: true,
      },
    },
    {
      label: t('pages/shared:requests_list.column.needs_attention', 'Needs attention'),
      keywords: ['needs', 'attention'],
      format: 'date',
      sort: {
        id: 'requested_attention_at',
        desc: true,
      },
    },
    {
      label: t('pages/shared:requests_list.column.needs_reedit', 'Needs re-edit'),
      keywords: ['needs', 're-edit', 'reedit', 'edit'],
      format: 'date',
      sort: {
        id: 'requested_reedit_at',
        desc: true,
      },
    },
    {
      label: t('pages/shared:requests_list.column.approved', 'Approved'),
      keywords: ['approved'],
      format: 'date',
      sort: {
        id: 'approved_at',
        desc: true,
      },
    },
    {
      label: t('pages/shared:requests_list.column.recently_updated', 'Recently updated'),
      keywords: ['recently', 'updated'],
      format: 'date',
      sort: {
        id: 'reviewed_by_customer',
        desc: true,
      },
    },
    {
      label: t('pages/shared:requests_list.column.editor_id', 'Editor'),
      keywords: ['editor'],
      sort: {
        id: 'assigned_editor',
        desc: false,
      },
    },
    {
      label: t('pages/shared:requests_list.column.brand_id', 'Brand'),
      keywords: ['brand'],
      sort: {
        id: 'brand',
        desc: false,
      },
    },
    {
      label: t('pages/shared:requests_list.column._status_changed_at', 'Status changed at'),
      keywords: ['status', 'changed', 'at'],
      format: 'date',
      sort: {
        id: 'status_changed_at',
        desc: true,
      },
    },
    {
      label: t('QA Check'),
      keywords: ['qa', 'checked'],
      format: 'boolean',
      sort: {
        id: 'qa_checked',
        desc: true,
      },
    },
    {
      label: t('pages/shared:requests_list.column.submitted_at', 'Submitted at'),
      keywords: ['submitted', 'at'],
      format: 'date',
      sort: {
        id: 'submitted_at',
        desc: true,
      },
    },
    {
      label: t('pages/shared:requests_list.column.created_at', 'Created at'),
      keywords: ['created', 'at'],
      format: 'date',
      sort: {
        id: 'created_at',
        desc: true,
      },
    },
  ];

  // if not in company context, there will be a company column, so add company sort too
  if (!companyId) {
    sortList.splice(1, 0, {
      label: t('pages/shared:requests_list.column.company', 'Company'),
      keywords: ['company'],
      sort: {
        id: 'company_name',
        desc: false,
      },
    });
  }

  return { sortList };
};

export { useRequestSorts };
