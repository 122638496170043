import React, { useEffect } from 'react';
import { useGenerateMultipartUpload } from 'src/components/Form/S3UploadInput';
import { useFormikContext } from 'formik';
import { DropzoneProps, DropzoneRef, DropzoneState, useDropzone } from 'react-dropzone';
import { MediaObject } from 'src/api/services/MediaClient';

type Props = Omit<DropzoneProps, 'children'> &
  React.RefAttributes<DropzoneRef> & {
    model: string;
    children: (state: DropzoneState) => JSX.Element;
    onGeneratedMultipartUpload?: (uploads: MediaObject[]) => void;
  };

const DropzoneInput: React.FC<Props> = ({
  children,
  onDrop,
  model,
  onGeneratedMultipartUpload,
  ...props
}) => {
  const { isSubmitting } = useFormikContext();
  const { generateUpload } = useGenerateMultipartUpload();

  const dropzone = useDropzone({
    onDrop: async (files, ...rest) => {
      await generateUpload(model, files)
        .then((uploads) => {
          onGeneratedMultipartUpload?.(uploads);
        })
        .catch(() => ({}));

      onDrop?.(files, ...rest);
    },
    disabled: isSubmitting || props.disabled,
    ...props,
  });

  const rootRef = dropzone.rootRef;

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    const wrapper = rootRef.current;

    const addStyles = () => {
      if (!wrapper.classList.contains('bg-dropzone')) {
        wrapper.classList.add('bg-dropzone');
      }

      for (const child of wrapper.children) {
        if (!child.classList.contains('tw-hidden')) {
          child.classList.add('tw-hidden');
        }
      }
    };

    const removeStyles = () => {
      wrapper.classList.remove('bg-dropzone');

      for (const child of wrapper.children) {
        child.classList.remove('tw-hidden');
      }
    };

    const dragOverHandler = () => {
      addStyles();
    };

    const dropHandler = () => {
      removeStyles();
    };

    const dragLeaveHandler = (e: DragEvent) => {
      if (e.relatedTarget === null) {
        removeStyles();
      }
    };

    window.addEventListener('dragover', dragOverHandler);
    window.addEventListener('drop', dropHandler);
    window.addEventListener('dragleave', dragLeaveHandler);

    return () => {
      window.removeEventListener('dragover', dragOverHandler);
      window.removeEventListener('drop', dropHandler);
      window.removeEventListener('dragleave', dragLeaveHandler);
    };
  }, [dropzone.rootRef]);

  return children(dropzone);
};

export type { Props as DropzoneInputProps };
export { DropzoneInput };
