import React from 'react';
import { components, MultiValueRemoveProps } from 'react-select';
import { X } from 'lucide-react';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { GroupBase } from 'react-select/dist/declarations/src/types';

type Props<T extends Record<string, any>> = MultiValueRemoveProps<
  OptionObject<T>,
  boolean,
  GroupBase<OptionObject<T>>
>;

const ReactSelectMultiValueRemove = <T extends Record<string, any>>(
  props: Props<T>,
): JSX.Element => {
  return (
    <components.MultiValueRemove {...props}>
      <X className={'tw-h-4 tw-w-4'} />
    </components.MultiValueRemove>
  );
};

export { ReactSelectMultiValueRemove };
