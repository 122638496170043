import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { OptionObject, ReactSelectHookFormInput } from 'src/components/ReactSelectInput';
import { If } from 'src/components/If';
import { ErrorMessage } from 'src/components/Form/hook-form/error-message';
import { Label } from 'src/components/ui/label';
import {
  descriptionTotalLength,
  lengthObjectToString,
  lengthStringToObject,
} from './request-field.helper';
import { SetValueConfig } from 'react-hook-form/dist/types/form';

type Props = {
  name: string;
  typeName: string;
};

const otherValue = '-other-';
const otherOption = { label: 'Other', value: otherValue };

const TotalLengthSelect: React.FC<Props> = ({ name, typeName }) => {
  const { register, setValue, getValues, trigger } = useFormContext();

  const requestType = useWatch({ name: typeName });

  const [isCustomInputShown, setIsCustomInputShown] = useState(false);

  const selectName = `${name}.text`;
  const valueName = `${name}.value`;
  const unitName = `${name}.unit`;

  const setFieldValue = (name: string, value: any, config?: SetValueConfig) => {
    setValue(name, value, { shouldValidate: true, ...config });
  };

  // Get select options based on request type
  const options = useMemo(() => {
    const selectOptions =
      descriptionTotalLength.find((obj) => obj.types.includes(requestType))?.attributes.options ??
      [];

    return [...selectOptions, otherOption] as OptionObject[];
  }, [requestType]);

  const handleSelectChange = (value: string) => {
    // need to manually trigger select input validation, because unit/value fields are being validated
    trigger(selectName);

    // Show custom value inputs if option is 'Other'
    if (value === otherValue) {
      setIsCustomInputShown(true);
      return;
    }

    setIsCustomInputShown(false);
    const valueObject = lengthStringToObject(value);

    setFieldValue(valueName, valueObject?.value);
    setFieldValue(unitName, valueObject?.unit);
  };

  const resetFields = () => {
    setFieldValue(selectName, null, { shouldValidate: false });
    setFieldValue(valueName, undefined, { shouldValidate: false });
    setFieldValue(unitName, undefined, { shouldValidate: false });
    setIsCustomInputShown(false);
  };

  useEffect(() => {
    // sync select and custom input values
    if (!requestType) {
      resetFields();
      return;
    }

    if (getValues(valueName)) {
      const predefinedValue = lengthObjectToString({
        value: getValues(valueName),
        unit: getValues(unitName),
      });

      if (predefinedValue && options.map((o) => o.value).includes(predefinedValue)) {
        setFieldValue(selectName, predefinedValue);
        setIsCustomInputShown(false);
      } else {
        setFieldValue(selectName, otherValue);
        setIsCustomInputShown(true);
      }
    }
  }, [requestType]);

  return (
    <>
      <ReactSelectHookFormInput
        id={selectName}
        name={selectName}
        isDisabled={options.length <= 1}
        options={options}
        handleChange={handleSelectChange}
      />
      <If when={isCustomInputShown}>
        <Label className={'tw-mt-3'} htmlFor={valueName}>
          Up to
        </Label>
        <div className="tw-grid tw-grid-cols-12 tw-gap-4">
          <div className="tw-col-span-6 md:tw-col-span-8 lg:tw-col-span-9">
            <Input
              id={valueName}
              type="number"
              placeholder={'0'}
              min={1}
              {...register(valueName, { valueAsNumber: true })}
            />
            <ErrorMessage name={valueName} />
          </div>
          <div className={'tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3'}>
            <ReactSelectHookFormInput
              id={unitName}
              name={unitName}
              options={[
                { label: 'Sec', value: 'sec' },
                { label: 'Min', value: 'min' },
              ]}
            />
            <ErrorMessage name={unitName} />
          </div>
        </div>
      </If>
    </>
  );
};

export { TotalLengthSelect };
