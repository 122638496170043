import React from 'react';
import { cn } from 'src/lib/utils';

type PageHeadingProps = React.ComponentPropsWithoutRef<'header'>;

export const PageHeading = React.forwardRef<HTMLHeadingElement, PageHeadingProps>(
  ({ className, ...props }, ref) => (
    <header
      ref={ref}
      {...props}
      className={cn('tw-inline-flex tw-flex-col tw-items-start tw-gap-2', className)}
    />
  ),
);
PageHeading.displayName = 'PageHeading';

type PageHeadingTitleProps = React.ComponentPropsWithoutRef<'h1'> & {
  icon?: React.ReactNode;
};

export const PageHeadingTitle = React.forwardRef<HTMLHeadingElement, PageHeadingTitleProps>(
  ({ className, children, ...props }, ref) => (
    <h1
      ref={ref}
      {...props}
      className={cn(
        'tw-hidden tw-flex-wrap tw-items-center tw-gap-2 tw-break-all tw-text-2xl tw-font-semibold tw-leading-7 tw-tracking-tight tw-text-text md:tw-inline-flex ',
        className,
      )}
    >
      {children}
    </h1>
  ),
);
PageHeadingTitle.displayName = 'PageHeadingTitle';
