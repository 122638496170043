import React from 'react';
import {
  ApiQueryObject,
  ApiQueryParams,
  mergeQueryParams,
} from 'src/lib/services/api-query-params';
import { parseEntriesToOptions } from 'src/components/ReactSelectInput';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/lib/utils';
import { ApiQueryParamsFilterFieldProps } from 'src/lib/services/api-query-params/api-query-params-filter-field';
import { authQueries } from 'src/api/queries';
import { Api } from 'src/api';
import { createCompaniesFilterQuery } from 'src/features/companies';

type Props = Omit<ApiQueryParamsFilterFieldProps, 'name'> & {
  name?: string;
  companyFilter?: ApiQueryObject;
};

const companiesFilterQuery = mergeQueryParams(createCompaniesFilterQuery(), {
  limit: 25,
});

const CompanyFilter: React.FC<Props> = ({
  name = 'company',
  companyFilter,
  isSearchable = true,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const filterQuery = companyFilter
    ? mergeQueryParams(companyFilter, companiesFilterQuery)
    : companiesFilterQuery;

  return (
    <ApiQueryParams.asyncFilter
      queryOptions={{
        queryKey: [authQueries.companies],
        queryFn: ({ signal }, filters) =>
          Api.company.options(mergeQueryParams(filters, filterQuery), {
            signal,
          }),
        staleTime: 60 * 1000,
      }}
      name={name}
      className={cn('[&>.select-control]:tw-h-11', className)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore idk why is u mad
      options={(pages) => {
        const flat = pages?.flatMap((page) => page.items) ?? [];

        return parseEntriesToOptions(flat, {
          label: 'name',
          value: 'id',
        });
      }}
      isSearchable={isSearchable}
      placeholder={t('name.singular', { ns: 'models/company' })}
      {...props}
    />
  );
};

export { CompanyFilter };
