import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { VideoPlayerContextProvider } from 'src/features/video-player/video-player';
import {
  decorateMedia,
  getPreferredSource,
  makeSourceOptions,
} from 'src/features/media/media.utils';
import { ComposedVideoPlayer } from 'src/features/video-player/composed-video-player';
import { SVGMedia } from 'src/components/MediaCard/SVGMedia';
import { FileIcon } from 'src/components/FileIcon';
import { StringParam, useQueryParam } from 'use-query-params';
import { formatSize } from 'src/helpers/file';

type ComposedMediaPreviewDialogProps = {
  media: ReturnType<typeof decorateMedia>;
  open?: string | undefined;
  onOpenChange?: (target: string) => void;
} & Omit<React.ComponentPropsWithoutRef<typeof Dialog>, 'open' | 'onOpenChange'>;

export const ComposedMediaPreviewDialog: React.FC<ComposedMediaPreviewDialogProps> = ({
  media,
  children,
  open,
  onOpenChange,
  ...props
}) => {
  const [dialogOpen, setDialogOpen] = useQueryParam(
    'media-preview',
    { ...StringParam, default: open },
    {
      updateType: 'replaceIn',
      includeAllParams: false,
    },
  );
  const handleOpenChange = (open: boolean) => {
    setDialogOpen(open ? media.id : undefined);
    onOpenChange?.(open ? media.id : '');
  };
  const isOpen = dialogOpen === media.id;

  return (
    <Dialog {...props} open={isOpen} onOpenChange={handleOpenChange}>
      {children}
      <DialogContent close={true} size={'xl'} className={'tw-max-h-[95vh] tw-overflow-auto'}>
        <DialogHeader className={'tw-flex-col tw-items-start tw-gap-4'}>
          <DialogTitle className={'tw-line-clamp-2'}>{media.file_name}</DialogTitle>
          <DialogDescription>
            <ul>
              <li>File name: {media.file_name}</li>
              <li>Mime type: {media.mime_type}</li>
              <li>File size: {formatSize(media.size)}</li>
            </ul>
          </DialogDescription>
        </DialogHeader>
        <AspectRatio ratio={16 / 9}>
          {media.canPlay ? (
            <VideoPlayerContextProvider
              meta={{
                fps: media.fps,
                durationInSeconds: media.durationInSeconds,
              }}
              defaultSrc={getPreferredSource(media)}
              srcOptions={makeSourceOptions(media)}
            >
              <ComposedVideoPlayer
                enableCustomTimeFormats={media.hasFps}
                ratio={16 / 9}
                poster={media.poster}
              />
            </VideoPlayerContextProvider>
          ) : media.isImage ? (
            <>
              {media.isSvg ? (
                <SVGMedia
                  src={media.index_url}
                  className={'!tw-mx-auto !tw-h-full !tw-w-auto !tw-rounded-lg !tw-object-contain'}
                />
              ) : (
                <img
                  className={'tw-mx-auto tw-h-full tw-rounded-lg tw-object-contain'}
                  alt={media.file_name}
                  title={media.file_name}
                  src={media.index_url}
                />
              )}
            </>
          ) : (
            <div
              className={
                'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-lg tw-rounded-t-md tw-bg-indigo-200'
              }
            >
              <FileIcon mimeType={media.mime_type} size={36} className={'tw-text-dark-800'} />
            </div>
          )}
        </AspectRatio>
      </DialogContent>
    </Dialog>
  );
};
