import { t } from 'i18next';
import { useMutation } from '@tanstack/react-query';
import { requestClient } from 'src/lib/services/api/request-api';
import { Api } from 'src/api';
import { decorateMedia } from 'src/features/media';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { requestStatus } from 'src/api/services/RequestClient';
import { useRequestQueryContext } from 'src/features/requests/use-request-query-context';
import { createWithEqualityFn } from 'zustand/traditional';
import { UpdateRevisionRequest } from 'src/api/services/RevisionClient';

type RevisionsCollection = Awaited<ReturnType<typeof requestClient.get>>['revisions'];
type RevisionItem = RevisionsCollection[number];

type DecoratedRevision = {
  raw: RevisionItem;
  id: string;
  isVideoRevision: boolean;
  isFinalFiles: boolean;
  isFirstContact: boolean;
  isLatest: boolean;
  isOldest: boolean;
  /** @deprecated */
  hasAirtableProps: boolean;
  hasMedia: boolean;
  canDelete: boolean;
  i: number;
  title: string;
  media: ReturnType<typeof decorateMedia>[];
};
type DecoratedRevisionMediaItem = DecoratedRevision['media'][number];
type DecoratedRevisionsCollection = DecoratedRevision[];

export const decorateRevisions = (revisions: RevisionsCollection): DecoratedRevisionsCollection => {
  // eslint-disable-next-line
  let version = 0;
  const n = revisions.length;

  return revisions.map((revision, i) => {
    const isVideoRevision = revision.type === 'revision',
      isFinalFiles = revision.type === 'final_files',
      isFirstContact = revision.type === 'first_contact',
      title = isVideoRevision
        ? `${t('models/revision:version.singular', {
            defaultValue: 'Version',
          })} ${++version}`
        : isFinalFiles
          ? t('models/revision:type.final_files', {
              defaultValue: 'Final Files',
            })
          : t('models/revision:type.first_contact', {
              defaultValue: 'First Contact',
            });

    return {
      raw: revision,
      id: revision.id,
      isVideoRevision,
      isFinalFiles,
      isFirstContact,
      canDelete: !isFirstContact,
      isLatest: n === i + 1,
      isOldest: i === 0,
      hasAirtableProps: !!revision.airtable?.revision_url,
      hasMedia: !!revision?.media?.length,
      i,
      title,
      media: revision.media?.map((m) => decorateMedia(m)) ?? [],
    };
  });
};

type SelectedRevisionStoreObject = {
  selectedRevision?: string;
  selectedRevisionMedia?: string;
  setSelectedRevision: (selectedRevision?: string) => void;
  setSelectedRevisionMedia: (selectedRevisionMedia?: string) => void;
  set: (state: Partial<SelectedRevisionStoreObject>) => void;
  reset: () => void;
};

export const useSelectedRevisionStore = createWithEqualityFn<SelectedRevisionStoreObject>((set) => {
  return {
    selectedRevision: undefined,
    selectedRevisionMedia: undefined,

    setSelectedRevision: (selectedRevision) => set({ selectedRevision }),
    setSelectedRevisionMedia: (selectedRevisionMedia) => set({ selectedRevisionMedia }),
    reset: () => set({ selectedRevision: undefined, selectedRevisionMedia: undefined }),
    set,
  };
});

type RevisionControlInterface = {
  revisions: DecoratedRevisionsCollection;
  isEmpty: boolean;
  canSelectNext: boolean;
  canSelectPrevious: boolean;
  canComment: boolean;
  selectedRevision?: DecoratedRevision;
  selectedRevisionMedia?: DecoratedRevisionMediaItem;
  isRevisionSelected: (revision: DecoratedRevision | string) => boolean;
  isRevisionMediaSelected: (media: DecoratedRevisionMediaItem | string) => boolean;
  select: (revision: DecoratedRevision | string) => void;
  selectNext: () => void;
  selectPrevious: () => void;
  selectOldest: () => void;
  selectLatest: () => void;
  updateRevisionMutation: ReturnType<
    typeof useMutation<void, unknown, [revision: string, data: UpdateRevisionRequest], unknown>
  >;
  deleteRevisionMutation: ReturnType<typeof useMutation<void, unknown, string, unknown>>;
  deleteMediaMutation: ReturnType<typeof useMutation<void, unknown, string, unknown>>;
  setSelectedRevision: (revision?: string) => void;
  setSelectedRevisionMedia: (selectedRevisionMedia?: string) => void;
  initialize: (bag?: DecoratedRevisionsCollection) => void;
  reset: () => void;
};

const getPreferredRevision = (
  revisions: DecoratedRevisionsCollection,
): DecoratedRevision | undefined => revisions.find((r) => r.isLatest);

const getPreferredMedia = (revision?: DecoratedRevision): DecoratedRevisionMediaItem | undefined =>
  revision?.media.find((m) => m?.isVideo) ?? revision?.media?.[0];

// Can only be used under request query context since it's all one big bag of things
// Really need to split this up
const useRequestRevisionsStore = (): RevisionControlInterface => {
  const {
    setSelectedRevision,
    selectedRevision: selectedRevisionId,
    selectedRevisionMedia: selectedRevisionMediaId,
    setSelectedRevisionMedia,
    reset,
    set,
  } = useSelectedRevisionStore();

  const request = useDecoratedRequestContext();
  const requestQueryContext = useRequestQueryContext();

  const decoratedRevisions = decorateRevisions(request.revisions);

  const selectPreferredRevision = (bag?: DecoratedRevisionsCollection) => {
    setSelectedRevision(getPreferredRevision(bag ?? decoratedRevisions)?.id);
  };

  const selectPreferredMedia = (revision?: DecoratedRevision) => {
    // Passed or latest
    const resolvedRevision = revision ?? decoratedRevisions.find((r) => r.isLatest);
    setSelectedRevisionMedia(getPreferredMedia(resolvedRevision)?.id);
  };

  const initialize = (bag?: DecoratedRevisionsCollection) => {
    const preferredRevision = getPreferredRevision(bag ?? decoratedRevisions);
    const preferredMedia = getPreferredMedia(preferredRevision);

    set({
      selectedRevision: preferredRevision?.id,
      selectedRevisionMedia: preferredMedia?.id,
    });
  };

  const updateRevisionMutation = useMutation({
    mutationFn: ([revision, data]: [revision: string, data: UpdateRevisionRequest]) =>
      Api.revision.update(revision, data),
    onSuccess: async (data, [revision]) => {
      const queryResponse = await requestQueryContext.refetch();

      const decoratedRevisions = decorateRevisions(queryResponse.data?.revisions ?? []);
      const updatedRevision = decoratedRevisions.find((r) => r.raw.id === revision);

      // Revision already had media, and selected media is in the updated revision, skip
      if (
        selectedRevisionMediaId &&
        updatedRevision?.media.find((m) => m?.id === selectedRevisionMediaId)
      ) {
        return;
      }

      // Select freshly uploaded media
      selectPreferredMedia(updatedRevision);
    },
  });

  const deleteRevisionMutation = useMutation({
    mutationFn: (revision: string) => Api.revision.destroy(revision),
    onSuccess: async () => {
      const queryResponse = await requestQueryContext.refetch();
      const decoratedRevisions = decorateRevisions(queryResponse.data?.revisions ?? []);
      selectPreferredRevision(decoratedRevisions);
      selectPreferredMedia(decoratedRevisions.find((r) => r.isLatest));
    },
  });

  const deleteMediaMutation = useMutation({
    mutationFn: (media: string) => Api.media.delete(media),
    onSuccess: async (response, media) => {
      const queryResponse = await requestQueryContext.refetch();

      const decoratedRevisions = decorateRevisions(queryResponse.data?.revisions ?? []);
      // Find revision by media
      const updatedRevision = decoratedRevisions.find((r) => r.media.find((m) => m?.id === media));

      // Revision already had media, and selected media is in the updated revision, skip
      if (
        selectedRevisionMediaId &&
        updatedRevision?.media.find((m) => m?.id === selectedRevisionMediaId)
      ) {
        return;
      }

      selectPreferredMedia(updatedRevision);
    },
  });

  const requestRevisions = decoratedRevisions,
    revisionsCount = requestRevisions.length,
    selectedRevision = requestRevisions.find((r) => r.id === selectedRevisionId),
    selectedRevisionMedia = selectedRevision?.media.find((m) => m?.id === selectedRevisionMediaId),
    isEmpty = !revisionsCount,
    canSelectNext = !isEmpty && !selectedRevision?.isLatest,
    canSelectPrevious = !isEmpty && !selectedRevision?.isOldest,
    canComment = !!(selectedRevision?.isLatest && !request.isOfStatus(requestStatus.complete));

  const isRevisionSelected = (revision: DecoratedRevision | string): boolean =>
    typeof revision === 'string'
      ? selectedRevision?.id === revision
      : selectedRevision?.id === revision.id;
  const isRevisionMediaSelected = (media: DecoratedRevisionMediaItem | string): boolean =>
    typeof media === 'string'
      ? selectedRevisionMedia?.id === media
      : selectedRevisionMedia?.id === media?.id;

  const select = (revision: string | DecoratedRevision): void => {
    const next =
      typeof revision === 'string'
        ? decoratedRevisions.find((r) => r.raw.id === revision)
        : revision;

    setSelectedRevision(next?.id);
    selectPreferredMedia(next);
  };

  const selectNext = (): void => {
    const index = decoratedRevisions.findIndex((r) => r.raw.id === selectedRevision?.raw.id);
    if (index < 0) {
      return;
    }

    const nextIndex = index + 1;
    const nextRevision = decoratedRevisions?.[nextIndex];

    select(nextRevision);
  };

  const selectPrevious = (): void => {
    const index = decoratedRevisions.findIndex((r) => r.raw.id === selectedRevision?.raw.id);
    const prevIndex = index - 1;
    if (index < 0) {
      return;
    }

    const prevRevision = decoratedRevisions?.[prevIndex];

    select(prevRevision);
  };

  const selectLatest = (): void => {
    select(decoratedRevisions?.[0]);
  };

  const selectOldest = (): void => {
    select(decoratedRevisions?.[decoratedRevisions.length - 1]);
  };

  return {
    revisions: decoratedRevisions,
    isEmpty,
    canSelectNext,
    canSelectPrevious,
    canComment,
    selectedRevision,
    selectedRevisionMedia,
    isRevisionSelected,
    isRevisionMediaSelected,
    select,
    selectNext,
    selectPrevious,
    selectOldest,
    selectLatest,
    updateRevisionMutation,
    deleteRevisionMutation,
    deleteMediaMutation,
    setSelectedRevision,
    setSelectedRevisionMedia,
    initialize,
    reset,
  };
};

export { useRequestRevisionsStore };
