import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Spinner } from 'src/components/Spinner';

interface Props {
  loading: boolean;
}

const ModalLoader: React.FC<Props> = ({ loading }): JSX.Element => {
  return (
    <Modal
      isOpen={loading}
      size={'sm'}
      centered={true}
      color={'transparent'}
      contentClassName={'bg-transparent border-0'}
    >
      <ModalBody>
        <Spinner className={'text-white'} />
      </ModalBody>
    </Modal>
  );
};

export default ModalLoader;
