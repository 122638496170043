import React, { useRef } from 'react';
import { Form, Field, useFormikContext } from 'formik';
import { ChatInput, ChatInputActionsRow, CloudUploadIcon } from '@becreatives/becreatives-ui';
import { DropzonePopoverInput } from 'src/components/Form/S3UploadInput';
import { useToggle } from 'src/helpers/useToggle';
import { StoreCommentFormObject } from '../RevisionChatContent.types';
import { useTranslation } from 'react-i18next';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { shallow } from 'zustand/shallow';
import { ElapsedTimeBadge } from 'src/pages/RevisionsPage/UpdatedDesign/RevisionsContentMain/RevisionChatContent/ChatInputCommentForm/elapsed-time-badge';
import { Button } from 'src/components/ui/button';
import { useRequestRevisionVideoPlayerGlobalStore } from 'src/features/revisions/use-request-revision-video-player-global-store';

const ChatInputCommentForm: React.FC = () => {
  const { t } = useTranslation('pages/shared');

  // :)
  const pageStore = useRequestRevisionVideoPlayerGlobalStore(
    ({ timeFormat, pause }) => ({
      timeFormat,
      pause,
    }),
    shallow,
  );

  const { submitForm, values, isSubmitting } = useFormikContext<StoreCommentFormObject>();
  const { selectedRevisionMedia } = useRequestRevisionsStore();

  const [isOpen, , toggle] = useToggle();
  const togglerRef = useRef(null);

  const { isUploading, isFailed } = useMediaUploadStore();
  const mediaUploaded = (values.media ?? []).every((m) => !isUploading(m.id));
  const uploadsFailed = (values.media ?? []).some((m) => isFailed(m.id));

  const canSubmit = mediaUploaded && !isSubmitting && !uploadsFailed;

  return (
    <Form
      onKeyDown={(e) => {
        if (e.shiftKey || e.code !== 'Enter') {
          return;
        }

        e.preventDefault();

        if (!canSubmit) {
          return;
        }

        submitForm();
      }}
    >
      <Field
        as={ChatInput}
        name={'comment'}
        color={'light'}
        rows={3}
        placeholder={t('forms.revision_comment.message.placeholder')}
        onKeyDown={() => {
          if (values.include_time) {
            pageStore.pause();
          }
        }}
      />
      <ChatInputActionsRow>
        <DropzonePopoverInput
          name={'media'}
          model={'request_revision_comment'}
          popoverProps={{
            isOpen,
            toggle,
            target: togglerRef,
            placement: 'top',
          }}
        >
          <CloudUploadIcon size={'lg'} color={'dark'} onClick={toggle} ref={togglerRef} />
        </DropzonePopoverInput>

        <ElapsedTimeBadge
          color={'secondary'}
          $centeredContent={true}
          className={'ms-auto gap-2 fs-6'}
          title={t('forms.revision_comment.include_time.title')!}
          $disabled={!(selectedRevisionMedia?.isVideo || selectedRevisionMedia?.isAudio)}
          formattingOptions={{
            format: pageStore.timeFormat,
            fps: selectedRevisionMedia?.fps ?? 24,
          }}
        />

        <Button disabled={!canSubmit} type={'submit'}>
          {t('actions:send', {
            defaultValue: 'Send',
          })}
        </Button>
      </ChatInputActionsRow>
    </Form>
  );
};

export { ChatInputCommentForm };
