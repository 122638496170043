import styled from 'styled-components';
import { Card, CardBody, CardFooter, TooltipButton } from '@becreatives/becreatives-ui';
import { ButtonFC } from '@becreatives/becreatives-ui/dist/components/Button/Button.types';
import { MediaViewer } from '../MediaViewer';
import { Progress } from 'reactstrap';
import { HrefLink } from 'src/components/Link';

const StyledMiniMediaCard = styled(Card)`
  background: transparent;
  box-shadow: none;
  margin-bottom: 0;
  max-width: 4.375rem;
`;

const StyledMiniMediaCardBody = styled(CardBody)`
  width: 4.375rem;
  height: 3rem;
  max-height: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  padding: 0;
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 0.335rem;
`;

const StyledMiniMediaCardFooter = styled(CardFooter)`
  padding: 0;
  margin-top: 0.3rem;
`;

const StyledMiniMediaCardDownloadLink = styled(HrefLink)`
  width: 100%;
  padding: 0.2rem 0.5rem;
  font-size: 0.7rem;
`;

const StyledMiniMediaCardRemoveButton = styled(TooltipButton)`
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  background: white;
  border: none;
  color: #878787;
  z-index: 1;

  & > * {
    font-size: 1.4rem;
  }

  &:hover {
    background: white;
  }
` as ButtonFC;

const StyledMinimalMediaViewer = styled(MediaViewer)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.335rem;
`;

const MinimalMediaCardUploadProgress = styled(Progress)`
  align-self: end;
  width: 100%;
  border-radius: 0 0 0.335rem 0.335rem;
`;

export {
  StyledMiniMediaCard,
  StyledMiniMediaCardBody,
  StyledMiniMediaCardFooter,
  StyledMiniMediaCardDownloadLink,
  StyledMiniMediaCardRemoveButton,
  StyledMinimalMediaViewer,
  MinimalMediaCardUploadProgress,
};
