import React from 'react';
import { DropdownToggle, DropdownToggleProps, UncontrolledDropdown } from 'reactstrap';
import { UncontrolledDropdownProps } from 'reactstrap/types/lib/Dropdown';
import { DropdownMenuProps } from 'reactstrap/types/lib/DropdownMenu';
import { StyledDropdownItem, StyledDropdownMenu } from '@becreatives/becreatives-ui';
import { CallableChildren, render } from 'src/lib/utils';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, useApiQueryParams } from './use-api-query-params';

type Props = Omit<UncontrolledDropdownProps, 'toggle'> & {
  menu?: DropdownMenuProps;
  toggle?: Omit<DropdownToggleProps, 'children'> & {
    children?: CallableChildren<{ limit: number }>;
  };
  totalItems?: number;
  groupId?: string;
};

type ResolveCurrentPageFn = (
  prev: {
    page: number;
    limit: number;
  },
  newLimit: number,
) => number;

const ApiQueryParamsPaginationInput: React.FC<Props> = ({
  menu = {},
  toggle = {},
  direction = 'down',
  totalItems = 0,
  groupId,
  ...props
}) => {
  const { t } = useTranslation('pagination');

  const { setPagination, filters } = useApiQueryParams(groupId);

  const { limit = DEFAULT_PAGE_SIZE, page = DEFAULT_PAGE_INDEX } = filters;

  const resolveCurrentPage: ResolveCurrentPageFn = (prev, newLimit) => {
    const currentPage = prev.page;
    const currentLimit = prev.limit;

    let seenRecords = (currentPage + 1) * currentLimit;
    if (seenRecords > totalItems) {
      seenRecords = totalItems;
    }

    let calculatedPageIndex = Math.ceil(seenRecords / newLimit) - 1;
    if (0 > calculatedPageIndex) {
      calculatedPageIndex = DEFAULT_PAGE_INDEX;
    }

    return calculatedPageIndex;
  };

  const toggleChildren =
    toggle.children ??
    t('show_entries', {
      replace: {
        limit,
      },
    });

  return (
    <UncontrolledDropdown {...props} direction={direction}>
      <DropdownToggle {...toggle}>{render(toggleChildren, { limit })}</DropdownToggle>
      <StyledDropdownMenu {...menu}>
        {[10, 20, 30, 40, 50].map((limitOption) => (
          <StyledDropdownItem
            key={limitOption}
            onClick={() => {
              setPagination({
                limit: limitOption,
                page: resolveCurrentPage(
                  {
                    page,
                    limit,
                  },
                  limitOption,
                ),
              });
            }}
          >
            {limitOption}
          </StyledDropdownItem>
        ))}
      </StyledDropdownMenu>
    </UncontrolledDropdown>
  );
};

export type { Props as ApiQueryParamsPaginationInputProps };
export { ApiQueryParamsPaginationInput };
