import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  resize: none;
`;

const PluginTextAreaArea = styled.div`
  padding-bottom: 0.87rem;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
`;

const PluginTextAreaWrapper = styled.div`
  padding-bottom: 0;
`;

export { PluginTextAreaWrapper, StyledTextArea, PluginTextAreaArea };
