import React, { forwardRef } from 'react';
import { PluginTextAreaArea, PluginTextAreaWrapper, StyledTextArea } from './PluginTextArea.styled';

type Props = React.ComponentPropsWithRef<'textarea'> & {
  children?: React.ReactNode;
};

const PluginTextArea = forwardRef<
  HTMLTextAreaElement,
  React.ComponentPropsWithoutRef<'textarea'> & {
    children?: React.ReactNode;
  }
>(function PluginTextArea({ children, className = 'form-control', ...props }, ref) {
  return (
    <PluginTextAreaWrapper className={className}>
      <StyledTextArea {...props} ref={ref} />
      <PluginTextAreaArea>{children}</PluginTextAreaArea>
    </PluginTextAreaWrapper>
  );
});

export type { Props as PluginTextAreaProps };
export { PluginTextArea };
