import { Client } from 'src/api/Client';

class HealthCheck extends Client {
  public readonly Route = {
    READINESS: `${this.http.baseUrl}/admin/health-check/readiness`,
    LIVENESS: {
      BE: `${this.http.baseUrl}/admin/health-check/liveness/backend`,
      DB: `${this.http.baseUrl}/admin/health-check/liveness/database`,
      SCHEDULE: `${this.http.baseUrl}/admin/health-check/liveness/schedule`,
      QUEUE: `${this.http.baseUrl}/admin/health-check/liveness/queue`,
      S3: `${this.http.baseUrl}/admin/health-check/liveness/s3`,
      STRIPE: `${this.http.baseUrl}/admin/health-check/liveness/stripe`,
    },
    NOTIFICATION: {
      REQUEST_STATUS: `${this.http.baseUrl}/admin/health-check/notification/request_status_change`,
    },
  };

  pingApp = (): Promise<string> => {
    return this.http.get(this.Route.READINESS);
  };

  pingBackend = (): Promise<string> => {
    return this.http.get(this.Route.LIVENESS.BE);
  };

  pingDatabase = (): Promise<string> => {
    return this.http.get(this.Route.LIVENESS.DB);
  };

  pingScheduler = (): Promise<string> => {
    return this.http.get(this.Route.LIVENESS.SCHEDULE);
  };

  pingQueue = (): Promise<string> => {
    return this.http.get(this.Route.LIVENESS.QUEUE);
  };

  pingS3 = (): Promise<string> => {
    return this.http.get(this.Route.LIVENESS.S3);
  };

  pingStripe = (): Promise<string> => {
    return this.http.get(this.Route.LIVENESS.STRIPE);
  };

  sendRequestStatusChangeNotification = (): Promise<unknown> => {
    return this.http.get(this.Route.NOTIFICATION.REQUEST_STATUS);
  };
}

export default HealthCheck;
