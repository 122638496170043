import React, { ComponentProps } from 'react';
import { VariantProps } from 'class-variance-authority';
import { linkVariants } from 'src/components/ui/link';
import { cn } from 'src/lib/utils';

export type HrefProps = ComponentProps<'a'> & VariantProps<typeof linkVariants>;

const Href = React.forwardRef<HTMLAnchorElement, HrefProps>(
  ({ className, underline, variant, ...props }, ref) => {
    return (
      <a
        className={cn(
          linkVariants({
            variant,
            underline,
            className,
          }),
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Href.displayName = 'Href';

export { Href };
