import styled from 'styled-components';
import { assertThemedColor, Badge as BecBadge } from '@becreatives/becreatives-ui';

const OverridenBadge = styled(BecBadge)<{ $disabled?: boolean }>`
  border-radius: 0.313rem;
  color: ${({ theme, color = 'secondary' }) => assertThemedColor(theme, color, 'color')} !important;
  background-color: ${({ theme, color = 'secondary' }) =>
    assertThemedColor(theme, color, 'bgColor')} !important;

  ${({ $disabled }) => $disabled && ['opacity: 0.5;', 'pointer-events: none;']}
`;

export { OverridenBadge };
