import React from 'react';
import Spinner, { SpinnerProps } from '../Spinner';

type Props = SpinnerProps;

const FullPageSpinner: React.FC<Props> = (props) => {
  return (
    <div id={'preloader'}>
      <Spinner {...props} />
    </div>
  );
};

export default FullPageSpinner;
