import { create } from 'zustand';
import { ThemeType } from '@becreatives/becreatives-ui';
import { getActiveTheme } from './theme.utils';

const useThemeStore = create<{
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
}>((set) => ({
  theme: getActiveTheme(),
  setTheme: (theme) => set({ theme }),
}));

export { useThemeStore };
