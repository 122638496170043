import React from 'react';
import { KeyOfObject, ValueOfObject } from 'src/types/generics';
import { getObjectKeys } from 'src/helpers/getObjectKeys';
import {
  airtableBaseRoute,
  airtableBaseTableRoute,
  airtableRootRoute,
} from 'src/lib/router/airtable-routes';
import { RouteObject as ReactRouterDomObject } from 'react-router-dom';

type ViewRestricted = 'admin' | 'editor' | 'guest';

type RouteObject = ReactRouterDomObject & {
  layout: React.FunctionComponent<any> | React.ComponentClass<any> | React.ComponentType<any>;
  restricted?: ViewRestricted[];
};

const appRoutes = {
  root: '/',
  any: '*',
  notFound: '/404',
  impersonateStart: '/impersonate/start',
  impersonateStop: '/impersonate/stop/:userId',
  login: '/login',
  socialLogin: '/login/:provider/callback',
  forgotPassword: '/forgot-password',
  forgotPasswordReset: '/forgot-password-reset',
  usersList: '/users',
  createUser: '/users/user/create',
  user: '/users/user/:userId',
  userRequestsList: '/users/user/:userId/requests',
  subscriptionsList: '/subscriptions',
  // subscription: '/subscriptions/:subscriptionId',
  companiesList: '/companies',
  company: '/companies/company/:companyId',
  companyPersonnel: '/companies/company/:companyId/personnel',
  requestsList: '/requests',
  companyRequestsList: '/companies/company/:companyId/requests',
  request: '/companies/company/:companyId/requests/:requestId',
  requestRevisions: '/companies/company/:companyId/requests/:requestId/revisions',
  companyBrandsList: '/companies/company/:companyId/brands',
  brand: '/companies/company/:companyId/brands/brand/:brandId',
  profile: '/profile',
  healthCheck: '/health-check',
  teamsList: '/teams',
  team: '/teams/:teamId',
  teamRequests: '/teams/:teamId/requests',
  socialProviderCallback: '/social/:provider/callback',
  urlBuilder: '/url-builder',
  styleGallery: '/style-gallery',
  styleGalleryVideoTemplate: '/style-gallery/:videoTemplateId',
  styleGalleryVideoTemplateExamples: '/style-gallery/:videoTemplateId/examples',

  airtableRoot: airtableRootRoute.path!,
  airtableBase: airtableBaseRoute.path!,
  airtableBaseTable: airtableBaseTableRoute.path!,
} as const;

type AppRoutes = typeof appRoutes;

type AppPaths = ValueOfObject<AppRoutes>;

type AppPathsKeys = KeyOfObject<AppRoutes>;

type AppRoutesObject = Record<AppPathsKeys, RouteObject>;

const excludeRoutes = <T extends AppPathsKeys>(routes: T[]) => {
  return getObjectKeys(appRoutes).reduce(
    (result, route) => {
      if (!routes.includes(route as any)) {
        (result as any)[route] = appRoutes[route];
      }

      return result;
    },
    {} as Omit<AppRoutes, T>,
  );
};

const includeRoutes = <T extends AppPathsKeys>(routes: T[]) => {
  return getObjectKeys(appRoutes).reduce(
    (result, route) => {
      if (routes.includes(route as any)) {
        (result as any)[route] = appRoutes[route];
      }

      return result;
    },
    {} as Pick<AppRoutes, T>,
  );
};

export type { ViewRestricted, RouteObject, AppRoutes, AppRoutesObject, AppPaths, AppPathsKeys };
export { appRoutes, excludeRoutes, includeRoutes };
