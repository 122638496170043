import React, { useLayoutEffect } from 'react';
import {
  FileCardsGrid,
  RequestAttentionRequestedAlert,
  RequestDetailsCard,
  RequestDetailsCardContent,
  RequestExtrasCard,
  RequestExtrasCardContent,
  RequestInformationCard,
  RequestInformationCardContent,
  RequestMediaSourceCard,
  RequestMediaSourceCardContent,
  RequestMultipleOutcomeCard,
  RequestMultipleOutcomeCardContent,
  RequestPageContainer,
  RequestPageContentMain,
} from 'src/pages/request/request-page/request-default-page';
import { cn, progressInStandardTime } from 'src/lib/utils';
import { Skeleton } from 'src/components/ui/skeleton';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { CollapsibleCard } from 'src/components/ui/collapsible-card';
import { ChevronDown, CirclePlus, GitFork, Loader2 } from 'lucide-react';
import { QueryErrorResetBoundary, useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { If } from 'src/components/If';
import { useRequestId } from 'src/features/requests/use-request-id';
import { Button } from 'src/components/ui/button';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { Badge } from 'src/components/ui/badge';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { requestOutcomeStatus } from 'src/lib/services/api/request-api';
import {
  DecoratedRequestOutcomeContextProvider,
  useDecoratedRequestOutcomeContext,
} from 'src/features/requests/request-outcome/use-decorated-request-outcome-context';
import {
  RequestOutcomesQueryContextProvider,
  useRequestOutcomesQueryData,
} from 'src/features/requests/request-outcome/use-request-outcomes-query-context';
import { CreateOutcomeFormDialog } from 'src/features/requests/request-outcome/create-outcome-form-dialog';
import { authQueries } from 'src/api/queries';
import { useRequestQueryMutationDecorator } from 'src/features/requests/use-request-query-mutation-decorator';
import { useToast } from 'src/components/ui/use-toast';
import { useRequestOutcomesQueryMutationDecorator } from 'src/features/requests/request-outcome/use-request-outcomes-query-mutation-decorator';
import { generatePath, useNavigate } from 'react-router-dom';
import { appRoutes } from 'src/routes';
import {
  RequestDeliverableCardBadge,
  RequestDeliverableOutcomeCard,
  RequestDeliverableOutcomeCardBackgroundContent,
  RequestDeliverableOutcomeCardBackgroundContentFallback,
  RequestDeliverableOutcomeCardContent,
  RequestDeliverableOutcomeCardFooter,
  RequestDeliverableOutcomeCardForegroundContent,
  RequestDeliverableOutcomeCardHeader,
  RequestDeliverableOutcomeCardTitle,
} from 'src/features/requests/request-outcome/request-deliverable-outcome-card';
import { SpaceErrorFallback } from 'src/features/fallback';
import {
  archivedOutcomesFilters,
  deliverableOutcomesFilters,
  pendingOutcomesFilters,
} from 'src/features/requests/request-outcome/filter-queries';
import {
  EmptySpaceFallback,
  EmptySpaceFallbackDescription,
  EmptySpaceFallbackHeader,
  EmptySpaceFallbackImage,
  EmptySpaceFallbackTitle,
} from 'src/features/fallback/ui';
import { RequestOutcomeCreatedByIcon } from 'src/features/requests/request-outcome/pending-outcomes';
import { RequestOutcomePriority } from 'src/features/requests/request-outcome/request-outcome-priority';
import { RequestPendingOutcomeCard as GenericRequestPendingOutcomeCard } from 'src/features/requests/request-outcome/pending-outcomes';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import { Separator } from 'src/components/ui/separator';
import { RequestOutcomesPaginatedResponse } from 'src/lib/services/api/request-api/request-client';
import { useToggle } from 'src/lib/state-utils';
import { requestContentTab } from './request-page.utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import {
  ConfirmDialog,
  ConfirmDialogAction,
  ConfirmDialogCancel,
  ConfirmDialogContent,
  ConfirmDialogDescription,
  ConfirmDialogFooter,
  ConfirmDialogHeader,
  ConfirmDialogTitle,
  ConfirmDialogTrigger,
} from 'src/components/ui/confirm-dialog';
import { useBreakpoint } from 'src/lib/hooks';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Card, CardContent, CardHeader } from 'src/components/ui/card';

const RequestPendingOutcomeCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);
  const request = useDecoratedRequestContext();
  const requestActions = useRequestQueryMutationDecorator(request.id);
  const [loading, setLoading] = useToggle();

  const outcome = useDecoratedRequestOutcomeContext();
  const outcomeActions = useRequestQueryMutationDecorator(outcome.id);

  const pendingOutcomesActions = useRequestOutcomesQueryMutationDecorator(
    request.id,
    pendingOutcomesFilters,
  );
  const deliverablesOutcomesActions = useRequestOutcomesQueryMutationDecorator(
    request.id,
    deliverableOutcomesFilters,
  );
  const deliverables = useRequestOutcomesQueryData(request.id, deliverableOutcomesFilters);

  const items = deliverables?.items ?? [];
  const areAnyCurrentlyEditingOutcomes = items?.some(
    (outcome) => outcome.status === requestOutcomeStatus.currentlyEditing,
  );

  const handleMoveToEditing = async () => {
    if (areAnyCurrentlyEditingOutcomes) {
      return;
    }

    setLoading(true);

    const syncServerState = () => {
      // Sync outcomes
      deliverablesOutcomesActions.push({
        ...outcome.raw,
        status: requestOutcomeStatus.currentlyEditing,
      });
      pendingOutcomesActions.remove(outcome.id);

      // Sync request
      requestActions.setData((prev) =>
        prev
          ? {
              ...prev,
              status: requestOutcomeStatus.currentlyEditing,
            }
          : prev,
      );
    };

    let promise = undefined;

    if (outcome.canCreateFirstContact) {
      promise = outcomeActions.createFirstContact({
        type: 'first_contact',
        media: [],
      });
    } else {
      promise = outcomeActions.moveToEditing();
    }

    promise
      .then(syncServerState)
      .catch((exception) => {
        console.error(exception);
      })
      .finally(() => setLoading(false));
  };

  return (
    <GenericRequestPendingOutcomeCard open={open} onOpenChange={(open) => setOpen(open)} {...props}>
      <If when={!outcome.isArchived}>
        <If
          when={outcome.canCreateFirstContact}
          else={
            <Button
              // TODO: disabled if editor has any currently editing requests in the company
              //  good enough for now
              disabled={areAnyCurrentlyEditingOutcomes || loading}
              variant={'accent1'}
              className={'tw-mt-6 tw-w-full'}
              onClick={handleMoveToEditing}
            >
              {t('actions:start_editing', {
                defaultValue: 'Start editing',
              })}
              <If when={loading}>
                <Loader2 className={'tw-ms-2 tw-animate-spin'} />
              </If>
            </Button>
          }
        >
          <ConfirmDialog>
            <ConfirmDialogTrigger asChild>
              <Button
                // TODO: disabled if editor has any currently editing requests in the company
                //  good enough for now
                disabled={areAnyCurrentlyEditingOutcomes || loading}
                variant={'accent1'}
                className={'tw-mt-6 tw-w-full'}
              >
                {t('actions:create_first_contact', {
                  defaultValue: 'Create first contact',
                })}
                <If when={loading}>
                  <Loader2 className={'tw-ms-2 tw-animate-spin'} />
                </If>
              </Button>
            </ConfirmDialogTrigger>
            <ConfirmDialogContent>
              <ConfirmDialogHeader>
                <ConfirmDialogTitle>
                  {t('actions:create_first_contact', {
                    defaultValue: 'Create first contact',
                  })}
                </ConfirmDialogTitle>
              </ConfirmDialogHeader>
              <ConfirmDialogDescription
                dangerouslySetInnerHTML={{
                  __html: t('alerts:confirm.create_first_contact', {
                    replace: {
                      title: outcome.raw.title,
                    },
                    defaultValue:
                      'By creating a first contact, the outcome <b class="tw-font-medium">:title</b> status will be changed to <b class="tw-font-bold">Currently editing</b>.',
                  })!,
                }}
              />
              <ConfirmDialogFooter>
                <ConfirmDialogCancel>
                  {t('actions:cancel', {
                    defaultValue: 'Cancel',
                  })}
                </ConfirmDialogCancel>
                <ConfirmDialogAction onClick={handleMoveToEditing}>
                  {t('actions:confirm', {
                    defaultValue: 'Confirm',
                  })}
                </ConfirmDialogAction>
              </ConfirmDialogFooter>
            </ConfirmDialogContent>
          </ConfirmDialog>
        </If>
      </If>
    </GenericRequestPendingOutcomeCard>
  );
};

const RequestEmptyPendingOutcomesFallback: React.FC<
  React.ComponentProps<typeof EmptySpaceFallback>
> = (props) => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const [isOpen, setOpen] = useToggle();
  const request = useDecoratedRequestContext();
  const requestActions = useRequestQueryMutationDecorator(request.id);
  const outcomesActions = useRequestOutcomesQueryMutationDecorator(
    request.id,
    pendingOutcomesFilters,
  );

  const { toast } = useToast();

  const createOutcomeInitialValues = {
    type: '',
    title: t('models/request:outcome.placeholder', {
      defaultValue: 'Outcome #:num',
      replace: {
        num: (request.raw.outcomes_count ?? 0) + 1,
      },
    }),
    total_length: undefined,
  };

  return (
    <EmptySpaceFallback {...props}>
      <EmptySpaceFallbackImage className={'tw-mx-auto'} />

      <EmptySpaceFallbackHeader className={'tw-gap-4 tw-text-center'}>
        <EmptySpaceFallbackTitle>
          {t('common:empty_space', {
            defaultValue: "There's an empty space",
          })}
        </EmptySpaceFallbackTitle>
        <EmptySpaceFallbackDescription>
          <CreateOutcomeFormDialog
            initialValues={createOutcomeInitialValues}
            open={isOpen}
            onOpenChange={(open) => {
              setOpen(open);
            }}
            onSubmit={async (values) => {
              await requestActions.createOutcome(values).then((outcome) => {
                toast({
                  description: t('alerts:success.request_outcome_created', {
                    defaultValue: ':type request outcome created',
                    replace: {
                      type: t(`models/request:type.${outcome.type}`, {
                        defaultValue: t('common:unknown', {
                          defaultValue: 'Unknown',
                        }),
                      }),
                    },
                  }),
                });

                outcomesActions.push(outcome);

                client.invalidateQueries({
                  queryKey: [authQueries.requestOutcomes, request.id, pendingOutcomesFilters],
                });

                setOpen(false);
              });
            }}
          >
            <Button type={'button'} variant={'accent1'} disabled={!request.canAddOutcome}>
              {t('actions:add_new_outcome', {
                defaultValue: 'Add new outcome',
              })}
            </Button>
          </CreateOutcomeFormDialog>
        </EmptySpaceFallbackDescription>
      </EmptySpaceFallbackHeader>
    </EmptySpaceFallback>
  );
};

const ComposedPendingOutcomesContent: React.FC<
  React.ComponentPropsWithoutRef<'div'> & {
    pendingOutcomes: RequestOutcomesPaginatedResponse['items'];
    archivedOutcomes: RequestOutcomesPaginatedResponse['items'];
  }
> = ({ pendingOutcomes, archivedOutcomes, className, ...props }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useToggle(false);

  return (
    <div
      className={cn('tw-grid tw-grid-cols-1 tw-items-start tw-gap-4 tw-p-4', className)}
      {...props}
    >
      {pendingOutcomes?.map((outcome, i) => (
        <DecoratedRequestOutcomeContextProvider outcome={outcome} key={i}>
          <RequestPendingOutcomeCard className={'tw-w-full'} />
        </DecoratedRequestOutcomeContextProvider>
      ))}

      <If when={!!archivedOutcomes.length}>
        <Collapsible onOpenChange={(open) => setOpen(open)} open={open} className={'tw-w-full'}>
          <CollapsibleTrigger className={'tw-group tw-flex tw-w-full tw-items-center tw-pb-4'}>
            <Separator className={'tw-shrink tw-bg-neutral-300'} />
            <Badge
              variant="unset"
              className="tw-gap-2 tw-bg-neutral-300 tw-text-base tw-font-medium tw-text-dark group-hover:tw-bg-neutral-400/30"
            >
              {t(`models/request:status.${requestOutcomeStatus.archived}`, {
                defaultValue: 'Archived',
              })}
              <ChevronDown
                size={16}
                className={cn('tw-transition-transform', {
                  'tw-rotate-180': open,
                })}
              />
            </Badge>
            <Separator className={'tw-shrink tw-bg-neutral-300'} />
          </CollapsibleTrigger>
          <CollapsibleContent className={'tw-flex tw-flex-col tw-gap-4 tw-pb-4'}>
            {archivedOutcomes?.map((outcome, i) => (
              <DecoratedRequestOutcomeContextProvider outcome={outcome} key={i}>
                <RequestPendingOutcomeCard />
              </DecoratedRequestOutcomeContextProvider>
            ))}
          </CollapsibleContent>
        </Collapsible>
      </If>
    </div>
  );
};

const ComposedPendingOutcomes: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { requestId: string }
> = ({ className, requestId, ...props }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<Skeleton className={'tw-h-full tw-w-full'} />}>
            <RequestOutcomesQueryContextProvider id={requestId} filters={pendingOutcomesFilters}>
              {({ data: pendingOutcomes }) => (
                <RequestOutcomesQueryContextProvider
                  id={requestId}
                  filters={archivedOutcomesFilters}
                >
                  {({ data: archivedOutcomes }) => (
                    <If
                      when={!!pendingOutcomes?.items.length || !!archivedOutcomes?.items.length}
                      else={<RequestEmptyPendingOutcomesFallback />}
                    >
                      <ComposedPendingOutcomesContent
                        className={className}
                        pendingOutcomes={pendingOutcomes?.items ?? []}
                        archivedOutcomes={archivedOutcomes?.items ?? []}
                        {...props}
                      />
                    </If>
                  )}
                </RequestOutcomesQueryContextProvider>
              )}
            </RequestOutcomesQueryContextProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

const RequestMultiOutcomeSkeleton: React.FC<React.ComponentProps<typeof Skeleton>> = ({
  className,
  ...props
}) => {
  return (
    <RequestPageContainer {...props} className={className}>
      <Skeleton className="tw-h-10 tw-w-1/3 tw-rounded-md" />
      <RequestPageContentMain className={'tw-grid-cols-12'}>
        <Skeleton className="tw-col-span-12 tw-h-full md:tw-col-span-8 lg:tw-col-span-9" />
        <Skeleton className="tw-col-span-12 tw-h-full md:tw-col-span-4 lg:tw-col-span-3" />
      </RequestPageContentMain>
    </RequestPageContainer>
  );
};

const MainContentTabsList: React.FC<
  React.ComponentProps<typeof TabsList> & {
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
    activeTab: string;
  }
> = ({ setActiveTab, activeTab, ...props }) => {
  const { t } = useTranslation();
  const { activeBreakpoint, upTo } = useBreakpoint();

  useLayoutEffect(() => {
    if (activeTab !== 'pending_outcomes' || upTo('laptop')) {
      return;
    }

    setActiveTab('deliverables');
  }, [activeTab, activeBreakpoint]);

  return (
    <TabsList variant={'start'} {...props}>
      <TabsTrigger
        padding={'unset'}
        className={'tw-rounded-lg tw-px-4 tw-py-3'}
        value={requestContentTab.summary}
      >
        {t(`common:${requestContentTab.summary}`, {
          defaultValue: 'Summary',
        })}
      </TabsTrigger>

      <TabsTrigger
        className={'tw-rounded-lg tw-px-4 tw-py-3 laptop:tw-hidden'}
        value={requestContentTab.pendingOutcomes}
      >
        {t(`common:${requestContentTab.pendingOutcomes}`, {
          defaultValue: 'Pending outcomes',
        })}
      </TabsTrigger>

      <TabsTrigger
        className={'tw-rounded-lg tw-px-4 tw-py-3'}
        value={requestContentTab.deliverables}
      >
        {t(`common:${requestContentTab.deliverables}`, {
          defaultValue: 'Deliverables',
        })}
      </TabsTrigger>
    </TabsList>
  );
};

const RequestMultiOutcomePageMainContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  const requestId = useRequestId();
  const [activeTab, setActiveTab] = useQueryParam<string>(
    'tab',
    withDefault(StringParam, requestContentTab.summary),
  );
  const request = useDecoratedRequestContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div ref={ref} {...props}>
      <Tabs
        onValueChange={(value) => setActiveTab(value)}
        defaultValue={activeTab}
        value={activeTab}
        className={'tw-flex tw-h-full tw-flex-col tw-gap-2'}
      >
        <MainContentTabsList
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          className={
            'tw-justify-start tw-gap-2 tw-overflow-x-auto tw-overflow-y-clip tw-border-0 tw-bg-transparent'
          }
        />

        <TabsContent className={'tw-relative tw-mt-0 tw-grow'} value={requestContentTab.summary}>
          <div className={'tw-absolute tw-inset-0 tw-flex tw-flex-col tw-gap-4'}>
            <ScrollArea>
              <div className={'tw-flex tw-flex-col tw-gap-4 tw-px-1 tw-pb-1'}>
                <RequestAttentionRequestedAlert />

                <RequestInformationCard>
                  <RequestInformationCardContent />
                </RequestInformationCard>

                <RequestExtrasCard>
                  <RequestExtrasCardContent />
                </RequestExtrasCard>

                <RequestMediaSourceCard>
                  <RequestMediaSourceCardContent>
                    <If when={request.hasMedia} else={t('common:unset', { defaultValue: '-' })}>
                      <FileCardsGrid
                        className={
                          'tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 desktop:tw-grid-cols-4'
                        }
                        files={request.media}
                      />
                    </If>
                  </RequestMediaSourceCardContent>
                </RequestMediaSourceCard>

                <RequestDetailsCard>
                  <RequestDetailsCardContent />
                </RequestDetailsCard>

                <RequestMultipleOutcomeCard>
                  <RequestMultipleOutcomeCardContent />
                </RequestMultipleOutcomeCard>
              </div>
            </ScrollArea>
          </div>
        </TabsContent>

        <TabsContent className={'tw-relative tw-grow'} value={requestContentTab.deliverables}>
          <div
            className={
              'tw-absolute tw-inset-0 tw-flex tw-flex-col tw-gap-2 tw-overflow-auto tw-rounded-lg tw-border tw-bg-background tw-p-4'
            }
          >
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <ErrorBoundary
                  fallbackRender={({ error, resetErrorBoundary }: any) => (
                    <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
                  )}
                  onReset={reset}
                >
                  <React.Suspense fallback={<Skeleton className={'tw-w-full tw-grow'} />}>
                    <RequestOutcomesQueryContextProvider
                      id={request.id}
                      filters={deliverableOutcomesFilters}
                    >
                      {({ data }) => (
                        <If
                          when={!!data?.items.length}
                          else={
                            <EmptySpaceFallback>
                              <EmptySpaceFallbackImage className={'tw-mx-auto'} />

                              <EmptySpaceFallbackHeader className={'tw-gap-2 tw-text-center'}>
                                <EmptySpaceFallbackTitle>
                                  {t('common:empty_space', {
                                    defaultValue: "There's an empty space",
                                  })}
                                </EmptySpaceFallbackTitle>
                                <EmptySpaceFallbackDescription>
                                  {t('alerts:info.create_first_deliverable', {
                                    defaultValue: 'Create first contact to make a deliverable',
                                  })}
                                </EmptySpaceFallbackDescription>
                              </EmptySpaceFallbackHeader>
                            </EmptySpaceFallback>
                          }
                        >
                          <div
                            className={
                              'tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 desktop:tw-grid-cols-4'
                            }
                          >
                            {data?.items.map((outcome, i) => (
                              <DecoratedRequestOutcomeContextProvider outcome={outcome} key={i}>
                                {(outcome) => (
                                  <RequestDeliverableOutcomeCard>
                                    <RequestDeliverableOutcomeCardContent
                                      onClick={() =>
                                        navigate(
                                          generatePath(appRoutes.requestRevisions, {
                                            requestId: outcome.id,
                                            companyId: outcome.raw.company_id,
                                          }),
                                        )
                                      }
                                    >
                                      <RequestDeliverableOutcomeCardBackgroundContent>
                                        <If
                                          when={!!outcome.revisionPreviewImage}
                                          else={
                                            <RequestDeliverableOutcomeCardBackgroundContentFallback />
                                          }
                                        >
                                          <img
                                            className={
                                              'tw-h-full tw-w-full tw-rounded-t-lg tw-object-cover'
                                            }
                                            src={outcome.revisionPreviewImage}
                                            alt={outcome.raw.title}
                                          />
                                        </If>
                                      </RequestDeliverableOutcomeCardBackgroundContent>

                                      <RequestDeliverableOutcomeCardForegroundContent>
                                        <div className={'tw-flex tw-w-full tw-items-start'}>
                                          <If when={outcome.needsReedit}>
                                            <Badge
                                              variant={'destructive'}
                                              className={'tw-text-base'}
                                            >
                                              {t('models/request:state.requested_reedit_at', {
                                                defaultValue: 'Needs re-edit',
                                              })}
                                            </Badge>
                                          </If>

                                          <RequestStatusBadge
                                            className={'tw-ms-auto'}
                                            variant={outcome.raw.status}
                                          >
                                            {t(`models/request:status.${outcome.raw.status}`)}
                                          </RequestStatusBadge>
                                        </div>

                                        <If when={!!outcome.revisionPreview?.durationInSeconds}>
                                          <RequestDeliverableCardBadge
                                            className={
                                              'tw-ms-auto tw-mt-auto tw-bg-background tw-py-1'
                                            }
                                          >
                                            {progressInStandardTime(
                                              outcome.revisionPreview?.durationInSeconds ?? 0,
                                            )}
                                          </RequestDeliverableCardBadge>
                                        </If>
                                      </RequestDeliverableOutcomeCardForegroundContent>
                                    </RequestDeliverableOutcomeCardContent>

                                    <RequestDeliverableOutcomeCardHeader>
                                      <RequestDeliverableOutcomeCardTitle>
                                        {outcome.raw.title}
                                      </RequestDeliverableOutcomeCardTitle>
                                    </RequestDeliverableOutcomeCardHeader>

                                    <RequestDeliverableOutcomeCardFooter>
                                      <TooltipProvider>
                                        <Tooltip>
                                          <TooltipTrigger asChild>
                                            <div>
                                              <RequestDeliverableCardBadge>
                                                <GitFork size={16} className={'tw-me-2'} />
                                                <p>v{outcome.raw.versions_count ?? 0}</p>
                                              </RequestDeliverableCardBadge>
                                            </div>
                                          </TooltipTrigger>
                                          <TooltipContent>
                                            {t('common:version', {
                                              defaultValue: 'Version',
                                            })}
                                          </TooltipContent>
                                        </Tooltip>
                                      </TooltipProvider>

                                      <RequestDeliverableCardBadge>
                                        <TooltipProvider>
                                          <Tooltip>
                                            <TooltipTrigger asChild>
                                              <div>
                                                <RequestOutcomeCreatedByIcon
                                                  size={16}
                                                  createdBy={outcome.raw.created_by}
                                                />
                                              </div>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                              {t(
                                                `models/request:outcome.created_by.${outcome.raw.created_by}`,
                                                {
                                                  defaultValue: 'Created by unknown',
                                                },
                                              )}
                                            </TooltipContent>
                                          </Tooltip>
                                        </TooltipProvider>
                                      </RequestDeliverableCardBadge>

                                      <RequestOutcomePriority
                                        variant={outcome.raw.is_default ? 'default' : 'additional'}
                                        size={'sm'}
                                      >
                                        {outcome.raw.sort}
                                      </RequestOutcomePriority>
                                    </RequestDeliverableOutcomeCardFooter>
                                  </RequestDeliverableOutcomeCard>
                                )}
                              </DecoratedRequestOutcomeContextProvider>
                            ))}
                          </div>
                        </If>
                      )}
                    </RequestOutcomesQueryContextProvider>
                  </React.Suspense>
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </div>
        </TabsContent>

        <TabsContent
          className={'tw-relative tw-grow laptop:tw-hidden'}
          value={requestContentTab.pendingOutcomes}
        >
          <div
            className={
              'tw-absolute tw-inset-0 tw-flex tw-flex-col tw-gap-2 tw-overflow-auto tw-rounded-lg tw-border tw-bg-white md:tw-gap-4'
            }
          >
            <ComposedPendingOutcomes requestId={requestId} />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
});
RequestMultiOutcomePageMainContent.displayName = 'RequestDefaultPageMainContent';

const RequestMultiOutcomePageSideContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  const { t } = useTranslation();
  const requestId = useRequestId();
  const request = useDecoratedRequestContext();
  const requestActions = useRequestQueryMutationDecorator(request.id);
  const { toast } = useToast();

  const [createOutcomeDialogOpen, setCreateOutcomeDialogOpen] = useToggle();

  const pendingOutcomesActions = useRequestOutcomesQueryMutationDecorator(
    request.id,
    pendingOutcomesFilters,
  );

  const createOutcomeInitialValues = {
    type: '',
    title: t('models/request:outcome.placeholder', {
      defaultValue: 'Outcome #:num',
      replace: {
        num: (request.raw.outcomes_count ?? 0) + 1,
      },
    }),
    total_length: undefined,
  };

  return (
    <div ref={ref} className={cn('tw-flex tw-grow tw-flex-col tw-gap-2', className)} {...props}>
      <div className={'tw-relative tw-h-full tw-w-full'}>
        <Card
          className={'tw-flex tw-h-full tw-w-full tw-flex-col tw-bg-background'}
          variant={'noShadow'}
        >
          <CardHeader>
            <div className={'tw-flex tw-items-center tw-justify-between'}>
              <div className={'tw-text-title-sm'}>
                {t('pages/shared:request.pending_outcomes', 'Pending outcomes')}
              </div>
              <CreateOutcomeFormDialog
                initialValues={createOutcomeInitialValues}
                open={createOutcomeDialogOpen}
                onOpenChange={(open) => {
                  setCreateOutcomeDialogOpen(open);
                }}
                onSubmit={async (values) => {
                  await requestActions.createOutcome(values).then((outcome) => {
                    toast({
                      description: t('alerts:success.request_outcome_created', {
                        defaultValue: ':type request outcome created',
                        replace: {
                          type: t(`models/request:type.${outcome.type}`, {
                            defaultValue: t('common:unknown', {
                              defaultValue: 'Unknown',
                            }),
                          }),
                        },
                      }),
                    });

                    pendingOutcomesActions.push(outcome);
                    pendingOutcomesActions.invalidate();

                    setCreateOutcomeDialogOpen(false);
                  });
                }}
              >
                <Button
                  variant={'outline'}
                  size={'iconStartSm'}
                  type={'button'}
                  disabled={!request.canAddOutcome}
                >
                  <CirclePlus className={'tw-size-4'} />
                  {t('actions:add_new', {
                    defaultValue: 'Add new',
                  })}
                </Button>
              </CreateOutcomeFormDialog>
            </div>
          </CardHeader>
          <CardContent className={'tw-relative tw-h-full tw-w-full'}>
            <div className={'tw-absolute tw-inset-0 tw-flex tw-flex-col'}>
              <ScrollArea viewportProps={{ className: '[&>div]:!tw-block' }}>
                <ComposedPendingOutcomes
                  requestId={requestId}
                  className={'tw-hidden tw-grow-0 tw-flex-col tw-px-6 tw-py-0 lg:tw-flex'}
                />
              </ScrollArea>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
});
RequestMultiOutcomePageSideContent.displayName = 'RequestMultiOutcomePageMainContent';

export {
  RequestMultiOutcomeSkeleton,
  RequestMultiOutcomePageMainContent,
  RequestMultiOutcomePageSideContent,
};
