import React from 'react';
import { DecoratedRequest, decorateRequest } from 'src/features/requests/decorate-request';
import { RequestResponse } from 'src/lib/services/api/request-api';

const DecoratedRequestContext = React.createContext<DecoratedRequest | null>(null);

const DecoratedRequestProvider: React.FC<
  React.PropsWithChildren<{ request?: RequestResponse }>
> = ({ request, children }) => {
  return (
    <DecoratedRequestContext.Provider value={decorateRequest(request)}>
      {children}
    </DecoratedRequestContext.Provider>
  );
};

const useDecoratedRequestContext = () => {
  const context = React.useContext(DecoratedRequestContext);

  if (!context) {
    throw new Error('useDecoratedRequestContext must be used within a DecoratedRequestProvider');
  }

  return context;
};

const withDecoratedRequest = <PropsType extends React.ComponentProps<any>>(
  Component: React.FC<Omit<PropsType, 'request'>>,
) => {
  const result = ({ request, ...props }: PropsType & { request?: RequestResponse }) => (
    <DecoratedRequestProvider request={request}>
      <Component {...props} />
    </DecoratedRequestProvider>
  );

  result.displayName = Component.displayName;

  return result;
};

export { DecoratedRequestProvider, withDecoratedRequest, useDecoratedRequestContext };
