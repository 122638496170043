import React, { useState } from 'react';
import { Volume2, VolumeX } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import { OverlayIcon, useVideoPlayerV2 } from 'src/features/video-player/video-player';

export const VolumeControl: React.FC<React.ComponentProps<'div'>> = ({ className, ...props }) => {
  const player = useVideoPlayerV2();

  const toggleMute = () => {
    player.setMuted((prev) => !prev);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    player.setVolume(parseFloat(e.target.value));
  };

  return (
    <div className={cn('tw-group tw-flex tw-items-center', className)} {...props}>
      <Button
        variant={'unset'}
        size={'icon'}
        onClick={toggleMute}
        type={'button'}
        className={
          'tw-flex tw-h-auto tw-w-6 tw-items-center tw-justify-center tw-duration-150 tw-ease-out hover:tw-opacity-100'
        }
      >
        {player.muted ? (
          <OverlayIcon
            className={'group-hover:tw-fill-gray-50 group-hover:tw-stroke-gray-50'}
            icon={VolumeX}
          />
        ) : (
          <OverlayIcon
            className={'group-hover:tw-fill-gray-50 group-hover:tw-stroke-gray-50'}
            icon={Volume2}
          />
        )}
      </Button>

      <div
        className={
          'tw-invisible tw-relative tw-mx-0 tw-h-1.5 tw-w-0 tw-rounded-full tw-duration-300 tw-ease-out group-hover:tw-visible group-hover:tw-mx-1 group-hover:tw-w-12'
        }
      >
        <input
          onInput={handleVolumeChange}
          type={'range'}
          min={'0'}
          max={'1'}
          value={player.volume}
          step={'0.01'}
          className="tw-z-30 tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-appearance-none tw-items-center tw-bg-transparent
        [&::-moz-range-progress]:tw-rounded-full [&::-moz-range-progress]:tw-bg-white [&::-moz-range-progress]:tw-bg-opacity-80
        [&::-moz-range-thumb]:tw-h-2 [&::-moz-range-thumb]:tw-w-2 [&::-moz-range-thumb]:tw-appearance-none
        [&::-moz-range-thumb]:tw-rounded-full [&::-moz-range-thumb]:tw-border-0 [&::-moz-range-thumb]:tw-bg-white
        [&::-moz-range-track]:tw-rounded-full [&::-moz-range-track]:tw-bg-neutral-200 [&::-ms-fill-lower]:tw-rounded-full
        [&::-ms-fill-lower]:tw-bg-white [&::-ms-fill-lower]:tw-bg-opacity-80 [&::-ms-thumb]:tw-h-2 [&::-ms-thumb]:tw-w-2 [&::-ms-thumb]:tw-appearance-none
        [&::-ms-thumb]:tw-rounded-full
        [&::-ms-thumb]:tw-border-0 [&::-ms-thumb]:tw-bg-white
        [&::-ms-track]:tw-rounded-full [&::-ms-track]:tw-bg-neutral-200
        [&::-webkit-slider-runnable-track]:tw-overflow-hidden [&::-webkit-slider-runnable-track]:tw-rounded-full [&::-webkit-slider-runnable-track]:tw-bg-white
        [&::-webkit-slider-runnable-track]:tw-bg-opacity-30
        [&::-webkit-slider-thumb]:tw-h-2 [&::-webkit-slider-thumb]:tw-w-2 [&::-webkit-slider-thumb]:tw-appearance-none
        [&::-webkit-slider-thumb]:tw-rounded-full [&::-webkit-slider-thumb]:tw-border-0 [&::-webkit-slider-thumb]:tw-bg-white
        [&::-webkit-slider-thumb]:tw-shadow-[-995px_0px_0px_990px_rgba(255,_255,_255,_0.8)]
        "
        />
      </div>
    </div>
  );
};
