import { HttpConfig } from './http.types';

import { API_URL } from 'src/env.json';

const config: HttpConfig = {
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Expose-Headers': 'Access-Control-*',
    'Access-Control-Allow-Headers':
      'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
    'Access-Control-Allow-Origin': '*',
    Allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
  },
};

const setBaseUrl = (baseUrl: string) => {
  Object.assign(config, { baseUrl });
};

const updateClientConfig = (newOptions: HttpConfig) => {
  Object.assign(config, newOptions);
};

const addHeader = (key: string, value: string) => {
  Object.assign(config.headers, { [key]: value });
};

const removeHeader = (key: string) => {
  delete config.headers[key];
};

const setBearerToken = (token: string) => {
  addHeader('Authorization', `Bearer ${token}`);
};

const removeBearerToken = () => {
  removeHeader('Authorization');
};

const hasBearerToken = () => {
  return !!config.headers['Authorization'] && !!config.headers['Authorization'].split(' ')?.[1];
};

const httpConfig = {
  options: config,
  setBaseUrl,
  updateClientConfig,
  addHeader,
  removeHeader,
  setBearerToken,
  removeBearerToken,
  hasBearerToken,
} as const;

export { httpConfig };
