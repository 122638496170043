import { ValueOfObject } from 'src/types/generics';
import { TeamMemberRole } from 'src/api/services/TeamMemberClient';
import { MediaObject } from 'src/api/services/MediaClient';

const userType = {
  editor: 'editor',
  admin: 'admin',
  customer: 'customer',
  teamLead: 'team_lead',
} as const;

type UserType = ValueOfObject<typeof userType>;

const userStatus = {
  active: 'active',
  suspended: 'suspended',
} as const;

type UserStatus = keyof typeof userStatus;

interface ProfileResponse {
  id: string;
  first_name?: string;
  last_name?: string;
  email: string;
  phone_number?: string;
  type: UserType;
  permissions: string[];
  avatar?: MediaObject;
  avatar_url?: string;
  created_at: string;
  intercom_hmac: string;
  days_since_last_login: number;
  subscription_assignment_companies: {
    id: string;
    name: string | null;
  }[];
  teams: {
    id: string;
    name: string;
    color: string;
    team_leaders: {
      id: string;
      first_name: string;
      last_name: string;
    }[];
    me: {
      id: string;
      role: TeamMemberRole;
    };
  }[];
}

type UploadAvatarRequest = {
  media_id: string;
};

type UpdateProfileRequest = Pick<
  ProfileResponse,
  'first_name' | 'last_name' | 'email' | 'phone_number'
>;

interface ChangePasswordRequest {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export type {
  UserType,
  UserStatus,
  ProfileResponse,
  UpdateProfileRequest,
  ChangePasswordRequest,
  UploadAvatarRequest,
};
export { userType, userStatus };
