import { Role } from '../api/services/RoleClient';
import { AuthUtils } from '../services/Auth/Auth.utils';

class Auth {
  public is = (role: Role | Role[]): boolean => {
    return Array.isArray(role) ? role.includes(this.role()!) : role === this.role();
  };

  public role = (): undefined | Role => {
    return AuthUtils.getPayload()?.user_type;
  };
}

const auth = new Auth();

export { auth };
