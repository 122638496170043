const hasJsonStructure = (str: unknown): str is string => {
  if (!str || typeof str !== 'string') return false;

  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
    return false;
  }
};

export { hasJsonStructure };
