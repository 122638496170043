import { useTranslation } from 'react-i18next';
import { OptionObject } from './ReactSelectInput.types';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { omitObject } from 'src/helpers/omitObject';
import { pickObject } from 'src/helpers/pickObject';
import { userType, UserType } from 'src/lib/services/api/profile-api';

const adminAppRoles = Object.values(omitObject(userType, [userType.customer]));
const spaceAppRoles = Object.values(pickObject(userType, [userType.customer]));

const rolesMap: Record<UserType, UserType[]> = {
  admin: adminAppRoles,
  customer: spaceAppRoles,
  editor: adminAppRoles,
  team_lead: adminAppRoles,
};

const useRolesFilterOptions = (currentRole?: UserType): OptionObject[] => {
  const { t } = useTranslation('models/role');

  const options: OptionObject[] = currentRole
    ? rolesMap[currentRole].map((role) => ({
        label: t(role),
        value: role,
      }))
    : Object.values(userType).map((role) => ({
        label: t(role),
        value: role,
      }));

  if (AuthUtils.getPayload()?.user_type !== userType.admin) {
    return options.filter((o) => o.value !== userType.admin);
  }

  return options;
};

export { useRolesFilterOptions };
