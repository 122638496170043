import { Client, PaginatedResponse } from 'src/api/Client';
import {
  RequestsTableResponse,
  RequestsCountsResponse,
  RequestAttentionRequest,
} from './RequestClient.types';
import { OrderResponse } from './Request.types';
import { CreateTaskRequest, TaskResponse } from '../TaskClient';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { AxiosRequestConfig } from 'axios';

class RequestClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/admin/requests`,
    companyFilter: `${this.http.baseUrl}/admin/requests/{model}/filter`,
    companyCounts: `${this.http.baseUrl}/admin/requests/{model}/counts`,
    read: `${this.http.baseUrl}/admin/requests/{model}`,

    changeStatus: `${this.http.baseUrl}/admin/requests/{model}/change-status`,
    requestAttention: `${this.http.baseUrl}/admin/requests/{model}/request-attention`,

    tasks: {
      filter: `${this.http.baseUrl}/admin/requests/{model}/tasks`,
      create: `${this.http.baseUrl}/admin/requests/{model}/tasks`,
    },
  };

  filterRequests = (params?: ApiQueryObject): Promise<PaginatedResponse<RequestsTableResponse>> => {
    return this.http.get(this.route.filter, { params });
  };

  filterCompanyRequests = (
    model: string,
    config?: AxiosRequestConfig,
  ): Promise<PaginatedResponse<RequestsTableResponse>> => {
    const url = this.buildUrl(this.route.companyFilter, { model });
    return this.http.get(url, config);
  };

  get = (model: string): Promise<OrderResponse> => {
    const url = this.buildUrl(this.route.read, { model });
    return this.http.get(url);
  };

  fetchCompanyRequestsCounts = (
    model: string,
    params?: ApiQueryObject,
  ): Promise<RequestsCountsResponse> => {
    return this.http.get(this.buildUrl(this.route.companyCounts, { model }), { params });
  };

  requestAttention = (model: string, data: RequestAttentionRequest): Promise<void> =>
    this.http.put(this.buildUrl(this.route.requestAttention, { model }), data);

  filterTasks = (
    model: string,
    params?: ApiQueryObject,
    config?: AxiosRequestConfig,
  ): Promise<PaginatedResponse<TaskResponse>> =>
    this.http.get(this.buildUrl(this.route.tasks.filter, { model }), { params, ...(config ?? {}) });

  createTask = (model: string, data: CreateTaskRequest): Promise<TaskResponse> =>
    this.http.post(this.buildUrl(this.route.tasks.create, { model }), data);
}

export default RequestClient;
