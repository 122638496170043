import styled from 'styled-components';

const Cover = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RoundedCover = styled(Cover)`
  border-radius: 0.335rem;
`;

const ImageCover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageRoundedCover = styled(ImageCover)`
  border-radius: 0.335rem;
`;

const VideoCover = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoRoundedCover = styled(VideoCover)`
  border-radius: 0.335rem;
`;

export { Cover, RoundedCover, ImageCover, ImageRoundedCover, VideoCover, VideoRoundedCover };
