import React from 'react';
import { useField, Field } from 'formik';
import { ErrorMessage } from '../ErrorMessage';
import { SmallText } from 'src/components/SmallText';

type Props<T extends Record<string, any>> = {
  as?: string | React.ComponentType<T> | React.ComponentClass<T> | React.FunctionComponent<T>;
  name: string;
  label?: any;
  labelProps?: React.ComponentPropsWithoutRef<'label'>;
  smallText?: React.ReactNode | 'string' | JSX.Element;
  wrapperClassNames?: string[];
} & T &
  React.ComponentPropsWithoutRef<'input'>;

const FieldComplex: <T extends Record<string, any>>(props: Props<T>) => JSX.Element = ({
  as = 'input',
  className = 'form-control',
  wrapperClassNames = ['mb-4'],
  type = 'text',
  required,
  label,
  labelProps = {},
  smallText,
  ...props
}) => {
  const [, { error, value, touched }] = useField(props.name);

  const wasValidated = touched && !!value && !error;

  const fieldClassNames = [className];
  if (error) {
    fieldClassNames.push('is-invalid');
  }

  if (wasValidated) {
    wrapperClassNames.push('was-validated');
  }

  const labelClassNames = labelProps?.className ? [labelProps.className] : [];
  if (required) {
    labelClassNames.push('required');
  }

  return (
    <div className={wrapperClassNames.join(' ')}>
      {!!label && (
        <label className={labelClassNames.join(' ')} htmlFor={props.name} {...labelProps}>
          {label}
        </label>
      )}
      {!!smallText && <SmallText>{smallText}</SmallText>}
      <Field as={as} className={fieldClassNames.join(' ')} type={type} {...props} />
      <ErrorMessage name={props.name} />
    </div>
  );
};

export type { Props as FieldComplexProps };
export default FieldComplex;
