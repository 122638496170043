import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingOptions: React.FC = () => {
  return (
    <div className={'py-4'}>
      <Loader2 className={'tw-mx-auto tw-size-9 tw-animate-spin tw-text-center tw-text-primary'} />
    </div>
  );
};

const NoOptionsMessage = { LoadingOptions };

export { NoOptionsMessage };
