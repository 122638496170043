import * as React from 'react';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import { cva, VariantProps } from 'class-variance-authority';

const segmentedButtonVariants = cva('tw-gap-2 tw-rounded-none tw-px-3 tw-py-2', {
  variants: {
    variant: {
      default: 'hover:tw-bg-brand-300',
      active: 'tw-bg-brand-200 hover:tw-bg-brand-300',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const SegmentedList = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-flex [&>*]:tw-border-y [&>*]:tw-border-e first:[&>*]:tw-rounded-e-none first:[&>*]:tw-rounded-s-lg first:[&>*]:tw-border-s last:[&>*]:tw-rounded-e-lg last:[&>*]:tw-rounded-s-none',
        className,
      )}
      {...props}
    />
  ),
);
SegmentedList.displayName = 'SegmentedList';

type SegmentedButtonProps = Omit<React.ComponentPropsWithoutRef<typeof Button>, 'variant'> &
  VariantProps<typeof segmentedButtonVariants>;

const SegmentedButton = React.forwardRef<React.ElementRef<typeof Button>, SegmentedButtonProps>(
  ({ className, variant, ...props }, ref) => (
    <Button
      ref={ref}
      variant={'unset'}
      data-state={variant}
      className={cn(segmentedButtonVariants({ variant }), className)}
      {...props}
    />
  ),
);
SegmentedButton.displayName = 'SegmentedButton';

export { SegmentedList, SegmentedButton };
