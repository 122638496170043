import React from 'react';
import { Formik } from 'formik';
import { array, boolean, object, string } from 'yup';
import { extractFormErrors } from 'src/components/Form/Form.utils';
import { StoreCommentFormObject } from '../RevisionChatContent.types';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { useRequestCommentsContext } from 'src/features/comments/use-request-comments';
import { useVideoProgressActions } from 'src/features/video-player/use-video-progress-store';

type Props = {
  children?: React.ReactNode;
  onSubmit?: () => void;
};

const validationSchema = object().shape({
  include_time: boolean(),
  media: array().nullable(),
  comment: string()
    .nullable()
    .when('media', {
      is: (media: any) => {
        return !media.length;
      },
      then: string().required('Field is required'),
    }),
});

const ChatInputCommentFormikProvider: React.FC<Props> = ({ children, onSubmit }) => {
  const { selectedRevision, selectedRevisionMedia } = useRequestRevisionsStore();
  const { addCommentMutation } = useRequestCommentsContext();

  // :)
  const { getPlayedSeconds } = useVideoProgressActions();

  const { isLatest = false } = selectedRevision ?? {};

  return (
    <Formik<StoreCommentFormObject>
      initialValues={{
        comment: '',
        include_time:
          !!(selectedRevisionMedia?.isVideo || selectedRevisionMedia?.isAudio) && isLatest,
        media: [],
      }}
      onSubmit={async (values, helpers) => {
        helpers.setSubmitting(true);

        const valuesSnapshot = { ...values };

        helpers.resetForm({
          values: {
            ...values,
            comment: '',
            media: [],
            include_time: values.include_time,
          },
        });

        addCommentMutation
          .mutateAsync([
            selectedRevision!.id,
            {
              ...values,
              revision_timestamp:
                !!(selectedRevisionMedia?.isVideo || selectedRevisionMedia?.isAudio) &&
                isLatest &&
                values.include_time
                  ? getPlayedSeconds()
                  : undefined,
              media_id: selectedRevisionMedia?.id,
            },
          ])
          .catch((e) => {
            helpers.setErrors(extractFormErrors(e) ?? {});
            helpers.setValues(valuesSnapshot);
          })
          .finally(() => helpers.setSubmitting(false));

        onSubmit?.();
      }}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};

export { ChatInputCommentFormikProvider };
