import React from 'react';
import { useRequestTasksQuery } from 'src/api/queries';
import { Button, Text } from '@becreatives/becreatives-ui';
import { CreateTaskModal, TaskItem } from 'src/components/Task';
import { BxTaskIcon } from 'src/components/Boxicons';
import { ModalToggler } from 'src/components/Modal';
import { useTranslation } from 'react-i18next';
import {
  OrderNotesActionsSection,
  OrderNotesContentWrapper,
  OrderNotesItemsBlock,
  OrderNotesItemsSection,
} from './OrderNotes.styled';
import { taskStatus } from 'src/api/services/TaskClient';
import { Api } from 'src/api';
import { useMutation } from '@tanstack/react-query';
import { useRequestId } from 'src/features/requests/use-request-id';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

const OrderNotes: React.FC<Props> = (props) => {
  const { t } = useTranslation('pages/shared');

  const requestId = useRequestId();

  const { data: staleTasks, refetch: refetchStaleTasks } = useRequestTasksQuery(requestId, {
    limit: 999,
    filters: [{ id: 'status', value: 0 }],
    sorts: [
      { id: 'status_changed_at', desc: false },
      { id: 'updated_at', desc: false },
    ],
  });

  const { data: completedTasks, refetch: refetchCompletedTasks } = useRequestTasksQuery(requestId, {
    limit: 999,
    filters: [{ id: 'status', value: 1 }],
    sorts: [
      { id: 'status_changed_at', desc: false },
      { id: 'updated_at', desc: false },
    ],
  });

  const createTaskMutation = useMutation({
    mutationFn: (args: Parameters<typeof Api.request.createTask>) =>
      Api.request.createTask(...args),
    onSettled: () => {
      refetchStaleTasks();
    },
  });

  const refetchAllTasks = () => {
    refetchStaleTasks();
    refetchCompletedTasks();
  };

  return (
    <OrderNotesContentWrapper {...props}>
      <OrderNotesActionsSection>
        <ModalToggler
          modal={CreateTaskModal}
          $centeredContent={true}
          modalProps={{
            onSubmit: (values: any) => {
              createTaskMutation.mutate([requestId, values]);
            },
          }}
          toggler={Button}
          outline={true}
        >
          {t('add_note', { ns: 'actions' })}
          <BxTaskIcon className={'ms-2 fs-5'} />
        </ModalToggler>
      </OrderNotesActionsSection>

      <OrderNotesItemsSection>
        <OrderNotesItemsBlock>
          <Text tag={'h5'} className={'mb-4'}>
            {t(`stage.${taskStatus.stale}`, { ns: 'models/task' })}
          </Text>

          {staleTasks?.items.map((task) => (
            <TaskItem
              className={'mb-3'}
              task={task}
              key={task.id}
              onComplete={refetchAllTasks}
              onEdit={refetchAllTasks}
              onDelete={refetchAllTasks}
            />
          ))}
        </OrderNotesItemsBlock>

        <OrderNotesItemsBlock>
          <Text tag={'h5'} className={'mb-4'}>
            {t(`stage.${taskStatus.done}`, { ns: 'models/task' })}
          </Text>

          {completedTasks?.items.map((task) => (
            <TaskItem
              className={'mb-3'}
              task={task}
              key={task.id}
              onComplete={refetchAllTasks}
              onEdit={refetchAllTasks}
              onDelete={refetchAllTasks}
            />
          ))}
        </OrderNotesItemsBlock>
      </OrderNotesItemsSection>
    </OrderNotesContentWrapper>
  );
};

export { OrderNotes };
