import { create } from 'zustand';
import { MediaObject } from 'src/api/services/MediaClient';
import { ModalProps } from 'reactstrap';
import { RequestStatus } from 'src/api/services/RequestClient';
import { ChangeRequestStatusRequest } from 'src/lib/services/api/request-api';

type StatusChangeModalProps = Omit<ModalProps, 'onSubmit'> & {
  requestId: string;
  companyId: string;
  onSubmit?: (values: ChangeRequestStatusRequest) => Promise<void>;
  newStatus?: RequestStatus;
};

interface RequestStatusChangeStore {
  modalState: number;
  isModalOpen: boolean;
  isMinimized?: boolean;
  requestName?: string;
  uploadingFiles: MediaObject[];
  modalProps?: StatusChangeModalProps;

  setRequestName: (requestName?: string) => void;
  setUploadingFiles: (files?: MediaObject[]) => void;
  setModalProps: (props?: StatusChangeModalProps) => void;

  openModal: () => void;
  minimizeModal: () => void;
  discardModal: () => void;
  reset: () => void;
}

const defaultValues = {
  isMinimized: undefined,
  requestName: undefined,
  uploadingFiles: [],
  currentRequestId: undefined,
  modalProps: undefined,
};

const useRequestStatusChangeStore = create<RequestStatusChangeStore>((set) => ({
  modalState: 0,
  isModalOpen: false,
  ...defaultValues,

  setRequestName: (requestName) => set(() => ({ requestName })),
  setUploadingFiles: (uploadingFiles) => set(() => ({ uploadingFiles })),
  setModalProps: (modalProps) => set(() => ({ modalProps })),

  openModal: () => set(() => ({ isModalOpen: true, isMinimized: false })),
  minimizeModal: () => set(() => ({ isModalOpen: false, isMinimized: true })),
  discardModal: () => set(() => ({ isModalOpen: false, isMinimized: false })),
  reset: () => set((state) => ({ ...defaultValues, modalState: state.modalState + 1 })),
}));

export { useRequestStatusChangeStore };
export type { StatusChangeModalProps };
