import { createQueryParams } from 'src/lib/services/api-query-params';

const createCompaniesFilterQuery = () =>
  createQueryParams({
    filters: [{ id: 'name', operator: 'is_set' }],
    sorts: [
      {
        id: 'name',
        desc: false,
      },
      {
        id: 'created_at',
        desc: false,
      },
    ],
  });

export const createCompaniesWithoutAirtableBaseQuery = () => createQueryParams({});

export { createCompaniesFilterQuery };
