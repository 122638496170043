import { Client } from '../../Client';
import { MediaObject } from '../MediaClient';

class MediaLinkClient extends Client {
  readonly route = {
    mediaByToken: `${this.http.baseUrl}/media-links/{token}`,
  };

  mediaByToken = (token: string): Promise<MediaObject> =>
    this.http.get(this.buildUrl(this.route.mediaByToken, { token }));
}

export { MediaLinkClient };
