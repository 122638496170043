import React from 'react';
import { cn } from 'src/lib/utils';
import SpacemanLost from 'src/assets/images/spaceman-lost.svg';

const EmptySpaceFallback = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} {...props} className={cn('tw-mx-auto tw-my-auto', className)} />
  ),
);
EmptySpaceFallback.displayName = 'EmptySpaceFallback';

const EmptySpaceFallbackImage = React.forwardRef<HTMLImageElement, React.ImgHTMLAttributes<any>>(
  ({ src, ...props }, ref) => <img ref={ref} src={src ?? SpacemanLost} {...props} />,
);
EmptySpaceFallbackImage.displayName = 'EmptySpaceFallbackImage';

const EmptySpaceFallbackHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} {...props} className={cn('tw-flex tw-flex-col tw-gap-2', className)} />
));
EmptySpaceFallbackHeader.displayName = 'EmptySpaceFallbackHeader';

const EmptySpaceFallbackTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h4 ref={ref} {...props} className={cn('tw-text-lg tw-font-bold tw-text-dark', className)} />
));
EmptySpaceFallbackTitle.displayName = 'EmptySpaceFallbackTitle';

const EmptySpaceFallbackDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ ...props }, ref) => <p ref={ref} {...props} />);
EmptySpaceFallbackDescription.displayName = 'EmptySpaceFallbackDescription';

export {
  EmptySpaceFallback,
  EmptySpaceFallbackImage,
  EmptySpaceFallbackHeader,
  EmptySpaceFallbackTitle,
  EmptySpaceFallbackDescription,
};
