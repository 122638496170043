import {
  StatusChangeModalProps,
  useRequestStatusChangeStore,
} from 'src/features/requests/request-status-change/use-request-status-change-store';

const useOpenRequestStatusChangeModal = () => {
  const { isMinimized, setModalProps, openModal, discardModal, reset } =
    useRequestStatusChangeStore();

  const openRequestStatusChangeModal = (modalProps: StatusChangeModalProps) => {
    if (!isMinimized) {
      setModalProps(modalProps);
      openModal();
      return;
    }

    const confirmed = confirm(
      'Looks like you already have a request status change form open. Opening a new one will discard your draft and any changes you’ve made won’t be saved.',
    );

    // alert closed - cancel action and do nothing
    if (!confirmed) {
      return;
    }

    discardModal();
    reset();
    setModalProps(modalProps);
    openModal();
  };

  return { openRequestStatusChangeModal };
};

export { useOpenRequestStatusChangeModal };
