const pickObject = <T extends Record<any, any>, K extends keyof T>(
  obj: T,
  pick: K[],
): Pick<T, K> => {
  return pick.reduce((accu, key) => {
    accu[key] = obj[key];

    return accu;
  }, {} as Pick<T, K>);
};

export { pickObject };
