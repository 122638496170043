import React from 'react';
import SpacemanLost from 'src/assets/images/spaceman-lost.svg';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>> & {
  renderDefaultText?: boolean;
};

const NotFoundView: React.FC<Props> = ({ children, renderDefaultText = true, ...props }) => {
  return (
    <div className={`tw-flex tw-flex-col tw-items-center`} {...props}>
      <div>
        <img src={SpacemanLost} alt={'Not found'} className={'tw-mx-auto tw-h-[500px]'} />
      </div>
      {renderDefaultText ? (
        <div className={'tw-text-center'}>
          <h4 className={'tw-mb-2 tw-mt-4 tw-text-lg tw-font-semibold tw-text-text-strong'}>
            Houston, we have a problem!
          </h4>
          <p className={''}>Page not found</p>
        </div>
      ) : null}
      {children}
    </div>
  );
};

export { NotFoundView };
