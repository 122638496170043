import { useParams } from 'react-router-dom';

/**
 * @deprecated
 */
const useCompanyId = (): string => {
  const { companyId } = useParams<{ companyId: string }>();

  if (!companyId) {
    throw Error('companyId is not set');
  }

  return companyId;
};

export { useCompanyId };
