import React, { createContext, Dispatch, useContext, useState } from 'react';
import ModalLoader from '../index';

export interface IModalLoaderState {
  loading: boolean;
}

export interface IModalLoaderContext {
  state: IModalLoaderState;
  setState: Dispatch<React.SetStateAction<IModalLoaderState>>;
}

export interface ModalLoaderControl {
  loading: (loading: boolean) => void;
  isLoading: boolean;
}

const initialState = {
  state: {
    loading: false,
  },
  setState: () => {
    return;
  },
};

export const ModalLoaderContext = createContext<IModalLoaderContext>(initialState);

const ModalLoaderProvider: React.FC<React.PropsWithChildren> = ({ children }): JSX.Element => {
  const [state, setState] = useState<IModalLoaderState>({
    loading: false,
  });

  return (
    <ModalLoaderContext.Provider value={{ state, setState }}>
      {children}
      <ModalLoader loading={state.loading} />
    </ModalLoaderContext.Provider>
  );
};

export const useModalLoader = (): ModalLoaderControl => {
  const { state, setState } = useContext(ModalLoaderContext);

  return {
    loading: (loading: boolean) => {
      state.loading = loading;
      setState({ ...state });
    },
    isLoading: state.loading,
  };
};

export default ModalLoaderProvider;
