import isObject from 'lodash/isObject';
import { FormMediaCollection, FormMediaObject } from './S3UploadInput.types';

const isMediaCollection = (arg: any): arg is FormMediaCollection => {
  return arg && isObject(arg);
};

const isMediaArray = (arg: any): arg is FormMediaObject[] => {
  return arg && Array.isArray(arg);
};

export { isMediaCollection, isMediaArray };
