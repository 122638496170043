import { download } from './download';
import { delay } from './delay';

const multiDownload = async (urls: string[]) => {
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];

    download(url);
    await delay(500);
  }
};

export { multiDownload };
