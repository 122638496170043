import React from 'react';

type CallableChildren<OptionsType = unknown> =
  | React.ReactNode
  | ((options: OptionsType) => React.ReactNode);

const render = <OptionsType = unknown,>(
  children: CallableChildren<OptionsType>,
  options?: OptionsType,
): React.ReactNode => {
  return children
    ? typeof children === 'function'
      ? children((options ?? {}) as OptionsType)
      : children
    : null;
};

export { render };
export type { CallableChildren };
