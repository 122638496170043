const supportsMSE = () => 'MediaSource' in window;

const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MediaSource' in window);

const system = {
  supportsMSE,
  isIOS,
} as const;

export { system };
