import ENV from 'src/env.json';
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from '@sentry/react';
import { BrowserOptions } from '@sentry/browser/types/client';

function initSentry(): void {
  if (ENV.NODE_ENV === 'dev') {
    return;
  }

  const options: BrowserOptions = {
    dsn: ENV.SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: ENV.SENTRY_TRACES_SAMPLE_RATE,
    environment: ENV.NODE_ENV,
  };

  const release = import.meta.env.VITE_REACT_APP_COMMIT_SHA;

  if (release) {
    options['release'] = release;
  } else {
    console.warn('Incomplete sentry configuration - REACT_APP_COMMIT_SHA env var is not defined');
  }

  Sentry.init(options);
}

export { initSentry };
