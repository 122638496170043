import { useCompanyRequestsCountsQuery } from 'src/api/queries';
import { useRequestsCounts } from '../request';
import { useCompanyId } from './useCompanyId';

type FnParams = Parameters<typeof useCompanyRequestsCountsQuery>;

const useCompanyRequestsCounts = (...args: FnParams) => {
  const query = useCompanyRequestsCountsQuery(...args);

  return useRequestsCounts(query);
};

const useCurrentCompanyRequestsCounts = (params?: FnParams[1], options?: FnParams[2]) => {
  const companyId = useCompanyId();

  return useCompanyRequestsCounts(companyId, params, options);
};

export { useCompanyRequestsCounts, useCurrentCompanyRequestsCounts };
