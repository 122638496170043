import React from 'react';
import { Label } from 'src/components/ui/label';
import clsx from 'clsx';
import { ErrorMessage } from './error-message';

type Props<T extends Record<string, any>> = {
  name: string;
  label?: any;
  smallText?: React.ReactNode | 'string' | React.ReactElement;
  children?: React.ReactNode;
  containerClass?: string;
} & T;

const FieldComplex: <T extends Record<string, any>>(props: Props<T>) => React.ReactElement = ({
  required,
  label,
  smallText,
  children,
  name,
  containerClass,
}) => {
  return (
    <div className={containerClass}>
      {label && (
        <Label htmlFor={name} className={clsx({ required })}>
          {label}
        </Label>
      )}
      {!!smallText && <p className="tw-mb-1 tw-text-sm tw-text-neutral-500">{smallText}</p>}
      {children}
      <ErrorMessage name={name} />
    </div>
  );
};

export type { Props as FieldComplexProps };
export { FieldComplex };
