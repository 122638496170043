import { DynamicObject } from 'src/types/generics';
import isObject from 'lodash/isObject';

export { default as Str } from './str';
export { default as UserTypeColor } from './UserTypeColor';
export { default as FileTypes } from './FileTypes';

export * from './DateAndTime';

export const passwordRegExp = RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
);

export const iterateRecursive = (
  iterable: DynamicObject<any> | string[] | string,
  callback: (entry: any) => void,
): void => {
  if (isObject(iterable)) {
    for (const key in iterable) {
      iterateRecursive((iterable as DynamicObject<any>)[key], callback);
    }
  } else if (Array.isArray(iterable)) {
    (iterable as any[]).forEach((entry) => {
      iterateRecursive(entry, callback);
    });
  } else {
    callback(iterable);
  }
};

export const isValidHttpUrl = (str?: string): boolean => {
  let url;

  try {
    url = new URL(str ?? '');
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const buildUrl = (url: string, params: { [key: string]: string }): string => {
  for (const key in params) {
    const value = params[key];
    url = url.replace('{' + key + '}', value);
  }

  return url;
};

const arrFromInt = (int: number): number[] => Array.from(Array(int).keys());

export { arrFromInt };
