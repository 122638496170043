import React from 'react';
import { useController } from 'react-hook-form';
import {
  ReactSelectInput,
  configReactSelectProps,
  useReactSelectValue,
} from 'src/components/ReactSelectInput';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';

type Props = Omit<ReactSelectProps<OptionObject>, 'name' | 'value' | 'onChange'> & {
  name: string;
  handleChange?: (value: any) => void;
};

const ReactSelectHookFormInput: React.FC<Props> = ({ name, ...props }) => {
  const { field, fieldState } = useController({
    name,
  });

  const { value, onChange, getOptionValue } = useReactSelectValue(
    {
      value: field.value,
      setValue: (value) => {
        if (props.handleChange) {
          props.handleChange(value);
        }
      },
    },
    {
      ...props,
      onChange: (newValue) => {
        field.onChange(getOptionValue(newValue));
      },
    },
  );

  props = configReactSelectProps({
    ...props,
    isError: fieldState.invalid,
  });

  return (
    <ReactSelectInput
      {...props}
      ref={field.ref}
      name={name}
      value={value}
      options={props.options}
      onBlur={(event) => {
        props.onBlur?.(event);
        field.onBlur();
      }}
      onChange={onChange}
    />
  );
};

export { ReactSelectHookFormInput, type Props as ReactSelectHookFormInputProps };
