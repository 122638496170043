type FileType = 'video' | 'image' | 'audio' | 'pdf' | 'zip' | 'unknown';

const getExtension = (fileName: string) => {
  return fileName.toLowerCase().split('.').pop() ?? '';
};

const isVideoMime = (mimeType: string) => {
  return mimeType.startsWith('video/');
};

const isImageMime = (mimeType: string) => {
  return mimeType.startsWith('image/');
};

const isAudioMime = (mimeType: string) => {
  return mimeType.startsWith('audio/');
};

const isPdfMime = (mimeType: string) => {
  return mimeType === 'application/pdf';
};

const isZipMime = (mimeType: string) => {
  return ['application/zip', 'application/x-7z-compressed'].includes(mimeType);
};

const getFileTypeByMime = (mimeType: string): FileType => {
  if (isVideoMime(mimeType)) {
    return 'video';
  } else if (isImageMime(mimeType)) {
    return 'image';
  } else if (isAudioMime(mimeType)) {
    return 'audio';
  } else if (isPdfMime(mimeType)) {
    return 'pdf';
  } else if (isZipMime(mimeType)) {
    return 'zip';
  } else {
    return 'unknown';
  }
};

const formatSize = (bytes : number): string => {
  if (!bytes) {
    return '0 Bytes';
  }
  
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(1000));

  return `${parseFloat((bytes / Math.pow(1000, i)).toFixed(0))} ${sizes[i]}`;
}

export type { FileType };
export {
  getExtension,
  isVideoMime,
  isImageMime,
  isAudioMime,
  isPdfMime,
  isZipMime,
  getFileTypeByMime,
  formatSize,
};
