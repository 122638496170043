import { useEffect, useState } from 'react';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { getSelectedOptions, getSelectedOption } from './ReactSelectInput.utils';
import { OptionObject, ReactSelectOnChangeFn } from './ReactSelectInput.types';

type Field = {
  value: any;
  setValue: (value?: any) => void;
};

type Props = Pick<ReactSelectProps<OptionObject>, 'isMulti' | 'options' | 'onChange'>;

const useReactSelectValue = (field: Field, props?: Props) => {
  const getValue = () => {
    if (typeof field.value === 'undefined' || field.value === null) {
      return null;
    }

    if (props?.isMulti) {
      return getSelectedOptions(props.options ?? [], field.value);
    } else {
      return getSelectedOption(props?.options ?? [], field.value);
    }
  };

  const [value, setValue] = useState<OptionObject | OptionObject[] | undefined | null>(getValue());

  const onChange: ReactSelectOnChangeFn = (newValue, actionMeta) => {
    field.setValue(getOptionValue(newValue));

    setValue(newValue as any);

    props?.onChange && props.onChange(newValue, actionMeta);
  };

  const getOptionValue = (newValue: any) => {
    if (!newValue) {
      return null;
    }

    if ('value' in newValue) {
      return newValue.value;
    }

    const mapped = newValue.map((option: OptionObject) => option.value);
    return mapped.length ? mapped : null;
  };

  //todo: figure out why is this needed
  useEffect(() => {
    setValue(getValue());
  }, [props?.options, field.value]);

  return {
    value,
    onChange,
    getOptionValue,
  };
};

export { useReactSelectValue };
