import React, { useState } from 'react';
import { CardProps } from 'reactstrap';
import {
  StyledMinimalMediaViewer,
  StyledMiniMediaCard,
  StyledMiniMediaCardBody,
  StyledMiniMediaCardDownloadLink,
  StyledMiniMediaCardRemoveButton,
} from './MinimalMediaCard.styled';
import { If } from 'src/components/If';
import { MediaModal } from '../MediaModal';
import { MediaObject } from 'src/api/services/MediaClient';
import { FileName } from './file-name';
import clsx from 'clsx';

type Props = CardProps & {
  media: MediaObject;
  canView?: boolean;
  canDownload?: boolean;
  displayName?: boolean;
  canDelete?: boolean;
  beforeDelete?: (e: MouseEvent) => boolean;
  onDelete?: (media: MediaObject) => Promise<void>;
};

const DeletableMinimalMediaCard: React.FC<Props> = ({
  media,
  canView = true,
  canDownload = true,
  displayName = false,
  canDelete = false,
  beforeDelete,
  onDelete,
  ...props
}) => {
  const [viewMode, setViewMode] = useState<boolean>(false);
  const toggleViewMode = () => setViewMode((prev) => !prev);

  return (
    <StyledMiniMediaCard {...props}>
      <StyledMiniMediaCardBody onClick={toggleViewMode}>
        <StyledMinimalMediaViewer media={media} />

        <If when={canDelete}>
          <StyledMiniMediaCardRemoveButton
            $centeredContent={true}
            color={'light'}
            onClick={(e: MouseEvent) => {
              if (beforeDelete && !beforeDelete(e)) {
                return;
              }

              onDelete?.(media);
            }}
          >
            <i className={'bx bxs-x-circle'} />
          </StyledMiniMediaCardRemoveButton>
        </If>
      </StyledMiniMediaCardBody>

      <If when={displayName && !canDownload}>{<FileName fileName={media.file_name} />}</If>

      <If when={canDownload}>
        <StyledMiniMediaCardDownloadLink
          href={media?.index_url}
          target={'_blank'}
          rel={'noreferrer'}
          className={clsx(!media?.index_url && 'disabled')}
        >
          {displayName ? <FileName fileName={media.file_name} /> : 'Download'}
        </StyledMiniMediaCardDownloadLink>
      </If>

      <If when={canView}>
        <MediaModal media={media} size={'lg'} toggle={toggleViewMode} isOpen={viewMode} />
      </If>
    </StyledMiniMediaCard>
  );
};

export { DeletableMinimalMediaCard };
