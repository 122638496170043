import React from 'react';
import { MenuListProps } from 'react-select';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { ScrollArea } from 'src/components/ui/scroll-area';

type Props<T extends Record<string, any>> = MenuListProps<
  OptionObject<T>,
  boolean,
  GroupBase<OptionObject<T>>
>;

const ReactSelectMenuList = <T extends Record<string, any>>({
  children,
  ...props
}: Props<T>): JSX.Element => {
  return (
    <ScrollArea
      onScrollDown={(ref, event) => {
        const reachedBottom = ref.scrollTop + ref.clientHeight >= ref.scrollHeight;
        const reachedTop = ref.scrollTop === 0;

        if (reachedBottom) {
          props.selectProps.onMenuScrollToBottom?.(event as any);
        }

        if (reachedTop) {
          props.selectProps.onMenuScrollToTop?.(event as any);
        }
      }}
    >
      <div className={'tw-max-h-[240px] tw-px-1'}>{children}</div>
    </ScrollArea>
  );
};

export { ReactSelectMenuList };
