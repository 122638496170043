import { useProfileQuery } from 'src/api/queries';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { hasPermissions } from 'src/services/Permission';
import { UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { profileClient, ProfileResponse, userType } from 'src/lib/services/api/profile-api';

const useProfile = (options?: UseQueryOptionsObject<ProfileResponse>) => {
  const { data, ...profileQuery } = useProfileQuery(options);

  const isEditor = data?.type === userType.editor,
    isAdmin = data?.type === userType.admin,
    loaded = !profileQuery.isLoading && profileQuery.isSuccess,
    fullName = joinExistingValues([data?.first_name, data?.last_name], ' '),
    isImpersonated = !!AuthUtils.getPayload()?.impersonator;

  const stopImpersonating = async (): Promise<string> => {
    return profileClient.stopImpersonating();
  };

  return {
    ...(data ?? {}),
    fullName,
    isEditor,
    isAdmin,
    loaded,
    isImpersonated,
    stopImpersonating,
    query: profileQuery,
    ...hasPermissions(data?.permissions ?? []),
  };
};

export { useProfile };
