import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'reactstrap';
import { TaskResponse } from 'src/api/services/TaskClient';
import { Button } from '@becreatives/becreatives-ui';
import { useTranslation } from 'react-i18next';
import { useTask } from '../../models';
import { If } from '../If';
import { ModalToggler } from '../Modal';
import { EditTaskModal } from './EditTaskModal';

type Props = Omit<ModalProps, 'toggle'> & {
  toggle: () => void;
  task: TaskResponse;
  onComplete?: (task: TaskResponse) => any | Promise<any>;
  onDelete?: (task: TaskResponse) => any | Promise<any>;
  onEdit?: (task: TaskResponse) => any | Promise<any>;
};

const ViewTaskModal: React.FC<Props> = ({
  toggle,
  task,
  onComplete,
  onEdit,
  onDelete,
  ...props
}) => {
  const { t } = useTranslation('actions');

  const [isDisabled, setDisabled] = useState(false);

  const { complete, remove } = useTask(task.id, { enabled: false });

  return (
    <Modal {...props} toggle={toggle}>
      <ModalHeader toggle={toggle} className={'border-0'}>
        {task.title}
      </ModalHeader>

      <ModalBody className={'read-newlines'}>{task.description}</ModalBody>

      <ModalFooter className={'border-0 gap-2'}>
        <ModalToggler
          className={'w-md flex-grow-1 flex-md-grow-0 m-0'}
          disabled={isDisabled}
          modal={EditTaskModal}
          modalProps={{
            task,
            onSubmit: onEdit,
          }}
          toggler={Button}
        >
          {t('edit')}
        </ModalToggler>

        <Button
          className={'m-0 me-md-auto w-md flex-grow-1 flex-md-grow-0'}
          color={'danger'}
          disabled={isDisabled}
          onClick={() => {
            if (confirm(t('confirm.delete_task', { ns: 'alerts' })!)) {
              remove();
              onDelete?.(task);
            }
          }}
        >
          {t('delete')}
        </Button>

        <If when={!task.status}>
          <Button
            className={'m-0 ms-md-auto w-xl flex-grow-1 flex-md-grow-0'}
            color={'success'}
            disabled={isDisabled}
            onClick={async () => {
              setDisabled(true);

              await complete()
                .then(() => {
                  toggle();
                  onComplete?.(task);
                })
                .catch(() => ({}))
                .finally(() => setDisabled(false));
            }}
          >
            {t('complete')}
          </Button>
        </If>
      </ModalFooter>
    </Modal>
  );
};

export { ViewTaskModal };
