import React, { useCallback, useEffect } from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { BlockerFunction, useBlocker } from 'react-router-dom';

type DirtyFormPromptProps = {
  isDirty: boolean;
};

type Props = {
  open: boolean;
  confirm: () => void;
  cancel: () => void;
};

const DirtyFormPrompt: React.FC<DirtyFormPromptProps> = ({ isDirty }) => {
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
    [isDirty],
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
  }, [blocker.state, isDirty]);

  return (
    <ConfirmationDialog
      open={blocker.state === 'blocked'}
      confirm={() => blocker.proceed?.()}
      cancel={() => blocker.reset?.()}
    />
  );
};

const ConfirmationDialog: React.FC<Props> = ({ open, confirm, cancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog modal open={open}>
      <DialogContent onOpenAutoFocus={(el) => el?.preventDefault()}>
        <DialogHeader>
          <DialogTitle className={'tw-text-xl tw-font-bold tw-tracking-tight'}>
            {t('Are you sure you want leave?')}
          </DialogTitle>
          <DialogClose asChild>
            <Button type={'button'} variant={'ghost'} size={'icon'} onClick={cancel}>
              <X size={20} />
            </Button>
          </DialogClose>
        </DialogHeader>
        <p>{t('It seems you have unsaved changes.')}</p>
        <DialogFooter>
          <Button size={'responsive'} variant={'destructiveOutline'} onClick={cancel}>
            {t('Stay')}
          </Button>
          <Button variant={'brand'} size={'responsive'} onClick={confirm}>
            {t('Leave to other page')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DirtyFormPrompt;
