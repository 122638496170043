import React from 'react';
import { RequestActivitiesQueryContextProvider } from 'src/features/requests/request-activity/use-request-activities-query-context';
import RequestActivityListContent, {
  RequestActivityListContentSkeleton,
} from 'src/features/requests/request-activity/request-activity-list-content';
import { CardErrorFallback } from 'src/features/fallback';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

type Props = {
  requestId: string;
};

const RequestActivityList: React.FC<Props> = ({ requestId }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <CardErrorFallback
              error={error}
              resetErrorBoundary={resetErrorBoundary}
              className={'tw-border-0 tw-shadow-none'}
              cardContentProps={{
                className: 'tw-p-0 tw-pt-4',
              }}
            />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<RequestActivityListContentSkeleton />}>
            <RequestActivitiesQueryContextProvider id={requestId}>
              <RequestActivityListContent />
            </RequestActivitiesQueryContextProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export default RequestActivityList;
