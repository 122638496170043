import { RouteObject } from 'react-router-dom';
import { appRouterPath } from 'src/lib/router/app-router-path';

export const airtableRootRoute: RouteObject = {
  id: 'airtableRoot',
  path: appRouterPath.airtable.root,
  lazy: async () => {
    const { AirtableRoot } = await import('src/pages/airtable/airtable-root');
    return { Component: AirtableRoot };
  },
};

export const airtableBaseRoute: RouteObject = {
  id: 'airtableBase',
  path: appRouterPath.airtable.base.root,
  lazy: async () => {
    const { AirtableBase } = await import('src/pages/airtable/airtable-base');
    return { Component: AirtableBase };
  },
};

export const airtableBaseTableRoute: RouteObject = {
  id: 'airtableTable',
  path: appRouterPath.airtable.base.table,
  lazy: async () => {
    const { AirtableBaseTable } = await import('src/pages/airtable/airtable-base-table');
    return { Component: AirtableBaseTable };
  },
};
