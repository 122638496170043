import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { Button } from 'src/components/ui/button';
import { cn } from 'src/lib/utils';
import { If } from 'src/components/If';
import { Breadcrumbs } from '@becreatives/becreatives-ui';
import { appRoutes } from 'src/routes';
import { useCompanyId } from 'src/features/companies/use-company-id';
import { Link } from 'src/components/ui/link';

const RequestOutcomePageContentTop = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = useCompanyId();
  const request = useDecoratedRequestContext();

  return (
    <div ref={ref} className={cn('tw-flex tw-flex-wrap tw-gap-4', className)} {...props}>
      <If
        when={!!children}
        else={
          <>
            <Breadcrumbs
              crumbs={[
                {
                  text: request?.title ?? t('unset', { ns: 'common' })!,
                  className: 'fw-bold fs-5',
                },
              ]}
              back={() => {
                if (location.state && location.state.from) {
                  navigate(location.state.from);
                } else {
                  navigate(
                    generatePath(appRoutes.request, {
                      requestId: request.parent_id ?? '',
                      companyId,
                    }),
                  );
                }
              }}
              className={'mb-4 tw-truncate'}
            />

            <div
              className={
                'tw-flex tw-grow tw-flex-wrap tw-items-center tw-justify-center tw-gap-2 sm:tw-justify-end md:tw-gap-4'
              }
            >
              <Button asChild variant={'accent1'}>
                <Link
                  variant={'unset'}
                  to={generatePath(appRoutes.requestRevisions, {
                    requestId: request.id,
                    companyId,
                  })}
                >
                  {t('models/revision:name.plural')}
                </Link>
              </Button>
            </div>
          </>
        }
      >
        {children}
      </If>
    </div>
  );
});
RequestOutcomePageContentTop.displayName = 'RequestOutcomePageContentTop';

export { RequestOutcomePageContentTop };
