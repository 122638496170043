const toFinite = (value: number | string | undefined | null) => {
  if (!value) {
    return 0;
  }

  const parsed = parseFloat(value as string);

  return Number.isFinite(parsed) ? parsed : 0;
};

export const float = {
  toFinite,
} as const;
