type RequestContentTab = {
  deliverables: string;
  pendingOutcomes: string;
  summary: string;
};

export const requestContentTab: RequestContentTab = {
  deliverables: 'deliverables',
  pendingOutcomes: 'pending_outcomes',
  summary: 'summary',
} as const;
