import React, { useState } from 'react';
import { TabContent } from 'reactstrap';
import {
  Card,
  NavItem,
  NavLink,
  Text,
  OrderInfoTabPane,
  OrderInfoTabInfoPaneContent,
  OrderInfoTabPaneGroupItem,
  OrderInfoNavLink,
} from '@becreatives/becreatives-ui';
import { OrderInfoNav, OrderInfoStatusCardGrid } from './RevisionsContentBottom.styled';
import { generatePath, Link } from 'react-router-dom';
import { appRoutes } from 'src/routes/routes.types';
import { OrderInfoMediaNavLink } from './OrderInfoMediaNavLink';
import { If } from 'src/components/If';
import { useTranslation } from 'react-i18next';
import { OrderNotes } from './OrderNotes';
import Str from 'src/helpers/str';
import dompurify from 'dompurify';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { totalLengthToString } from 'src/features/requests/request-fields/request-field.helper';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useCompanyId } from 'src/features/companies/use-company-id';
import { CardContent } from 'src/components/ui/card';
import RequestActivityList from 'src/features/requests/request-activity/request-activity-list';
import { ScrollArea } from 'src/components/ui/scroll-area';

const RevisionsContentBottom: React.FC = () => {
  const { t } = useTranslation('attributes/request');

  const companyId = useCompanyId();
  const [activeTab, setActiveTab] = useState('general');
  const { status = 'queued', editor_note, ...request } = useDecoratedRequestContext();

  const contentFallback = t('not_available', { ns: 'common' });

  const tabs = [
    {
      title: t('revisions.order.tab.general', { ns: 'pages/shared' }),
      id: 'general',
    },
    {
      title: t('revisions.order.tab.resolutions', { ns: 'pages/shared' }),
      id: 'resolutions',
    },
    {
      title: t('revisions.order.tab.description', { ns: 'pages/shared' }),
      id: 'description',
    },
    {
      title: t('revisions.order.tab.supporting_materials', { ns: 'pages/shared' }),
      id: 'supporting_materials',
    },
    {
      title: t('revisions.order.tab.extra', { ns: 'pages/shared' }),
      id: 'extra',
    },
  ];

  const tabContent: Record<string, Record<'title' | 'content', any>[]> = {
    general: [
      {
        title: t('type'),
        content: request?.type
          ? t(`type.${request.type}`, { ns: 'models/request' })
          : contentFallback,
      },
      {
        title: t('title'),
        content: request?.title || contentFallback,
      },
      {
        title: t('brand_id'),
        content: !request?.brand ? (
          contentFallback
        ) : (
          <OrderInfoNavLink
            as={Link as any}
            to={generatePath(appRoutes.brand, { companyId, brandId: request.brand.id })}
            className={'text-decoration-underline'}
          >
            {request.brand.name}
          </OrderInfoNavLink>
        ),
      },
    ],
    resolutions: [
      {
        title: t('resolutions.main_size'),
        content: request?.resolutions?.main_size || contentFallback,
      },
      {
        title: t('resolutions.resize'),
        content: request?.resolutions?.resize?.join(', ') || contentFallback,
      },
    ],
    description: [
      {
        title: t('total_length', { defaultValue: 'Total length' }),
        content:
          totalLengthToString(request?.total_length) ||
          request?.description?.total_length ||
          contentFallback,
      },
      {
        title: t('description.video_request_description'),
        content: (
          <p
            className={'m-0'}
            dangerouslySetInnerHTML={{
              __html: Str.wrapHrefs(
                dompurify.sanitize(
                  request?.description?.video_request_description || contentFallback,
                  {
                    USE_PROFILES: { html: true },
                  },
                ),
              ),
            }}
          />
        ),
      },
    ],
    supporting_materials: [
      {
        title: t('supporting_materials'),
        content: !request?.media?.length ? (
          contentFallback
        ) : (
          <>
            {request.media.map((thumbnail, i) => (
              <OrderInfoMediaNavLink key={i} media={thumbnail}>
                <If when={request!.media!.length - 1 > i}>{','}&nbsp;</If>
              </OrderInfoMediaNavLink>
            ))}
          </>
        ),
      },
      {
        title: t('supporting_materials.music_preference'),
        content: request?.supporting_materials?.music_preference || contentFallback,
      },
    ],
    extra: [
      {
        title: t('extra.subtitles'),
        content: request?.extra?.subtitles || contentFallback,
      },
      {
        title: t('extra.end_card'),
        content: request?.extra?.end_card ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' }),
      },
      {
        title: t('extra.examples'),
        content: request?.extra?.examples || contentFallback,
      },
    ],
  };

  return (
    <div className={'tw-flex tw-flex-col tw-gap-6'}>
      <h3 className="tw-text-2xl tw-font-semibold">
        {t('revisions.order.section_title', { ns: 'pages/shared' })}
      </h3>

      <Card>
        <OrderInfoStatusCardGrid>
          <Text tag={'b'}>{t('status')}</Text>
          <div>
            <RequestStatusBadge variant={status} />
          </div>

          <Text tag={'b'}>{t('editor_note')}:</Text>
          <Text>{editor_note || t('common:unset')}</Text>
        </OrderInfoStatusCardGrid>
      </Card>

      <Card>
        <OrderInfoNav>
          {tabs.map((tab, i) => (
            <NavItem key={i}>
              <NavLink
                active={tab.id === activeTab}
                onClick={() => setActiveTab(tab.id)}
                color={'primary'}
                className={'py-2 py-md-3'}
              >
                {tab.title}
              </NavLink>
            </NavItem>
          ))}
        </OrderInfoNav>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, i) => (
            <OrderInfoTabPane key={i} tabId={tab.id}>
              <OrderInfoTabInfoPaneContent>
                {tabContent[tab.id].map((content, j) => (
                  <OrderInfoTabPaneGroupItem key={j}>
                    <Text tag={'b'}>{content.title}</Text>
                    <Text tag={'span'}>{content.content}</Text>
                  </OrderInfoTabPaneGroupItem>
                ))}
              </OrderInfoTabInfoPaneContent>
            </OrderInfoTabPane>
          ))}
        </TabContent>
      </Card>

      <h3 className="tw-text-2xl tw-font-semibold">
        {t('revisions.order.notes.section_title', { ns: 'pages/shared' })}
      </h3>

      <Card>
        <OrderNotes />
      </Card>

      <div className={'tw-flex tw-flex-col tw-gap-4'}>
        <h3 className="tw-text-2xl tw-font-semibold">
          {t('pages/shared:revisions.activity', 'Activity')}
        </h3>

        <Card>
          <CardContent className={'tw-p-4 md:tw-p-6'}>
            <ScrollArea>
              <div className="tw-max-h-96">
                <RequestActivityList requestId={request.id} />
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export { RevisionsContentBottom };
