import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';

const useDebouncedState = <T,>(
  initialState: T,
  delay = 500,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initialState);

  const debouncedSetState = useCallback(debounce(setState, delay), []);

  return [state, debouncedSetState];
};

export { useDebouncedState };
