import { createContext } from 'react';
import { TableStoreContextObject } from './Table.types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/lib/services/api-query-params';

const TableStoreContext = createContext<TableStoreContextObject>({
  state: {
    data: [],
    totalPages: 0,
    totalItems: 0,
    isLoading: false,
    isSuccess: false,
    isFetching: false,
    filters: {
      page: DEFAULT_PAGE_INDEX,
      limit: DEFAULT_PAGE_SIZE,
    },
    columnVisibility: {},
    setColumnVisibility: () => ({}),
    expanded: {},
    setExpanded: () => ({}),
  },
  setFilters: () => ({}),
  refetch: () =>
    new Promise(() => {
      return null;
    }),
  setData: () => ({}),
  getEditRow: () => () => ({}),
  reorder: () => ({}),
});

export { TableStoreContext };
