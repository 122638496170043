export const progressInStandardTime = (seconds: number) => {
  const date = new Date(Math.round(seconds) * 1000);

  const hh = date.getUTCHours();
  const mm = `${date.getUTCMinutes()}`.padStart(2, '0');
  const ss = `${date.getUTCSeconds()}`.padStart(2, '0');

  if (hh) {
    return `${hh}:${mm}:${ss}`;
  }

  return `${mm}:${ss}`;
};
