import React from 'react';
import { ClearIndicatorProps, components } from 'react-select';
import { X } from 'lucide-react';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { GroupBase } from 'react-select/dist/declarations/src/types';

type Props<T extends Record<string, any>> = ClearIndicatorProps<
  OptionObject<T>,
  boolean,
  GroupBase<OptionObject<T>>
>;

const ReactSelectClearIndicator = <T extends Record<string, any>>(props: Props<T>): JSX.Element => {
  return (
    <components.ClearIndicator {...props}>
      <X className={'tw-h-4 tw-w-4'} />
    </components.ClearIndicator>
  );
};

export { ReactSelectClearIndicator };
