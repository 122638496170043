import React from 'react';
import { cn } from 'src/lib/utils';
import { Megaphone } from 'lucide-react';

const ErrorBlock: React.FC<React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>> = ({
  className,
  children,
}) => {
  return (
    <div className={cn('tw-rounded-lg tw-bg-destructive-300 tw-p-4', className)}>
      <div className={'tw-flex tw-items-center tw-gap-2'}>
        <div>
          <Megaphone />
        </div>
        <div className={'tw-font-medium'}>{children}</div>
      </div>
    </div>
  );
};

export { ErrorBlock };
