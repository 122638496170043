import React, { useEffect } from 'react';
import {
  VideoPlayer,
  VideoPlayerContainer,
  VideoPlayerWrapper,
  useVideoPlayerV2,
  useVideoPlayToggle,
  OverlayIcon,
  makeVideoPlayerConfig,
  RootVideoPlayerWrapper,
  VideoOverlay,
  VideoPlayerPlaybackToggleBox,
  VideoPlayerLoadingIndicator,
  VideoPlayerControlsBottomWrapper,
} from 'src/features/video-player/video-player';
import { Play } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { VolumeControl } from 'src/features/video-player/volume-control';
import { TogglePlayButton } from 'src/features/video-player/toggle-play-button';
import { ToggleFullscreenButton } from 'src/features/video-player/toggle-fullscreen-button';
import { VideoPlayerSettingsDropdownMenu } from 'src/features/video-player/video-player-settings-dropdown-menu';
import { VideoPlayerPreview } from 'src/features/video-player/video-player-preview';
import { VideoProgressTimeText } from 'src/features/video-player/video-progress-time-text';
import { VideoProgressSlider } from 'src/features/video-player/video-progress-slider';
import { useVideoPlayerKeyboardEvents } from 'src/features/video-player/use-video-player-keyboard-events';
import { VideoPlayerErrorIndicator } from 'src/features/video-player/video-player-error-overlay';
import { useVideoPlayerControlsVisibility } from 'src/features/video-player/use-video-player-controls-visibility';

type ComposedVideoPlayerProps = {
  children?: React.ReactNode;
  ratio?: number;
  sprite?: React.ComponentProps<typeof VideoProgressSlider>['sprite'];
  enableCustomTimeFormats?: boolean;
  onTimeFormatChange?: (format: string) => void;
  poster?: string;
} & React.ComponentProps<typeof VideoPlayer>;

export const ComposedVideoPlayer: React.FC<ComposedVideoPlayerProps> = ({
  ratio,
  children,
  sprite,
  poster,
  enableCustomTimeFormats,
  onTimeFormatChange,
  onReady,
  onError,
  ...props
}) => {
  const context = useVideoPlayerV2();
  const { pause, playing } = useVideoPlayToggle();
  const { controlsVisible, setControlsVisible } = useVideoPlayerControlsVisibility();
  const { handleKeyDown } = useVideoPlayerKeyboardEvents();

  const videoPlayerConfig = makeVideoPlayerConfig();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [context.videoDriverRef.current]);

  return (
    <VideoPlayerContainer ratio={ratio} ref={context.containerRef}>
      <VideoPlayerWrapper className={cn('tw-bg-dark/90', context.fullscreen && 'tw-rounded-none')}>
        {!context.isPreview && context.canRender ? (
          <VideoPlayer
            onPlay={() => {
              context.setLoading(false);
              context.setError(undefined);
              context.setPlaying(true);
              setControlsVisible(false);
            }}
            onPause={() => {
              context.setPlaying(false);
              setControlsVisible(true);
            }}
            onBuffer={() => {
              context.setLoading(true);
              context.setPlayerReady(false);
            }}
            onBufferEnd={() => {
              context.setLoading(false);
              context.setPlayerReady(true);
            }}
            ref={context.videoDriverRef}
            url={context.src}
            playing={playing}
            volume={context.volume}
            muted={context.muted}
            onError={(err, ...args) => {
              context.setInitialLoaded(true);
              context.setLoading(false);
              context.debouncedSetError(err);
              pause();
              onError?.(err, ...args);
            }}
            onReady={(player) => {
              context.setInitialLoaded(true);
              context.setLoading(false);
              context.setError(undefined);
              context.setPlayerReady(true);
              context.onPlayerReady?.(player);
              onReady?.(player);
            }}
            wrapper={RootVideoPlayerWrapper}
            config={videoPlayerConfig}
            playbackRate={context.playbackRate}
            playsinline={context.playsinline}
            onProgress={(args) => {
              context.progressStoreActions.set(args);
              context.setError(undefined);
            }}
            onEnded={pause}
            progressInterval={1 / context.meta.fps}
            {...props}
          />
        ) : null}

        <VideoOverlay
          ref={context.overlayRef}
          onMouseMove={() => {
            setControlsVisible(true);
          }}
          onMouseLeave={() => {
            if (!context.playing) {
              return;
            }
            setControlsVisible(false);
          }}
        >
          {/* While player is initially loading render poster image */}
          {!context.initialLoaded && (
            <VideoPlayerPreview
              preview={{
                src: poster,
              }}
            >
              {context.isPreview ? (
                <div
                  onClick={() => context.setPreview(false)}
                  className={
                    'tw-absolute tw-inset-0 tw-z-10 tw-flex tw-cursor-pointer tw-items-center tw-justify-center'
                  }
                >
                  <OverlayIcon icon={Play} className={'!tw-size-16 sm:!tw-size-20'} />
                </div>
              ) : (
                <></>
              )}
            </VideoPlayerPreview>
          )}

          {context.loading && <VideoPlayerLoadingIndicator />}

          {!context.isPreview && (
            <>
              {context.isError && <VideoPlayerErrorIndicator />}

              <VideoPlayerPlaybackToggleBox />

              <VideoPlayerControlsBottomWrapper
                className={cn(
                  'group/hover-overlay:tw-opacity-100 tw-transition-opacity tw-duration-300',
                  controlsVisible ? 'lg:tw-opacity-100' : 'lg:tw-opacity-0',
                )}
              >
                <VideoProgressSlider sprite={sprite} />

                {children}

                <div className={'tw-flex tw-w-full tw-gap-2'}>
                  <TogglePlayButton variant={'unset'} size={'icon'} />

                  <VolumeControl className={'tw-hidden md:tw-flex'} />

                  <VideoProgressTimeText />

                  <VideoPlayerSettingsDropdownMenu
                    enableCustomTimeFormats={enableCustomTimeFormats}
                    onTimeFormatChange={onTimeFormatChange}
                  />

                  <ToggleFullscreenButton variant={'unset'} size={'icon'} />
                </div>
              </VideoPlayerControlsBottomWrapper>
            </>
          )}
        </VideoOverlay>
      </VideoPlayerWrapper>
    </VideoPlayerContainer>
  );
};
