const defaultNamespaces = [
  'attributes/airtable_base',
  'attributes/airtable_table',
  'attributes/airtable_model_template',

  'attributes/company_brand',
  'attributes/company',
  'attributes/request',
  'attributes/subscription',
  'attributes/subscription_product',
  'attributes/subscription_assignment',
  'attributes/user',
  'attributes/common',
  'attributes/task',
  'attributes/team',
  'attributes/team_member',

  'models/airtable_base',
  'models/airtable_table',
  'models/airtable_model_template',
  'models/company_brand',
  'models/company',
  'models/company_personnel',
  'models/notification',
  'models/request',
  'models/revision',
  'models/revision_comment',
  'models/subscription',
  'models/user',
  'models/role',
  'models/task',
  'models/team',
  'models/team_member',

  'pages/shared',
  'pages/admin',

  'services/airtable',

  'exception/request_status_is_not_editable',

  'actions',
  'alerts',
  'auth',
  'common',
  'pagination',
  'passwords',
  'validation',
  'dates',
] as const;

type DefaultNamespaces = (typeof defaultNamespaces)[number];

type NamespaceTranslationsResponse = Record<string, string>;

type TranslationsResponse = {
  [namespace: string | DefaultNamespaces]: NamespaceTranslationsResponse;
};

export type { DefaultNamespaces, NamespaceTranslationsResponse, TranslationsResponse };
export { defaultNamespaces };
