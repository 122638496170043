import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FormMediaObject, FormMediaValue } from './S3UploadInput.types';
import { isMediaArray, isMediaCollection } from './S3UploadInput.utils';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';

const useRemoveMedia = (media: FormMediaObject) => {
  const { setValues } = useFormikContext<{ media: FormMediaValue<string | never> }>();
  const { removeUpload } = useMediaUploadStore();

  return useCallback(() => {
    const filterFn = (m: FormMediaObject) => {
      return m.id !== media.id;
    };

    setValues((prev) => {
      if (isMediaArray(prev.media)) {
        return {
          ...prev,
          media: prev.media.filter(filterFn),
        };
      }

      if (isMediaCollection(prev.media)) {
        return {
          ...prev,
          media: Object.keys(prev.media).reduce((value, collectionKey) => {
            value[collectionKey] = prev.media[collectionKey].filter(filterFn);
            return value;
          }, {} as any),
        };
      }

      return prev;
    });

    removeUpload(media.id);
  }, []);
};

export { useRemoveMedia };
