import React from 'react';

type Props = React.PropsWithChildren<React.ComponentPropsWithRef<'p'>>;

const SmallText: React.FC<Props> = ({
  children,
  className,
  dangerouslySetInnerHTML,
}): JSX.Element => {
  const classNames = ['mb-2 text-dark'];
  if (className) {
    classNames.push(className);
  }

  let smallProps = {};
  if (dangerouslySetInnerHTML) {
    smallProps = {
      dangerouslySetInnerHTML,
    };
  }

  return (
    <p className={classNames.join(' ')}>
      <small {...smallProps}>{children}</small>
    </p>
  );
};

export { SmallText };
