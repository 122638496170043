import React from 'react';
import Select, { Props as ReactSelectProps } from 'react-select';
import { OptionObject } from './ReactSelectInput.types';
import { configReactSelectProps } from 'src/components/ReactSelectInput/ReactSelectInput.utils';

type Props = ReactSelectProps<OptionObject>;

//todo: move react-select variants, dependencies and utilities to a single directory.
// now it is scattered between `components/ReactSelectInput` and `components/ui/react-select`

const ReactSelectInput = React.forwardRef<any, Props>(({ ...props }, ref) => {
  const selectProps = configReactSelectProps(props);

  return <Select ref={ref} {...selectProps} />;
});

ReactSelectInput.displayName = 'ReactSelectInput';

export default ReactSelectInput;
