import React from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useProfile } from 'src/models/profile';
import { appRoutes } from 'src/routes/routes.types';
import { useTranslation } from 'react-i18next';
import { useRequestStatusMap } from 'src/pages/RequestPage/RequestStatusChangeForm/RequestStatusChangeForm.utils';
import { RequestAttentionAction } from 'src/pages/RequestPage/RequestActionComponents/RequestAttentionAction';
import { If } from 'src/components/If';
import { useMediaDownload } from 'src/features/media';
import { RenderForAdmin } from 'src/components/Middleware';
import { roles } from 'src/api/services/RoleClient';
import { useOpenRequestStatusChangeModal } from 'src/features/requests/request-status-change/use-open-request-status-change-modal';
import { Link } from 'src/components/ui/link';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { useCompanyId } from 'src/features/companies/use-company-id';
import { useRequestId } from 'src/features/requests/use-request-id';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useRequestQueryContext } from 'src/features/requests/use-request-query-context';
import { useRequestQueryMutationDecorator } from 'src/features/requests/use-request-query-mutation-decorator';
import {
  decorateRevisions,
  useRequestRevisionsStore,
} from 'src/features/revisions/use-revisions-context-provider';
import { requestClient, requestStatus } from 'src/lib/services/api/request-api';
import { Button, ButtonProps } from 'src/components/ui/button';
import { ChevronLeft, Megaphone, Square, SquareCheckBig, Video } from 'lucide-react';
import { PageHeading, PageHeadingTitle } from 'src/components/ui/page-heading';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { defaultSortFilter } from 'src/api/services/RequestClient';
import { AttachRequestTranscriptFormDialog } from 'src/features/requests/attach-request-transcript-form-dialog';
import { DialogTrigger } from 'src/components/ui/dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authQueries } from 'src/api/queries';
import { useToast } from 'src/components/ui/use-toast';

const RevisionsContentTop: React.FC = () => {
  const { t } = useTranslation('pages/shared');
  const navigate = useNavigate();
  const location = useLocation();

  const companyId = useCompanyId();
  const requestId = useRequestId();
  const { canChangeStatus } = useRequestStatusMap();

  const user = useProfile({
    enabled: false,
  });

  const request = useDecoratedRequestContext();
  const requestQueryContext = useRequestQueryContext();
  const requestQueryActions = useRequestQueryMutationDecorator(requestId);
  const { initialize, selectedRevision } = useRequestRevisionsStore();

  const { openRequestStatusChangeModal } = useOpenRequestStatusChangeModal();

  const listRoute = generatePath(appRoutes.companyRequestsList, { companyId });
  const requestRoute = generatePath(appRoutes.request, {
    companyId,
    requestId: request.parent_id ? request.parent_id : request.id,
  });
  const { downloadAll, isDownloading } = useMediaDownload();

  const changeStatusButtonProps: ButtonProps = {
    variant: 'secondary',
    onClick: () =>
      openRequestStatusChangeModal({
        requestId,
        companyId,
        onSubmit: async ({ status }) => {
          if (status !== requestStatus.delivered) {
            return;
          }

          // If new status is delivered, means we need update revisions store
          const revisions = await requestQueryContext
            .refetch()
            .then(({ data }) => decorateRevisions(data?.revisions ?? []));

          initialize(revisions);
        },
      }),
  };

  if (user.isAdmin && !request.hasEditor) {
    changeStatusButtonProps['title'] = t('warning.request.change_status.unsigned_editor', {
      ns: 'alerts',
    })!;
    changeStatusButtonProps['disabled'] = true;
  }

  if (!canChangeStatus(request.status)) {
    changeStatusButtonProps['title'] = t('warning.request.change_status.customer_action_required', {
      ns: 'alerts',
    })!;
    changeStatusButtonProps['disabled'] = true;
  }

  const revisionsBreadcrumb = t('revisions.breadcrumb', {
    replace: {
      request: request?.title ?? t('unset', { ns: 'common' }),
    },
  })!;

  const hasAnyRevisionMedia = request?.revisions.some((r) => !!r.media);
  const allRevisionsMedia =
    request?.revisions?.filter((r) => r.media).flatMap((r) => r.media!) ?? [];

  return (
    <div className={'tw-mb-4 tw-flex tw-flex-wrap tw-gap-4 md:tw-mb-10'}>
      <div
        className={
          'tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 md:tw-justify-start'
        }
      >
        <PageHeading>
          <Breadcrumb>
            <BreadcrumbList className={'tw-flex-nowrap'}>
              <BreadcrumbItem>
                <Button
                  variant={'unset'}
                  size={'iconXs'}
                  onClick={() => {
                    navigate(requestRoute, { state: location.state });
                  }}
                >
                  <ChevronLeft className={'tw-size-4'} />
                </Button>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={{ pathname: listRoute, search: defaultSortFilter }}>
                    {t('Requests')}
                  </Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={requestRoute} state={location.state}>
                    {t('Request')}
                  </Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbPage>{t('Revisions')}</BreadcrumbPage>
              <RequestStatusBadge variant={request.status} />
            </BreadcrumbList>
          </Breadcrumb>
          <PageHeadingTitle className={'tw-flex-nowrap'}>
            <div>
              <Video className={'tw-size-6'} />
            </div>
            <span className={'tw-line-clamp-1'}>
              {revisionsBreadcrumb || t('Request revision')}
            </span>
          </PageHeadingTitle>
        </PageHeading>
      </div>
      <div className={'tw-ms-auto tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2'}>
        {request.isCompleted && (
          <AttachRequestTranscriptFormDialog requestId={requestId}>
            <DialogTrigger asChild>
              <Button variant={'default'}>{t('Upload transcript')}</Button>
            </DialogTrigger>
          </AttachRequestTranscriptFormDialog>
        )}

        <RenderForAdmin and={[roles.teamLead]}>
          <QaCheckButton />
        </RenderForAdmin>

        <RenderForAdmin and={[roles.teamLead]}>
          <If when={hasAnyRevisionMedia}>
            <Button
              variant={'outline'}
              disabled={isDownloading}
              onClick={() => downloadAll(allRevisionsMedia)}
            >
              {t('download_all_revisions_files', { ns: 'actions' })}
            </Button>
          </If>
        </RenderForAdmin>

        <If when={request.hasMedia}>
          <Button
            variant={'outline'}
            color={'secondary'}
            disabled={isDownloading}
            onClick={() => downloadAll(request?.media ?? [])}
          >
            {t('download_request_files', { ns: 'actions' })}
          </Button>
        </If>

        <If when={request.canRequestAttention}>
          <RequestAttentionAction
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            form={{
              requestId: requestId!,
              initialValues: {
                attention_text: request?.attention_text || '',
              },
              onSubmit: async ({ attention_text }) => {
                requestQueryContext.setQueryData({
                  attention_text,
                  requested_attention_at: new Date().toDateString(),
                });
                requestQueryActions.invalidate();
              },
            }}
            as={Button}
            variant={'outline'}
            className={'tw-flex tw-grow tw-items-center sm:tw-grow-0'}
          >
            <span className={'tw-me-3'}>
              <Megaphone className={'tw-h-5 tw-w-5'} />
            </span>
            {t('actions:request_attention')}
          </RequestAttentionAction>
        </If>

        <Button {...changeStatusButtonProps}>
          {t('change_status', { ns: 'actions' })}
          <i className={'bx bx-video-plus ms-2 font-size-18'} />
        </Button>
      </div>
    </div>
  );
};

export { RevisionsContentTop };

const QaCheckButton = () => {
  const client = useQueryClient();
  const request = useDecoratedRequestContext();
  const { t } = useTranslation();
  const { toast } = useToast();

  const changeQAStatus = useMutation({
    mutationFn: (args: Parameters<typeof requestClient.changeQAStatus>) =>
      requestClient.changeQAStatus(...args),
    onError: (e) => {
      toast({
        description: t('Oops, something went wrong, please try again later.'),
        variant: 'destructive',
      });
      console.error(e);
    },
    onMutate: async () => {
      client.setQueryData([authQueries.request, request?.id], (prev: any) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          qa_checked: !request.qa_checked,
        };
      });
    },
    onSuccess: () => {
      toast({
        description: t('Request QA status updated successfully.'),
      });
      client.invalidateQueries({
        queryKey: [authQueries.request, request?.id],
      });
    },
  });

  return (
    <Button
      className={'tw-flex tw-gap-2'}
      variant={'outline'}
      disabled={changeQAStatus.isLoading}
      onClick={() => changeQAStatus.mutate([request.id, { qa_checked: !request.qa_checked }])}
    >
      {request.qa_checked ? <SquareCheckBig size={20} /> : <Square size={20} />}
      {t('QA')}
    </Button>
  );
};
