class PermissionParts {
  private readonly token = '*';

  private readonly permission: string;

  private readonly parts: string[];

  public readonly resource: string;

  public readonly action: string;

  protected readonly object?: string = undefined;

  constructor(permission: string, object?: string) {
    if (object && !permission.endsWith(this.token)) {
      permission = `${permission}.${object}`;
    }

    this.permission = permission;

    this.parts = permission.split('.');

    if (this.count() < 2) {
      throw new Error('Invalid permission');
    }

    this.resource = this.parts[0];
    this.action = this.parts[1];

    if (this.count() > 2) {
      this.object = this.parts[2];
    }
  }

  isForObject = (): boolean => {
    return !!this.object;
  };

  count = (): number => {
    return this.parts.length;
  };

  base = (): string => {
    return `${this.resource}.${this.action}`;
  };

  asForAll = (): string => {
    return `${this.base()}.${this.token}`;
  };

  toString = (): string => {
    return this.permission;
  };
}

export { PermissionParts };
