import React, { forwardRef } from 'react';
import { LinkStyled } from './Link.styled';

const HrefLink = forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<'a'>>(
  function Component(props, ref) {
    return <LinkStyled target={'_blank'} rel={'noreferrer'} {...props} ref={ref} />;
  },
);

export { HrefLink };
