import React from 'react';
import { AsyncReactSelect } from 'src/components/ui/react-select';
import { AsyncReactSelectProps } from 'src/components/ui/react-select';
import { useDebouncedState, wrap } from 'src/lib/utils';
import { useApiQueryParams } from './use-api-query-params';

type Props<TQueryFnData = unknown> = Omit<
  AsyncReactSelectProps<TQueryFnData>,
  'filters' | 'name' | 'value' | 'setValue' | 'onSearch'
> & {
  groupId?: string;
  name: string;
  onSelect?: (value: any) => void;
};

const ApiQueryParamsAsyncFilterField = <TQueryFnData,>({
  groupId,
  name,
  onSelect,
  ...props
}: Props<TQueryFnData>): JSX.Element => {
  const { setFilter, getFilter, removeFilter } = useApiQueryParams(groupId);
  const [search, setSearch] = useDebouncedState('');

  const value = getFilter(name);

  return (
    <AsyncReactSelect
      filters={{
        search,
        include: wrap(value),
      }}
      {...props}
      value={value}
      setValue={(newValue) => {
        if (!newValue) {
          removeFilter(name);
        } else {
          setFilter({ id: name, value: newValue });
        }

        onSelect?.(newValue);
      }}
      name={name}
      onSearch={setSearch}
    />
  );
};

export type { Props as ApiQueryParamsAsyncFilterFieldProps };
export { ApiQueryParamsAsyncFilterField };
