import React, { useState } from 'react';
import { FormMediaObject } from '../../Form/S3UploadInput';
import { MediaModal } from '../MediaModal';
import {
  StyledMinimalMediaViewer,
  StyledMiniMediaCard,
  StyledMiniMediaCardBody,
  StyledMiniMediaCardDownloadLink,
} from './MinimalMediaCard.styled';
import { If } from 'src/components/If';
import { CardProps } from '@becreatives/becreatives-ui';
import { FileName } from './file-name';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type Props = CardProps & {
  media: FormMediaObject;
  canView?: boolean;
  canDownload?: boolean;
  displayName?: boolean;
};

const MinimalMediaCard: React.FC<Props> = ({
  media,
  canView = true,
  canDownload = true,
  displayName = false,
  ...props
}) => {
  const { t } = useTranslation('actions');

  const [viewMode, setViewMode] = useState<boolean>(false);
  const toggleViewMode = () => setViewMode((prev) => !prev);

  return (
    <StyledMiniMediaCard {...props}>
      <StyledMiniMediaCardBody onClick={toggleViewMode}>
        <StyledMinimalMediaViewer media={media} />
      </StyledMiniMediaCardBody>

      <If when={displayName && !canDownload}>{<FileName fileName={media.file_name} />}</If>

      <If when={canDownload}>
        <StyledMiniMediaCardDownloadLink
          href={media?.index_url}
          target={'_blank'}
          rel={'noreferrer'}
          className={clsx(!media?.index_url && 'disabled')}
        >
          {displayName ? <FileName fileName={media.file_name} /> : t('download')}
        </StyledMiniMediaCardDownloadLink>
      </If>

      <If when={canView}>
        <MediaModal media={media} size={'lg'} toggle={toggleViewMode} isOpen={viewMode} />
      </If>
    </StyledMiniMediaCard>
  );
};

export type { Props as MinimalMediaCardProps };
export default MinimalMediaCard;
