import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { If } from 'src/components/If';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from 'src/lib/utils';
import { Label } from 'src/components/ui/label';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { CollapsibleCard, CollapsibleCardContent } from 'src/components/ui/collapsible-card';
import { useToggle } from 'src/lib/state-utils';
import {
  Copy,
  Dessert,
  Eye,
  FileText,
  FileVideo2,
  Link2Icon,
  PenLine,
  Undo,
  FileStack,
} from 'lucide-react';
import dompurify from 'dompurify';
import { Button } from 'src/components/ui/button';
import { Href } from 'src/components/ui/href';
import { useToast } from 'src/components/ui/use-toast';
import { Skeleton } from 'src/components/ui/skeleton';
import { Link } from 'src/components/ui/link';
import { generatePath } from 'react-router-dom';
import { appRoutes } from 'src/routes';
import { Alert } from 'src/components/ui/alert';
import { useRequestQueryMutationDecorator } from 'src/features/requests/use-request-query-mutation-decorator';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { requestContentTab } from 'src/pages/request/request-page/request-page.utils';
import { alertButtonVariants, AlertDismissible } from 'src/components/ui/alert-dismissible';
import { Str } from 'src/helpers';
import { DialogTrigger } from 'src/components/ui/dialog';
import { decorateMedia } from 'src/features/media';
import { ComposedMediaPreviewDialog } from 'src/features/media/composed-media-preview-dialog';
import { RenderForAdmin } from 'src/components/Middleware';
import { roles } from 'src/api/services/RoleClient';
import { RequestPriorityBadge } from 'src/features/requests/request-priority-badge';
import { ComposedMediaCard } from 'src/features/media/composed-media-card';

const RequestPageContainer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('tw-flex tw-h-full tw-w-full tw-flex-col', className)}
        {...props}
      >
        {children}
      </div>
    );
  },
);
RequestPageContainer.displayName = 'RequestPageContainer';

const RequestPageContentMain = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('tw-grid tw-grow tw-gap-6', className)} {...props}>
      {children}
    </div>
  );
});
RequestPageContentMain.displayName = 'RequestPageContentMain';

const RequestInformationCardContentRow = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn('tw-grid tw-h-auto tw-grid-cols-2 tw-items-start tw-font-medium', className)}
    >
      {children}
    </div>
  );
});

const RequestDefaultPageSkeleton = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <RequestPageContainer ref={ref} className={className} {...props}>
      <Skeleton className={'tw-h-10 tw-w-full tw-rounded-md md:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4'} />
      <RequestPageContentMain className={'tw-grid-cols-12'}>
        <Skeleton className={'tw-col-span-12 tw-h-full'} />
      </RequestPageContentMain>
    </RequestPageContainer>
  );
});

const RequestInformationCardContent: React.FC<
  React.ComponentProps<typeof CollapsibleCardContent>
> = ({ className, children, ...props }) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-2', className)} {...props}>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'} asChild>
          <span>
            {t('attributes/request:video_template', {
              defaultValue: 'Video template',
            })}
          </span>
        </Label>
        <div className={'tw-break-words'}>
          {request.video_template ? request.video_template.title : t('common:unset', '-')}
        </div>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:status', {
            defaultValue: 'Status',
          })}
        </Label>
        <div>
          <RequestStatusBadge variant={request.status}>
            {t(`models/request:status.${request.status}`, {
              defaultValue: t('common:unknown', {
                defaultValue: 'Unknown',
              }),
            })}
          </RequestStatusBadge>
        </div>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:priority', {
            defaultValue: 'Priority',
          })}
        </Label>
        <div>
          <RequestPriorityBadge variant={request.priority} />
        </div>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:title_2', {
            defaultValue: 'Request name',
          })}
        </Label>
        <p className={'tw-break-words'}>{request.title}</p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:brand', {
            defaultValue: 'Brand',
          })}
        </Label>
        <If
          when={!!request.brand}
          else={t('common:unset', {
            defaultValue: '-',
          })}
        >
          <Link
            to={generatePath(appRoutes.brand, {
              companyId: request.company?.id ?? '',
              brandId: request.brand?.id ?? '',
            })}
            variant={'sky'}
            underline={'hover'}
          >
            {request.brand?.name ??
              t('common:unset', {
                defaultValue: '-',
              })}
          </Link>
        </If>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:type_2', {
            defaultValue: 'Video request type',
          })}
        </Label>
        <p>
          {t(`models/request:type.${request.type}`, {
            defaultValue: t('common:unknown', {
              defaultValue: 'Unknown',
            }),
          })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:resolutions.main_size', {
            defaultValue: 'Main size',
          })}
        </Label>
        <p>
          {request.resolutions?.main_size ??
            t('common:unknown', {
              defaultValue: 'Unknown',
            })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:resolutions.resize', {
            defaultValue: 'Resizes',
          })}
        </Label>
        <p>
          {request.resolutions?.resize?.join(', ') ??
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:total_length', {
            defaultValue: 'Total length',
          })}
        </Label>
        <p className={'tw-break-words'}>
          {request.totalLength ||
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestInformationCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<FileText />}
      title={
        t('common:information', {
          defaultValue: 'Information',
        })!
      }
      open={open}
      onOpenChange={(open) => setOpen(open)}
      variant={'shadow'}
      className={cn('tw-shadow-violet-200', className)}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestExtrasCardContent: React.FC<React.ComponentProps<typeof CollapsibleCardContent>> = ({
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-2', className)} {...props}>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('common:music', {
            defaultValue: 'Music',
          })}
        </Label>
        <p>
          {request.supporting_materials?.music_preference ??
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('common:captions', {
            defaultValue: 'Captions',
          })}
        </Label>
        <p>
          {request.extra?.subtitles ??
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestExtrasCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<Dessert />}
      title={
        t('common:extras', {
          defaultValue: 'Extras',
        })!
      }
      open={open}
      onOpenChange={(open) => setOpen(open)}
      variant={'shadow'}
      className={cn('tw-shadow-violet-200', className)}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestAssetCommentWrapper = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement>
>(({ className, children, ...props }, ref) => {
  return (
    <span
      {...props}
      ref={ref}
      className={cn(
        'tw-break-all tw-rounded-lg tw-border tw-border-neutral-300 tw-bg-neutral-100 tw-p-3',
        className,
      )}
    >
      {children}
    </span>
  );
});
RequestAssetCommentWrapper.displayName = 'RequestAssetCommentWrapper';

const FileCardsGridContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn('tw-col-span-12 tw-grid tw-grid-cols-1 tw-gap-4', className)}
    >
      {children}
    </div>
  );
});
FileCardsGridContainer.displayName = 'RequestFilesGrid';

const FileCardsGrid: React.FC<
  React.ComponentProps<typeof FileCardsGridContainer> & {
    files?: ReturnType<typeof useDecoratedRequestContext>['media'];
    fallback?: React.ReactNode;
  }
> = ({ children, files = [], fallback, ...props }) => {
  const { t } = useTranslation();

  return (
    <FileCardsGridContainer {...props}>
      <If when={!children} else={children}>
        <If when={!!files.length} else={fallback}>
          {files?.map((media, i) => {
            const decoratedMedia = decorateMedia(media);

            return (
              <ComposedMediaCard
                key={i}
                fileName={decoratedMedia.file_name}
                fileSize={decoratedMedia.size}
                mimeType={decoratedMedia.mime_type}
                download={decoratedMedia.index_url}
                thumbnail={
                  decoratedMedia.generated_conversions.thumbnail ??
                  decoratedMedia.generated_conversions.poster
                }
                actions={
                  decoratedMedia.canPlay || decoratedMedia.isImage ? (
                    <RenderForAdmin and={[roles.teamLead]}>
                      <ComposedMediaPreviewDialog media={decoratedMedia}>
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                              <DialogTrigger asChild>
                                <Button variant={'ghost'} size={'icon'}>
                                  <Eye size={20} />
                                </Button>
                              </DialogTrigger>
                            </TooltipTrigger>

                            <TooltipContent>
                              {t('actions:preview', {
                                defaultValue: 'Preview',
                              })}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </ComposedMediaPreviewDialog>
                    </RenderForAdmin>
                  ) : null
                }
              />
            );
          })}
        </If>
      </If>
    </FileCardsGridContainer>
  );
};

const RequestMediaSourceCardContent: React.FC<
  React.ComponentProps<typeof CollapsibleCardContent>
> = ({ className, children, ...props }) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  const { toast } = useToast();

  const comments: {
    target: string;
    value: string;
  }[] = [];

  request.media.forEach((m) => {
    if (!m.comment) {
      return;
    }

    comments.push({
      target: m.file_name,
      value: m.comment,
    });
  });

  request.assets?.forEach((a) => {
    if (!a.comment) {
      return;
    }

    comments.push({
      target: a.url,
      value: a.comment,
    });
  });

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-8', className)} {...props}>
      <RequestInformationCardContentRow className={'tw-gap-4 tw-font-normal'}>
        <Label size={'lg'} className={'tw-col-span-12 tw-mb-0'}>
          {t('common:files', {
            defaultValue: 'Files',
          })}
        </Label>
        <If when={!children} else={children}>
          <If when={request.hasMedia} else={t('common:unset', { defaultValue: '-' })}>
            <FileCardsGrid
              className={
                'sm:tw-grid-cols-2 lg:tw-grid-cols-3 laptop:tw-grid-cols-4 desktop:tw-grid-cols-6'
              }
              files={request.media}
            />
          </If>
        </If>
      </RequestInformationCardContentRow>

      <RequestInformationCardContentRow className={'tw-gap-4'}>
        <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'}>
          {t('common:links', {
            defaultValue: 'Links',
          })}
        </Label>
        <If
          when={!!request.parent_id}
          else={
            <If
              when={!!request.assets?.length}
              else={t('common:unset', {
                defaultValue: '-',
              })}
            >
              {request.assets?.map(({ url }, i) => (
                <div key={i} className={'tw-col-span-2 tw-flex tw-gap-2 md:tw-gap-4'}>
                  <div
                    className={
                      'tw-flex tw-grow tw-items-center tw-gap-3 tw-rounded-lg tw-bg-neutral-100 tw-px-3 tw-transition-colors'
                    }
                  >
                    <Link2Icon size={20} className={'tw-text-dark'} />
                    <div className={'tw-relative tw-flex tw-w-full tw-grow tw-items-center'}>
                      <span className={'tw-absolute tw-inset-0 tw-flex tw-items-center'}>
                        <Href
                          className={'tw-w-full tw-truncate tw-font-normal'}
                          underline={'hover'}
                          variant={'sky'}
                          href={url}
                          rel={'noreferrer'}
                          target={'_blank'}
                        >
                          {url}
                        </Href>
                      </span>
                    </div>
                  </div>

                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          type={'button'}
                          size={'unset'}
                          className={'tw-h-12 tw-w-12 tw-border-neutral-400 tw-p-3 tw-text-dark'}
                          variant={'outline'}
                          onClick={() => {
                            navigator.clipboard.writeText(url).then(() => {
                              toast({
                                className: 'tw-text-base',
                                description: t('Link copied to clipboard'),
                              });
                            });
                          }}
                        >
                          <Copy size={20} />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {t('actions:copy_link', {
                          defaultValue: 'Copy link',
                        })}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              ))}
            </If>
          }
        >
          <div
            className={
              'tw-flex tw-grow tw-items-center tw-gap-3 tw-rounded-lg tw-bg-neutral-100 tw-px-3 tw-transition-colors'
            }
          >
            <Link2Icon size={20} className={'tw-text-dark'} />
            <div className={'tw-relative tw-flex tw-w-full tw-grow tw-items-center'}>
              <span className={'tw-absolute tw-inset-0 tw-flex tw-items-center'}>
                <Href
                  className={'tw-w-full tw-truncate tw-font-normal'}
                  underline={'hover'}
                  variant={'sky'}
                  href={generatePath(appRoutes.request, {
                    requestId: request.parent_id ?? '',
                    companyId: request.company?.id ?? '',
                  })}
                  rel={'noreferrer'}
                  target={'_blank'}
                >
                  {t('Parent request')}
                </Href>
              </span>
            </div>
          </div>
        </If>
      </RequestInformationCardContentRow>

      <RequestInformationCardContentRow className={'tw-gap-4'}>
        <Label size={'lg'} className={'tw-col-span-12 tw-mb-0'}>
          {t('common:file_comments', {
            defaultValue: 'File comments',
          })}
        </Label>
        <If
          when={!!comments.length}
          else={t('common:unset', {
            defaultValue: '-',
          })}
        >
          <div className={'tw-col-span-12 tw-flex tw-flex-col tw-items-stretch tw-gap-4'}>
            {comments.map((c, i) => (
              <RequestAssetCommentWrapper key={i}>
                <b className={'tw-font-bold tw-text-dark'}>{c.target}</b>{' '}
                <p className={'tw-inline tw-font-normal'}>{c.value}</p>
              </RequestAssetCommentWrapper>
            ))}
          </div>
        </If>
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestMediaSourceCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<FileVideo2 />}
      title={
        t('common:media_source', {
          defaultValue: 'Media source',
        })!
      }
      open={open}
      variant={'shadow'}
      onOpenChange={(open) => setOpen(open)}
      className={cn('tw-shadow-violet-200', className)}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestDetailsCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<PenLine />}
      title={
        t('common:details', {
          defaultValue: 'Details',
        })!
      }
      open={open}
      variant={'shadow'}
      onOpenChange={(open) => setOpen(open)}
      className={cn('tw-shadow-violet-200', className)}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestDetailsCardContent: React.FC<React.ComponentProps<typeof CollapsibleCardContent>> = ({
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-2', className)} {...props}>
      <RequestInformationCardContentRow
        className={'tw-gap-6 tw-break-words tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'}>
          {t('common:description', {
            defaultValue: 'Description',
          })}
        </Label>
        <p
          className={'tw-col-span-2 tw-whitespace-pre-line'}
          dangerouslySetInnerHTML={{
            __html: Str.wrapHrefs(
              dompurify.sanitize(
                request.description?.video_request_description ??
                  t('common:unset', {
                    defaultValue: '-',
                  })!,
              ),
            ),
          }}
        />
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-6 tw-break-all tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'}>
          {t('common:examples', {
            defaultValue: 'Examples',
          })}
        </Label>
        <p
          className={'tw-col-span-2 tw-whitespace-pre-line'}
          dangerouslySetInnerHTML={{
            __html: Str.wrapHrefs(
              dompurify.sanitize(
                request.extra?.examples ??
                  t('common:unset', {
                    defaultValue: '-',
                  })!,
              ),
            ),
          }}
        />
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestMultipleOutcomeCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<FileStack />}
      title={
        t('attributes/request:multiple_outcomes', {
          defaultValue: 'Multiple outcomes',
        })!
      }
      open={open}
      variant={'shadow'}
      onOpenChange={(open) => setOpen(open)}
      className={cn('tw-shadow-violet-200', className)}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestMultipleOutcomeCardContent: React.FC<
  React.ComponentProps<typeof CollapsibleCardContent>
> = ({ className, children, ...props }) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-2', className)} {...props}>
      <RequestInformationCardContentRow
        className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        {request.isEditorsChoice ? (
          <>
            <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'} asChild>
              <span>
                {t('attributes/request:editor_selection', {
                  defaultValue: `Editor's selection`,
                })}
              </span>
            </Label>
            <p className={'tw-col-span-2 tw-whitespace-pre-line tw-text-lg tw-text-neutral-400'}>
              {t('attributes/request:editor_selection_description', {
                defaultValue: `Look over the client's content and create outcomes yourself`,
              })}
            </p>
          </>
        ) : (
          <>
            <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'} asChild>
              <span>
                {t('attributes/request:customized', {
                  defaultValue: 'Customized',
                })}
              </span>
            </Label>
            <p className={'tw-col-span-2 tw-whitespace-pre-line tw-text-lg tw-text-neutral-400'}>
              {t('attributes/request:customized_description', {
                defaultValue:
                  'Check the pending tasks and edit them in the order requested by the client',
              })}
            </p>
          </>
        )}
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestAttentionRequestedAlert: React.FC<React.ComponentProps<typeof Alert>> = (props) => {
  const { t } = useTranslation();
  const { toast } = useToast();

  const [resolving, setResolving] = useToggle();
  const { attention_text, requested_attention_at, id } = useDecoratedRequestContext();
  const actions = useRequestQueryMutationDecorator(id);

  const resolve = async () => {
    setResolving(true);

    actions
      .resolveAttention()
      .then(() => {
        toast({
          className: 'tw-text-base',
          description: t('Attention resolved'),
        });
      })
      .catch((err) => {
        toast({
          title: 'Error',
          description:
            err.message ??
            t('alerts:error.generic.title', {
              defaultValue: 'Oops, something went wrong, please try again later.',
            }),
        });
      })
      .finally(() => setResolving(false));
  };

  return (
    <If when={!!requested_attention_at}>
      <AlertDismissible
        layout={'flex'}
        variant={'warning'}
        title={'Attention'}
        message={
          attention_text ??
          t('common:unset', {
            defaultValue: '-',
          })
        }
      >
        <div>
          <Button
            disabled={resolving}
            type={'button'}
            size={'unset'}
            className={alertButtonVariants({ variant: 'warning' })}
            variant={'ghost'}
            onClick={resolve}
          >
            <div className={'tw-me-2'}>
              <Undo size={20} />
            </div>
            {t('actions:undo', { defaultValue: 'Undo' })}
          </Button>
        </div>
      </AlertDismissible>
    </If>
  );
};

const RequestSummaryContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  Omit<React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>, 'value'>
>(({ className, ...props }, ref) => {
  return (
    <TabsContent
      ref={ref}
      className={cn('tw-relative tw-grow', className)}
      value={requestContentTab.summary}
      {...props}
    >
      <div className={'tw-absolute tw-inset-0 tw-flex tw-flex-col tw-gap-4 tw-overflow-auto'}>
        <RequestAttentionRequestedAlert />

        <RequestInformationCard>
          <RequestInformationCardContent />
        </RequestInformationCard>

        <RequestExtrasCard>
          <RequestExtrasCardContent />
        </RequestExtrasCard>

        <RequestMediaSourceCard>
          <RequestMediaSourceCardContent />
        </RequestMediaSourceCard>

        <RequestDetailsCard>
          <RequestDetailsCardContent />
        </RequestDetailsCard>
      </div>
    </TabsContent>
  );
});

const RequestDefaultPageMainContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} {...props}>
      <Tabs
        defaultValue={requestContentTab.summary}
        className={'tw-flex tw-h-full tw-flex-col tw-gap-2'}
      >
        <TabsList
          className={
            'tw-hidden tw-w-full tw-gap-2 tw-overflow-x-auto tw-overflow-y-clip tw-bg-background tw-p-2'
          }
        >
          <TabsTrigger className={'tw-grow'} value={requestContentTab.summary}>
            {t(`common:${requestContentTab.summary}`, {
              defaultValue: 'Summary',
            })}
          </TabsTrigger>
        </TabsList>

        <RequestSummaryContent />
      </Tabs>
    </div>
  );
});
RequestDefaultPageMainContent.displayName = 'RequestDefaultPageMainContent';

export {
  RequestDefaultPageMainContent,
  RequestInformationCard,
  RequestExtrasCard,
  RequestMediaSourceCard,
  RequestDetailsCard,
  RequestMultipleOutcomeCard,
  RequestPageContainer,
  RequestPageContentMain,
  RequestDefaultPageSkeleton,
  RequestInformationCardContent,
  RequestExtrasCardContent,
  RequestMediaSourceCardContent,
  RequestDetailsCardContent,
  RequestMultipleOutcomeCardContent,
  RequestAttentionRequestedAlert,
  FileCardsGridContainer,
  FileCardsGrid,
};
