import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

const labelVariants = cva(
  'tw-mb-2 tw-font-bold tw-text-dark peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70',
  {
    variants: {
      size: {
        sm: 'tw-text-sm',
        base: 'tw-text-base',
        lg: 'tw-text-lg',
      },
    },

    defaultVariants: {
      size: 'base',
    },
  },
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, size, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ size, className }))} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
