import React, { useEffect } from 'react';
import { useReactMenu } from '@becreatives/react-menu';
import { shallow } from 'zustand/shallow';
import { useCurrentCompanyRequestsCounts } from 'src/models';
import { RevisionsContentBottom, RevisionsContentMain, RevisionsContentTop } from './UpdatedDesign';
import { RevisionOutcomeContentBottom } from 'src/pages/request/revision-page/revision-outcome-content-bottom';
import { RequestResponse } from 'src/lib/services/api/request-api';
import { useCompanyId } from 'src/features/companies/use-company-id';
import { useRequestId } from 'src/features/requests/use-request-id';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { CompanyQueryProvider } from 'src/features/companies/use-company-query-context';
import { RequestQueryProvider } from 'src/features/requests/use-request-query-context';
import {
  DecoratedRequestProvider,
  useDecoratedRequestContext,
} from 'src/features/requests/use-decorated-request-context';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Breadcrumbs, ClockIcon, Text, WarningOctagonIcon } from '@becreatives/becreatives-ui';
import { appRoutes } from 'src/routes';
import { SpaceErrorFallback } from 'src/features/fallback';
import { Skeleton } from 'src/components/ui/skeleton';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { If } from 'src/components/If';
import {
  RevisionFallbackImage,
  RevisionFallbackWrapper,
} from 'src/pages/RevisionsPage/UpdatedDesign/RevisionsContentMain/RevisionsContentMain.styled';

const RevisionsPageFallbackContent: React.FC<{ error: any; resetErrorBoundary: () => any }> = ({
  error,
  resetErrorBoundary,
}) => {
  const companyId = useCompanyId();
  const requestId = useRequestId();
  const navigate = useNavigate();

  return (
    <>
      <div className={'tw-flex tw-w-full tw-flex-wrap tw-items-center tw-gap-12'}>
        <Breadcrumbs
          crumbs={[]}
          back={() => navigate(generatePath(appRoutes.request, { companyId, requestId }))}
        />
      </div>

      <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
    </>
  );
};

const RevisionsPageSkeleton = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} {...props} className={'tw-grid tw-h-full tw-gap-4 lg:tw-grid-cols-12'}>
      <Skeleton className={'tw-rounded-xl lg:tw-col-span-7 xl:tw-col-span-8'} />
      <Skeleton className={'tw-rounded-xl lg:tw-col-span-5 xl:tw-col-span-4'} />
    </div>
  );
});

const RevisionContentBottomResolver: React.FC<{
  flow?: RequestResponse['flow'];
}> = ({ flow = 'default' }) => {
  switch (flow) {
    case 'outcome':
      return <RevisionOutcomeContentBottom />;
    default:
      return <RevisionsContentBottom />;
  }
};

const RevisionsPageContents: React.FC = () => {
  const { t } = useTranslation();
  const { flow, hasEditor } = useDecoratedRequestContext();
  const { initialize, reset, isEmpty, selectedRevision } = useRequestRevisionsStore();
  const setCollapsed = useReactMenu(({ setCollapsed }) => setCollapsed, shallow);

  const requestId = useRequestId();

  const { review } = useCurrentCompanyRequestsCounts(
    {},
    {
      suspense: true,
    },
  );

  useEffect(() => {
    setCollapsed(true);
    review(requestId!);
    initialize();

    const preventKeyDownSpace = function (e: KeyboardEvent) {
      const target = e.target as HTMLElement;
      // don't scroll down when pressing 'space' to play/pause video
      if (e.key == ' ' && !['textarea', 'input'].includes(target.tagName.toLowerCase())) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', preventKeyDownSpace, false);

    return () => {
      reset();
      window.removeEventListener('keydown', preventKeyDownSpace, false);
    };
  }, []);

  return (
    <>
      <RevisionsContentTop />

      <If
        when={hasEditor && !isEmpty}
        else={
          <RevisionFallbackWrapper
            className={
              '!tw-my-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-rounded-xl tw-bg-white tw-p-4 tw-shadow-sm'
            }
          >
            <RevisionFallbackImage />
            <Text color={'gray'} $size={'md'} $md={'xl'} $centeredContent={true}>
              <If
                when={isEmpty}
                else={
                  <>
                    <ClockIcon className={'me-2'} />
                    {t('pages/shared:revisions.revision.notice.no_editor', {
                      defaultValue: 'Choosing an editor',
                    })}
                  </>
                }
              >
                <WarningOctagonIcon className={'me-2'} />
                {t('pages/shared:revisions.revision.notice.no_revisions', {
                  defaultValue: 'Request does not have any revisions',
                })}
              </If>
            </Text>
          </RevisionFallbackWrapper>
        }
      >
        {selectedRevision ? <RevisionsContentMain /> : null}
      </If>
      <RevisionContentBottomResolver flow={flow} />
    </>
  );
};

const RevisionsPage = () => {
  const companyId = useCompanyId();
  const requestId = useRequestId();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <RevisionsPageFallbackContent error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<RevisionsPageSkeleton />}>
            <CompanyQueryProvider id={companyId}>
              <RequestQueryProvider id={requestId}>
                {({ data }) => (
                  <DecoratedRequestProvider request={data}>
                    <RevisionsPageContents />
                  </DecoratedRequestProvider>
                )}
              </RequestQueryProvider>
            </CompanyQueryProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export default RevisionsPage;
