import React from 'react';
import { cn } from 'src/lib/utils';

type VideoPlayerPreviewProps = React.ComponentProps<'div'> & {
  preview?: React.ComponentProps<'img'>;
  action?: React.ReactNode;
};

export const VideoPlayerPreview: React.FC<VideoPlayerPreviewProps> = ({
  className,
  preview = {},
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cn(
        'tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-rounded-lg',
        className,
      )}
    >
      <img
        {...preview}
        src={preview?.src}
        alt={preview?.alt}
        className={cn(
          'tw-h-full tw-rounded-md tw-object-contain tw-object-center',
          preview?.className,
        )}
      />

      {children}
    </div>
  );
};
