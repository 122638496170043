import React from 'react';
import { MediaObject } from 'src/api/services/MediaClient';
import { OrderInfoNavLink } from '@becreatives/becreatives-ui';

type Props = {
  media: MediaObject;
  children?: React.ReactNode;
};

const OrderInfoMediaNavLink: React.FC<Props> = ({ children, media }) => {
  return (
    <OrderInfoNavLink
      href={media.index_url}
      target={'_blank'}
      rel={'noreferrer'}
      disabled={!media.index_url}
      className={'text-truncate'}
      title={media.file_name}
    >
      {media.file_name}
      {children}
    </OrderInfoNavLink>
  );
};

export { OrderInfoMediaNavLink };
