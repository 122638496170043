import { Api } from 'src/api';
import { TaskResponse } from 'src/api/services/TaskClient';
import { authQueries } from 'src/api/queries/queries';
import { useMakeQuery } from '../useMakeQuery';
import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';

const useTaskQuery = (taskId: string, options?: UseQueryOptionsObject<TaskResponse>) => {
  return useMakeQuery<TaskResponse>([authQueries.task, taskId], () => Api.task.view(taskId), {
    staleTime: 10 * 60 * 1000,
    ...defaultOptions({
      auth: true,
      ...(options ?? {}),
    }),
  });
};

export { useTaskQuery };
