import React from 'react';
import {
  ErrorMessage as FormikErrorMessage,
  ErrorMessageProps as FormikErrorMessageProps,
} from 'formik';

interface Props extends FormikErrorMessageProps {
  position?: 'left' | 'center' | 'right';
}

const ErrorMessage: React.FC<Props> = ({
  position = 'left',
  className,
  component = 'span',
  ...props
}) => {
  const classes = [
    'text-danger',
    'mt-2 mb-0',
    `text-${position}`,
    'd-block',
    'invalid-feedback',
    'read-newlines',
  ];
  if (className) {
    classes.push(className);
  }

  return <FormikErrorMessage {...props} className={classes.join(' ')} component={component} />;
};

export type { Props as ErrorMessageProps };
export default ErrorMessage;
