import styled from 'styled-components';
import { Badge, lightTheme, shades, tintColor } from '@becreatives/becreatives-ui';
import { Role } from 'src/api/services/RoleClient';

const colorMap: Record<Role, { color: string; bgColor: string }> = {
  admin: { bgColor: tintColor(lightTheme.color.primary, 30), color: lightTheme.color.primary },
  editor: { bgColor: shades.pink['200'], color: shades.pink['500'] },
  substitute_team_lead: { bgColor: shades.indigo['100'], color: shades.indigo['500'] },
  team_lead: { bgColor: shades.indigo['200'], color: shades.indigo['500'] },
  customer: { bgColor: shades.blue['200'], color: shades.blue['500'] },
};

const StyledRoleBadge = styled(Badge)<{ role: Role }>`
  background-color: ${({ role }) => colorMap[role].bgColor} !important;
  color: ${({ role }) => colorMap[role].color} !important;

  padding: 0.3rem 0.75rem;
`;

export { StyledRoleBadge };
