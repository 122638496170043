import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalProps } from 'reactstrap';
import { TaskResponse, UpdateTaskRequest } from 'src/api/services/TaskClient';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { EditTaskForm } from './TaskForm/EditTaskForm';

type Props = Omit<ModalProps, 'toggle'> & {
  toggle: () => void;
  task: TaskResponse;
  onSubmit?: (
    values: UpdateTaskRequest,
    helpers: FormikHelpers<UpdateTaskRequest>,
  ) => Promise<void>;
};

const EditTaskModal: React.FC<Props> = ({ toggle, task, onSubmit, ...props }) => {
  const { t } = useTranslation('actions');

  const handleSubmit: Props['onSubmit'] = async (...args) => {
    if (onSubmit) {
      onSubmit(...args);
    }

    toggle();
  };

  return (
    <Modal {...props} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('edit_note')}</ModalHeader>
      <ModalBody>
        <EditTaskForm task={task} onSubmit={handleSubmit} />
      </ModalBody>
    </Modal>
  );
};

export type { Props as TaskFormModalProps };
export { EditTaskModal };
