import React from 'react';
import Spinner from '../Spinner';
import { SpinnerProps } from '../Spinner';

const CenteredSpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <div className={'d-flex justify-content-center align-items-center h-100 w-100'}>
      <Spinner {...props} />
    </div>
  );
};

export default CenteredSpinner;
