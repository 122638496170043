import React from 'react';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

const FullPageWrapper: React.FC<Props> = ({ className, ...props }) => {
  const classNames = [
    `tw-w-full tw-flex tw-justify-center tw-items-center tw-h-full tw-overflow-auto`,
  ];
  if (className) {
    classNames.push(className);
  }

  return <div {...props} className={classNames.join(' ')} />;
};

export default FullPageWrapper;
