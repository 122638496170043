const omitObject = <T extends Record<any, any>, K extends keyof T>(
  obj: T,
  omit: K[],
): Omit<T, K> => {
  const cloned = { ...obj };

  return omit.reduce((accu, key) => {
    delete (accu as any)[key];

    return accu;
  }, cloned as Omit<T, K>);
};

export { omitObject };
