import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

type VideoProgressStoreState = {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
};

type VideoProgressStoreActions = {
  setPlayed: (played: number) => void;
  setPlayedSeconds: (playedSeconds: number) => void;
  getPlayed: () => number;
  getPlayedSeconds: () => number;
  setLoaded: (loaded: number) => void;
  getLoaded: () => number;
  setLoadedSeconds: (loadedSeconds: number) => void;
  getLoadedSeconds: () => number;
  set: (state: Partial<VideoProgressStoreState>) => void;
  reset: () => void;
};

type VideoProgressStore = VideoProgressStoreState & VideoProgressStoreActions;

export const useVideoProgressStore = createWithEqualityFn<VideoProgressStore>((set, get) => ({
  played: 0,
  setPlayed: (played) => set({ played }),
  getPlayed: () => get().played,
  playedSeconds: 0,
  setPlayedSeconds: (playedSeconds) => set({ playedSeconds }),
  getPlayedSeconds: () => get().playedSeconds,

  loaded: 0,
  setLoaded: (loaded) => set({ loaded }),
  getLoaded: () => get().loaded,
  loadedSeconds: 0,
  setLoadedSeconds: (loadedSeconds) => set({ loadedSeconds }),
  getLoadedSeconds: () => get().loadedSeconds,

  set,
  reset: () => set({ played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 }),
}));

export const useVideoProgressState = () =>
  useVideoProgressStore(
    ({ loaded, loadedSeconds, played, playedSeconds }) => ({
      loaded,
      loadedSeconds,
      played,
      playedSeconds,
    }),
    shallow,
  );

export const useVideoProgressActions = () =>
  useVideoProgressStore(
    ({
      setLoaded,
      getLoaded,
      setLoadedSeconds,
      getLoadedSeconds,
      setPlayed,
      getPlayed,
      setPlayedSeconds,
      getPlayedSeconds,
      set,
      reset,
    }) => ({
      setLoaded,
      getLoaded,
      setLoadedSeconds,
      getLoadedSeconds,
      setPlayed,
      getPlayed,
      setPlayedSeconds,
      getPlayedSeconds,
      set,
      reset,
    }),
    shallow,
  );
