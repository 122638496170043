import { AlertCircle, CheckCircle2 } from 'lucide-react';
import {
  Toast,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from 'src/components/ui/toast';
import { ToasterToast, useToast } from 'src/components/ui/use-toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, variant = 'success', ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="tw-flex tw-gap-2">
              {getVariantIcon(variant)}
              <div className={'tw-flex tw-flex-col tw-gap-1'}>
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && <ToastDescription>{description}</ToastDescription>}
              </div>
            </div>
            {action}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}

function getVariantIcon(variant: ToasterToast['variant']) {
  switch (variant) {
    case 'success':
      return <CheckCircle2 size={20} className="tw-text-green-500" />;
    case 'destructive':
      return <AlertCircle size={20} className="tw-text-red-500" />;
    case 'warning':
      return <AlertCircle size={20} className="tw-text-yellow-500" />;
    case 'info':
      return <AlertCircle size={20} className="tw-text-white" />;
    default:
      return null;
  }
}
