import React, { useRef } from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { cn } from 'src/lib/utils';

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    onScrollUp?: (ref: HTMLDivElement, event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    onScrollDown?: (ref: HTMLDivElement, event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    scrollRef?: React.RefObject<HTMLDivElement>;
    viewportProps?: React.ComponentProps<typeof ScrollAreaPrimitive.Viewport>;
    vertical?: boolean;
    horizontal?: boolean;
  }
>(({ className, vertical = true, horizontal, children, onScrollUp, viewportProps, scrollRef, onScrollDown, ...props }, ref) => {
  const lastScrollY = useRef(0);

  const handler: React.ComponentProps<typeof ScrollAreaPrimitive.Viewport>['onScroll'] = (
    event,
  ) => {
    const currentScrollY = event.currentTarget.scrollTop;

    // Check if the scroll direction is upwards
    if (currentScrollY < lastScrollY.current) {
      // Call the onScrollUp callback if provided
      onScrollUp?.(scrollRef?.current || event.currentTarget, event);
    }

    // Check if the scroll direction is downwards
    if (currentScrollY > lastScrollY.current) {
      // Call the onScrollDown callback if provided
      onScrollDown?.(scrollRef?.current || event.currentTarget, event);
    }

    lastScrollY.current = currentScrollY;
  };

  return (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn('tw-relative tw-overflow-hidden', className)}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        ref={scrollRef}
        {...viewportProps}
        onScroll={handler}
        className={cn('tw-h-full tw-w-full tw-rounded-[inherit]', viewportProps?.className)}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      {vertical && <ScrollBar />}
      {!!horizontal && <ScrollBar orientation={'horizontal'} />}
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
});
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      'tw-flex tw-touch-none tw-select-none tw-rounded tw-transition-colors',
      orientation === 'vertical' &&
        'tw-h-full tw-w-2.5 tw-border-l tw-border-l-transparent tw-p-[1px]',
      orientation === 'horizontal' &&
        'tw-h-2.5 tw-flex-col tw-border-t tw-border-t-transparent tw-p-[1px]',
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="tw-relative tw-flex-1 tw-rounded-full tw-bg-border" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
