import React from 'react';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { OptionObject } from 'src/components/ReactSelectInput';
import { configReactSelectProps } from 'src/components/ReactSelectInput/ReactSelectInput.utils';
import Select from 'react-select';
import { useReactSelectValue } from 'src/components/ReactSelectInput/useReactSelectValue';
import { useApiQueryParams } from './use-api-query-params';

type Props = Omit<ReactSelectProps<OptionObject>, 'name' | 'value'> & {
  name: string;
  groupId?: string;
};

const ApiQueryParamsFilterField: React.FC<Props> = ({ groupId, name, ...props }) => {
  const { filters, setFilter } = useApiQueryParams(groupId);

  const queryValue = filters.filters?.find(({ id }) => name === id)?.value;
  const setValue = (newValue: unknown) => {
    setFilter({ id: name, value: newValue });
  };

  const { onChange, value } = useReactSelectValue(
    {
      value: queryValue,
      setValue,
    },
    props,
  );

  props = configReactSelectProps(props);

  return <Select {...props} onChange={onChange} value={value} />;
};

export type { Props as ApiQueryParamsFilterFieldProps };
export { ApiQueryParamsFilterField };
