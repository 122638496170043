import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikConfig } from 'formik';
import { RequestAttentionRequest } from 'src/api/services/RequestClient';
import { FieldComplex } from 'src/components/Form/FieldComplex';
import { PluginTextArea } from 'src/components/Form/PluginTextArea';
import { useTranslation } from 'react-i18next';
import { Button } from '@becreatives/becreatives-ui';
import { extractFormErrors } from 'src/components/Form/Form.utils';
import { Api } from 'src/api';

type Props = Omit<FormikConfig<RequestAttentionRequest>, 'validationSchema'> & {
  requestId: string;
};

const RequestAttentionForm: React.FC<Props> = ({ requestId, onSubmit, ...props }) => {
  const { t } = useTranslation('attributes/request');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        attention_text: Yup.string().required(),
      }),
    [],
  );

  return (
    <Formik
      {...props}
      onSubmit={async (values, helpers) => {
        try {
          await Api.request.requestAttention(requestId, values);
          await onSubmit(values, helpers);
        } catch (e) {
          helpers.setErrors(extractFormErrors(e) ?? {});
        } finally {
          helpers.setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <FieldComplex
            label={t('attention_text')}
            required={true}
            name={'attention_text'}
            as={PluginTextArea}
            rows={6}
          />

          <div className={'d-flex justify-content-end'}>
            <Button
              style={{
                flexGrow: 0.25,
              }}
              disabled={isSubmitting}
              type={'submit'}
              color={'primary'}
            >
              {t('submit', { ns: 'actions' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export type { Props as RequestAttentionFormProps };
export { RequestAttentionForm };
