import { ApiQuerySearchInput } from './api-query-search-input';
import { ApiQueryParamsPaginationInput } from './api-query-params-pagination-input';
import { ApiQueryParamsFilterField } from './api-query-params-filter-field';
import { ApiQueryParamsResetComponent } from './api-query-params-reset-component';
import { ApiQueryParamsAsyncFilterField } from './api-query-params-async-filter-field';

const ApiQueryParams = {
  search: ApiQuerySearchInput,
  pagination: ApiQueryParamsPaginationInput,
  filter: ApiQueryParamsFilterField,
  asyncFilter: ApiQueryParamsAsyncFilterField,
  reset: ApiQueryParamsResetComponent,
};

export { ApiQueryParams };
