import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { Button } from '@becreatives/becreatives-ui';
import { FieldComplex } from 'src/components/Form/FieldComplex';
import { parseEntriesToOptions } from 'src/components/ReactSelectInput';
import { CreateTeamRequest } from 'src/api/services/TeamClient';
import { extractFormErrors } from 'src/components/Form/Form.utils';
import { authQueries, scopedQueries } from 'src/api/queries';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { TeamColorsFieldGroup } from './TeamColorsFieldGroup';
import { Form } from 'src/components/ui/form';
import { createTeamLeadsFilterQuery } from 'src/features/users';
import { mergeQueryParams } from 'src/lib/services/api-query-params';
import { useQueryClient } from '@tanstack/react-query';
import { userClient } from 'src/lib/services/api/user-api';

type CreateTeamFormValues = Pick<CreateTeamRequest, 'name' | 'color' | 'team_leaders'>;

type Props = React.PropsWithChildren<Omit<React.ComponentPropsWithoutRef<'div'>, 'onSubmit'>> & {
  onSubmit: (
    values: CreateTeamFormValues,
    formikHelpers: FormikHelpers<CreateTeamFormValues>,
  ) => Promise<any> | any;
};

const teamLeadsFiltersQuery = mergeQueryParams(createTeamLeadsFilterQuery(), {
  limit: 25,
});

const CreateTeamForm: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation('');

  const queryClient = useQueryClient();

  return (
    <Formik<CreateTeamFormValues>
      initialValues={{
        name: '',
        color: '',
        team_leaders: [],
      }}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        try {
          await props.onSubmit(values, formikHelpers);

          queryClient.invalidateQueries({
            queryKey: [authQueries.users, scopedQueries.infinite],
            exact: false,
          });
        } catch (e) {
          formikHelpers.setErrors(extractFormErrors(e) ?? {});
        } finally {
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <FormikForm>
          <div className={'d-flex flex-column flex-sm-row flex-wrap gap-4'}>
            <FieldComplex
              required={true}
              label={'Name'}
              name={'name'}
              wrapperClassNames={['flex-1']}
            />

            <FieldComplex
              as={Form.asyncSelect<Awaited<ReturnType<typeof userClient.options>>>}
              wrapperClassNames={['flex-1']}
              isMulti={true}
              name={'team_leaders'}
              queryOptions={{
                queryKey: [authQueries.users, teamLeadsFiltersQuery],
                queryFn: ({ signal }, params) =>
                  userClient.options({
                    params: mergeQueryParams(teamLeadsFiltersQuery, params),
                    signal,
                  }),
                staleTime: 60 * 1000,
              }}
              options={(pages) => {
                const flat = pages?.flatMap((page) => page.items) ?? [];

                return parseEntriesToOptions(flat, {
                  label: (user) => joinExistingValues([user.first_name, user.last_name], ' '),
                  value: 'id',
                });
              }}
              isSearchable={true}
              className={''}
              label={t('models/role:team_lead')}
              required={true}
            />

            <TeamColorsFieldGroup name={'color'} />

            <Button type={'submit'} disabled={isSubmitting}>
              {t('create', { ns: 'actions' })}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export { CreateTeamForm };
