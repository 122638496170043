import { create } from 'zustand';
import { requestListType, RequestListType } from 'src/api/services/RequestClient';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { userType } from 'src/lib/services/api/profile-api';

const useRequestStore = create<{
  requestsListType: RequestListType;
  setRequestsListType: (type: RequestListType) => void;
}>((set) => {
  return {
    requestsListType:
      AuthUtils.getPayload()?.user_type === userType.admin
        ? requestListType.list
        : requestListType.kanban,
    setRequestsListType: (type) => set({ requestsListType: type }),
  };
});

export { useRequestStore };
