import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalToggler } from 'src/components/Modal';
import { Modal, ModalBody, ModalProps } from 'reactstrap';
import { RequestAttentionForm, RequestAttentionFormProps } from './RequestAttentionForm';
import { Button } from 'src/components/ui/button';
import { X } from 'lucide-react';

type Props<ComponentProps extends object> = {
  form: RequestAttentionFormProps;
  as: React.ComponentType<ComponentProps>;
} & React.PropsWithChildren<ComponentProps>;

type FormModalProps = Omit<ModalProps, 'onSubmit' | 'toggle'> & {
  toggle: () => void;
  form: RequestAttentionFormProps;
};

const FormModal: React.FC<FormModalProps> = ({ form: { onSubmit, ...form }, ...props }) => {
  return (
    <Modal {...props}>
      <div className={'tw-flex tw-gap-4 tw-px-4 tw-pt-4'}>
        <Button
          size={'icon'}
          variant={'ghost'}
          className={'tw-ms-auto tw-flex'}
          onClick={props.toggle}
        >
          <X className={'tw-size-5'} />
          <span className="tw-sr-only">Close</span>
        </Button>
      </div>
      <ModalBody>
        <RequestAttentionForm
          {...form}
          onSubmit={(values, helpers) => {
            onSubmit(values, helpers);
            props.toggle?.();
          }}
        />
      </ModalBody>
    </Modal>
  );
};

const RequestAttentionAction = <ComponentProps extends object>({
  as,
  form,
  ...props
}: Props<ComponentProps>) => {
  const { t } = useTranslation('actions');

  return (
    <ModalToggler
      {...props}
      toggler={as as any}
      modalProps={{
        form,
        backdrop: 'static',
      }}
      modal={FormModal}
    >
      {props.children || t('request_attention')}
    </ModalToggler>
  );
};

export type { Props as RequestAttentionActionProps };
export { RequestAttentionAction };
