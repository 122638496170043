import { useLocation } from 'react-router-dom';
import { storage } from 'src/lib/utils';

let used = false;

export const usePreLoginAuthLocation = () => {
  const location = useLocation();

  const rememberLocation = (object: typeof location) => {
    if (used) {
      return object;
    }

    storage.set('intendedPreLoginAuthLocation', object);
    return object;
  };

  const getLocation = (fallback: typeof location) => {
    const result = storage.get<typeof location>('intendedPreLoginAuthLocation', true);
    storage.remove('intendedPreLoginAuthLocation');
    used = true;
    return result || fallback;
  };

  return { rememberLocation, getLocation };
};
