import React, { useMemo } from 'react';
import { Icon, IconProps } from '@iconify/react';
import { FileTypes } from 'src/helpers';
import ICONS from './icons.json';
import { DynamicObject } from 'src/types/generics';

interface Props extends Partial<IconProps> {
  fileName: string;
}

export const placeholder: JSX.Element = <i className="mdi mdi-file-alert-outline large-icon" />;

const FileThumbnail: React.FC<Props> = ({ fileName, ...props }): JSX.Element => {
  const icon = useMemo<string>(() => {
    const extension = FileTypes.getExtension(fileName);
    const extensions: string[] = Object.keys(ICONS);

    if (extension && extensions.includes(extension)) {
      return (ICONS as DynamicObject<string>)[extension];
    }

    return ICONS.unknown;
  }, [fileName]);

  return <Icon fr={'true'} icon={icon} className={props.className} />;
};

export default FileThumbnail;
