import { useParams } from 'react-router-dom';

const useTeamId = (): string => {
  const { teamId } = useParams<{ teamId: string }>();

  if (!teamId) {
    throw new Error('Route param teamId is required');
  }

  return teamId!;
};

export { useTeamId };
