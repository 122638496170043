import React from 'react';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { requestClient } from 'src/lib/services/api/request-api';
import { authQueries } from 'src/api/queries';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { render } from 'src/lib/utils';
import { UseQueryOptionsObject } from 'src/api/queries/queries.utils';

type ApiResponse = Awaited<ReturnType<typeof requestClient.outcomes>>;

type RequestOutcomesQueryContextType = ReturnType<typeof useMakeQuery<ApiResponse>>;

export const getRequestOutcomesQueryKey = (id: string, filters?: ApiQueryObject) => [
  authQueries.requestOutcomes,
  id,
  filters,
];

export const RequestOutcomesQueryContext = React.createContext<
  RequestOutcomesQueryContextType | undefined
>(undefined);

export const RequestOutcomesQueryContextProvider = ({
  children,
  id,
  config = {},
  filters = {},
}: {
  id: string;
  filters?: ApiQueryObject;
  children: React.ReactNode | ((query: RequestOutcomesQueryContextType) => React.ReactNode);
  config?: UseQueryOptionsObject<ApiResponse>;
}) => {
  const query = useMakeQuery<ApiResponse>(
    getRequestOutcomesQueryKey(id, filters),
    ({ signal }) => requestClient.outcomes(id, { signal, params: filters }),
    {
      suspense: true,
      retry: false,
      staleTime: 60 * 1000,
      ...config,
    },
  );

  return (
    <RequestOutcomesQueryContext.Provider value={query}>
      {render(children, query)}
    </RequestOutcomesQueryContext.Provider>
  );
};

export const useRequestOutcomesQueryContext = () => {
  const context = React.useContext(RequestOutcomesQueryContext);

  if (!context) {
    throw new Error(
      'useRequestOutcomesQueryContext must be used within a RequestOutcomesQueryContext',
    );
  }

  return context;
};

export const useRequestOutcomesQueryData = (id: string, filters?: ApiQueryObject) => {
  const query = useMakeQuery<ApiResponse>(
    getRequestOutcomesQueryKey(id, filters),
    ({ signal }) => requestClient.outcomes(id, { signal, params: filters }),
    {
      enabled: false,
    },
  );

  return query.data;
};
