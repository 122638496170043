import React from 'react';
import { RequestKanbanCard } from 'src/features/requests/request-kanban/request-kanban-card';
import { Draggable, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { RequestsTableResponse } from 'src/api/services/RequestClient';
import { CardProps } from '@becreatives/becreatives-ui';
import { PaginatedResponse } from 'src/api/Client';
import { DraggableCardGrip } from 'src/features/requests/draggable-card-grip';
import { cn } from 'src/lib/utils';
import { useBreakpoint } from 'src/lib/hooks';
import { requestFlow } from 'src/lib/services/api/request-api';

type Props = {
  pages: PaginatedResponse<RequestsTableResponse>[];
  status: string;
  cardProps?: (request: RequestsTableResponse) => CardProps;
  isDragDisabled?: boolean;
  pageSize: number;
};

// using pure component to avoid some unneeded re-renders as it is advised to do so in the docs:
// https://github.com/hello-pangea/dnd/blob/main/docs/api/droppable.md#recommended-droppable--performance-optimisation
const RequestKanbanColumnList: React.FC<Props> = React.memo(function RequestKanbanColumnList({
  pages,
  status,
  cardProps,
  isDragDisabled,
  pageSize,
}) {
  const { isMobile } = useBreakpoint();

  // disabling animation of item being dragged into new column/sorting
  function getStyle(style: React.CSSProperties | undefined, snapshot: DraggableStateSnapshot) {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  }

  return (
    <>
      {pages?.map(
        (group, pageIndex) =>
          group?.items.map((request, i) => {
            const dragDisabled = isDragDisabled || request.flow === requestFlow.multiOutcome;

            return (
              <Draggable
                key={`${status}-${request.id}`}
                draggableId={request.id}
                index={pageIndex * pageSize + i}
                isDragDisabled={dragDisabled}
              >
                {(provided, snapshot) => {
                  const cardWrapperProps = isMobile ? { ...provided.dragHandleProps } : {};

                  return (
                    <div
                      ref={provided.innerRef}
                      className={'mx-2 mb-3'}
                      {...provided.draggableProps}
                      style={getStyle(provided.draggableProps.style, snapshot)}
                      {...cardWrapperProps}
                    >
                      <RequestKanbanCard
                        request={request}
                        cardProps={cardProps}
                        cardTop={
                          !isMobile && (
                            <DraggableCardGrip
                              className={cn(
                                'tw-absolute tw-left-1/2 tw-top-0 tw-hidden tw-animate-in tw-fade-in group-hover:tw-block',
                                snapshot.isDragging && 'tw-block',
                                dragDisabled && '!tw-hidden',
                              )}
                              iconProps={{
                                className: 'tw-h-4 tw-w-4',
                              }}
                              {...provided.dragHandleProps}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          )
                        }
                      />
                    </div>
                  );
                }}
              </Draggable>
            );
          }),
      )}
    </>
  );
});

export { RequestKanbanColumnList };
