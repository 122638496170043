import { AxiosRequestConfig } from 'axios';
import { httpConfig } from 'src/lib/modules/http';
import { Api } from 'src/api';
import { url } from 'src/lib/utils';
import { PaginatedResponse } from 'src/api/Client';
import {
  UsersTableResponse,
  UserOptionResponse,
  UserResponse,
  CreateUserRequest,
  ChangeUserTypeRequest,
  ChangeUserPasswordRequest,
  UserTransferCompanyRequest,
  UserTeamResponse,
} from 'src/lib/services/api/user-api';
import { RequestsCountsResponse, RequestsTableResponse } from 'src/api/services/RequestClient';

const baseUrl = `${httpConfig.options.baseURL}/admin/users`;

export const filter = (
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<UsersTableResponse>> => Api.http.get(`${baseUrl}`, config);

export const options = (
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<UserOptionResponse>> => Api.http.get(`${baseUrl}/options`, config);

export const get = (model: string, config?: AxiosRequestConfig): Promise<UserResponse> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}`, { model }), config);

export const store = (
  data: CreateUserRequest,
  config?: AxiosRequestConfig,
): Promise<UserResponse> => Api.http.post(`${baseUrl}/store`, data, config);

export const suspend = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.delete(url.insertArguments(`${baseUrl}/{model}`, { model }), config);

export const activate = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.put(url.insertArguments(`${baseUrl}/{model}/unsuspend`, { model }), config);

export const impersonate = (model: string, config?: AxiosRequestConfig): Promise<string> =>
  Api.http.post(
    url.insertArguments(`${httpConfig.options.baseURL}/profile/impersonate/start/{model}`, {
      model,
    }),
    config,
  );

export const changeRole = (
  model: string,
  data: ChangeUserTypeRequest,
  config?: AxiosRequestConfig,
): Promise<UserResponse> =>
  Api.http.put(url.insertArguments(`${baseUrl}/{model}/change-role`, { model }), data, config);

export const changePassword = (
  model: string,
  data: ChangeUserPasswordRequest,
  config?: AxiosRequestConfig,
): Promise<UserResponse> =>
  Api.http.put(url.insertArguments(`${baseUrl}/{model}/change-password`, { model }), data, config);

export const transferCompany = (
  model: string,
  data: UserTransferCompanyRequest,
  config?: AxiosRequestConfig,
): Promise<UserResponse> =>
  Api.http.post(
    url.insertArguments(`${baseUrl}/{model}/transfer-to-company`, { model }),
    data,
    config,
  );

export const teams = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<UserTeamResponse>> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}/teams`, { model }), config);

export const requests = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<RequestsTableResponse>> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}/requests`, { model }), config);

export const requestsCounts = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<RequestsCountsResponse> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}/requests/counts`, { model }), config);
