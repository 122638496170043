import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Broadcast } from 'src/broadcast';
import { authQueries } from 'src/api/queries';
import { RequestsCountsResponse, RequestStatus } from 'src/api/services/RequestClient';
import { getObjectKeys } from 'src/helpers/getObjectKeys';
import { UseMakeQueryReturnType } from 'src/api/queries/useMakeQuery';

let hookInitialized = false;

// TODO: static types
const useRequestsCounts = (countsQueryFn: UseMakeQueryReturnType<RequestsCountsResponse>) => {
  const client = useQueryClient();

  const { data: counts, ...query } = countsQueryFn;

  const totalQueued = counts?.queued.total.length ?? 0,
    newQueued = counts?.queued.new.length ?? 0,
    totalEditing = counts?.currently_editing.total.length ?? 0,
    newEditing = counts?.currently_editing.new.length ?? 0,
    totalDelivered = counts?.delivered.total.length ?? 0,
    newDelivered = counts?.delivered.new.length ?? 0,
    totalComplete = counts?.complete.total.length ?? 0,
    newComplete = counts?.complete.new.length ?? 0,
    totalNew = newQueued + newEditing + newDelivered + newComplete;

  const add = (id: string) => {
    query.setQueryData((prev) => {
      prev.queued.total.push(id);

      return prev;
    });
  };

  const update = (id: string, status: RequestStatus) => {
    query.setQueryData((prev) => {
      const foundInCounts = prev[status].total.find((r) => r === id);
      if (!foundInCounts) {
        remove(id);
        prev[status].total.push(id);
      }

      return prev;
    });
  };

  const remove = (id: string) => {
    query.setQueryData((prev) => {
      const statuses = getObjectKeys(prev);
      for (let i = 0; i < statuses.length; i++) {
        const status = statuses[i];
        const found = prev[status].total.find((r) => r === id);

        if (found) {
          prev[status].total = prev[status].total.filter((r) => r !== found);
          prev[status].new = prev[status].new.filter((r) => r !== found);

          break;
        }
      }

      return prev;
    });
  };

  const review = (id: string, status?: RequestStatus) => {
    query.setQueryData((prev) => {
      if (status) {
        prev[status].new = prev[status].new.filter((r) => r !== id);

        return prev;
      }

      const statuses = getObjectKeys(prev);
      for (let i = 0; i < statuses.length; i++) {
        const key = statuses[i];
        const oldCount = prev[key].new.length;
        if (!oldCount) {
          continue;
        }

        prev[key].new = prev[key].new.filter((r) => r !== id);
        const newCount = prev[key].new.length;
        if (oldCount > newCount) {
          break;
        }
      }

      return prev;
    });
  };

  useEffect(() => {
    if (hookInitialized) {
      return;
    }

    Broadcast.user.requestUpdatedByCustomer.setListener(({ id }) => {
      client.invalidateQueries({ queryKey: [authQueries.request, id] });
      client.invalidateQueries({ queryKey: [authQueries.request, 'v2', id] });
      client.invalidateQueries({ queryKey: [authQueries.requestOutcomes, id] });

      query.refetch();
    });

    hookInitialized = true;
  }, []);

  return {
    counts,
    query,
    totalQueued,
    newQueued,
    totalEditing,
    newEditing,
    totalDelivered,
    newDelivered,
    totalComplete,
    newComplete,
    totalNew,
    review,
    add,
    update,
    remove,
  };
};

export { useRequestsCounts };
