const extractFormErrors = (error: any): Record<string, any> | undefined => error?.errors?.form;
const extractHookFormErrors = (error: any): Record<string, any> | undefined =>
  error?.errors?.['form_v2'];

const handleTextareaResize = ({ currentTarget }: React.KeyboardEvent<HTMLTextAreaElement>) => {
  const getPropertyInt = (prop: string) =>
    parseInt(window.getComputedStyle(currentTarget).getPropertyValue(prop), 10);

  currentTarget.style.height = '1px';

  currentTarget.style.height = `${currentTarget.scrollHeight}px`;

  currentTarget.style.overflowY = getPropertyInt('height') >= 170 ? 'auto' : 'hidden';
};

const phoneNumberRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

const passwordRegex =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

export {
  extractFormErrors,
  extractHookFormErrors,
  handleTextareaResize,
  phoneNumberRegex,
  passwordRegex,
};
