import { Channel } from 'src/broadcast/Channels';
import { Client } from 'src/broadcast/Client';
import { NotificationCreatedEvent, RequestUpdatedByCustomerEvent } from 'src/broadcast/Events/User';

import { buildUrl } from 'src/helpers';
import { AuthUtils } from 'src/services/Auth/Auth.utils';

class UserChannel extends Channel {
  private channel?: string = undefined;

  public readonly channelName = 'user.{user}';

  public notificationCreated: NotificationCreatedEvent;
  public requestUpdatedByCustomer: RequestUpdatedByCustomerEvent;

  constructor(client: Client) {
    super(client);

    this.notificationCreated = new NotificationCreatedEvent();
    this.requestUpdatedByCustomer = new RequestUpdatedByCustomerEvent();
  }

  public connect(channel: string): this {
    if (AuthUtils.isExpired() || this.isConnected()) {
      return this;
    }

    this.client.setBearer(channel);

    const { user_id: user } = AuthUtils.getPayload()!;
    this.channel = buildUrl(this.channelName, { user });
    const room = this.client.echo.private(this.channel);

    this.notificationCreated.setChannel(room).listen();
    this.requestUpdatedByCustomer.setChannel(room).listen();

    this.setIsConnected(true);

    return this;
  }

  public disconnect(): this {
    this.notificationCreated.removeChannel().close();
    this.requestUpdatedByCustomer.removeChannel().close();
    if (this.channel) {
      this.client.echo.leave(this.channel);
      this.channel = undefined;
    }
    this.setIsConnected(false);

    return this;
  }
}

export default UserChannel;
