import { Client } from 'src/broadcast/Client';

abstract class Channel {
  public readonly client: Client;

  private connected = false;

  constructor(client: Client) {
    this.client = client;
  }

  abstract connect(channel: string): this;

  abstract disconnect(): this;

  setIsConnected = (connected: boolean): this => {
    this.connected = connected;

    return this;
  };

  isConnected = (): boolean => {
    return this.connected;
  };
}

export default Channel;
