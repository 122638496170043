import { Client } from 'src/api/Client';
import {
  AuthLoginRequest,
  AuthLoginResponse,
  AuthSocialLoginRequest,
  ForgetPasswordConfirmRequest,
} from './AuthClient.types';
import { RefreshTokenAbortController } from './AuthHttpAbortController';
import { AxiosRequestConfig } from 'axios';

class AuthClient extends Client {
  public readonly route = {
    login: `${this.http.baseUrl}/admin/auth/login`,
    socialLogin: `${this.http.baseUrl}/admin/auth/social-login`,
    logout: `${this.http.baseUrl}/admin/auth/logout`,
    refresh: `${this.http.baseUrl}/admin/auth/refresh`,
    passwordReset: `${this.http.baseUrl}/admin/auth/password-reset`,
  };

  login = (data: AuthLoginRequest): Promise<AuthLoginResponse> => {
    return this.http.post(this.route.login, data);
  };

  socialLogin = (
    data: AuthSocialLoginRequest,
    config?: AxiosRequestConfig,
  ): Promise<AuthLoginResponse> => {
    return this.http.post(this.route.socialLogin, data, config);
  };

  logout = (): Promise<void> => {
    return this.http.post(this.route.logout);
  };

  refresh = (): Promise<AuthLoginResponse> => {
    return this.http.post(this.route.refresh, {}, { signal: RefreshTokenAbortController.signal });
  };

  initPasswordReset = (data: { email: string }): Promise<any> => {
    return this.http.post(this.route.passwordReset, data);
  };

  confirmPasswordReset = (data: ForgetPasswordConfirmRequest): Promise<any> => {
    return this.http.put(this.route.passwordReset, data);
  };
}

export { AuthClient };
