import React, { useCallback, useRef, useState } from 'react';
import { If } from 'src/components/If';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from 'src/components/ui/button';
import { Loader2, UploadCloud } from 'lucide-react';
import { FormMediaCard } from 'src/components/MediaCard/form-media-card';
import { useDropzone } from 'react-dropzone';
import { cn } from 'src/lib/utils';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { useMediaUpload } from 'src/lib/services/media-upload-store/use-media-upload';
import { DropzonePlaceholder } from 'src/components/Form/hook-form/hook-form-dropzone-input/dropzone-placeholder';

type UploadHookFormInputProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'name' | 'type' | 'onChange' | 'onBlur'
> & {
  name: string;
  model: string;
  collectionName?: string;
  mediaContainerProps?: React.HTMLAttributes<HTMLDivElement>;
};

const UploadHookFormInput: React.FC<UploadHookFormInputProps> = ({
  name,
  model,
  collectionName,
  mediaContainerProps,
  multiple = true,
  ...props
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { getValues, setError, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });

  const { t } = useTranslation();
  const { generateUpload, upload } = useMediaUpload();
  const { abort, removeUpload } = useMediaUploadStore(
    useShallow(({ abort, removeUpload }) => ({ abort, removeUpload })),
  );

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors(name);

    const files = e.currentTarget.files!;

    if (!multiple) {
      fields.forEach((_, index) => remove(index));
    }

    try {
      await uploadFiles(files);
    } catch (e: any) {
      setError(name, { type: 'custom', message: e.message });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (files) => {
      if (!multiple) {
        fields.forEach((_, index) => remove(index));
      }

      try {
        await uploadFiles(files as any);
      } catch (e: any) {
        setError(name, { type: 'custom', message: e.message });
      }
    },
  });

  const uploadFiles = useCallback(
    async (files: FileList) => {
      setIsGenerating(true);
      const promises = [];

      for (let i = 0; i < files.length; i++) {
        promises.push(generateUpload(model, files[i], collectionName));
      }

      const multipartUploads = await Promise.all(promises).finally(() => setIsGenerating(false));

      multipartUploads.forEach((multipartUpload, index) => {
        // append form value
        append({
          id: multipartUpload.id,
          file_name: multipartUpload.file_name,
          mime_type: multipartUpload.mime_type,
          filePreviewUrl: URL.createObjectURL(files[index]),
        });

        upload(multipartUpload);
      });
    },
    [model, collectionName, generateUpload, append, upload],
  );

  const openFileSelector = () => {
    fileInputRef.current?.click();
  };

  const deleteFile = (index: number) => {
    // useFieldArray fields are not meant to get form values, so we get values this way
    const mediaId = getValues(`${name}.${index}`).id;

    abort(mediaId); // abort upload
    remove(index); // remove from form
    removeUpload(mediaId); // remove from upload store
  };

  return (
    <div>
      <input
        {...props}
        {...getInputProps()}
        ref={fileInputRef}
        type={'file'}
        className={'tw-hidden'}
        onChange={handleChange}
        multiple={multiple}
      />

      <div
        className={cn(
          'tw-relative tw-rounded-lg tw-border tw-border-dashed tw-border-indigo-300 tw-bg-neutral-50 tw-transition-colors',
          { 'tw-cursor-pointer': !fields.length, 'tw-border-solid tw-border-brand': isDragActive },
        )}
      >
        <ScrollArea>
          <div
            className={'tw-max-h-[628px] tw-min-h-[320px]'}
            {...getRootProps()}
            onClick={() => {
              if (!fields.length) {
                openFileSelector();
              }
            }}
          >
            <If
              when={!!fields.length || isDragActive}
              else={
                <DropzonePlaceholder>
                  <span className={'tw-text-center tw-text-neutral-500'}>
                    {t('actions:file_dropzone', 'Click to browse or drag and drop files here')}
                  </span>
                </DropzonePlaceholder>
              }
            >
              <If when={isDragActive}>
                <DropzonePlaceholder text={t('actions:file_drop', 'Drop files here to upload')!} />
              </If>

              <div
                {...mediaContainerProps}
                className={cn(
                  'tw-grid tw-grid-cols-1 tw-gap-4 tw-overflow-auto tw-px-4 tw-py-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4',
                  isDragActive && 'tw-invisible',
                  mediaContainerProps?.className,
                )}
              >
                {fields.map((media, index) => {
                  const mediaObject = getValues(`${name}.${index}`);
                  if (!mediaObject) {
                    return;
                  }

                  return (
                    <FormMediaCard
                      key={media.id}
                      name={`${name}.${index}`}
                      mediaObject={mediaObject}
                      onDelete={() => deleteFile(index)}
                    />
                  );
                })}
              </div>
            </If>
          </div>
        </ScrollArea>
      </div>

      <div className={'tw-mt-6 tw-flex tw-justify-center'}>
        <Button
          type={'button'}
          variant={'sky'}
          className={'tw-w-full tw-gap-2 lg:tw-w-auto'}
          disabled={isGenerating}
          onClick={openFileSelector}
        >
          <If
            when={isGenerating}
            else={
              <>
                <UploadCloud size={20} />
                {t('actions:upload')}
              </>
            }
          >
            <Loader2 className={'tw-mx-4 tw-animate-spin'} />
          </If>
        </Button>
      </div>
    </div>
  );
};

export { UploadHookFormInput };
