import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from 'src/lib/utils';

type ProgressProps = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  indicatorProps?: React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Indicator>;
};

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, ProgressProps>(
  ({ className, value, indicatorProps = {}, ...props }, ref) => {
    const { className: indicatorClass, ...restIndicatorProps } = indicatorProps;

    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          'tw-relative tw-h-4 tw-w-full tw-overflow-hidden tw-rounded-full tw-bg-neutral-400',
          className,
        )}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className={cn(
            'tw-h-full tw-w-full tw-flex-1 tw-bg-primary tw-transition-all',
            indicatorClass,
          )}
          style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
          {...restIndicatorProps}
        />
      </ProgressPrimitive.Root>
    );
  },
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
