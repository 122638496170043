import React from 'react';
import { RenderForRoleProps } from './RenderForRole.types';
import { RenderForRole } from './RenderForRole';
import { roles } from 'src/api/services/RoleClient';

type Props = RenderForRoleProps;

const RenderForAdmin: React.FC<Props> = ({ and = [], else: otherwise, children }) => {
  return (
    <RenderForRole roles={[roles.admin, ...and]} else={otherwise}>
      {children}
    </RenderForRole>
  );
};

export { RenderForAdmin };
