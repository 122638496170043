import React from 'react';
import { useField } from 'formik';
import { StyledTeamColorFieldInput } from './TeamColorFieldInput.styled';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'span'>> & {
  color: string;
  name: string;
};

const TeamColorFieldInput: React.FC<Props> = ({ name, color, ...props }) => {
  const [{ value }, , { setValue, setTouched }] = useField<string>(name);

  const isSelected = value === color;

  return (
    <StyledTeamColorFieldInput
      {...props}
      $selected={isSelected}
      color={color}
      onClick={() => {
        setValue(color);
        setTouched(true);
      }}
    />
  );
};

export { TeamColorFieldInput };
