import { ValueOfObject } from 'src/types/generics';
import { HasStatus } from '../../Interfaces';

const taskStatus = {
  stale: 0,
  done: 1,
} as const;

type TaskStatus = ValueOfObject<typeof taskStatus>;

type TaskTraitsObject = Record<string, any>;

type TaskResponse = HasStatus<TaskStatus> & {
  id: string;
  title: string;
  description: string | undefined;
  traits?: TaskTraitsObject;
  created_at: string;
  updated_at: string;
};

type CreateTaskRequest = {
  title: string;
  description?: string;
};

type UpdateTaskRequest = {
  title: string;
  description?: string;
  status: TaskStatus;
};

export { taskStatus };
export type { TaskStatus, TaskTraitsObject, TaskResponse, CreateTaskRequest, UpdateTaskRequest };
