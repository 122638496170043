import { HasPermissions } from './hasPermissions.types';
import { PermissionParts } from './PermissionParts';

const hasPermissions = (permissions: string[]): HasPermissions => {
  const hasPermissionTo: HasPermissions['hasPermissionTo'] = (permission, object = undefined) => {
    const parts = new PermissionParts(permission, object);

    // Narrow the search
    const permissionsGroup = permissions.filter((permission) =>
      permission.startsWith(parts.base()),
    );

    if (!parts.isForObject()) {
      return permissionsGroup.includes(parts.toString());
    }

    return (
      permissionsGroup.includes(parts.asForAll()) || permissionsGroup.includes(parts.toString())
    );
  };

  return {
    hasPermissionTo,
  };
};

export { hasPermissions };
