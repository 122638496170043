import React, { lazy, useEffect } from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { NotFoundView } from 'src/components/not-found-view';
import { Button } from 'src/components/ui/button';
import { appRoutes } from 'src/routes';
import FullPageWrapper from 'src/containers/FullPageWrapper';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

const AuthenticatedLayout = lazy(
  () => import('src/components/ui/layouts/authenticated-layout/authenticated-layout'),
);

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const isLogged = !AuthUtils.isExpired();
  const error = useRouteError() as Error;

  const redirect = isLogged
    ? {
        to: appRoutes.profile,
        text: t('Back to Profile'),
      }
    : {
        to: appRoutes.login,
        text: t('Back to Login'),
      };

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const Wrapper = isLogged ? AuthenticatedLayout : FullPageWrapper;

  return (
    <Wrapper>
      <NotFoundView>
        <div className={'tw-mt-6'}>
          <Button asChild size={'responsive'} variant={'brand'}>
            <Link to={redirect.to}>{redirect.text}</Link>
          </Button>
        </div>
      </NotFoundView>
    </Wrapper>
  );
};

export { NotFoundPage };
