import React from 'react';
import {
  RequestActivityEvent,
  requestActivityEvent,
  RequestActivityResponse,
  RequestActivityStatusChangeData,
  RequestActivityType,
  requestActivityType,
} from 'src/lib/services/api/request-api';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { str } from 'src/lib/utils';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { useFormatter } from 'src/services/i18n';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { RequestStatusDetails } from 'src/features/requests/request-table/request-status-details';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { appRoutes } from 'src/routes';
import { Skeleton } from 'src/components/ui/skeleton';
import { AppLogoShort } from 'src/components/ui/app-logo-short';
import { roles } from 'src/api/services/RoleClient';
import { RenderForAdmin } from 'src/components/Middleware';

const defaultActivityMessages: Record<RequestActivityType, Record<RequestActivityEvent, string>> = {
  [requestActivityType.status]: {
    [requestActivityEvent.created]: 'changed request status to:', // shouldn't happen, defining because typescript
    [requestActivityEvent.updated]: 'changed request status to:',
    [requestActivityEvent.deleted]: 'changed request status to:', // shouldn't happen, defining because typescript
  },
  [requestActivityType.approval]: {
    [requestActivityEvent.created]: 'changed request sub-status to:',
    [requestActivityEvent.updated]: 'changed request sub-status to:',
    [requestActivityEvent.deleted]: 'removed sub-status "approved"',
  },
  [requestActivityType.reedit]: {
    [requestActivityEvent.created]: 'changed request sub-status to:',
    [requestActivityEvent.updated]: 'changed request sub-status to:',
    [requestActivityEvent.deleted]: 'removed sub-status "needs re-edit"',
  },
  [requestActivityType.attention]: {
    [requestActivityEvent.created]: 'changed request sub-status to:',
    [requestActivityEvent.updated]: 'changed request sub-status to:',
    [requestActivityEvent.deleted]: 'removed sub-status "needs attention"',
  },
};

type Props = {
  activity: RequestActivityResponse;
};

const RequestActivityEntry: React.FC<Props> = ({ activity }) => {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();

  const fullName = activity.user
    ? joinExistingValues([activity.user.first_name, activity.user.last_name], ' ') ||
      activity.user.email
    : t('common:system', 'System');

  return (
    <div className={'tw-flex tw-gap-4'}>
      <div>
        <RequestActivityUserAvatar user={activity.user} />
      </div>

      <div className={'tw-flex tw-flex-col tw-gap-2'}>
        <div>
          <span className={'tw-text-lg tw-font-medium'}>{fullName}</span>{' '}
          <RequestActivityEntryMessage activity={activity} />
        </div>

        <RequestActivityEntryValue activity={activity} />

        <TooltipProvider>
          <Tooltip>
            <div className={'tw-flex tw-text-sm tw-font-normal tw-text-text-subtle'}>
              <TooltipTrigger asChild>
                <div>
                  {formatDateTime(activity.created_at, {
                    weekday: 'long',
                    month: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
              </TooltipTrigger>
            </div>

            <TooltipContent>{formatDateTime(activity.created_at)}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

const RequestActivityUserAvatar: React.FC<{ user: RequestActivityResponse['user'] }> = ({
  user,
}) => {
  return user ? (
    <RenderForAdmin
      and={[roles.teamLead]}
      else={
        <Avatar size={'md'}>
          <AvatarImage src={user.avatar?.index_url} alt={user.first_name} />
          <AvatarFallback className={'tw-bg-orange tw-text-white'}>
            {str.acronym(joinExistingValues([user.first_name, user.last_name], ' ')).slice(0, 2)}
          </AvatarFallback>
        </Avatar>
      }
    >
      <Link to={generatePath(appRoutes.user, { userId: user?.id })}>
        <Avatar size={'md'}>
          <AvatarImage src={user.avatar?.index_url} alt={user.first_name} />
          <AvatarFallback className={'tw-bg-orange tw-text-white'}>
            {str.acronym(joinExistingValues([user.first_name, user.last_name], ' ')).slice(0, 2)}
          </AvatarFallback>
        </Avatar>
      </Link>
    </RenderForAdmin>
  ) : (
    <Avatar size={'md'} className={'tw-items-center tw-justify-center'}>
      <AppLogoShort width={24} />
    </Avatar>
  );
};

const RequestActivityEntryMessage: React.FC<Props> = ({ activity }) => {
  const { t } = useTranslation();

  return (
    <>
      {t(`pages/admin:request.request_activity.${activity.type}.${activity.event}`, {
        defaultValue: defaultActivityMessages[activity.type][activity.event],
      })}
    </>
  );
};

const RequestActivityEntryValue: React.FC<{ activity: RequestActivityResponse }> = ({
  activity,
}) => {
  if (activity.event === requestActivityEvent.deleted) {
    return null;
  }

  const ValueComponent = () => {
    switch (activity.type) {
      case requestActivityType.status:
        return (
          <RequestStatusBadge
            variant={(activity.data as RequestActivityStatusChangeData).to}
            size={'sm'}
          />
        );
      case requestActivityType.approval:
        return <RequestStatusDetails approved={activity.created_at} size={'sm'} />;
      case requestActivityType.reedit:
        return <RequestStatusDetails needsReedit={activity.created_at} size={'sm'} />;
      case requestActivityType.attention:
        return <RequestStatusDetails needsAttention={activity.created_at} size={'sm'} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <ValueComponent />
    </div>
  );
};

const RequestActivityEntrySkeleton: React.FC = () => {
  return (
    <div className={'tw-flex tw-gap-4'}>
      <Skeleton className={'tw-size-8 tw-rounded-full'} />
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Skeleton className={'tw-h-3 tw-w-60'} />
        <Skeleton className={'tw-h-3 tw-w-16'} />
        <Skeleton className={'tw-h-3 tw-w-24'} />
      </div>
    </div>
  );
};

export { RequestActivityEntrySkeleton };
export default RequestActivityEntry;
