import { DependencyList, useCallback } from 'react';
import debounce from 'lodash/debounce';

const useDebouncedAction = <T extends (...args: any[]) => void>(
  action: T,
  deps: DependencyList = [],
  delay = 250,
): T => {
  return useCallback(debounce(action as any, delay), [delay, ...deps]) as unknown as T;
};

export { useDebouncedAction };
