import React, { useState } from 'react';
import { TaskResponse, TaskStatus } from 'src/api/services/TaskClient';
import {
  TaskItemActionsDropdownToggle,
  TaskItemCompleteActionCheckbox,
  TaskItemStyled,
  TaskItemText,
} from './TaskItem.styled';
import {
  PencilIcon,
  StyledDropdownItem,
  StyledDropdownMenu,
  TrashIcon,
  VerticalRoundedDotsIcon,
  Text,
} from '@becreatives/becreatives-ui';
import { useTask } from 'src/models/task';
import { useTranslation } from 'react-i18next';
import { UncontrolledDropdown } from 'reactstrap';
import { BxShowIcon } from '../Boxicons';
import { ModalToggler } from '../Modal';
import { ViewTaskModal } from './ViewTaskModal';
import { EditTaskModal } from './EditTaskModal';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>> & {
  task: TaskResponse;
  onComplete?: (task: TaskResponse) => any | Promise<any>;
  onDelete?: (task: TaskResponse) => any | Promise<any>;
  onEdit?: (task: TaskResponse) => any | Promise<any>;
};

const TaskItem: React.FC<Props> = ({ task, onComplete, onDelete, onEdit, ...props }) => {
  const { t } = useTranslation('actions');

  const { changeStatus, remove } = useTask(task.id);

  const [isDisabled, setDisabled] = useState(false);

  let itemTitle = task.title;
  if (task.description) {
    itemTitle += `\n\n${task.description}`;
  }

  return (
    <TaskItemStyled $status={task.status} {...props}>
      <TaskItemCompleteActionCheckbox
        checked={!!task.status}
        disabled={isDisabled}
        onChange={() => {
          setDisabled(true);
          changeStatus(+!task.status as TaskStatus)
            .then(() => onComplete?.(task))
            .finally(() => setDisabled(false));
        }}
      />

      <TaskItemText title={itemTitle}>{task.title}</TaskItemText>

      <UncontrolledDropdown className={'ms-auto'}>
        <TaskItemActionsDropdownToggle tag={'span'}>
          <VerticalRoundedDotsIcon color={'danger'} />
        </TaskItemActionsDropdownToggle>

        <StyledDropdownMenu persist={true}>
          <ModalToggler
            modal={ViewTaskModal}
            modalProps={{
              task,
              onComplete,
              onEdit,
              onDelete,
            }}
            toggler={StyledDropdownItem}
            toggle={false}
          >
            <Text as={BxShowIcon} className={'me-2'} $xs={'lg'} color={'primary'} />
            {t('view')}
          </ModalToggler>

          <ModalToggler
            modal={EditTaskModal}
            modalProps={{
              task,
              onSubmit: onEdit,
            }}
            toggler={StyledDropdownItem}
            toggle={false}
          >
            <PencilIcon className={'me-2'} color={'blue'} />
            {t('edit')}
          </ModalToggler>

          <StyledDropdownItem
            onClick={async () => {
              if (confirm(t('confirm.delete_task', { ns: 'alerts' })!)) {
                await remove();
                onDelete?.(task);
              }
            }}
            toggle={false}
          >
            <TrashIcon className={'me-2'} color={'danger'} />
            {t('delete')}
          </StyledDropdownItem>
        </StyledDropdownMenu>
      </UncontrolledDropdown>
    </TaskItemStyled>
  );
};

export type { Props as TaskItemProps };
export { TaskItem };
