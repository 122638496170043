import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'reactstrap';
import { ChatMessageActions, StyledDropdownMenu } from '@becreatives/becreatives-ui';

const RevisionChatMessageActionsDropdown: React.FC<
  React.PropsWithChildren<Omit<DropdownProps, 'isOpen' | 'toggle'>>
> = ({ children, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);

  return (
    <>
      <ChatMessageActions onClick={toggle} />
      <Dropdown {...props} toggle={toggle} isOpen={isOpen}>
        <StyledDropdownMenu>{children}</StyledDropdownMenu>
      </Dropdown>
    </>
  );
};

export { RevisionChatMessageActionsDropdown };
