import React from 'react';
import { useRequestActivitiesQueryContext } from 'src/features/requests/request-activity/use-request-activities-query-context';
import RequestActivityEntry, {
  RequestActivityEntrySkeleton,
} from 'src/features/requests/request-activity/request-activity-entry';
import { NoRecordsFallback } from 'src/features/fallback/ui/no-records-fallback';

const RequestActivityListContent: React.FC = () => {
  const { data } = useRequestActivitiesQueryContext();

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {!!data?.items.length ? (
        data?.items.map((activity) => (
          <RequestActivityEntry key={activity.id} activity={activity} />
        ))
      ) : (
        <NoRecordsFallback />
      )}
    </div>
  );
};

const RequestActivityListContentSkeleton: React.FC = () => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {Array.from({ length: 5 }).map((_, index) => (
        <RequestActivityEntrySkeleton key={index} />
      ))}
    </div>
  );
};

export { RequestActivityListContentSkeleton };
export default RequestActivityListContent;
