import { EntryToOptionMap, OptionObject } from './ReactSelectInput.types';
import { isGroupedOptionObjectGuard, isOptionObjectGuard } from './ReactSelectInput.guards';
import { OptionsOrGroups } from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { KeyOfObject } from 'src/types/generics';
import { getObjectKeys } from 'src/helpers/getObjectKeys';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { cn } from 'src/lib/utils';
import {
  ReactSelectInputOption,
  ReactSelectDropdownIndicator,
  ReactSelectClearIndicator,
  ReactSelectMultiValueRemove,
  ReactSelectValueContainer,
  ReactSelectMenuList,
  ReactSelectMultiValue,
} from 'src/components/ui/react-select/components';

const configReactSelectProps = <
  T extends Partial<Omit<ReactSelectProps<OptionObject>, 'name' | 'value' | 'options'>>,
>(
  props: T & { isError?: boolean },
): T => {
  if (props.isMulti && typeof props.closeMenuOnSelect === 'undefined') {
    props.closeMenuOnSelect = false;
  }

  if (typeof props.isSearchable === 'undefined') {
    props.isSearchable = false;
  }

  if (typeof props.isClearable === 'undefined') {
    props.isClearable = true;
  }

  if (!props.isSearchable) {
    props.filterOption = null;
  }

  if (typeof props.hideSelectedOptions === 'undefined') {
    props.hideSelectedOptions = false;
  }

  // Reset default styles and style everything using classNames and tailwind
  props.unstyled = true;

  props.classNames = {
    control: ({ isFocused, isDisabled, className }) =>
      cn(
        'select-control tw-h-12 !tw-cursor-pointer tw-rounded-md tw-border tw-bg-background tw-px-3',
        isFocused && 'tw-outline-none tw-ring-2 tw-ring-ring tw-ring-offset-2',
        isDisabled && 'tw-opacity-50',
        props.isError && 'validation-error tw-border-destructive',
        className,
      ),
    menu: () =>
      cn(
        '!tw-z-50 tw-mt-1 tw-gap-1 tw-rounded-lg tw-border tw-bg-background tw-shadow-md tw-animate-in tw-fade-in-0 tw-zoom-in-95 tw-slide-in-from-top-2',
      ),
    menuPortal: () => '!tw-z-[1000]',
    option: ({ isFocused, isSelected, isDisabled, className }) =>
      cn(
        'tw-my-1 !tw-cursor-pointer tw-rounded tw-px-3 tw-py-2 hover:tw-bg-brand-100 active:tw-bg-brand-200',
        isFocused && 'tw-bg-brand-100 active:tw-bg-brand-200',
        isSelected && 'tw-bg-brand-300 hover:tw-bg-brand-300/70 active:tw-bg-brand-300/50',
        isDisabled && '!tw-cursor-default tw-bg-background tw-opacity-50',
        className,
      ),
    valueContainer: () => cn('tw-flex !tw-flex-nowrap tw-gap-1 tw-py-1'),
    multiValue: () =>
      cn(
        'tw-items-center tw-gap-1.5 tw-rounded-md tw-border tw-border-brand-subtle tw-bg-brand-200 tw-p-1',
      ),
    multiValueRemove: () => cn('tw-rounded hover:tw-bg-brand-300'),
    groupHeading: () => cn('tw-my-2 tw-ml-3 tw-font-bold'),
    placeholder: () => 'tw-text-text-inactive',
    indicatorSeparator: () => 'tw-hidden tw-bg-inactive tw-mx-1 tw-my-2',
    dropdownIndicator: () => 'tw-p-1 hover:tw-bg-neutral-100 tw-rounded-md',
    clearIndicator: () => 'tw-p-1 hover:tw-bg-neutral-100 tw-rounded-md !tw-text-subtle',
    noOptionsMessage: () => 'tw-p-3 tw-text-text-inactive tw-rounded-md',
    input: () => 'tw-text-lg lg:tw-text-base',
  };

  if (!props.components) {
    props.components = {};
  }

  // Custom scroll-area
  if (!('MenuList' in props.components)) {
    props.components['MenuList'] = ReactSelectMenuList;
  }

  // Custom select option
  if (!('Option' in props.components)) {
    props.components['Option'] = ReactSelectInputOption;
  }

  // Custom dropdown indicator
  if (!('DropdownIndicator' in props.components)) {
    props.components['DropdownIndicator'] = ReactSelectDropdownIndicator;
  }

  // Custom clear values indicator
  if (!('ClearIndicator' in props.components)) {
    props.components['ClearIndicator'] = ReactSelectClearIndicator;
  }

  // Custom multi value remove indicator
  if (!('MultiValueRemove' in props.components)) {
    props.components['MultiValueRemove'] = ReactSelectMultiValueRemove;
  }

  // Render only the first multi-select selected value
  if (!('MultiValue' in props.components)) {
    props.components['MultiValue'] = ReactSelectMultiValue;
  }

  // Custom selected value container
  if (!('ValueContainer' in props.components)) {
    props.components['ValueContainer'] = ReactSelectValueContainer;
  }

  return props;
};

const getSelectedOption = (
  options: OptionsOrGroups<OptionObject, GroupBase<OptionObject>>,
  value: any,
): OptionObject | undefined => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    if (isOptionObjectGuard(option)) {
      if (option.value === value) {
        return option;
      }

      continue;
    }

    if (isGroupedOptionObjectGuard(option)) {
      const match = option.options.find((opt) => opt.value === value);
      if (match) {
        return match;
      }
    }
  }

  return undefined;
};

const getSelectedOptions = (
  options: OptionsOrGroups<OptionObject, GroupBase<OptionObject>>,
  values: any[],
): OptionObject[] => {
  const results: OptionObject[] = [];

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    if (isOptionObjectGuard(option)) {
      if (values.includes(option.value)) {
        results.push(option);
      }

      continue;
    }

    if (isGroupedOptionObjectGuard(option)) {
      const match = option.options.find((opt) => values.includes(opt.value));
      if (match) {
        results.push(match);
      }
    }
  }

  return results;
};

const parseEntryToOption: <T extends Record<string, any>, K extends string>(
  data: T,
  map: EntryToOptionMap<T>,
) => OptionObject<Record<K, KeyOfObject<T>>> = (data, map) => {
  return getObjectKeys(map).reduce((opt, key) => {
    const mapData = map[key];
    if (typeof mapData === 'function') {
      opt[key] = mapData(data);
      return opt;
    }

    opt[key] = data[mapData!];
    return opt;
  }, {} as any);
};

const parseEntriesToOptions: <T extends Record<string, any>, K extends string>(
  data: T[],
  map: EntryToOptionMap<T>,
) => OptionObject<Record<K, KeyOfObject<T>>>[] = (data, map) => {
  return data.map((item) => parseEntryToOption(item, map));
};

export {
  configReactSelectProps,
  getSelectedOption,
  getSelectedOptions,
  parseEntryToOption,
  parseEntriesToOptions,
};
