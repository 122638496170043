import React, { useContext, useRef } from 'react';
import { ScrollableContainerContext } from './scrollable-container.types';

type Props<Element extends HTMLElement> = {
  children: React.ReactNode | ((props: ScrollableContainerContext<Element>) => React.ReactNode);
  scrollBuffer?: number;
};

const ScrollableContext = React.createContext<ScrollableContainerContext>({
  ref: null,
  onScrollToBottom: () => () => ({}),
});

const ScrollableContainer = <Element extends HTMLElement>({
  scrollBuffer = 0,
  children,
}: Props<Element>): JSX.Element => {
  const ref = useRef<Element>(null);
  const props = {
    ref,
    onScrollToBottom: (cb: () => void) => {
      return () => {
        if (!ref.current) {
          return;
        }

        const { scrollTop, scrollHeight, clientHeight } = ref.current;

        if (scrollTop + clientHeight >= scrollHeight - scrollBuffer) {
          cb();
        }
      };
    },
  };

  return (
    <ScrollableContext.Provider value={props}>
      {children instanceof Function
        ? children(props as ScrollableContainerContext<Element>)
        : children}
    </ScrollableContext.Provider>
  );
};

const useScrollableContainer = () => useContext(ScrollableContext);

export { ScrollableContainer, useScrollableContainer };
