import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FieldComplex } from '../../Form/FieldComplex';
import { PluginTextArea } from '../../Form/PluginTextArea';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Button';
import { CreateTaskRequest } from 'src/api/services/TaskClient';
import { extractFormErrors } from '../../Form/Form.utils';

type Props = {
  onSubmit: (values: CreateTaskRequest, helpers: FormikHelpers<CreateTaskRequest>) => Promise<void>;
};

const CreateTaskForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation('attributes/task');

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      title: Yup.string().required(),
      description: Yup.string().nullable(),
    });
  }, []);

  return (
    <Formik<CreateTaskRequest>
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{
        title: '',
        description: '',
      }}
      onSubmit={async (values, helpers) => {
        helpers.setSubmitting(true);

        try {
          await onSubmit(values, helpers);
        } catch (e: any) {
          helpers.setErrors(extractFormErrors(e) ?? {});
        } finally {
          helpers.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FieldComplex name={'title'} label={t('title')} type={'text'} required={true} />

          <FieldComplex
            name={'description'}
            label={t('description')}
            rows={6}
            as={PluginTextArea}
          />

          <div className={'d-flex'}>
            <Button
              className={'ms-auto flex-grow-1 flex-md-grow-0 min-w-24'}
              submitting={isSubmitting}
              disabled={isSubmitting}
              type={'submit'}
            >
              {t('create', { ns: 'actions' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export type { Props as CreateTaskFormProps };
export { CreateTaskForm };
