import { isValidationRule, ValidationRule } from './Form.types';

const resolveValidationRule = <V, M = string>(
  rule: V | ValidationRule<V, M>,
  defaultMessage?: (value: NonNullable<V>) => M,
) => {
  return {
    validate: (passes: (value: NonNullable<V>, error: M) => void) => {
      const value = isValidationRule(rule) ? (rule.value as V) : (rule as V);
      if (!value) {
        return;
      }

      const message = isValidationRule(rule)
        ? rule.message
        : (() => {
            if (!defaultMessage) {
              throw new Error('Default message is not specified');
            }

            if (defaultMessage instanceof Function) {
              return defaultMessage(value);
            }

            return defaultMessage;
          })();

      passes(value, message as M);
    },
  };
};

export { resolveValidationRule };
