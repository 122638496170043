import { GroupedOptionObject, OptionObject } from './ReactSelectInput.types';

const isGroupedOptionObjectGuard = (obj: any): obj is GroupedOptionObject => {
  return !!obj && typeof obj === 'object' && 'options' in obj;
};

const isOptionObjectGuard = (obj: any): obj is OptionObject => {
  return !!obj && typeof obj === 'object' && 'label' in obj && 'value' in obj;
};

export { isGroupedOptionObjectGuard, isOptionObjectGuard };
