import { default as LaravelEcho } from 'laravel-echo';
import Pusher from 'pusher-js';
import { AuthUtils } from 'src/services/Auth/Auth.utils';

import { API_URL, PUSHER_APP_KEY, PUSHER_APP_CLUSTER } from 'src/env.json';

// TODO: improve this with error handling and stuff
class Client {
  public echo: LaravelEcho;

  public pusher = Pusher;

  public readonly urlOrigin = API_URL;

  constructor() {
    const options = {
      broadcaster: 'pusher',
      key: PUSHER_APP_KEY,
      cluster: PUSHER_APP_CLUSTER,
      forceTLS: true,
      authEndpoint: `${API_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: '',
        },
      },
    };

    this.echo = new LaravelEcho(options);

    if (!AuthUtils.isExpired()) {
      this.setBearer(AuthUtils.getJwt().token);
    }
  }

  public setBearer(token: string): this {
    this.echo.options.auth.headers['Authorization'] = `Bearer ${token}`;

    return this;
  }

  public hangUp(): this {
    delete this.echo.options.auth.headers.Authorization;
    this.echo.disconnect();

    return this;
  }
}

export default Client;
