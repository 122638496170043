import { PostProcessorModule } from 'i18next';
import isObject from 'lodash/isObject';
import { getObjectKeys } from 'src/helpers/getObjectKeys';

const applyBasicPluralization = (value: string, count: number): string => {
  const pluralization = value.split('|');
  if (count === 1) {
    return pluralization[0];
  }

  return pluralization[1];
};

const formatLaravelTranslation: PostProcessorModule['process'] = (value, key, options) => {
  if (!(typeof value !== 'string')) {
    value = `${value ?? ''}`;
  }

  if (!('replace' in options) || !isObject(options.replace) || !value) {
    return value;
  }

  const replaced = getObjectKeys(options.replace).reduce((v, key) => {
    v = v?.replaceAll(`:${key}`, options.replace[key]);
    return v;
  }, value);

  // TODO: split interpolation and pluralization logic
  const hasPluralization =
    replaced.includes('|') && 'count' in options && typeof options.count !== 'undefined';
  if (!hasPluralization) {
    return replaced;
  }

  return applyBasicPluralization(replaced, options.count!);
};

const LaravelFormatter: PostProcessorModule = {
  type: 'postProcessor',
  name: 'laravelFormatter',
  process: (...args) => formatLaravelTranslation(...args),
};

export { LaravelFormatter };
