import React from 'react';
import {
  BookText,
  FileMusic,
  FileQuestion,
  FileVideo,
  FolderArchive,
  Image,
  LucideProps,
} from 'lucide-react';
import { getFileTypeByMime } from 'src/helpers/file';

const FileIcon: React.FC<LucideProps & { mimeType: string }> = ({ mimeType, ...props }) => {
  const fileType = getFileTypeByMime(mimeType);
  switch (fileType) {
    case 'image':
      return <Image {...props} />;
    case 'video':
      return <FileVideo {...props} />;
    case 'audio':
      return <FileMusic {...props} />;
    case 'pdf':
      return <BookText {...props} />;
    case 'zip':
      return <FolderArchive {...props} />;
    default:
      return <FileQuestion {...props} />;
  }
};

export { FileIcon };
