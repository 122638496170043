type FormErrors<T extends Record<string, unknown>, K extends keyof T> = Record<K, string[]>;

class HttpError<T extends Record<string, unknown> = Record<string, unknown>> extends Error {
  readonly data: T | undefined;
  readonly formErrors: FormErrors<T, keyof T> | undefined;
  readonly message: string;
  readonly status: number;

  constructor(
    message: string,
    options: {
      data?: T;
      status: number;
    },
  ) {
    super(message);

    this.message = message;
    this.data = options.data;
    this.status = options.status;

    if (options.data && typeof options.data === 'object' && 'form' in options.data) {
      this.formErrors = options.data.form as FormErrors<T, keyof T>;
    }
  }

  isFormError = () => typeof this.formErrors !== 'undefined' && this.status === 422;
}

const isHttpError = <T extends Record<string, unknown> = never>(
  obj: unknown,
): obj is HttpError<T> => {
  return obj instanceof HttpError;
};

const isFormError = <T extends Record<string, unknown> = never>(
  obj: unknown,
): obj is HttpError<T> => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'formErrors' in obj &&
    typeof obj.formErrors !== 'undefined'
  );
};

const hasMessage = <T extends Record<string, unknown>>(
  obj: unknown,
): obj is { message: string } & T => {
  return typeof !Array.isArray(obj) && typeof obj === 'object' && obj !== null && 'message' in obj;
};

export { HttpError, isHttpError, isFormError, hasMessage };
