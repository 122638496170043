import styled from 'styled-components';
import { Badge } from '@becreatives/becreatives-ui';
import { RequestStatus } from 'src/api/services/RequestClient';

const colorMap: Record<
  RequestStatus,
  {
    color: string;
    bgColor: string;
  }
> = {
  queued: {
    color: '#939393',
    bgColor: '#E4E4E4',
  },
  currently_editing: {
    color: '#C67327',
    bgColor: '#F8EABB',
  },
  delivered: {
    color: '#5E15D7',
    bgColor: '#E3D8F4',
  },
  complete: {
    color: '#4E9A63',
    bgColor: '#CDECC8',
  },
};

const StyledRequestStatusBadge = styled(Badge)<{ status: RequestStatus }>`
  background-color: ${({ status }) => colorMap[status].bgColor} !important;
  color: ${({ status }) => colorMap[status].color} !important;

  padding: 0.3rem 0.75rem;
`;

export { StyledRequestStatusBadge };
