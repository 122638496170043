import React from 'react';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { extractFormErrors } from 'src/components/Form/Form.utils';
import { parseEntriesToOptions, ReactSelectFormInput } from 'src/components/ReactSelectInput';
import { FieldComplex } from 'src/components/Form/FieldComplex';
import { useTranslation } from 'react-i18next';
import { authQueries, scopedQueries } from 'src/api/queries';
import { Api } from 'src/api';
import { CreateTeamMemberRequest, teamMemberRole } from 'src/api/services/TeamMemberClient';
import { RoleBadge } from 'src/components/Badge';
import { useTeamId } from 'src/models';
import { createEditorsFilterQuery } from 'src/features/users';
import { mergeQueryParams } from 'src/lib/services/api-query-params';
import { Form } from 'src/components/ui/form';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'src/components/ui/button';

type TeamMemberFormValues = Pick<CreateTeamMemberRequest, 'user_id' | 'role'>;

type Props = React.PropsWithChildren<Omit<React.ComponentPropsWithoutRef<'div'>, 'onSubmit'>> & {
  onSubmit: (
    values: TeamMemberFormValues,
    formikHelpers: FormikHelpers<TeamMemberFormValues>,
  ) => Promise<any> | any;
  initialValues?: TeamMemberFormValues;
};

const editorsFiltersQuery = mergeQueryParams(createEditorsFilterQuery(), {
  limit: 25,
});

const AddTeamMemberForm: React.FC<Props> = ({
  onSubmit,
  initialValues = {
    user_id: '',
    role: 'editor',
  },
}) => {
  const { t } = useTranslation('actions');

  const teamId = useTeamId();

  const queryClient = useQueryClient();

  return (
    <Formik<TeamMemberFormValues>
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        try {
          await onSubmit(values, formikHelpers);
          await queryClient.refetchQueries({
            queryKey: [authQueries.users, teamId, scopedQueries.infinite],
          });
        } catch (e) {
          formikHelpers.setErrors(extractFormErrors(e) ?? {});
        } finally {
          formikHelpers.setSubmitting(false);
        }
      }}
      initialValues={initialValues}
    >
      {({ isSubmitting }) => (
        <FormikForm className={'my-4 d-flex flex-column gap-4'}>
          <FieldComplex
            as={Form.asyncSelect<Awaited<ReturnType<typeof Api.teamMember.filterUsers>>>}
            wrapperClassNames={['flex-1']}
            name={'user_id'}
            queryOptions={{
              queryKey: [authQueries.users, teamId],
              queryFn: (context, params) =>
                Api.teamMember.filterUsers(teamId, mergeQueryParams(editorsFiltersQuery, params)),
              staleTime: 60 * 1000,
            }}
            options={(pages) => {
              const flat = pages?.flatMap((page) => page.items) ?? [];

              return parseEntriesToOptions(flat, {
                label: (user) => joinExistingValues([user.first_name, user.last_name], ' '),
                value: 'id',
                description: (user) => <RoleBadge pill role={user.role} className={'ms-auto'} />,
              });
            }}
            isSearchable={true}
            className={''}
            label={t('name.singular', { ns: 'models/user' })}
            required={true}
          />

          <FieldComplex
            label={t('name.singular', { ns: 'models/role' })}
            as={ReactSelectFormInput}
            wrapperClassNames={['flex-1']}
            name={'role'}
            options={
              Object.values(teamMemberRole).map((role) => ({
                value: role,
                label: <RoleBadge pill role={role} />,
              })) ?? []
            }
            className={''}
            required={true}
          />

          <Button disabled={isSubmitting} type={'submit'} variant={'brand'} size={'responsive'}>
            {t('save')}
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

export type { Props as AddTeamMemberFormProps };
export { AddTeamMemberForm };
