import React from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from 'src/components/ui/card';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Badge, BadgeProps } from 'src/components/ui/badge';
import { cn } from 'src/lib/utils';
import RevisionFileDisplayFallback from 'src/assets/images/revision-file-display-fallback.svg';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';

export const RequestDeliverableOutcomeCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <Card
    ref={ref}
    className={cn('tw-bg-neutral-50 tw-transition-colors hover:tw-border-indigo-300', className)}
    {...props}
  />
));
RequestDeliverableOutcomeCard.displayName = 'RequestDeliverableOutcomeCard';

export const RequestDeliverableOutcomeCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { ratio?: number }
>(({ className, children, ratio = 4 / 3, ...props }, ref) => (
  <CardContent
    ref={ref}
    className={'tw-w-full tw-cursor-pointer tw-items-stretch tw-p-0'}
    {...props}
  >
    <AspectRatio ratio={ratio}>
      <div className={'tw-relative tw-h-full tw-w-full'}>{children}</div>
    </AspectRatio>
  </CardContent>
));
RequestDeliverableOutcomeCardContent.displayName = 'RequestDeliverableOutcomeCardContent';

export const RequestDeliverableOutcomeCardBackgroundContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('tw-absolute tw-inset-0', className)} {...props}>
    {children}
  </div>
));
RequestDeliverableOutcomeCardBackgroundContent.displayName =
  'RequestDeliverableOutcomeCardBackgroundContent';

export const RequestDeliverableOutcomeCardBackgroundContentFallback = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn(
      'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-t-md tw-bg-neutral-linear',
      className,
    )}
  >
    <img src={RevisionFileDisplayFallback} />
  </div>
));
RequestDeliverableOutcomeCardBackgroundContentFallback.displayName =
  'RequestDeliverableOutcomeCardBackgroundContentFallback';

export const RequestDeliverableOutcomeCardForegroundContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'tw-absolute tw-inset-0 tw-z-10 tw-flex tw-flex-col tw-items-stretch tw-p-4',
      className,
    )}
    {...props}
  >
    {children}
  </div>
));
RequestDeliverableOutcomeCardForegroundContent.displayName =
  'RequestDeliverableOutcomeCardForegroundContent';

export const RequestDeliverableOutcomeCardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <CardFooter
    ref={ref}
    className={cn('tw-flex tw-gap-2 tw-px-4 tw-pb-3 tw-pt-0', className)}
    {...props}
  />
));
RequestDeliverableOutcomeCardFooter.displayName = 'RequestDeliverableOutcomeCardFooter';

export const RequestDeliverableOutcomeCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <CardHeader ref={ref} className={cn('tw-mb-1 tw-px-4 tw-pb-0 tw-pt-3', className)} {...props} />
));
RequestDeliverableOutcomeCardHeader.displayName = 'RequestDeliverableOutcomeCardHeader';

export const RequestDeliverableOutcomeCardTitle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <TooltipProvider>
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <CardTitle
          ref={ref}
          className={
            'tw-mb-4 tw-line-clamp-1 tw-w-full tw-break-all tw-text-lg tw-font-bold tw-leading-normal tw-text-dark'
          }
          {...props}
        >
          {children}
        </CardTitle>
      </TooltipTrigger>
      <TooltipContent align={'start'} className={'tw-max-w-96 tw-overflow-x-auto tw-break-all'}>
        {children}
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
));
RequestDeliverableOutcomeCardTitle.displayName = 'RequestDeliverableOutcomeCardTitle';

export const RequestDeliverableCardBadge: React.FC<BadgeProps> = ({
  className,
  variant = 'unset',
  padding = 'unset',
  ...props
}) => (
  <Badge
    variant={variant}
    padding={padding}
    className={cn('tw-rounded-lg tw-border-0 tw-p-2 tw-text-base', className)}
    {...props}
  />
);
