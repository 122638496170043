import React from 'react';
import { useFormikContext } from 'formik';
import { MinimalMediaCardUpload } from 'src/components/MediaCard';
import { StoreCommentFormObject } from '../RevisionChatContent.types';
import { ChatInputCommentSelectedMediaWrapper } from './ChatInputCommentSelectedMedia.styled';
import { If } from 'src/components/If';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';

const ChatInputCommentSelectedMedia: React.FC = () => {
  const { values } = useFormikContext<StoreCommentFormObject>();
  const { isFailed } = useMediaUploadStore();
  const uploadsFailed = values.media?.some((m) => isFailed(m.id));

  const media = values?.media ?? [];

  return (
    <If when={!!media.length}>
      <ChatInputCommentSelectedMediaWrapper>
        {media.map((m) => (
          <MinimalMediaCardUpload media={m} key={m.id} canDownload={false} />
        ))}
      </ChatInputCommentSelectedMediaWrapper>
      <If when={uploadsFailed}>
        <span className="text-danger invalid-feedback d-block">
          Some files failed to upload, remove them and try again
        </span>
      </If>
    </If>
  );
};

export { ChatInputCommentSelectedMedia };
