import { Api } from 'src/api';
import { RequestsCountsResponse } from 'src/api/services/RequestClient';
import { authQueries } from 'src/api/queries/queries';
import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

const useCompanyRequestsCountsQuery = (
  companyId: string,
  params?: ApiQueryObject,
  options?: UseQueryOptionsObject<RequestsCountsResponse>,
) => {
  return useMakeQuery<RequestsCountsResponse>(
    [authQueries.requestCounts, companyId, params],
    () => Api.request.fetchCompanyRequestsCounts(companyId, params),
    {
      staleTime: 60 * 1000,
      ...defaultOptions({
        auth: true,
        ...(options ?? {}),
      }),
    },
  );
};

export { useCompanyRequestsCountsQuery };
