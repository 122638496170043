import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  assertThemedColor,
  customColors,
  shadeColor,
  ThemeColor,
} from '@becreatives/becreatives-ui';

const LinkStyled = styled.a<{ $color?: ThemeColor }>`
  cursor: pointer;
  transition: all 250ms ease-in-out;
  text-decoration: underline !important;
  color: ${({ $color = customColors.mariner, theme }) =>
    assertThemedColor(theme, $color, 'bgColor')} !important;

  &:hover {
    color: ${({ $color = customColors.mariner, theme }) =>
      shadeColor(assertThemedColor(theme, $color, 'bgColor'), 20)} !important;
  }
`;

const RouteStyled = styled(Link)<{ $color?: ThemeColor }>`
  transition: all 250ms ease-in-out;
  color: ${({ $color = customColors.mariner, theme }) =>
    assertThemedColor(theme, $color, 'bgColor')} !important;

  &:hover {
    color: ${({ $color = customColors.mariner, theme }) =>
      shadeColor(assertThemedColor(theme, $color, 'bgColor'), 20)} !important;
  }
`;

export { LinkStyled, RouteStyled };
