import React from 'react';
import {
  ApiQueryObject,
  ApiQueryParams,
  mergeQueryParams,
} from 'src/lib/services/api-query-params';
import { parseEntriesToOptions } from 'src/components/ReactSelectInput';
import { useTranslation } from 'react-i18next';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { cn } from 'src/lib/utils';
import { ApiQueryParamsFilterFieldProps } from 'src/lib/services/api-query-params/api-query-params-filter-field';
import { authQueries } from 'src/api/queries';
import { RoleBadge } from 'src/components/Badge';
import { createEditorsFilterQuery } from 'src/features/users';
import { userClient } from 'src/lib/services/api/user-api';
import { EditorSelectOption } from 'src/components/ui/react-select/select-options/editor-select-option';

type Props = Omit<ApiQueryParamsFilterFieldProps, 'name'> & {
  name?: string;
  editorsFilter?: ApiQueryObject;
  includeUnassigned?: boolean;
};

const editorsFilterQuery = mergeQueryParams(createEditorsFilterQuery(), {
  limit: 25,
});

const EditorFilter: React.FC<Props> = ({
  name = 'editor',
  editorsFilter,
  includeUnassigned = true,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const filterQuery = editorsFilter
    ? mergeQueryParams(editorsFilter, editorsFilterQuery)
    : editorsFilterQuery;

  return (
    <ApiQueryParams.asyncFilter
      queryOptions={{
        queryKey: [authQueries.users],
        queryFn: ({ signal }, filters) =>
          userClient.options({ params: mergeQueryParams(filters, filterQuery), signal }),
        staleTime: 60 * 1000,
      }}
      name={name}
      className={cn('[&>.select-control]:tw-h-11', className)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore idk why is u mad
      options={(pages) => {
        const flat = pages?.flatMap((page) => page.items) ?? [];

        const options = parseEntriesToOptions(flat, {
          label: (data) => joinExistingValues([data.first_name, data.last_name], ' '),
          value: 'id',
          description: (data) => (
            <RoleBadge className={'tw-ms-auto tw-self-center'} pill role={data.role} />
          ),
          avatar: (data) => data.avatar,
        });

        if (includeUnassigned) {
          // prepend "unassigned" null option
          options.unshift({
            label: t('common:unassigned', 'Unassigned')!,
            value: '',
          } as any);
        }

        return options;
      }}
      isMulti
      isSearchable
      placeholder={t('editor', { ns: 'models/role' })}
      components={{
        Option: EditorSelectOption,
      }}
      {...props}
    />
  );
};

export { EditorFilter };
