import React from 'react';
import { Card, CardContent } from 'src/components/ui/card';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { cn } from 'src/lib/utils';
import { useTranslation } from 'react-i18next';
import dompurify from 'dompurify';
import { Str } from 'src/helpers';
import { totalLengthToString } from 'src/features/requests/request-fields/request-field.helper';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { OrderNotes } from 'src/pages/RevisionsPage/UpdatedDesign/RevisionsContentBottom/OrderNotes';
import RequestActivityList from 'src/features/requests/request-activity/request-activity-list';
import { ScrollArea } from 'src/components/ui/scroll-area';

const RevisionOutcomeContentBottom: React.FC = () => {
  const { t } = useTranslation();
  const { id, status, type, description, editor_note, total_length, title } =
    useDecoratedRequestContext();

  return (
    <div className={'tw-flex tw-flex-col tw-gap-6'}>
      <div className={'tw-flex tw-flex-col tw-gap-4'}>
        <h3 className="tw-text-2xl tw-font-semibold">
          {t('pages/shared:revisions.outcome_info', 'Outcome info')}
        </h3>
        <Card>
          <CardContent className={'tw-px-6 tw-py-0 md:tw-py-6'}>
            <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
              <div className={'tw-col-span-1 tw-mb-0 tw-text-lg tw-font-bold tw-text-text-strong'}>
                {t('attributes/request:title', {
                  defaultValue: 'Request name',
                })}
              </div>
              <div className={'tw-col-span-2 tw-break-words'}>
                {title ?? t('common:unset', { defaultValue: '-' })}
              </div>
            </CardContentRow>
            <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
              <div className={'tw-col-span-1 tw-mb-0 tw-text-lg tw-font-bold tw-text-text-strong'}>
                {t('attributes/request:status', {
                  defaultValue: 'Status',
                })}
              </div>
              <div className={'tw-col-span-2'}>
                <RequestStatusBadge variant={status}>
                  {t(`models/request:status.${status}`, {
                    defaultValue: t('common:unknown', {
                      defaultValue: 'Unknown',
                    }),
                  })}
                </RequestStatusBadge>
              </div>
            </CardContentRow>
            <CardContentRow
              className={'tw-min-w-0 tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}
            >
              <div className={'tw-mb-0 tw-text-lg tw-font-bold tw-text-text-strong'}>
                {t('attributes/request:type_2', {
                  defaultValue: 'Video type',
                })}
              </div>
              <div className={'tw-col-span-2 tw-break-words'}>
                {t(`models/request:type.${type}`, {
                  defaultValue: t('common:unknown', {
                    defaultValue: 'Unknown',
                  }),
                })}
              </div>
            </CardContentRow>
            <CardContentRow
              className={'tw-min-w-0 tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}
            >
              <div className={'tw-mb-0 tw-text-lg tw-font-bold tw-text-text-strong'}>
                {t('attributes/request:total_length', {
                  defaultValue: 'Total length',
                })}
              </div>
              <div className={'tw-col-span-2 tw-break-words'}>
                {totalLengthToString(total_length) || t('common:unset', { defaultValue: '-' })}
              </div>
            </CardContentRow>
            <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
              <div
                className={'tw-mb-0 tw-align-middle tw-text-lg tw-font-bold tw-text-text-strong'}
              >
                {t('common:description', {
                  defaultValue: 'Description',
                })}
              </div>
              <div
                className={'tw-col-span-2 tw-whitespace-pre-line tw-break-words'}
                dangerouslySetInnerHTML={{
                  __html: Str.wrapHrefs(
                    dompurify.sanitize(
                      description?.video_request_description ??
                        t('common:unset', {
                          defaultValue: '-',
                        })!,
                    ),
                  ),
                }}
              />
            </CardContentRow>
            <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
              <div className={'tw-mb-0 tw-text-lg tw-font-bold tw-text-text-strong'}>
                {t('attributes/request:editor_note', {
                  defaultValue: "Editor's note",
                })}
              </div>
              <div className={'tw-col-span-2 tw-break-words'}>
                {editor_note ?? t('common:unset', { defaultValue: '-' })}
              </div>
            </CardContentRow>
          </CardContent>
        </Card>
      </div>

      <div className={'tw-flex tw-flex-col tw-gap-4'}>
        <h3 className="tw-text-2xl tw-font-semibold">
          {t('revisions.order.notes.section_title', { ns: 'pages/shared' })}
        </h3>

        <Card>
          <OrderNotes />
        </Card>
      </div>

      <div className={'tw-flex tw-flex-col tw-gap-4'}>
        <h3 className="tw-text-2xl tw-font-semibold">
          {t('pages/shared:revisions.activity', 'Activity')}
        </h3>

        <Card>
          <CardContent className={'tw-p-4 md:tw-p-6'}>
            <ScrollArea>
              <div className="tw-max-h-96">
                <RequestActivityList requestId={id} />
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const CardContentRow = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          'tw-flex tw-h-auto tw-flex-col tw-font-medium md:tw-grid md:tw-grid-cols-3 md:tw-items-center',
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

export { RevisionOutcomeContentBottom };
