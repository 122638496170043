import React from 'react';
import { ImageMedia } from './ImageMedia';
import { VideoMedia } from './VideoMedia';
import FileThumbnail from '../FileThumbnail';
import { MediaObject } from 'src/api/services/MediaClient';
import { SVGMedia } from './SVGMedia';
import { AudioMedia } from './AudioMedia';
import { If } from 'src/components/If';
import { BxMusicIcon } from 'src/components/Boxicons';
import clsx from 'clsx';
import { decorateMedia } from 'src/features/media';

type Props = {
  media: MediaObject;
  playable?: boolean;
  renderOriginal?: boolean;
} & Omit<React.ComponentPropsWithoutRef<'img' | 'video'>, 'src' | 'poster' | 'controls'>;

// TODO: broken images/videos
const MediaViewer: React.FC<Props> = ({
  media,
  playable = false,
  renderOriginal = false,
  ...props
}) => {
  const file = decorateMedia(media);

  const canRenderVideoAsImage = !!(file?.isVideo && file?.hasThumbnail);

  const shouldRenderImage = file?.isImage || (canRenderVideoAsImage && !playable);
  const shouldRenderSvg = file?.isSvg && (renderOriginal || !file?.hasThumbnail);
  const shouldRenderVideo = file?.isVideo && (playable || !canRenderVideoAsImage);
  const shouldRenderAudio = file?.isAudio && file?.canPlay;

  const imageSrc = renderOriginal ? file?.index_url : file?.thumbnail ?? file?.index_url;

  const videoPosterSrc = file?.poster ?? file?.thumbnail ?? file?.index_url;

  return (
    <If
      when={!file?.isUnknown}
      else={
        <FileThumbnail
          fileName={file?.file_name ?? ''}
          className={clsx('w-100 h-100', props.className)}
        />
      }
    >
      <If when={shouldRenderImage}>
        <If when={shouldRenderSvg} else={<ImageMedia src={imageSrc ?? ''} {...props} />}>
          <SVGMedia src={imageSrc ?? ''} {...(props as any)} />
        </If>
      </If>

      <If when={shouldRenderVideo}>
        <VideoMedia
          src={file?.index_url ?? ''}
          mime_type={file?.mime_type}
          poster={videoPosterSrc}
          controls={playable}
          {...(props as any)}
        />
      </If>

      <If when={shouldRenderAudio}>
        <AudioMedia
          wrapperProps={{
            className: clsx('tw-flex tw-w-full tw-flex-col', props.className),
          }}
          src={file?.index_url ?? ''}
          poster={
            <div
              className={
                'align-self-center align-items-center justify-content-center tw-flex tw-w-full tw-grow'
              }
            >
              <BxMusicIcon className={'fs-4'} />
            </div>
          }
          controls={playable}
        />
      </If>
    </If>
  );
};

export type { Props as MediaViewerProps };
export { MediaViewer };
