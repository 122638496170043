import React from 'react';
import { components, MultiValueProps } from 'react-select';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { GroupBase } from 'react-select/dist/declarations/src/types';

type Props<T extends Record<string, any>> = MultiValueProps<
  OptionObject<T>,
  boolean,
  GroupBase<OptionObject<T>>
>;

const ReactSelectMultiValue = <T extends Record<string, any>>({
  children,
  ...props
}: Props<T>): JSX.Element | null => {
  return props.index === 0 ? (
    <components.MultiValue {...props}>{children}</components.MultiValue>
  ) : null;
};

export { ReactSelectMultiValue };
