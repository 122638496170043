import { UserType } from "src/lib/services/api/profile-api";

const jwtStorageKey = 'jwt-becreatives';

type JwtStorageKey = typeof jwtStorageKey;

interface JwtInterface {
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  prv: string;
  sub: string;
  user_type: UserType;
  impersonator: string | null;
  user_id: string;
}

type AuthJwtObject = {
  token: string;
  expires: number;
};

export type { JwtStorageKey, JwtInterface, AuthJwtObject };
export { jwtStorageKey };
