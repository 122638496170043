import React from 'react';
import {
  StyledTeamColorFieldGroup,
  StyledTeamColorFieldLabel,
  StyledTeamColorsGroup,
} from './TeamColorFieldInput.styled';
import { colors } from './TeamForm.styled';
import { TeamColorFieldInput } from './TeamColorFieldInput';
import { ErrorMessage } from 'src/components/Form/ErrorMessage';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>> & {
  name: string;
};

const TeamColorsFieldGroup: React.FC<Props> = ({ name, ...props }) => {
  return (
    <StyledTeamColorFieldGroup {...props}>
      <StyledTeamColorFieldLabel className={'required'}>Color</StyledTeamColorFieldLabel>
      <StyledTeamColorsGroup>
        {colors.map((color) => (
          <TeamColorFieldInput key={color} name={name} color={color} />
        ))}
      </StyledTeamColorsGroup>

      <ErrorMessage name={name} />
    </StyledTeamColorFieldGroup>
  );
};

export type { Props as TeamColorsFieldGroupProps };
export { TeamColorsFieldGroup };
