import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UseQueryOptionsObject } from './queries.utils';
import { QueryFunctionContext } from '@tanstack/query-core/src/types';

const useMakeQuery = <Data, SetData = Partial<Data>>(
  queryKey: any[],
  promise: (context: QueryFunctionContext<readonly any[]>) => Promise<Data>,
  options?: UseQueryOptionsObject<Data>,
) => {
  const client = useQueryClient();
  const query = useQuery<Data, any>(queryKey, promise, options);
  return {
    ...query,
    queryKey,
    queryFn: promise,
    fetch: () => client.fetchQuery<Data>({ queryKey, queryFn: promise }),
    invalidate: () => client.invalidateQueries(queryKey),
    setQueryData: (data: SetData | ((prev: Data) => Data)) =>
      client.setQueryData<Data>(queryKey, (prev) => {
        if (data instanceof Function) {
          return data(prev!);
        }

        return { ...prev!, ...data };
      }),
  };
};

// eslint-disable-next-line
type UseMakeQueryReturnType<Response> = ReturnType<typeof useMakeQuery<Response>>;

export type { UseMakeQueryReturnType };
export { useMakeQuery };
