import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { ChevronDown } from 'lucide-react';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { GroupBase } from 'react-select/dist/declarations/src/types';

type Props<T extends Record<string, any>> = DropdownIndicatorProps<
  OptionObject<T>,
  boolean,
  GroupBase<OptionObject<T>>
>;

const ReactSelectDropdownIndicator = <T extends Record<string, any>>(
  props: Props<T>,
): JSX.Element => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown className={'tw-h-4 tw-w-4'} />
    </components.DropdownIndicator>
  );
};

export { ReactSelectDropdownIndicator };
