import { hasJsonStructure } from './has-json-structure';
import { default as objHas } from 'lodash/has';

const toObject = <T extends Record<string, unknown>>(query: string): Partial<T> => {
  if (query.indexOf('?') === 0) {
    query = query.substring(1);
  }

  const searchParams = query.split('&');

  const result: Partial<T> = {};

  for (const param of searchParams) {
    const [key, value] = param.split('=') as [keyof T, string];
    if (key && value) {
      const decoded = decodeURIComponent(value);
      if (hasJsonStructure(decoded)) {
        result[key] = JSON.parse(decoded);
      } else {
        result[key] = decoded;
      }
    }
  }

  return result;
};

const has = (key: string, query: string): boolean => {
  const obj = toObject(query);

  return objHas(obj, key) !== undefined;
};

const insertArguments = (url: string, args: { [key: string]: string }): string => {
  for (const key in args) {
    const value = args[key];
    url = url.replace('{' + key + '}', value);
  }

  return url;
};

const url = {
  toObject,
  has,
  insertArguments,
} as const;

export { url };
