import styled from 'styled-components';
import { breakpointUp, Button, customColors } from '@becreatives/becreatives-ui';

const StyledSearchInput = styled.input`
  max-width: 100%;

  ${breakpointUp.md} {
    max-width: 33%;
  }
`;

const StyledTotalMembersButton = styled(Button)`
  color: ${({ theme }) => theme.color.white};
  background-color: ${customColors.mariner};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { StyledSearchInput, StyledTotalMembersButton };
