import { Client, PaginatedResponse } from 'src/api/Client';
import {
  TeamMemberResponse,
  CreateTeamMemberRequest,
  UpdateTeamMemberRequest,
  AvailableUserResponse,
} from './TeamMemberClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class TeamMemberClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/admin/team-members/{model}/filter`,
    filterUsers: `${this.http.baseUrl}/admin/team-members/{model}/filter-users`,
    create: `${this.http.baseUrl}/admin/team-members/{model}`,
    view: `${this.http.baseUrl}/admin/team-members/{model}`,
    update: `${this.http.baseUrl}/admin/team-members/{model}`,
    delete: `${this.http.baseUrl}/admin/team-members/{model}`,
  };

  filter = (
    model: string,
    params?: ApiQueryObject,
  ): Promise<PaginatedResponse<TeamMemberResponse>> =>
    this.http.get(this.buildUrl(this.route.filter, { model }), { params });

  filterUsers = (
    model: string,
    params?: ApiQueryObject,
  ): Promise<PaginatedResponse<AvailableUserResponse>> =>
    this.http.get(this.buildUrl(this.route.filterUsers, { model }), { params });

  create = (model: string, data: CreateTeamMemberRequest): Promise<TeamMemberResponse> =>
    this.http.post(this.buildUrl(this.route.create, { model }), data);

  view = (model: string): Promise<TeamMemberResponse> =>
    this.http.get(this.buildUrl(this.route.view, { model }));

  update = (model: string, data: UpdateTeamMemberRequest): Promise<TeamMemberResponse> =>
    this.http.put(this.buildUrl(this.route.update, { model }), data);

  delete = (model: string): Promise<void> =>
    this.http.delete(this.buildUrl(this.route.delete, { model }));
}

export { TeamMemberClient };
