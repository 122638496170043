import { CSSProperties } from 'react';
import { CssHelper } from '../CssHelper';
import { userType, UserType } from 'src/lib/services/api/profile-api';

export default class UserTypeColor extends CssHelper {
  private static readonly map: { [key in UserType]: CSSProperties } = {
    [userType.admin]: {
      color: UserTypeColor.primary,
      backgroundColor: UserTypeColor.primary + UserTypeColor.opacity['3'],
    },
    [userType.customer]: {
      color: UserTypeColor.blue,
      backgroundColor: UserTypeColor.blue + UserTypeColor.opacity['1'],
    },
    [userType.editor]: {
      color: UserTypeColor.primary,
      backgroundColor: UserTypeColor.primary + UserTypeColor.opacity['1'],
    },
    [userType.teamLead]: {
      color: UserTypeColor.indigo,
      backgroundColor: UserTypeColor.indigo + UserTypeColor.opacity['3'],
    },
  };

  public static readonly getColorProperties = (role: UserType): CSSProperties => {
    return UserTypeColor.map[role];
  };
}
