import { create } from 'zustand';
import { AuthUtils } from 'src/services/Auth/Auth.utils';

interface GlobalLoadingStoreObject {
  isGlobalLoading: boolean;
  setGlobalLoading: (isGlobalLoading: boolean) => void;
}

const useGlobalLoading = create<GlobalLoadingStoreObject>((set) => ({
  isGlobalLoading: !AuthUtils.isExpired(),
  setGlobalLoading: (isGlobalLoading) => set(() => ({ isGlobalLoading })),
}));

export { useGlobalLoading };
