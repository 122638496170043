import { useVideoPlayerV2 } from 'src/features/video-player/video-player';
import { system } from 'src/lib/utils';

type Parameters = {
  onFullscreenChange?: (fullscreen: boolean) => void;
};

export const useFullscreenToggle = ({ onFullscreenChange }: Parameters = {}) => {
  const { fullscreen, setFullscreen, containerRef, getInternalPlayer } = useVideoPlayerV2();
  const videoRef = getInternalPlayer();

  const isFullscreen = () => {
    if (system.isIOS()) {
      if (videoRef && 'webkitDisplayingFullscreen' in videoRef) {
        return (videoRef as any).webkitDisplayingFullscreen;
      }

      return false;
    }

    return !!document.fullscreenElement;
  };

  const requestFullscreen = async () => {
    if (containerRef?.current && 'requestFullscreen' in containerRef.current) {
      await containerRef.current.requestFullscreen();
      setFullscreen(true);
      onFullscreenChange?.(true);
      return;
    }

    // iOS
    if (
      videoRef &&
      'webkitSupportsFullscreen' in videoRef &&
      videoRef.webkitSupportsFullscreen &&
      'webkitEnterFullscreen' in videoRef
    ) {
      (videoRef as any).webkitEnterFullscreen();
    }
  };

  const exitFullscreen = async () => {
    if ('exitFullscreen' in document) {
      await document.exitFullscreen();
      setFullscreen(false);
      onFullscreenChange?.(false);
      return;
    }

    // iOS
    if (videoRef && 'webkitExitFullscreen' in videoRef) {
      (videoRef as any).webkitExitFullscreen();
    }
  };

  const toggleFullscreen = async () => {
    try {
      isFullscreen() ? exitFullscreen() : requestFullscreen();
    } catch (e) {
      console.error('Failed to toggle fullscreen', e);
    }
  };

  return {
    fullscreen,
    setFullscreen,
    toggleFullscreen,
    isFullscreen,
    requestFullscreen,
    exitFullscreen,
  };
};
