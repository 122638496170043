import React, { DOMAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiQueryParams } from './use-api-query-params';

type Props<T extends Partial<Record<'onClick', DOMAttributes<Element>['onClick']>>> = {
  groupId?: string;
  component: React.ComponentType<T>;
  children?: React.ReactNode;
} & T;

const ApiQueryParamsResetComponent = <
  T extends Partial<Record<'onClick', DOMAttributes<Element>['onClick']>>,
>({
  component: Component,
  onClick,
  groupId,
  children,
  ...props
}: Props<T>) => {
  const { t } = useTranslation('actions');

  const { reset } = useApiQueryParams(groupId);

  return (
    <Component
      {...(props as unknown as T)}
      onClick={(e) => {
        reset();
        onClick?.(e);
      }}
    >
      {children || t('reset_filters')}
    </Component>
  );
};

export type { Props as ApiQueryParamsResetComponentProps };
export { ApiQueryParamsResetComponent };
