import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { components, GroupBase, OptionProps } from 'react-select';
import { User2 } from 'lucide-react';
import { OptionObject } from 'src/components/ReactSelectInput';
import { str } from 'src/lib/utils';

const EditorSelectOption = <T extends Record<string, any>>({
  ...props
}: OptionProps<OptionObject<T>, boolean, GroupBase<OptionObject<T>>>): JSX.Element => {
  const data = props.data;

  return (
    <components.Option {...props}>
      <div className={'tw-flex tw-items-center tw-gap-2'}>
        <Avatar size={'sm'}>
          {!data.value ? (
            <AvatarFallback className={'tw-bg-neutral-400 tw-text-white'}>
              <User2 size={20} />
            </AvatarFallback>
          ) : (
            <>
              <AvatarImage
                className={'tw-object-cover'}
                src={data.avatar?.generated_conversions.square_200 ?? data.avatar?.index_url}
                alt={str.acronym(data.label as string)}
              />
              <AvatarFallback className={'tw-bg-orange tw-text-white'}>
                {str.acronym(data.label as string)}
              </AvatarFallback>
            </>
          )}
        </Avatar>
        {data.label}
        {data.description}
      </div>
    </components.Option>
  );
};

export { EditorSelectOption };
