import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiQueryParams } from './use-api-query-params';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'input'>> & {
  groupId?: string;
};

const ApiQuerySearchInput: React.FC<Props> = ({ groupId, onChange, ...props }) => {
  const { t } = useTranslation('actions');

  const { setSearch, filters } = useApiQueryParams(groupId);

  const search = filters.search;

  const [value, setValue] = useState(search);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setValue(event.target.value);
    onChange?.(event);
  };

  useEffect(() => {
    setValue(search ?? '');
  }, [search]);

  return <input {...props} onChange={handleSearch} value={value} placeholder={t('search')!} />;
};

export { ApiQuerySearchInput };
