const download = (url: string) => {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = url;
  anchor.target = '_blank';
  anchor.rel = 'noopener';

  document.body.append(anchor);

  anchor.click();

  setTimeout(() => {
    anchor.remove();
  }, 1000);
};

export { download };
