import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'src/components/ui/dialog';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { authQueries } from 'src/api/queries';
import { requestClient, RequestTranscriptResponse } from 'src/lib/services/api/request-api';
import { CallableChildren, render } from 'src/lib/utils';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary, useMutation, useQueryClient } from '@tanstack/react-query';
import { SpaceErrorFallback } from 'src/features/fallback';
import { Skeleton } from 'src/components/ui/skeleton';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { UploadHookFormInput } from 'src/components/Form/hook-form/hook-form-dropzone-input/upload-hook-form-input';
import { Button } from 'src/components/ui/button';
import { ErrorMessage } from 'src/components/Form/hook-form/error-message';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loader2 } from 'src/components/ui/animated-loader2';
import { Alert, AlertDescription } from 'src/components/ui/alert';
import { useToast } from 'src/components/ui/use-toast';

const RequestTranscriptQueryContextProvider: React.FC<{
  requestId: string;
  children: CallableChildren<ReturnType<typeof useMakeQuery<RequestTranscriptResponse | null>>>;
}> = (props) => {
  const query = useMakeQuery(
    [authQueries.request, props.requestId, 'transcript'],
    ({ signal }) =>
      requestClient.transcript(props.requestId, {
        signal,
      }),
    {
      suspense: true,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  );

  return <>{render(props.children, query)}</>;
};

type FormValues = {
  transcript?: {
    id: string;
    filePreviewUrl: string;
  }[];
};

const useAttachRequestTranscriptMutation = () => {
  return useMutation({
    mutationFn: (args: Parameters<typeof requestClient.attachTranscript>) =>
      requestClient.attachTranscript(...args),
  });
};

const AttachRequestTranscriptFormDialogContent: React.FC<{
  initialValues: FormValues;
  onSubmit?: (values: FormValues) => void;
  requestId: string;
}> = ({ initialValues, requestId, onSubmit }) => {
  const { t } = useTranslation();
  const attachTranscriptMutation = useAttachRequestTranscriptMutation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        transcript: yup
          .array()
          .min(1, t('Transcript is required')!)
          .required(t('Transcript is required')!),
      }),
    [],
  );

  const form = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema) as any,
  });

  const submitHandler: SubmitHandler<FormValues> = async (values) => {
    try {
      await attachTranscriptMutation.mutateAsync([requestId, (values.transcript as any)[0].id], {
        onSuccess: () => onSubmit?.(values),
      });
    } catch (e) {}
  };

  return (
    <FormProvider {...form}>
      <form className={'tw-flex tw-flex-col tw-gap-4'} onSubmit={form.handleSubmit(submitHandler)}>
        <div>
          <UploadHookFormInput
            disabled={form.formState.isSubmitting}
            name={'transcript'}
            model={'request_revision'}
            multiple={false}
            mediaContainerProps={{ className: '!tw-flex [&>*]:tw-min-w-64 tw-justify-center' }}
          />
          <ErrorMessage name={'transcript'} />
        </div>

        <div className={'tw-flex tw-flex-col tw-gap-4'}>
          {attachTranscriptMutation.isError && (
            <Alert variant={'destructive'}>
              <AlertDescription>{(attachTranscriptMutation.error as any).message}</AlertDescription>
            </Alert>
          )}

          <Button
            disabled={form.formState.isSubmitting}
            type={'submit'}
            className={'tw-min-w-32 tw-self-end'}
          >
            {t('Save')}
            {form.formState.isSubmitting && <Loader2 className={'tw-ms-1'} variant={'secondary'} />}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

type AttachRequestTranscriptFormDialogProps = React.ComponentPropsWithoutRef<typeof Dialog> & {
  requestId: string;
  onSubmit?: () => void;
};

const AttachRequestTranscriptFormDialog: React.FC<AttachRequestTranscriptFormDialogProps> = ({
  children,
  requestId,
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const client = useQueryClient();
  const [open, setOpen] = useState(props.open);
  const handleOpen = (open: boolean) => {
    props.onOpenChange?.(open);
    setOpen((prev) => !prev);
  };

  return (
    <Dialog {...props} open={props.open || open} onOpenChange={handleOpen}>
      {children}

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Attach transcript')}</DialogTitle>
        </DialogHeader>

        <div>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }: any) => (
                  <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
                )}
                onReset={reset}
              >
                <React.Suspense fallback={<Skeleton className={'tw-h-64 tw-w-full'} />}>
                  <RequestTranscriptQueryContextProvider requestId={requestId}>
                    {({ data, refetch }) => (
                      <AttachRequestTranscriptFormDialogContent
                        initialValues={{
                          transcript: !data
                            ? []
                            : [
                                {
                                  ...data.media,
                                  filePreviewUrl: data.media.index_url,
                                },
                              ],
                        }}
                        requestId={requestId}
                        onSubmit={async () => {
                          await refetch();
                          await client.invalidateQueries({
                            queryKey: [authQueries.request, 'legacy', requestId],
                          });
                          await client.refetchQueries({
                            queryKey: [authQueries.request, requestId],
                          });
                          toast({
                            description: t('Transcript attached successfully'),
                          });
                          handleOpen(false);
                        }}
                      />
                    )}
                  </RequestTranscriptQueryContextProvider>
                </React.Suspense>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { AttachRequestTranscriptFormDialog };
