import React, { useState } from 'react';
import { Text } from '@becreatives/becreatives-ui';
import {
  BxClipboardIcon,
  BxCloseIcon,
  BxLoaderCircleIcon,
  BxShareIcon,
} from 'src/components/Boxicons';
import { useToggle } from 'src/helpers/useToggle';
import { Api } from 'src/api';
import { If } from 'src/components/If';
import { Modal, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/ui/use-toast';

type Props = { mediaId: string } & React.ComponentPropsWithoutRef<'i'>;

const ShareMediaButton: React.FC<Props> = ({ mediaId, onClick, className, ...props }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [loading, setLoading] = useToggle();
  const [isOpen, setOpen, toggle] = useToggle();
  const [urls, setUrls] = useState<Record<string, string>>({});

  return (
    <>
      <If
        when={!loading}
        else={
          <BxLoaderCircleIcon {...props} className={!className ? 'spin' : `${className} spin`} />
        }
      >
        <BxShareIcon
          className={className}
          onClick={async (e) => {
            if (mediaId in urls) {
              setOpen(true);
              return;
            }

            setLoading(true);

            await Api.media
              .share(mediaId)
              .then((url) => {
                setUrls((prev) => ({ ...prev, [mediaId]: url }));
                navigator.clipboard
                  .writeText(url)
                  .then(() =>
                    toast({
                      description: t('Sharing url was copied to clipboard!'),
                      variant: 'info',
                    }),
                  )
                  .catch(() => ({}));
              })
              .then(toggle)
              .then(() => onClick?.(e))
              .catch(() => ({}))
              .finally(() => setLoading(false));
          }}
          {...props}
        />
      </If>
      <If when={mediaId in urls}>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
          <ModalHeader
            toggle={toggle}
            tag={'div'}
            close={
              <>
                <BxClipboardIcon
                  title={'Copy'}
                  className={'fs-5 c-pointer'}
                  onClick={async () => {
                    await navigator.clipboard.writeText(urls[mediaId]);
                    toggle();
                  }}
                />
                <BxCloseIcon title={'Close'} className={'fs-3 c-pointer ms-3'} onClick={toggle} />
              </>
            }
          >
            <Text tag={'code'}>{urls[mediaId]}</Text>
          </ModalHeader>
        </Modal>
      </If>
    </>
  );
};

export type { Props as ShareMediaButtonProps };
export { ShareMediaButton };
