import React from 'react';
import { OverridenBadge } from 'src/components/Badge';
import { ClockIcon } from '@becreatives/becreatives-ui';
import { Field } from 'formik';
import { formatTime } from 'src/features/video-player/video-player-utils';
import { useVideoProgressState } from 'src/features/video-player/use-video-progress-store';

type ElapsedTimeBadgeProps = React.ComponentProps<typeof OverridenBadge> & {
  formattingOptions?: Parameters<typeof formatTime>[1];
};

export const ElapsedTimeBadge: React.FC<ElapsedTimeBadgeProps> = ({
  formattingOptions,
  ...props
}) => {
  const { playedSeconds } = useVideoProgressState();

  const formattedElapsed = formatTime(playedSeconds, formattingOptions);

  return (
    <OverridenBadge {...props}>
      <ClockIcon color={'#FFFFFF'} size={'lg'} />
      {formattedElapsed}
      <Field name={'include_time'} type={'checkbox'} className={'form-check-input m-0 pointer'} />
    </OverridenBadge>
  );
};
