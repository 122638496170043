export class CssHelper {
  protected static readonly primary: string = '#FF5A3C';
  protected static readonly blue: string = '#5B73E8';
  protected static readonly light: string = '#F5F6F8';
  protected static readonly indigo: string = '#a370f7';

  // .1 - 1
  protected static readonly opacity = {
    1: '1A',
    2: '33',
    3: '4D',
    4: '66',
    5: '80',
    6: '99',
    7: 'B3',
    8: 'CC',
    9: 'E6',
    10: 'FF',
  };
}
