import React, { useMemo } from 'react';
import { Card, CardContent } from 'src/components/ui/card';
import { Trash2 } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { If } from 'src/components/If';
import { Progress } from 'src/components/ui/progress';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';
import { video } from 'src/features/media';
import { FileIcon } from 'src/components/FileIcon';
import { getExtension, isImageMime, isVideoMime } from 'src/helpers/file';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

type Props = {
  name: string;
  mediaObject: {
    id: string;
    file_name: string;
    mime_type: string;
    filePreviewUrl?: string;
  };
  onDelete: () => void;
};

const FormMediaCard: React.FC<Props> = ({ name, mediaObject, onDelete }) => {
  const { uploadDetails, uploadStatus } = useMediaUploadStore(
    useShallow((state) => ({
      uploadDetails: state.details[mediaObject.id],
      uploadStatus: state.status[mediaObject.id],
    })),
  );

  const { t } = useTranslation();

  const { shouldRenderVideoTag, shouldRenderImageTag } = useMemo(() => {
    const extension = getExtension(mediaObject.file_name);
    const isVideo = isVideoMime(mediaObject.mime_type) || video.hasExtension(extension);
    const isImage = isImageMime(mediaObject.mime_type);

    const shouldRenderVideoTag = isVideo && mediaObject.filePreviewUrl?.startsWith('blob:');
    const shouldRenderImageTag =
      (isImage && !!mediaObject.filePreviewUrl) ||
      (isVideo && !shouldRenderVideoTag && !!mediaObject.filePreviewUrl);

    return { shouldRenderVideoTag, shouldRenderImageTag };
  }, [mediaObject]);

  const remainingTime = uploadDetails?.remainingTime ?? 0;
  const minutesLeft = Math.floor(remainingTime / 60);
  const secondsLeft = Math.floor(remainingTime % 60);

  const uploadSpeed = uploadDetails?.uploadSpeed ?? 0;
  const speedMBs = uploadSpeed / 1024 / 1024;

  return (
    <Card
      className={cn('tw-flex tw-h-[288px] tw-min-w-[150px] tw-max-w-full md:tw-max-w-[368px]', {
        'validation-error tw-border tw-border-orange': uploadStatus?.failed,
      })}
    >
      <CardContent
        className={cn('tw-w-full tw-rounded-b-lg tw-rounded-t-lg tw-p-0', {
          'tw-bg-orange-100': uploadStatus?.failed,
        })}
      >
        <div className={'tw-relative tw-h-[152px]'}>
          <div
            className={cn(
              'tw-absolute tw-z-10 tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-rounded-t-md tw-bg-text-strong tw-bg-opacity-70 tw-font-semibold tw-text-white tw-transition-opacity',
              { 'tw-opacity-0': !uploadStatus?.isUploading },
            )}
          >
            <If when={uploadStatus?.isUploading}>
              <div>Uploading</div>
              <div>{speedMBs.toFixed(2)} MB/s</div>
              <div>{`${minutesLeft} minutes ${secondsLeft} seconds left`}</div>
            </If>
          </div>
          <If when={shouldRenderVideoTag}>
            <video
              src={mediaObject.filePreviewUrl}
              controls={false}
              className={'tw-h-full tw-w-full tw-rounded-t-md tw-object-cover'}
            />
          </If>
          <If when={shouldRenderImageTag}>
            <img
              src={mediaObject.filePreviewUrl}
              alt={'media'}
              className={'tw-h-full tw-w-full tw-rounded-t-md tw-object-cover'}
            />
          </If>
          <If when={!shouldRenderVideoTag && !shouldRenderImageTag}>
            <div
              className={
                'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-t-md tw-bg-indigo-200'
              }
            >
              <FileIcon
                mimeType={mediaObject.mime_type}
                size={36}
                className={'tw-text-text-strong'}
              />
            </div>
          </If>
        </div>

        <If when={uploadStatus?.isUploading}>
          <Progress value={uploadDetails?.progress} className={'tw-h-1 tw-rounded-none'} />
        </If>

        <div
          className={cn('tw-flex tw-flex-col tw-justify-between tw-p-3 tw-text-text', {
            'tw-text-orange-900': uploadStatus?.failed,
          })}
        >
          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    className={
                      'tw-line-clamp-2 tw-h-10 tw-break-all tw-font-bold tw-leading-normal'
                    }
                  >
                    {mediaObject.file_name}
                  </div>
                </TooltipTrigger>
                <TooltipContent>{mediaObject.file_name}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <div className={'tw-mt-2 tw-line-clamp-1'}>{mediaObject.mime_type}</div>
          </div>
          <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2'}>
            <div>
              <If when={uploadStatus?.failed}>
                <span className={'tw-font-bold tw-text-orange-900'}>Upload failed</span>
              </If>
            </div>
            <div className={'tw-flex tw-gap-2'}>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Button
                      type={'button'}
                      variant={'destructiveLight'}
                      size={'icon'}
                      onClick={onDelete}
                    >
                      <Trash2 size={20} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('Delete')}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export { FormMediaCard };
