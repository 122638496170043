import styled from 'styled-components';

const StyledTeamColorFieldGroup = styled.div`
  flex-basis: 100%;
`;

const StyledTeamColorFieldLabel = styled.label`
  width: 100%;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const StyledTeamColorsGroup = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StyledTeamColorFieldInput = styled.span<{ color: string; $selected: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;

  ${({ $selected, color }) => $selected && `box-shadow: 0px 0px 5px 2px ${color};`}
`;

export {
  StyledTeamColorFieldGroup,
  StyledTeamColorFieldLabel,
  StyledTeamColorsGroup,
  StyledTeamColorFieldInput,
};
