import React from 'react';
import { VideoRoundedCover } from 'src/components/Generic';

type Props = {
  src: string;
  mime_type: string;
} & React.ComponentPropsWithoutRef<'video'>;

const VideoMedia: React.FC<Props> = ({ src, mime_type, ...props }) => {
  return (
    <VideoRoundedCover autoPlay={false} {...props}>
      <source src={src} type={mime_type} />
      {"Seems like your browser doesn't support HTML video."}
    </VideoRoundedCover>
  );
};

export { VideoMedia };
