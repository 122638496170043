import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { reactQueryClient } from './reactQueryClient';

type Props = React.PropsWithChildren;

const ReactQueryClientProvider: React.FC<Props> = (props) => {
  return <QueryClientProvider client={reactQueryClient}>{props.children}</QueryClientProvider>;
};

export type { Props as ReactQueryClientProviderProps };
export default ReactQueryClientProvider;
