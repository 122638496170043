import { RequestType, requestType, TotalLengthResponse } from 'src/lib/services/api/request-api';
import { SelectInputOption } from 'src/components/Form/SelectInput';
import { getObjectKeys } from 'src/helpers/getObjectKeys';

export const totalLengthToString = (totalLength?: TotalLengthResponse) => {
  if (!totalLength || !totalLength.value || !totalLength.unit) {
    return undefined;
  }

  return `up to ${totalLength.value} ${totalLength.unit}`;
};

const buildSelectOption = (label: string): SelectInputOption => ({
  label,
  value: label,
});
const buildLength = (time: number, units: string): string => `up to ${time} ${units}`;
const possibleLengths = {
  s10: buildSelectOption(buildLength(10, 's')),
  s15: buildSelectOption(buildLength(15, 's')),
  s30: buildSelectOption(buildLength(30, 's')),
  s60: buildSelectOption(buildLength(60, 's')),
  min3: buildSelectOption(buildLength(3, 'min')),
  min5: buildSelectOption(buildLength(5, 'min')),
  min10: buildSelectOption(buildLength(10, 'min')),
  min15: buildSelectOption(buildLength(15, 'min')),
  min20: buildSelectOption(buildLength(20, 'min')),
};

type DescriptionTotalLengthType = {
  attributes: {
    options: (typeof possibleLengths)[keyof typeof possibleLengths][];
  };
  types: RequestType[];
}[];

export const descriptionTotalLength: DescriptionTotalLengthType = [
  {
    attributes: {
      options: [possibleLengths.s10, possibleLengths.s15, possibleLengths.s30, possibleLengths.s60],
    },
    types: [requestType.ad],
  },
  {
    attributes: {
      options: [
        possibleLengths.s30,
        possibleLengths.s60,
        possibleLengths.min3,
        possibleLengths.min5,
        possibleLengths.min10,
      ],
    },
    types: [requestType.talkingHead],
  },
  {
    attributes: {
      options: [
        possibleLengths.min5,
        possibleLengths.min10,
        possibleLengths.min15,
        possibleLengths.min20,
      ],
    },
    types: [
      requestType.youtubeVideo,
      requestType.podcast,
      requestType.vlog,
      requestType.courseVideo,
      requestType.tutorial,
      requestType.motionGraphics,
    ],
  },
  {
    attributes: {
      options: [
        possibleLengths.s10,
        possibleLengths.s15,
        possibleLengths.s30,
        possibleLengths.s60,
        possibleLengths.min3,
        possibleLengths.min5,
      ],
    },
    types: [requestType.reel, requestType.tiktok, requestType.youtubeShorts, requestType.story],
  },
  {
    attributes: {
      options: [
        possibleLengths.s10,
        possibleLengths.s15,
        possibleLengths.s30,
        possibleLengths.s60,
        possibleLengths.min3,
        possibleLengths.min5,
      ],
    },
    types: [requestType.other],
  },
];

const lengthKeyToValue = {
  s10: {
    value: 10,
    unit: 'sec',
  },
  s15: {
    value: 15,
    unit: 'sec',
  },
  s30: {
    value: 30,
    unit: 'sec',
  },
  s60: {
    value: 60,
    unit: 'sec',
  },
  min3: {
    value: 3,
    unit: 'min',
  },
  min5: {
    value: 5,
    unit: 'min',
  },
  min10: {
    value: 10,
    unit: 'min',
  },
  min15: {
    value: 15,
    unit: 'min',
  },
  min20: {
    value: 20,
    unit: 'min',
  },
};

export const lengthStringToObject = (value: string) => {
  const key = getObjectKeys(possibleLengths).find((key) => possibleLengths[key].value === value);

  if (key && key in lengthKeyToValue) {
    return lengthKeyToValue[key];
  }

  return undefined;
};

export const lengthObjectToString = (value?: TotalLengthResponse) => {
  if (!value) {
    return undefined;
  }

  const key = getObjectKeys(lengthKeyToValue).find(
    (key) =>
      lengthKeyToValue[key].value === value.value && lengthKeyToValue[key].unit === value.unit,
  );

  if (!key) {
    return undefined;
  }

  return possibleLengths[key].value;
};
