import { useTranslation } from 'react-i18next';
import * as utils from './helpers';

const useFormatRelativeTime = () => {
  const { t } = useTranslation('dates');

  return (date: Date | string | number) => {
    const currentDate = new Date();
    date = date instanceof Date ? date : new Date(date);

    const diff = currentDate.getTime() - date.getTime();

    if (utils.hasMonths(diff)) {
      const months = utils.getMonths(diff);
      return t(...utils.getTransObject(months));
    }

    if (utils.hasWeeks(diff)) {
      const weeks = utils.getWeeks(diff);
      return t(...utils.getTransObject(weeks));
    }

    if (utils.hasDays(diff)) {
      const days = utils.getDays(diff);
      return t(...utils.getTransObject(days));
    }

    if (utils.hasHours(diff)) {
      const hours = utils.getHours(diff);
      return t(...utils.getTransObject(hours));
    }

    if (utils.hasMinutes(diff)) {
      const minutes = utils.getMinutes(diff);
      return t(...utils.getTransObject(minutes));
    }

    const seconds = utils.getSeconds(diff);
    return t(...utils.getTransObject(seconds));
  };
};

export { useFormatRelativeTime };
