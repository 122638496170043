import React from 'react';
import { components, ValueContainerProps } from 'react-select';
import { OptionObject } from 'src/components/ReactSelectInput/ReactSelectInput.types';
import { GroupBase } from 'react-select/dist/declarations/src/types';

type Props<T extends Record<string, any>> = ValueContainerProps<
  OptionObject<T>,
  boolean,
  GroupBase<OptionObject<T>>
>;

const ReactSelectValueContainer = <T extends Record<string, any>>({
  children,
  ...props
}: Props<T>): JSX.Element => {
  const valueLength = props.getValue().length;

  return (
    <components.ValueContainer {...props}>
      {props.isMulti ? (
        <div className={'tw-flex tw-min-w-0 tw-grow tw-items-center tw-justify-between tw-gap-1'}>
          <div className={'tw-flex tw-min-w-0 tw-items-center'}>
            {React.Children.map(children, (child) => {
              // @ts-ignore
              // always render input component. required for search/select close logic
              const isInput = child?.type === components.Input;

              if (!props.selectProps.inputValue) {
                return child;
              }

              // When typing in the search input, only show the search string and nothing else.
              return !!props.selectProps.inputValue && isInput ? child : null;
            })}
          </div>
          {!props.selectProps.inputValue && valueLength > 1 && (
            <span className={'tw-px-1 tw-text-text-subtle'}>+{valueLength - 1}</span>
          )}
        </div>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

export { ReactSelectValueContainer };
