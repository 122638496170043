import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useGlobalLoading } from 'src/components/GlobalLoading';
import { If } from 'src/components/If';
import { FullPageSpinner } from 'src/components/Spinner';
import { useAuth } from 'src/services/Auth';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

let tokenRefreshed = false;

const AuthLayout = () => {
  const { mount, dismount, refresh, isInitialLoading } = useAuth();
  const { isGlobalLoading, setGlobalLoading } = useGlobalLoading();

  useEffect(() => {
    if (!tokenRefreshed) {
      refresh().then(() => setGlobalLoading(false));
      tokenRefreshed = true;
    }

    mount();
    return () => {
      dismount();
    };
  }, []);

  return (
    <If
      when={isGlobalLoading || isInitialLoading}
      else={
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Outlet />
        </QueryParamProvider>
      }
    >
      <FullPageSpinner />
    </If>
  );
};

export default AuthLayout;
