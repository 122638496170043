import React, { useState } from 'react';
import {
  DialogTitle,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  Dialog,
} from 'src/components/ui/dialog';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { requestType, StoreOutcomeRequest } from 'src/lib/services/api/request-api';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Loader2, X } from 'lucide-react';
import { ReactSelectHookFormInput } from 'src/components/ReactSelectInput';
import { object, string, number } from 'yup';
import { If } from 'src/components/If';
import { cn } from 'src/lib/utils';
import { extractHookFormErrors } from 'src/components/Form/Form.utils';
import { ErrorBlock } from 'src/components/ui/error-block';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { DialogProps } from '@radix-ui/react-dialog';
import { FieldComplex } from 'src/components/Form/hook-form/field-complex';
import { TotalLengthSelect } from 'src/features/requests/request-fields/total-length-select';

type FormRequest = Omit<StoreOutcomeRequest, 'description'>;

type Props = DialogProps & {
  initialValues: DefaultValues<FormRequest>;
  onSubmit: (values: FormRequest) => Promise<void>;
};

export const CreateOutcomeFormDialog: React.FC<Props> = ({
  onOpenChange,
  onSubmit,
  initialValues,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const requestTypesOptions = Object.values(requestType).map((type) => ({
    label: t(`models/request:type.${type}`),
    value: type,
  }));

  const validationSchema = React.useMemo(
    () =>
      object({
        type: string().required().nullable(),
        title: string().required(),
        total_length: object({
          text: string().nullable().required().label('Length'),
          value: number()
            .integer()
            .typeError('The Length field is required')
            .min(1)
            .nullable()
            .required()
            .label('Length'),
          unit: string().nullable().required().label('Length unit'),
        }),
      }),
    [],
  );

  const form = useForm<FormRequest>({
    defaultValues: initialValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const onFormSubmit: SubmitHandler<FormRequest> = async (values) => {
    setError(undefined);

    try {
      await onSubmit?.(values);
    } catch (exception: any) {
      const formErrors = extractHookFormErrors(exception) ?? {};

      if (!formErrors) {
        setError(
          exception?.message ??
            t('alerts:error.generic.title', {
              defaultValue: 'Oops, something went wrong, please try again later.',
            })!,
        );

        return;
      }

      Object.keys(formErrors).forEach((key) => {
        form.setError(key as any, { type: 'custom', message: formErrors[key] });
      });
    }
  };

  return (
    <Dialog
      onOpenChange={(open) => {
        onOpenChange?.(open);
        form.reset();
      }}
      {...props}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent
        size={'xl'}
        className={'tw-h-full tw-p-0 md:tw-h-auto'}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <FormProvider {...form}>
          <div className={'tw-flex tw-flex-col tw-p-0'}>
            <DialogHeader
              className={
                'tw-flex-row tw-items-center tw-justify-center tw-border-b tw-border-neutral-200 tw-px-6 tw-py-4'
              }
            >
              <DialogTitle className={'tw-text-2xl tw-text-dark'}>
                {t('actions:add_outcome', {
                  defaultValue: 'Add outcome',
                })}
              </DialogTitle>

              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <DialogClose asChild>
                      <Button
                        type={'button'}
                        variant={'ghost'}
                        size={'icon'}
                        className={'tw-ms-auto tw-text-dark'}
                      >
                        <X size={20} />
                      </Button>
                    </DialogClose>
                  </TooltipTrigger>
                  <TooltipContent>{t('actions:close')}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </DialogHeader>

            <div className={'tw-grow'}>
              <form onSubmit={form.handleSubmit(onFormSubmit as any)}>
                <div className={'tw-flex tw-flex-col tw-gap-4 tw-px-6 tw-py-8 laptop:tw-px-10'}>
                  <If when={!!error}>
                    <ErrorBlock>{error}</ErrorBlock>
                  </If>

                  <div className={'tw-flex tw-flex-col tw-gap-2'}>
                    <FieldComplex
                      name={'type'}
                      label={t('attributes/request:type', {
                        defaultValue: 'Video type',
                      })}
                      required
                    >
                      <ReactSelectHookFormInput
                        id={'type'}
                        name={'type'}
                        options={requestTypesOptions}
                        className={cn([form.formState.errors.type && 'tw-border-destructive'])}
                      />
                    </FieldComplex>
                  </div>

                  <div className={'tw-flex tw-flex-col tw-gap-2'}>
                    <FieldComplex
                      name={'title'}
                      label={t('attributes/request:title', {
                        defaultValue: 'Title',
                      })}
                      required
                    >
                      <Input
                        id={'title'}
                        {...form.register('title')}
                        className={cn([form.formState.errors.title && 'tw-border-destructive'])}
                      />
                    </FieldComplex>
                  </div>

                  <FieldComplex
                    name={`total_length.text`}
                    label={t('attributes/request:total_length', {
                      defaultValue: 'Total length',
                    })}
                    required
                  >
                    <TotalLengthSelect name={'total_length'} typeName={'type'} />
                  </FieldComplex>
                </div>
              </form>
            </div>

            <DialogFooter className={'tw-border-t tw-border-neutral-200 tw-px-6 laptop:tw-px-10'}>
              <div
                className={
                  'tw-grid tw-w-full tw-grid-cols-2 tw-gap-4 tw-py-4 lg:tw-flex lg:tw-justify-end'
                }
              >
                <DialogClose asChild>
                  <Button
                    disabled={form.formState.isSubmitting}
                    type={'button'}
                    variant={'unset'}
                    className={
                      'tw-border-transparent tw-text-brand hover:tw-border-accent hover:tw-bg-purple-200 hover:tw-text-brand'
                    }
                  >
                    {t('actions:cancel', {
                      defaultValue: 'Cancel',
                    })}
                  </Button>
                </DialogClose>

                <Button
                  variant={'brand'}
                  disabled={form.formState.isSubmitting}
                  type={'button'}
                  className={'tw-rounded-lg tw-px-6 tw-py-3 tw-text-base'}
                  onClick={() => {
                    form.handleSubmit(onFormSubmit as any)();
                  }}
                >
                  {t('actions:save', {
                    defaultValue: 'Save',
                  })}
                  <If when={form.formState.isSubmitting}>
                    <Loader2 className={'tw-ml-2 tw-animate-spin'} size={20} />
                  </If>
                </Button>
              </div>
            </DialogFooter>
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};
