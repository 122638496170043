import { progressInFrames } from 'src/lib/utils/progress-in-frames';

export const progressInTimecode = (seconds: number, fps: number) => {
  const currentFrame = progressInFrames(seconds, fps);

  const hh = `${Math.floor(currentFrame / (60 * 60 * fps))}`.padStart(2, '0');
  const mm = `${Math.floor(currentFrame / (60 * fps)) % 60}`.padStart(2, '0');
  const ss = `${Math.floor(currentFrame / fps) % 60}`.padStart(2, '0');
  const ff = `${currentFrame % fps}`.padStart(2, '0');

  return `${hh}:${mm}:${ss}:${ff}`;
};
