import { MediaTemporaryUrlsResponse } from 'src/api/services/MediaClient';
import { getObjectKeys } from 'src/helpers/getObjectKeys';
import { KeyOfObject } from 'src/types/generics';

const decorateMediaSignedUrls = (urlsResponse: MediaTemporaryUrlsResponse) => {
  const sourceUrl = urlsResponse.url,
    resolutions = urlsResponse.resolutions,
    resolutionsKeys = getObjectKeys(resolutions)
      .map((r) => parseInt(`${r}`))
      .sort((a, b) => (a > b ? 1 : -1)),
    conversions = urlsResponse.conversions,
    hasResolutions = !!resolutionsKeys.length,
    hasConversions = !!getObjectKeys(conversions).length,
    sprite = urlsResponse.sprite;

  const getResolutionSrc = (resolution: KeyOfObject<typeof urlsResponse.resolutions>) =>
      resolutions?.[resolution],
    getConversionSrc = (conversion: KeyOfObject<typeof urlsResponse.conversions>) =>
      conversions?.[conversion];

  return {
    sourceUrl,
    resolutions,
    resolutionsKeys,
    conversions,
    hasResolutions,
    hasConversions,
    sprite,
    getResolutionSrc,
    getConversionSrc,
  };
};

export { decorateMediaSignedUrls };
