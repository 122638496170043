import React from 'react';
import Select from 'react-select';
import { useField } from 'formik';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { OptionObject } from '../ReactSelectInput.types';
import { useReactSelectValue } from '../useReactSelectValue';
import { configReactSelectProps } from '../ReactSelectInput.utils';

type Props = Omit<ReactSelectProps<OptionObject>, 'name' | 'value' | 'onChange'> & {
  name: string;
  handleChange?: (value: any) => void;
};

const ReactSelectFormInput: React.FC<Props> = ({ name, ...props }) => {
  const [
    ,
    { value: fieldValue, error: fieldError, touched: fieldTouched },
    { setValue: setFieldValue, setTouched },
  ] = useField(name);

  const { value, onChange } = useReactSelectValue(
    {
      value: fieldValue,
      setValue: (value) => {
        setTouched(true);
        setFieldValue(value);

        if (props.handleChange) {
          props.handleChange(value);
        }
      },
    },
    { ...props, onChange: undefined },
  );

  props = configReactSelectProps({ ...props, isError: fieldTouched && !!fieldError });

  return <Select {...props} onChange={onChange} onBlur={() => setTouched(true)} value={value} />;
};

export { ReactSelectFormInput };
