import { MouseEvent } from 'react';
import { ValueOfObject } from 'src/types/generics';
import {
  float,
  progressInFrames,
  progressInStandardTime,
  progressInTimecode,
  system,
} from 'src/lib/utils';
import { t } from 'i18next';
import { decorateMedia } from 'src/features/media';

export const makePlaybackRates = () => {
  const defaultRate: [number, string?] = [1, t('common:normal', { defaultValue: 'normal' })!];
  const all: [number, string?][] = [[0.25], [0.5], [0.75], defaultRate, [1.25], [1.5], [1.75], [2]];

  const options = all.map(([rate, label]) => ({ value: rate, label: label || rate }));

  const raw = {
    data: all,
    find: (rate: number) => all.find(([r]) => r === rate),
    default: defaultRate,
  };

  return {
    raw,
    default: options.find((o) => o.value === defaultRate[0])!,
    options,
    find: (rate: number) => options.find((r) => r.value === rate),
  };
};

export const timeFormat = {
  standard: 'standard',
  frames: 'frames',
  timecode: 'timecode',
} as const;

export type TimeFormat = ValueOfObject<typeof timeFormat> | string;

export const makeTimeFormats = () => {
  const options = Object.values(timeFormat).map((value) => ({
    label: t(`common:${value}`, { defaultValue: value }),
    value,
  }));

  const defaultOption = options.find((o) => o.value === timeFormat.standard)!;

  return {
    options,
    default: defaultOption,
    find: (format: string) => options.find((f) => f.value === format),
  };
};

type FormatTimeOptions = {
  format: TimeFormat;
  fps: number;
};

export const isTimeFormat = (format: string): format is TimeFormat => {
  return Object.values(timeFormat).includes(format as any);
};

export const formatTime = (seconds: number, options?: FormatTimeOptions) => {
  if (Number.isNaN(seconds)) {
    return progressInStandardTime(0);
  }

  if (!options) {
    return progressInStandardTime(seconds);
  }

  const { format, fps } = options;

  switch (format) {
    case timeFormat.standard:
      return progressInStandardTime(seconds);
    case timeFormat.frames:
      return progressInFrames(seconds, fps);
    case timeFormat.timecode:
      return progressInTimecode(seconds, fps);
  }
};

export const getVideoProgressbarCursorPosition = (e: MouseEvent<HTMLElement>, duration: number) => {
  const pos = e.nativeEvent.offsetX / e.currentTarget.clientWidth;

  // make sure 'pos' is a non-negative number
  return (pos < 0 ? 0 : pos) * duration;
};

export const secondsToFraction = (seconds: number, durationInSeconds: number) => {
  return float.toFinite(seconds / durationInSeconds);
};
export const fractionToSeconds = (fraction: number, durationInSeconds: number) => {
  return float.toFinite(fraction * durationInSeconds);
};
