import styled from 'styled-components';

const ChatInputCommentSelectedMediaWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 0.75rem;
  padding-bottom: 0.5rem;
  height: 4.5rem;
  overflow-x: auto;

  & > * {
    flex-shrink: 0;
  }
`;

export { ChatInputCommentSelectedMediaWrapper };
