class HttpAbortController {
  controller: AbortController;
  signal: AbortController['signal'];
  onAbort?: () => void;

  constructor() {
    this.controller = new AbortController();
    this.signal = this.controller.signal;
  }

  abort = (reason?: any) => {
    this.controller.abort(reason);
    this.refresh();
    this.onAbort?.();
  };

  refresh = () => {
    this.controller = new AbortController();
    this.signal = this.controller.signal;
  };
}

const httpAbortController = new HttpAbortController();

export { httpAbortController, HttpAbortController };
