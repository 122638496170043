import React, { createContext, useState } from 'react';

const useToggle = (initialValue?: boolean) => {
  const [value, setValue] = useState(!!initialValue);

  const toggle = () => setValue((prev) => !prev);

  return [value, setValue, toggle] as const;
};

type ToggleContextType = ReturnType<typeof useToggle>;

const ToggleContext = createContext<ToggleContextType>([
  false,
  () => {
    return;
  },
  () => {
    return;
  },
]);

const ToggleContextProvider: React.FC<
  React.PropsWithChildren<{
    initialValue?: boolean;
  }>
> = ({ initialValue, children }) => {
  const value = useToggle(initialValue);

  return <ToggleContext.Provider value={value}>{children}</ToggleContext.Provider>;
};

const withToggleContext = <P extends object>(
  Component: React.ComponentType<P>,
  initialValue?: boolean,
) => {
  const fc: React.FC<P> = (props) => (
    <ToggleContextProvider initialValue={initialValue}>
      <Component {...props} />
    </ToggleContextProvider>
  );

  fc.displayName = `withToggleContext(${Component.displayName || Component.name})`;

  return fc;
};

const useToggleContext = () => {
  return React.useContext(ToggleContext);
};

export { useToggle, ToggleContext, ToggleContextProvider, withToggleContext, useToggleContext };
