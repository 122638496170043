import React from 'react';
import { Role } from 'src/api/services/RoleClient';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { If } from 'src/components/If';

type Props = React.PropsWithChildren<{
  roles: Role[];
  else?: React.ReactNode;
}>;

const RenderForRole: React.FC<Props> = ({ roles, else: otherwise, children }) => {
  const authRole = AuthUtils.getPayload()?.user_type;

  return (
    <If when={roles.includes(authRole as any)} else={otherwise}>
      {children}
    </If>
  );
};

export { RenderForRole };
