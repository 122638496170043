import React from 'react';
import { components } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { OptionObject } from 'src/components/ReactSelectInput';
import { If } from 'src/components/If';
import { Check } from 'lucide-react';

type Props<T extends Record<string, any>> = OptionProps<
  OptionObject<T>,
  boolean,
  GroupBase<OptionObject<T>>
>;

const ReactSelectInputOption = <T extends Record<string, any>>({
  ...props
}: Props<T>): JSX.Element => {
  const { data } = props;
  const { label } = props.data;

  return (
    <components.Option {...props}>
      <div className={'tw-flex tw-items-center tw-justify-between'}>
        <div className={'tw-flex'}>
          <span className={'tw-me-2'}>{label}</span>

          <If when={!!data?.description}>
            {typeof data.description === 'string' ? <i>{data.description}</i> : data.description}
          </If>
        </div>

        {props.isSelected && (
          <div>
            <Check className={'tw-size-4'} />
          </div>
        )}
      </div>
    </components.Option>
  );
};

export { ReactSelectInputOption };
