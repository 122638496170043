import React from 'react';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { requestClient } from 'src/lib/services/api/request-api';
import { authQueries } from 'src/api/queries';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { render } from 'src/lib/utils';
import { UseQueryOptionsObject } from 'src/api/queries/queries.utils';

type ApiResponse = Awaited<ReturnType<typeof requestClient.getActivities>>;

type QueryContextType = ReturnType<typeof useMakeQuery<ApiResponse>>;

export const RequestActivitiesQueryContext = React.createContext<QueryContextType | undefined>(
  undefined,
);

export const RequestActivitiesQueryContextProvider = ({
  id,
  children,
  filters = {},
  config = {},
}: {
  id: string;
  children: React.ReactNode | ((query: QueryContextType) => React.ReactNode);
  filters?: ApiQueryObject;
  config?: UseQueryOptionsObject<ApiResponse>;
}) => {
  const query = useMakeQuery<ApiResponse>(
    [authQueries.request, id, 'activity', filters],
    ({ signal }) => requestClient.getActivities(id, { signal, params: filters }),
    {
      suspense: true,
      retry: false,
      staleTime: 60 * 1000,
      ...config,
    },
  );

  return (
    <RequestActivitiesQueryContext.Provider value={query}>
      {render(children, query)}
    </RequestActivitiesQueryContext.Provider>
  );
};

export const useRequestActivitiesQueryContext = () => {
  const context = React.useContext(RequestActivitiesQueryContext);

  if (!context) {
    throw new Error(
      'useRequestActivitiesQueryContext must be used within a RequestActivitiesQueryContext',
    );
  }

  return context;
};

export const useRequestActivitiesQueryData = (id: string, filters?: ApiQueryObject) => {
  const query = useMakeQuery<ApiResponse>(
    [authQueries.request, id, 'activity', filters],
    ({ signal }) => requestClient.getActivities(id, { signal, params: filters }),
    {
      enabled: false,
    },
  );

  return query.data;
};
