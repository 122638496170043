import React from 'react';
import { ReactQueryClientProvider } from 'src/providers/ReactQueryClientProvider';
import { ThemeProvider } from 'src/providers/ThemeProvider';

type Props = React.PropsWithChildren;

const Providers: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <ReactQueryClientProvider>
        <ThemeProvider>{props.children}</ThemeProvider>
      </ReactQueryClientProvider>
    </React.Fragment>
  );
};

export default Providers;
