import React from 'react';
import { ThemeColor } from '@becreatives/becreatives-ui';

type Props = React.PropsWithChildren<React.ComponentPropsWithRef<'button'>> & {
  outline?: boolean;
  color?: string | ThemeColor;
  submitting?: boolean;
  loading?: boolean;
  centerContent?: boolean;
};

const Button: React.FC<Props> = ({
  type = 'button',
  color,
  outline = false,
  centerContent = false,
  className,
  children,
  submitting,
  style = {
    pointerEvents: 'auto',
  },
  ...props
}) => {
  const showSpinner = submitting || !!props?.loading;

  const colorClass = ['btn'];
  if (outline) {
    colorClass.push('outline');
  }
  colorClass.push(color ?? 'primary');

  const classNames = ['btn', colorClass.join('-')];
  if (centerContent) {
    classNames.push('d-flex justify-content-center align-items-center');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <button {...props} style={style} type={type} className={classNames.join(' ')}>
      {showSpinner ? <i className={'fa fa-spinner fa-spin'} /> : children}
    </button>
  );
};

export type { Props as ButtonProps };
export { Button };
