import React from 'react';
import { Modal, ModalProps } from 'reactstrap';
import { FormMediaObject } from 'src/components/Form/S3UploadInput';
import { MediaViewer } from '../MediaViewer';
import { BxCloseIcon } from 'src/components/Boxicons';
import clsx from 'clsx';

type Props = ModalProps & {
  media: FormMediaObject;
};

const MediaModal: React.FC<Props> = ({ media, ...props }) => {
  return (
    <Modal {...props} className={'d-flex'}>
      <div className={'px-2 pt-2 d-flex justify-content-end'}>
        <BxCloseIcon className={'fs-3 pointer'} onClick={props.toggle as any} />
      </div>
      <div className={'d-flex justify-content-between pt-2 px-4 align-items-center'}>
        <h5 className={'text-dark text-truncate'}>{media.file_name}</h5>
        <a href={media?.index_url} className={clsx(['py-2', !media?.index_url && 'disabled'])}>
          <i className={'bx bx-cloud-download fs-3'} title={'Download'} />
        </a>
      </div>
      <h6 className={'pb-4 px-4 text-muted'}>{media.mime_type}</h6>
      <div
        style={{
          maxHeight: window.innerHeight / 1.5,
        }}
      >
        <MediaViewer
          style={{
            objectFit: 'scale-down',
          }}
          className={'rounded-3 mt-auto mb-auto'}
          media={media}
          playable={true}
          renderOriginal={true}
        />
      </div>
    </Modal>
  );
};

export default MediaModal;
