import axios, { AxiosError } from 'axios';
import { HttpError } from './HttpError';

type AxiosHttpError = AxiosError<{
  message?: string;
  error?: string;
  errors?: {
    form: Record<string, string[]>;
  };
}>;

type FormErrorsResponse = Record<string, string[]>;

type HttpErrorResponse<T = unknown> = {
  message: string;
  code: number | string;
  errors?: {
    form: T;
  };
};

const isAxiosHttpError = (error: unknown): error is AxiosHttpError => {
  return axios.isAxiosError(error) as boolean;
};

const isCancelError = (error: HttpError): boolean => {
  return error.code === 'ERR_CANCELED';
};

export type { AxiosHttpError, FormErrorsResponse, HttpErrorResponse };
export { isAxiosHttpError, isCancelError };
