import React from 'react';
import { Api } from 'src/api';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { render } from 'src/lib/utils';
import { reactQueryClient } from 'src/providers/ReactQueryClientProvider';
import { decorateMediaSignedUrls } from 'src/features/media/decorate-media-signed-urls';
import { mediaQueryKey } from 'src/features/media/media-query-key';

type ApiResponse = Awaited<ReturnType<typeof Api.media.getTemporaryUrls>>;
type DecoratedResponse = {
  raw: ApiResponse;
} & ReturnType<typeof decorateMediaSignedUrls>;

type QueryContextType = ReturnType<typeof useMakeQuery<DecoratedResponse>>;

const MediaSignedUrlsQueryContext = React.createContext<QueryContextType | undefined>(undefined);

export const MediaSignedUrlsQueryProvider: React.FC<{
  id: string;
  children: React.ReactNode | ((query: QueryContextType) => React.ReactNode);
  config?: UseQueryOptionsObject<DecoratedResponse>;
}> = ({ id, config = {}, children }) => {
  const query = useMakeQuery(
    mediaQueryKey.signedUrls(id),
    ({ signal }) =>
      Api.media
        .getTemporaryUrls(id, {
          signal,
        })
        .then((response) => ({
          raw: response,
          ...decorateMediaSignedUrls(response),
        })),
    {
      suspense: true,
      retry: false,
      staleTime: 60 * 1000,
      ...config,
    },
  );

  return (
    <MediaSignedUrlsQueryContext.Provider value={query}>
      {render(children, query)}
    </MediaSignedUrlsQueryContext.Provider>
  );
};

export const useMediaSignedUrlsQueryContext = () => {
  const context = React.useContext(MediaSignedUrlsQueryContext);

  if (!context) {
    throw new Error(
      'useMediaSignedUrlsQueryContext must be used within a MediaSignedUrlsQueryProvider',
    );
  }

  return context;
};

export const getMediaSignedUrlsQueryData = (media: string) =>
  reactQueryClient.getQueryData<DecoratedResponse>(mediaQueryKey.signedUrls(media));

export const useMediaSignedUrlsReadonlyContext = (media: string) =>
  useMakeQuery(
    mediaQueryKey.signedUrls(media),
    ({ signal }) =>
      Api.media
        .getTemporaryUrls(media, {
          signal,
        })
        .then((response) => ({
          raw: response,
          ...decorateMediaSignedUrls(response),
        })),
    {
      enabled: !getMediaSignedUrlsQueryData(media),
      retry: false,
    },
  );
