// @ts-nocheck
import { INTERCOM_APP_ID } from 'src/env.json';

window.intercomSettings = {
  app_id: '',
  is_booted: false,
  initial_unread_count_checked: false,
};

window.Intercom = undefined;

class IntercomObj {
  public static init = (): void => {
    window.intercomSettings.app_id = INTERCOM_APP_ID;

    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  };

  public static boot = (options = {}) => {
    if (!!window?.Intercom) {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        ...options,
      });

      window.intercomSettings.is_booted = true;
      window.intercomSettings.app_id = INTERCOM_APP_ID;
    }
  };

  public static shutdown = () => {
    if (!!window?.Intercom) {
      window.Intercom('shutdown');

      window.intercomSettings.app_id = '';
      window.intercomSettings.is_booted = false;
    }
  };

  public static hardShutdown = () => {
    if (!!window?.Intercom) {
      window.Intercom('shutdown');

      window.Intercom = undefined;
      window.intercomSettings.app_id = '';
      window.intercomSettings.is_booted = false;
    }
  };

  public static trackEvent = (event: string, options = {}) => {
    if (!!window?.Intercom) {
      window?.Intercom('trackEvent', event, options);
    }
  };

  public static show = () => {
    if (!!window?.Intercom) {
      window?.Intercom('show');
    }
  };

  public static hide = () => {
    document.body.classList.add('hide-intercom');
  };

  public static unhide = () => {
    document.body.classList.remove('hide-intercom');
  };

  public static onUnreadCountChange = (callback: (unreadCount: number) => void) => {
    window.Intercom('onUnreadCountChange', callback);
  };
}

export { IntercomObj };
