import React, { forwardRef } from 'react';

const makeBxsIcon = (css: string) => {
  return forwardRef<HTMLElement, React.ComponentPropsWithoutRef<'i'>>(function BxsIcon(
    { className, ...props },
    ref,
  ) {
    const cssClasses = [css];
    if (className) {
      cssClasses.push(className);
    }

    return <i {...props} className={cssClasses.join(' ')} ref={ref} />;
  });
};

const BxsCloseIcon = makeBxsIcon('bx bxs-x-circle');

const BxsCheckIcon = makeBxsIcon('bx bxs-check-circle');

const BxChevronUp = makeBxsIcon('bx bx-chevron-up');

const BxChevronDown = makeBxsIcon('bx bx-chevron-down');

const BxCloseIcon = makeBxsIcon('bx bx-x');

const BxPlusIcon = makeBxsIcon('bx bx-plus-circle');

const BxsPlusIcon = makeBxsIcon('bx bxs-plus-circle');

const BxSunIcon = makeBxsIcon('bx bx-sun');

const BxMoonIcon = makeBxsIcon('bx bx-moon');

const BxTaskIcon = makeBxsIcon('bx bx-task');

const BxShowIcon = makeBxsIcon('bx bx-show');

const BxGroupIcon = makeBxsIcon('bx bx-group');

const BxLoaderCircleIcon = makeBxsIcon('bx bx-loader-circle');

const BxUpArrowCircleIcon = makeBxsIcon('bx bx-up-arrow-circle');

const BxDownArrowCircleIcon = makeBxsIcon('bx bx-down-arrow-circle');

const BxBriefcaseIcon = makeBxsIcon('bx bx-briefcase');

const BxTimeIcon = makeBxsIcon('bx bx-time');

const BxsMinusCircleIcon = makeBxsIcon('bx bxs-minus-circle');

const BxEditIcon = makeBxsIcon('bx bx-edit-alt');

const BxShoppingBagIcon = makeBxsIcon('bx bx-shopping-bag');

const BxShareIcon = makeBxsIcon('bx bx-share-alt');

const BxCloudDownloadIcon = makeBxsIcon('bx bx-cloud-download');

const BxCopyIcon = makeBxsIcon('bx bx-copy');

const BxClipboardIcon = makeBxsIcon('bx bx-clipboard');

const BxMusicIcon = makeBxsIcon('bx bx-music');

const BxListIcon = makeBxsIcon('bx bx-list-ul');

const BxDashboardIcon = makeBxsIcon('bx bxs-dashboard');

const BxMenuLeftIcon = makeBxsIcon('bx bx-menu-alt-left');

const BxCommentIcon = makeBxsIcon('bx bxs-comment');

const BxMoveIcon = makeBxsIcon('bx bx-move-vertical');

const BxErrorCircleIcon = makeBxsIcon('bx bxs-error-circle');

const BxFilterIcon = makeBxsIcon('bx bx-filter-alt');

const BxSadIcon = makeBxsIcon('bx bx-sad');

export {
  BxsCloseIcon,
  BxsCheckIcon,
  BxChevronUp,
  BxChevronDown,
  BxCloseIcon,
  BxPlusIcon,
  BxsPlusIcon,
  BxSunIcon,
  BxMoonIcon,
  BxTaskIcon,
  BxShowIcon,
  BxGroupIcon,
  BxUpArrowCircleIcon,
  BxDownArrowCircleIcon,
  BxLoaderCircleIcon,
  BxBriefcaseIcon,
  BxTimeIcon,
  BxsMinusCircleIcon,
  BxEditIcon,
  BxShoppingBagIcon,
  BxShareIcon,
  BxCloudDownloadIcon,
  BxCopyIcon,
  BxClipboardIcon,
  BxMusicIcon,
  BxListIcon,
  BxDashboardIcon,
  BxMenuLeftIcon,
  BxCommentIcon,
  BxMoveIcon,
  BxErrorCircleIcon,
  BxFilterIcon,
  BxSadIcon,
};
