import { useMediaQuery } from 'src/lib/hooks';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'laptop' | '2xl';

type Breakpoints = {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  isLaptop: boolean;
  is2xl: boolean;
  activeBreakpoint: Breakpoint;
};

const breakpointNumber: Record<Breakpoint, number> = {
  xs: 10,
  sm: 20,
  md: 30,
  lg: 40,
  xl: 50,
  laptop: 60,
  '2xl': 70,
};

const useBreakpoint = () => {
  const breakpoints: Breakpoints = {
    isXs: useMediaQuery('(max-width: 639px)'),
    isSm: useMediaQuery('(min-width: 640px) and (max-width: 767px)'),
    isMd: useMediaQuery('(min-width: 768px) and (max-width: 1023px)'),
    isLg: useMediaQuery('(min-width: 1024px) and (max-width: 1279px)'),
    isXl: useMediaQuery('(min-width: 1280px) and (max-width: 1439px)'),
    isLaptop: useMediaQuery('(min-width: 1440px) and (max-width: 1535px)'),
    is2xl: useMediaQuery('(min-width: 1536px)'),
    activeBreakpoint: 'xs',
  };

  if (breakpoints.isXs) breakpoints.activeBreakpoint = 'xs';
  if (breakpoints.isSm) breakpoints.activeBreakpoint = 'sm';
  if (breakpoints.isMd) breakpoints.activeBreakpoint = 'md';
  if (breakpoints.isLg) breakpoints.activeBreakpoint = 'lg';
  if (breakpoints.isXl) breakpoints.activeBreakpoint = 'xl';
  if (breakpoints.isLaptop) breakpoints.activeBreakpoint = 'laptop';
  if (breakpoints.is2xl) breakpoints.activeBreakpoint = '2xl';

  const upTo = (breakpoint: Breakpoint) => {
    return breakpointNumber[breakpoints.activeBreakpoint] < breakpointNumber[breakpoint];
  };

  const biggerThan = (breakpoint: Breakpoint) => {
    return breakpointNumber[breakpoints.activeBreakpoint] >= breakpointNumber[breakpoint];
  };

  const isMobile = upTo('md');

  return { ...breakpoints, isMobile, upTo, biggerThan };
};

export { useBreakpoint };
