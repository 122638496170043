import axios from 'axios';
import { httpConfig } from './http.config';
import { HttpError } from './http-error';
import { httpAbortController } from './http-abort-controller';

const instance = axios.create(httpConfig.options);
instance.defaults.headers.common = httpConfig.options.headers;
httpAbortController.onAbort = () => {
  instance.defaults.signal = httpAbortController.signal;
};

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    if (axios.isCancel(err)) {
      return Promise.reject(err);
    }

    const responseData = err.response.data;
    const message = responseData.message;
    const status = err.response.status;
    const data = responseData?.errors;

    const httpErr = new HttpError(message, {
      status,
      data,
    });

    return Promise.reject(httpErr);
  },
);

const requestInterceptor = axios.interceptors.request;

const responseInterceptor = axios.interceptors.response;

const client = {
  instance,
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  requestInterceptor,
  responseInterceptor,
} as const;

const http = {
  client,
  config: httpConfig,
  abortController: httpAbortController,
} as const;

export { http };
