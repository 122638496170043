import { Client, PaginatedResponse } from 'src/api/Client';
import {
  AssignmentResponse,
  CreateAssignmentRequest,
  UpdateAssignmentRequest,
} from './SubscriptionAssignmentClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class SubscriptionAssignmentClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/admin/subscription-assignments/{model}/filter`,
    create: `${this.http.baseUrl}/admin/subscription-assignments/{model}`,
    view: `${this.http.baseUrl}/admin/subscription-assignments/{model}`,
    update: `${this.http.baseUrl}/admin/subscription-assignments/{model}`,
  };

  filter = (
    model: string,
    params?: ApiQueryObject,
  ): Promise<PaginatedResponse<AssignmentResponse>> =>
    this.http.get(this.buildUrl(this.route.filter, { model }), { params });

  create = (model: string, data: CreateAssignmentRequest): Promise<AssignmentResponse> =>
    this.http.post(this.buildUrl(this.route.create, { model }), data);

  view = (model: string): Promise<AssignmentResponse> =>
    this.http.get(this.buildUrl(this.route.view, { model }));

  update = (model: string, data: UpdateAssignmentRequest): Promise<AssignmentResponse> =>
    this.http.put(this.buildUrl(this.route.update, { model }), data);
}

export { SubscriptionAssignmentClient };
