import { shallow } from 'zustand/shallow';
import { useVideoPlayerV2, useVideoPlayToggle } from 'src/features/video-player/video-player';
import { timeFormat } from 'src/features/video-player/video-player-utils';
import { createWithEqualityFn } from 'zustand/traditional';
import { useVideoPlayerProgress } from 'src/features/video-player/use-video-player-progress';

// Hacking our way to communication with least re-renders for video player and chat

type Fn = () => void;

type PlayerActions = {
  pause: Fn;
  seek: (time: number) => void;
};

type RequestRevisionsPageStore = PlayerActions & {
  setPause: (pause: Fn) => void;
  setSeek: (seek: (time: number) => void) => void;
  timeFormat: string;
  setTimeFormat: (timeFormat: string) => void;
  reset: () => void;
  onMount?: (player: PlayerActions) => void;
  setOnMount: (onMount?: (player: PlayerActions) => void) => void;
};

export const useRequestRevisionVideoPlayerGlobalStore =
  createWithEqualityFn<RequestRevisionsPageStore>((set) => ({
    pause: () => {
      return;
    },
    setPause: (pause) => set({ pause }),
    seek: () => {
      return;
    },
    setSeek: (seek) => set({ seek }),
    timeFormat: timeFormat.standard,
    setTimeFormat: (timeFormat) => set({ timeFormat }),
    onMount: undefined,
    setOnMount: (onMount) => set({ onMount }),
    // reset does not include onMount, because we're cleaning up the player utilities
    //  and onMount will be called when new played is mounted
    //  if we clean onMount, then it will be undefined once the player mounts.
    //  This is needed for when users click on a request revision comment timestamp
    reset: () =>
      set(() => ({
        pause: () => {
          return;
        },
        seek: () => {
          return;
        },
        timeFormat: timeFormat.standard,
      })),
  }));

export const useInitializeRequestRevisionVideoPlayerGlobalStore = () => {
  const { timeFormat } = useVideoPlayerV2();
  const { seekSeconds } = useVideoPlayerProgress();

  const { pause } = useVideoPlayToggle();

  const { setPause, setSeek, setTimeFormat, reset, onMount, setOnMount } =
    useRequestRevisionVideoPlayerGlobalStore(
      ({ setPause, setSeek, setTimeFormat, onMount, setOnMount, reset }) => ({
        setPause,
        setSeek,
        setTimeFormat,
        reset,
        onMount,
        setOnMount,
      }),
      shallow,
    );

  const mount = () => {
    setPause(pause);
    setSeek(seekSeconds);
    setTimeFormat(timeFormat);

    onMount?.({
      pause,
      seek: seekSeconds,
    });
    setOnMount(undefined);
  };

  const unmount = reset;

  return {
    mount,
    unmount,
    setTimeFormat,
  };
};
