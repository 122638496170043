import { Client, PaginatedResponse } from '../../Client';
import { SubscriptionResponse } from './SubscriptionClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class SubscriptionClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/subscriptions`,
    get: `${this.http.baseUrl}/subscriptions/{model}`,
  };

  filter = (params?: ApiQueryObject): Promise<PaginatedResponse<SubscriptionResponse>> =>
    this.http.get(this.route.filter, { params });

  get = (model: string): Promise<SubscriptionResponse> =>
    this.http.get(this.buildUrl(this.route.get, { model }));
}

export { SubscriptionClient };
