import React from 'react';
import { AsyncReactSelect, AsyncReactSelectProps } from 'src/components/ui/react-select';
import { useDebouncedState, wrap } from 'src/lib/utils';
import { useField } from 'formik';

type Props<TQueryFnData = unknown> = Omit<
  AsyncReactSelectProps<TQueryFnData>,
  'filters' | 'name' | 'value' | 'setValue' | 'onSearch'
> & {
  name: string;
  handleChange?: (value: any) => void;
};

const AsyncSelectInput = <TQueryFnData,>({ name, ...props }: Props<TQueryFnData>): JSX.Element => {
  const [
    ,
    { value: fieldValue, error: fieldError, touched: fieldTouched },
    { setValue: setFieldValue, setTouched },
  ] = useField(name);

  const [search, setSearch] = useDebouncedState('');

  return (
    <AsyncReactSelect
      filters={{
        search,
        include: wrap(fieldValue),
      }}
      {...props}
      value={fieldValue}
      setValue={(newValue) => {
        setTouched(true);
        setFieldValue(newValue);

        if (props.handleChange) {
          props.handleChange(newValue);
        }
      }}
      name={name}
      onSearch={setSearch}
      isError={fieldTouched && !!fieldError}
    />
  );
};

export { AsyncSelectInput };
