import React from 'react';
import { If } from 'src/components/If';

type Props = {
  src: string;
  poster?: string | JSX.Element;
  posterProps?: React.ComponentPropsWithoutRef<'img'>;
  wrapperProps?: React.ComponentPropsWithoutRef<'div'>;
} & Omit<React.ComponentPropsWithoutRef<'audio'>, 'src'>;

const AudioMedia: React.FC<Props> = ({
  poster,
  wrapperProps = {},
  posterProps = {},
  src,
  ...props
}) => {
  return (
    <div {...wrapperProps}>
      <If when={!!poster}>
        <If when={typeof poster === 'string'} else={poster}>
          <img {...posterProps} src={poster as string} />
        </If>
      </If>
      <audio {...props}>
        <source src={src} />
      </audio>
    </div>
  );
};

export type { Props as AudioMediaProps };
export { AudioMedia };
