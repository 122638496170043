import { Client, PaginatedResponse } from 'src/api/Client';
import { CreateTaskRequest, TaskResponse, TaskStatus, UpdateTaskRequest } from './TaskClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class TaskClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/tasks`,
    create: `${this.http.baseUrl}/tasks`,
    view: `${this.http.baseUrl}/tasks/{model}`,
    update: `${this.http.baseUrl}/tasks/{model}`,
    delete: `${this.http.baseUrl}/tasks/{model}`,
    complete: `${this.http.baseUrl}/tasks/{model}/complete`,
    changeStatus: `${this.http.baseUrl}/tasks/{model}/change-status`,
  };

  filter = (params?: ApiQueryObject): Promise<PaginatedResponse<TaskResponse>> =>
    this.http.get(this.route.filter, { params });

  create = (data: CreateTaskRequest): Promise<TaskResponse> =>
    this.http.post(this.route.create, data);

  view = (model: string): Promise<TaskResponse> =>
    this.http.get(this.buildUrl(this.route.view, { model }));

  update = (model: string, data: UpdateTaskRequest): Promise<TaskResponse> =>
    this.http.put(this.buildUrl(this.route.update, { model }), data);

  delete = (model: string): Promise<void> =>
    this.http.delete(this.buildUrl(this.route.delete, { model }));

  complete = (model: string): Promise<void> =>
    this.http.put(this.buildUrl(this.route.complete, { model }));

  changeStatus = (model: string, status: TaskStatus): Promise<void> =>
    this.http.put(this.buildUrl(this.route.changeStatus, { model }), { status });
}

export { TaskClient };
