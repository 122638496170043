import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { QueryErrorResetBoundary, useQueryClient } from '@tanstack/react-query';
import { useRequestQueryContext } from 'src/features/requests/use-request-query-context';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useCompanyQueryContextData } from 'src/features/companies/use-company-query-context';
import { useMediaDownload } from 'src/features/media';
import { Button } from 'src/components/ui/button';
import { If } from 'src/components/If';
import { Link } from 'src/components/ui/link';
import { appRoutes } from 'src/routes';
import { RequestAttentionAction } from 'src/pages/RequestPage/RequestActionComponents/RequestAttentionAction';
import { authQueries } from 'src/api/queries';
import { ChevronLeft, Download, Loader2, Megaphone, Sparkles, Video } from 'lucide-react';
import { useToggle } from 'src/helpers/useToggle';
import { CreateOutcomeFormDialog } from 'src/features/requests/request-outcome/create-outcome-form-dialog';
import { useRequestQueryMutationDecorator } from 'src/features/requests/use-request-query-mutation-decorator';
import { useToast } from 'src/components/ui/use-toast';
import { useRequestOutcomesQueryMutationDecorator } from 'src/features/requests/request-outcome/use-request-outcomes-query-mutation-decorator';
import { ErrorBoundary } from 'react-error-boundary';
import { RequestOutcomesQueryContextProvider } from 'src/features/requests/request-outcome/use-request-outcomes-query-context';
import {
  pendingOutcomesFilters,
  unfinishedOutcomesFilters,
} from 'src/features/requests/request-outcome/filter-queries';
import { PageHeading, PageHeadingTitle } from 'src/components/ui/page-heading';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { defaultSortFilter } from 'src/api/services/RequestClient';
import { Badge } from 'src/components/ui/badge';

const RequestMultiOutcomePageContentTop = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useQueryClient();
  const location = useLocation();

  const company = useCompanyQueryContextData();

  const request = useDecoratedRequestContext();
  const requestQuery = useRequestQueryContext();
  const requestActions = useRequestQueryMutationDecorator(request.id);

  const pendingOutcomesActions = useRequestOutcomesQueryMutationDecorator(
    request.id,
    pendingOutcomesFilters,
  );

  const { downloadAll, isDownloading } = useMediaDownload();
  const { toast } = useToast();
  const [createOutcomeDialogOpen, setCreateOutcomeDialogOpen] = useToggle();
  const [isCompleting, setCompleting] = useToggle();

  const createOutcomeInitialValues = {
    type: '',
    title: t('models/request:outcome.placeholder', {
      defaultValue: 'Outcome #:num',
      replace: {
        num: (request.raw.outcomes_count ?? 0) + 1,
      },
    }),
    total_length: undefined,
  };

  return (
    <div ref={ref} className={className} {...props}>
      <If
        when={!!children}
        else={
          <div className={'tw-mb-4 tw-flex tw-flex-wrap tw-gap-4'}>
            <div
              className={
                'tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 md:tw-justify-start'
              }
            >
              <PageHeading>
                <Breadcrumb>
                  <BreadcrumbList className={'tw-flex-nowrap'}>
                    <BreadcrumbItem>
                      <Button
                        variant={'unset'}
                        size={'iconXs'}
                        onClick={() => {
                          if (location.state && location.state.from) {
                            navigate(location.state.from);
                          } else {
                            navigate({
                              pathname: generatePath(appRoutes.companyRequestsList, {
                                companyId: company?.id ?? '',
                              }),
                              search: defaultSortFilter,
                            });
                          }
                        }}
                      >
                        <ChevronLeft className={'tw-size-4'} />
                      </Button>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <BreadcrumbLink asChild>
                        <Link to={appRoutes.companiesList}>{t('Companies')}</Link>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbLink asChild>
                        <Link
                          to={generatePath(appRoutes.company, { companyId: company?.id ?? '' })}
                        >
                          {company?.name ?? t('unset')!}
                        </Link>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbLink asChild>
                        <Link
                          to={{
                            pathname: generatePath(appRoutes.companyRequestsList, {
                              companyId: company?.id ?? '',
                            }),
                            search: defaultSortFilter,
                          }}
                          state={location.state}
                        >
                          {t('Requests')}
                        </Link>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
                <PageHeadingTitle className={'tw-flex-nowrap'}>
                  <div>
                    <Video className={'tw-size-6'} />
                  </div>
                  <span className={'tw-line-clamp-1'}>
                    {request?.title ?? t('unset', { ns: 'common' })!}
                  </span>
                  {request.flow === 'repurpose' && (
                    <Badge variant={'unset'} className={'tw-gap-1 tw-bg-brand tw-text-white'}>
                      <span>{t('Repurposed with AI')}</span> <Sparkles size={16} />
                    </Badge>
                  )}
                </PageHeadingTitle>
              </PageHeading>
            </div>
            <div
              className={'tw-ms-auto tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2'}
            >
              <If when={request.canRequestAttention}>
                <RequestAttentionAction
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  form={{
                    requestId: request.id,
                    initialValues: {
                      attention_text: request?.attention_text || '',
                    },
                    onSubmit: async ({ attention_text }) => {
                      requestQuery.setQueryData({
                        attention_text,
                        requested_attention_at: new Date().toDateString(),
                      });
                      client.invalidateQueries({
                        queryKey: [authQueries.requests, request?.status],
                      });
                    },
                  }}
                  as={Button}
                  variant={'outline'}
                  size={'responsive'}
                  className={'tw-flex tw-grow tw-items-center sm:tw-grow-0 '}
                >
                  <span className={'tw-me-3'}>
                    <Megaphone className={'tw-h-5 tw-w-5'} />
                  </span>
                  {t('actions:request_attention')}
                </RequestAttentionAction>
              </If>

              <If when={request.hasMedia}>
                <Button
                  variant={'outline'}
                  size={'responsive'}
                  className={'tw-flex tw-grow tw-items-center sm:tw-grow-0'}
                  disabled={isDownloading}
                  onClick={() => {
                    downloadAll(request.media ?? []);
                  }}
                >
                  <span className={'tw-me-3'}>
                    <Download className={'tw-h-5 tw-w-5'} />
                  </span>
                  {t('download_files', { ns: 'actions' })}
                </Button>
              </If>

              <CreateOutcomeFormDialog
                initialValues={createOutcomeInitialValues}
                open={createOutcomeDialogOpen}
                onOpenChange={(open) => {
                  setCreateOutcomeDialogOpen(open);
                }}
                onSubmit={async (values) => {
                  await requestActions.createOutcome(values).then((outcome) => {
                    toast({
                      description: t('alerts:success.request_outcome_created', {
                        defaultValue: ':type request outcome created',
                        replace: {
                          type: t(`models/request:type.${outcome.type}`, {
                            defaultValue: t('common:unknown', {
                              defaultValue: 'Unknown',
                            }),
                          }),
                        },
                      }),
                    });

                    pendingOutcomesActions.push(outcome);
                    pendingOutcomesActions.invalidate();

                    setCreateOutcomeDialogOpen(false);
                  });
                }}
              >
                <Button
                  className={'tw-flex tw-grow tw-items-center sm:tw-grow-0 laptop:tw-hidden'}
                  variant={'accent1'}
                  type={'button'}
                  size={'responsive'}
                  disabled={!request.canAddOutcome}
                >
                  {t('actions:add_new_outcome', {
                    defaultValue: 'Add new outcome',
                  })}
                </Button>
              </CreateOutcomeFormDialog>

              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary
                    fallbackRender={({ resetErrorBoundary }: any) => (
                      <Button
                        className={'tw-flex tw-grow tw-items-center sm:tw-grow-0'}
                        size={'responsive'}
                        disabled={!request.raw.outcomes_count || request.isCompleted}
                        onClick={resetErrorBoundary}
                        variant={'brand'}
                      >
                        {t('actions:complete', {
                          defaultValue: 'Complete',
                        })}
                      </Button>
                    )}
                    onReset={reset}
                  >
                    <React.Suspense
                      fallback={
                        <Button
                          disabled={true}
                          variant={'brand'}
                          size={'responsive'}
                          className={'tw-flex tw-grow tw-items-center sm:tw-grow-0'}
                        >
                          {t('actions:complete', {
                            defaultValue: 'Complete',
                          })}
                        </Button>
                      }
                    >
                      <RequestOutcomesQueryContextProvider
                        id={request.id}
                        filters={unfinishedOutcomesFilters}
                      >
                        {({ data }) => (
                          <Button
                            type={'button'}
                            variant={'brand'}
                            size={'responsive'}
                            className={'tw-flex tw-grow tw-items-center sm:tw-grow-0'}
                            disabled={
                              !request.raw.outcomes_count ||
                              !!data?.items.length ||
                              isCompleting ||
                              request.isCompleted
                            }
                            onClick={() => {
                              if (data?.items.length) {
                                return;
                              }

                              setCompleting(true);

                              requestActions
                                .complete()
                                .then(() => {
                                  toast({
                                    description: t('alerts:success.request_completed', {
                                      defaultValue: 'Request marked as completed',
                                    }),
                                  });
                                })
                                .finally(() => setCompleting(false));
                            }}
                          >
                            {t('actions:complete', {
                              defaultValue: 'Complete',
                            })}
                            <If when={isCompleting}>
                              <Loader2 size={16} className={'tw-ms-2 tw-animate-spin'} />
                            </If>
                          </Button>
                        )}
                      </RequestOutcomesQueryContextProvider>
                    </React.Suspense>
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
            </div>
          </div>
        }
      >
        {children}
      </If>
    </div>
  );
});
RequestMultiOutcomePageContentTop.displayName = 'RequestMultiOutcomePageContentTop';

export { RequestMultiOutcomePageContentTop };
