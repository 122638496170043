import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '@becreatives/becreatives-ui';
import { useThemeStore } from './theme.store';

type Props = React.PropsWithChildren;

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const themeStore = useThemeStore();

  return (
    <StyledThemeProvider theme={theme[themeStore.theme]}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};

export { ThemeProvider };
