import styled from 'styled-components';
import {
  assertThemedColor,
  RoundCheckmarkIcon,
  Text,
  truncatesText,
} from '@becreatives/becreatives-ui';
import { DropdownToggle } from 'reactstrap';
import React from 'react';

const TaskItemStyled = styled.div<{ $status: number }>`
  background-color: ${({ theme, $status }) =>
    assertThemedColor(theme, $status ? 'success' : 'light', 'bgColor')};
  color: ${({ theme, $status }) =>
    assertThemedColor(theme, $status ? 'success' : 'light', 'color')};
  padding: 0.5rem 0.75rem;
  border-radius: 0.33rem;
  flex-shrink: 0;
  max-width: 100%;
  width: 200px;

  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const TaskItemCompleteTaskIcon = styled(RoundCheckmarkIcon)`
  flex-shrink: 0;
`;

const TaskItemText = styled(Text)`
  ${truncatesText};
  cursor: default;
`;

const TaskItemActionsDropdownToggle = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TaskItemCompleteActionCheckbox: React.FC<React.ComponentProps<'input'>> = ({
  className,
  ...props
}) => {
  const classNames = ['form-check-input border-light pointer'];
  if (className) {
    classNames.push(className);
  }

  return <input type={'checkbox'} className={classNames.join(' ')} {...props} />;
};

export {
  TaskItemStyled,
  TaskItemText,
  TaskItemCompleteTaskIcon,
  TaskItemActionsDropdownToggle,
  TaskItemCompleteActionCheckbox,
};
