import React, { useState } from 'react';
import { ModalProps } from 'reactstrap';
import { Button, ButtonProps } from '../Button';

type ModalPropsObject = Omit<ModalProps, 'onSubmit'>;

type Props<T extends ModalPropsObject = ModalPropsObject> = ButtonProps & {
  modal: React.ComponentType<T> | React.ComponentClass<T> | React.FunctionComponent<T>;
  modalProps?: Partial<Omit<T, 'isOpen' | 'toggle'>>;
  initialOpen?: boolean;
};

const ModalButton = <T extends ModalPropsObject>({
  modal: Modal,
  modalProps = {},
  initialOpen = false,
  onClick,
  ...props
}: Props<T>): JSX.Element => {
  const [isOpen, setOpen] = useState(initialOpen);
  const toggle = () => setOpen((prev) => !prev);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <>
      <Button {...props} onClick={handleClick}>
        {props.children}
      </Button>
      <Modal {...(modalProps as T)} isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export type { Props as ModalButtonProps };
export { ModalButton };
