import React from 'react';
import { Loader2 as BaseLoader2 } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const iconVariants = cva('tw-size-5 tw-animate-spin', {
  variants: {
    variant: {
      default: 'tw-text-primary',
      secondary: 'tw-text-secondary',
      secondaryForeground: 'tw-text-secondary-foreground',
      destructive: 'tw-text-destructive',
      unset: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type IconProps = React.ComponentProps<typeof BaseLoader2> &
  VariantProps<typeof iconVariants>;

export const Loader2: React.FC<IconProps> = ({ className, variant, ...props }) => (
  <BaseLoader2 {...props} className={cn(iconVariants({ variant }), className)} />
);
