import { defaultOptions, UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { TaskResponse } from 'src/api/services/TaskClient';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { PaginatedResponse } from 'src/api/Client';
import { authQueries, scopedQueries } from 'src/api/queries/queries';
import { Api } from 'src/api';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

const useRequestTasksQuery = (
  requestId: string,
  params?: ApiQueryObject,
  options?: UseQueryOptionsObject<TaskResponse>,
) => {
  return useMakeQuery<PaginatedResponse<TaskResponse>>(
    [authQueries.request, authQueries.task, requestId, scopedQueries.table, params],
    ({ signal }) => Api.request.filterTasks(requestId, params, { signal }),
    {
      staleTime: 60 * 1000,
      ...defaultOptions({
        auth: true,
        ...(options ?? {}),
      }),
    },
  );
};

export { useRequestTasksQuery };
