import { Channel } from 'laravel-echo';

class ModelEvent<T = unknown> {
  protected channel?: Channel;

  protected listener?: (broadcast: T) => void;

  public namespace = '';

  setChannel = (channel: Channel): this => {
    this.channel = channel;

    return this;
  };

  removeChannel = (): this => {
    this.channel = undefined;

    return this;
  };

  setListener = (callback: (broadcast: T) => void): this => {
    this.listener = callback;

    return this;
  };

  removeListener = (): this => {
    this.listener = undefined;

    return this;
  };

  listen = (): undefined | Channel => {
    return this.channel?.listen(this.namespace, (broadcast: T) => {
      this.listener?.(broadcast);
    });
  };

  close = (): undefined | Channel => {
    return this.channel?.stopListening(this.namespace);
  };
}

export default ModelEvent;
