import { useCallback } from 'react';
import { Api } from 'src/api';
import { FormMediaObject } from 'src/components/Form/S3UploadInput';
import { calculateChunks, S3Uploader } from 'src/services/s3-uploader';
import { useShallow } from 'zustand/react/shallow';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store/use-media-upload-store';

const THROTTLE_PROGRESS_UPDATE = 100;
const useMediaUpload = () => {
  const [isUploading, addUpload, updateProgress, finishUpload, setFailed, getStatus] =
    useMediaUploadStore(
      useShallow(
        ({ isUploading, addUpload, updateProgress, finishUpload, setFailed, getStatus }) => [
          isUploading,
          addUpload,
          updateProgress,
          finishUpload,
          setFailed,
          getStatus,
        ],
      ),
    );

  const generateUpload = useCallback(
    async (model: string, file: File, collection_name?: string): Promise<FormMediaObject> => {
      const data = {
        mime_type: file.type,
        name: file.name,
        size: file.size,
        chunks: calculateChunks(file.size),
        collection_name: collection_name,
      };

      const multiPartUpload = await Api.media.generateMultipartUpload(model, data);

      return {
        ...multiPartUpload.media,
        upload_id: multiPartUpload.upload_id,
        presigned_urls: multiPartUpload.presigned_urls,
        file,
      };
    },
    [],
  );

  const upload = useCallback(
    async (media: FormMediaObject, controller?: AbortController, onFail?: (error: any) => void) => {
      if (!media?.upload_id) {
        return;
      }

      if (isUploading(media.id)) {
        return;
      }

      addUpload({ id: media.id, name: media.file_name, uploadId: media.upload_id });

      try {
        const S3 = new S3Uploader(controller ?? getStatus(media.id)?.abortController);
        const uploadStartTime = Date.now();
        let lastUpdate = uploadStartTime;

        const uploadedData: { [chunk: number]: number } = {};

        const parts = await S3.upload(
          media.presigned_urls!,
          media.file!,
          (data: ProgressEvent, chunkIndex) => {
            uploadedData[chunkIndex] = data.loaded;

            // throttle progress update to avoid massive amount of store updates and re-renders at once.
            if (Date.now() - lastUpdate < THROTTLE_PROGRESS_UPDATE) {
              return;
            }

            const sum = Object.values(uploadedData).reduce((a, b) => a + b, 0);
            const percent = Math.round((sum / media.file!.size) * 100);

            // Calculate elapsed time
            const currentTime = Date.now();
            const elapsedTime = currentTime - uploadStartTime;

            // Calculate upload speed (bytes per millisecond)
            const uploadSpeed = sum / (elapsedTime / 1000);

            // Calculate remaining time in milliseconds
            const remainingBytes = media.file!.size - sum;
            const timeLeft = remainingBytes / uploadSpeed;

            updateProgress(media.id, percent, timeLeft, uploadSpeed);
            lastUpdate = Date.now();
          },
          onFail,
        );

        await Api.media.completeMultipartUpload(
          media.id,
          {
            upload_id: media.upload_id!,
            parts: parts,
          },
          controller ?? getStatus(media.id)?.abortController,
        );
      } catch (e) {
        setFailed(media.id);
        throw e;
      } finally {
        finishUpload(media.id);
      }
    },
    [],
  );

  return { generateUpload, upload };
};

export { useMediaUpload };
