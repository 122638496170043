import styled from 'styled-components';
import { breakpointUp, GridContainer, Nav } from '@becreatives/becreatives-ui';

const OrderInfoNav = styled(Nav)`
  padding: 0 2rem;
`;

const OrderInfoStatusCardGrid = styled(GridContainer)`
  padding: 2rem;
  grid-template-columns: 1fr 2fr;
  grid-row-gap: 2rem;

  ${breakpointUp.sm} {
    grid-template-columns: 1fr 3fr;
  }

  ${breakpointUp.md} {
    grid-template-columns: 1fr 5fr;
  }
`;

export { OrderInfoNav, OrderInfoStatusCardGrid };
