import { Http } from './Http';
import {
  AuthClient,
  HealthCheck,
  CompanyClient,
  CompanyPersonnelClient,
  NotificationClient,
  BrandClient,
  RequestClient,
  RevisionClient,
  CommentClient,
  MediaClient,
  TaskClient,
  TeamClient,
  TeamMemberClient,
  SubscriptionClient,
  SubscriptionAssignmentClient,
  MediaLinkClient,
} from './services';

const http = new Http();

const Api = {
  auth: new AuthClient(http),
  company: new CompanyClient(http),
  companyPersonnel: new CompanyPersonnelClient(http),
  brand: new BrandClient(http),
  request: new RequestClient(http),
  revision: new RevisionClient(http),
  comment: new CommentClient(http),
  health: new HealthCheck(http),
  notification: new NotificationClient(http),
  media: new MediaClient(http),
  sharedMedia: new MediaLinkClient(http),
  task: new TaskClient(http),
  team: new TeamClient(http),
  teamMember: new TeamMemberClient(http),
  subscription: new SubscriptionClient(http),
  subscriptionAssignments: new SubscriptionAssignmentClient(http),
  http,
};

export { Api, http as Http };
